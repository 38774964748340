import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useForgotPassword } from '../../hooks/authHooks';
import HighMarkError from '../../assets/svg/HighMarkError';
import CloseIcon from '@mui/icons-material/Close';
import CustomLoginButton from '../commonComponents/CustomLoginButton';
import Require from '../commonComponents/Required';
import SmartShopLogowhite from '../../assets/images/SmartShopLogowhite.png';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('enter correct email').required('email is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '4px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  // background: 'linear-gradient(180deg, #265AA8 0%, #AACCFF 104.19%)',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  /* height: '100vh', */
  // overflow: 'auto',
  '.left-contained': {
    background: '#0160B9',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  '.right-contained': {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.vector-workflow-box': {
    marginTop: '114.92px',
    paddingRight: '12%',
    // paddingLeft: '21%',
  },
  '.workFlow-title-box': {
    position: 'absolute',
    bottom: '86px',
  },
  '.workFlow-title': {
    color: '#ffffff',
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: '72px',
  },
  // '.login-box': {
  //   //height: '450px',
  //   width: '503px',
  //   justifyContent: 'center',
  // },
  '.head-title': {
    fontSize: '24px',
    fontWeight: '600',
  },
  '.forgot-password': {
    color: '#0160B9',
    fontWeight: '600',
    fontSize: '14px',
    marginLeft: '32px',
    marginTop: '10px',
  },
  '.head-sub-title': {
    fontSize: '16px',
    fontWeight: '400',
    color: '#8A8A8A',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 6px 0px',
    color: '#020202',
    //marginTop: '15px',
  },
  '.input-field': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px',
  },
  '.login-btn-box': {
    display: 'flex',
    // justifyContent: 'center',
    marginTop: '24px',
  },
  '.left-box': {
    width: '10px',
    height: '84px',
    background: '#CC0000',
  },
  '.left-box-success': {
    width: '10px',
    height: '84px',
    background: 'green',
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC',
  },
  '.right-box-success': {
    width: '493px',
    height: '52px',
    background: '#90EE90',
  },
  '.close-icon': {
    cursor: 'pointer',
  },
}));

const ForgotPassword = () => {
  const [errorBox, setErrorBox] = React.useState<boolean>(false);
  const handleClose = () => setErrorBox(false);
  const handleOpen = () => setErrorBox(true);
  const [successBox, setSuccessBox] = React.useState<boolean>(false);
  const handleSuccessClose = () => setSuccessBox(false);
  const handleSuccessOpen = () => setSuccessBox(true);
  //   const { mutate: addLogin } = useLogin(handleOpen);
  const { mutate: forgotPassword } = useForgotPassword(handleOpen, handleSuccessOpen);

  const icon = <AiOutlineInfoCircle color='red' />;

  return (
    <React.Fragment>
      <HeadBox>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            forgotPassword(values);
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, isValid, dirty }) => (
            <Grid container>
              <Grid item xs={6} className='left-contained'>
                <Box className='vector-workflow-box'>
                  {/* TODO: old logo */}
                  {/* <Box display={'flex'}>
                    <Box>
                      <Vector />
                    </Box>
                    <Box ml={'13px'} pt={'17px'}>
                      <WorkFlow />
                    </Box>
                  </Box> */}
                  <Box display={'flex'}>
                    <Box>
                      <img src={SmartShopLogowhite} height={'100%'} width={'100%'} />
                    </Box>
                  </Box>
                  <Box className='workFlow-title-box'>
                    <Typography className='workFlow-title'>Powerful</Typography>
                    <Typography className='workFlow-title'>Automation</Typography>
                    <Typography className='workFlow-title'>Made Simple.</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} className='right-contained'>
                <Form onSubmit={handleSubmit}>
                  <Box className='login-box'>
                    <Box>
                      <Typography className='head-title'>Welcome</Typography>
                      <Typography className='head-sub-title'>
                        {/* Login to account using your User Id */}
                        Reset your Password using your register Email id.
                      </Typography>
                      {/* <Typography className='head-sub-title'>
                        and Password provided by your organization.
                      </Typography> */}
                    </Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box>
                          <Typography className='field-title' mt={'33px'}>
                            User ID
                            <Require />
                          </Typography>

                          <Input
                            placeholder='Enter your User Id'
                            fullWidth
                            className='input-field'
                            onChange={handleChange}
                            name='email'
                            InputProps={{
                              endAdornment: touched.email && errors.email && icon,
                            }}
                          />
                          {touched.email && errors.email && (
                            <Typography className='validation-text'>{errors.email}</Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className='login-btn-box'>
                      {errorBox ? (
                        <>
                          <Box display={'flex'}>
                            <Box className='left-box'></Box>
                            <Box className='right-box' p={2}>
                              <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                  <Box display={'flex'}>
                                    <HighMarkError />
                                    <Typography className='field-tit' ml={2}>
                                      You have entered incorrect email Id
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography className='field-title' ml={6}>
                                      Please try again or contact the admin
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box onClick={() => handleClose()} className='close-icon'>
                                  <CloseIcon />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : successBox ? (
                        <>
                          <Box display={'flex'}>
                            <Box className='left-box-success'></Box>
                            <Box className='right-box-success' p={2}>
                              <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                  <Box display={'flex'}>
                                    {/* <HighMarkError /> */}
                                    <Typography className='field-tit' ml={2}>
                                      An email will be sent to the user regarding the same, the user
                                      can setup a new password using the link in the email.
                                    </Typography>
                                  </Box>
                                  {/* <Box>
                                    <Typography className='field-title' ml={6}>
                                      Please try again or contact the admin
                                    </Typography>
                                  </Box> */}
                                </Box>
                                <Box onClick={() => handleSuccessClose()} className='close-icon'>
                                  <CloseIcon />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>
                          <CustomLoginButton
                            type='submit'
                            className={!(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                          >
                            Enter your Email
                          </CustomLoginButton>
                        </>
                      )}
                    </Box>
                  </Box>
                </Form>
              </Grid>
            </Grid>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  );
};
export default ForgotPassword;
