import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { Typography, Button, Select, Grid, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import Require from '../pages/commonComponents/Required';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'rc-time-picker/assets/index.css';
import CustomButton from '../pages/commonComponents/CustomButton';
import { useAddTaskWorkOrder, useUpdateTaskWorkOrder } from '../hooks/taskWorkOrderHooks';

const validationDialogSchema = Yup.object().shape({
  operation: Yup.string().trim().required('Title is Required'),
  face: Yup.string().required('Time is Required'),
  vendorName: Yup.string().required('Vender Name is Required'),
  expectedDate: Yup.string().required('select time is Required'),
  expected: Yup.string().required('select time is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const MainBox = styled(Box)(() => ({
  '.dialog-contain': {
    padding: '20px 0px',
  },
  '.padding-box': {
    padding: '0px 24px',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.btn-box': {
    display: 'flex',
    justifyContent: 'end',
    margin: '3px 20px 30px 0px',
  },
  '.view-checklist': {
    cursor: 'pointer',
    marginTop: '8px',
    color: '#0160B9',
  },
  '.view-checklist-text': {
    cursor: 'pointer',
    color: '#0160B9',
    fontSize: '14px !important',
    fontWeight: 600,
    padding: '0px 8px',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  },
  '.btn-text-transform': {
    textTransform: 'capitalize !important',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 888,
  height: 804,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '50px 20px 30px 30px !important',
  },
  '.rediobutton': {
    margin: '0px 10px 10px 10px !important',
  },
  '.task-pandding': {
    padding: '20px 56px 18px 25px !important',
  },
};
const HeadBox = styled(Box)(() => ({
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.task-pandding': {
    padding: '20px 56px 18px 25px !important',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.fildborder': {
    border: '1px solid #8A8A8A',
    borderColor: '#8A8A8A',
    borderRadius: '4px',
    padding: '7px 20px 7px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
    backgroundColor: '#F6F6F6',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.task-mr-23': {
    marginRight: '70px',
    fontSize: 14,
  },
  '.task-mr-25': {
    marginRight: '15px',
    fontSize: 14,
  },
  '.task-mr-24': {
    marginRight: '60px',
    fontSize: 14,
  },
  '.task-delete-icon': {
    cursor: 'pointer',
    marginLeft: '50px',
  },
  '.mr-30': {
    marginTop: '30px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    height: '28px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.uploadreferencesIcon-3': {
    color: '#020202',
  },
}));
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface PropI {
  handleClose: () => void;
  addScheduleData: any;
  taskListType: string;
  currentTask: any;
  setCurrentSchedule: any;
  requestCount?: number;
  changeReqCount: any;
}
const OutsourcedDetailsConfirm = ({ currentTask, taskListType, handleClose }: PropI) => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = React.useState(false);
  React.useEffect(() => {
    if (currentTask) {
      setIsEdit(true);
    }
  }, [currentTask]);
  const { mutate: addTaskWorkorder } = useAddTaskWorkOrder();
  const { mutate: updateTaskWorkorder } = useUpdateTaskWorkOrder();
  // ! for future use
  // const currentSchedule = props?.currentSchedule;

  // if (currentSchedule.id) {
  //   currentSchedule.mid = currentSchedule.id;
  // }
  // const initialValues = {
  //   mid: _.get(currentSchedule, 'mid', useId()),
  //   maintenanceType: 'daily',
  //   frequency: 'daily',
  //   documentList: _.get(currentSchedule, 'documentList', []),
  //   title: _.get(currentSchedule, 'title', ''),
  //   time: _.get(currentSchedule, 'time', ''),
  //   checklist: _.get(currentSchedule, 'checklist', ''),
  //   checklistId: _.get(currentSchedule, 'checklistId', ''),
  //   startDate: _.get(currentSchedule, 'startDate', ''),
  //   selectTime: _.get(currentSchedule, 'selectTime', ''),
  //   operation: '',
  //   face: '',
  //   operator: '',
  //   manufacturer: '',
  //   dateBought: '',
  //   category: '',
  //   machine: '',
  //   instructions: '',
  //   toolCategory: '',
  //   accessories: '',
  //   tool: '',
  // };
  // const requestNumber = `MR-${1000 + (props?.requestCount || 0) + (props?.requestCount ? 1 : 1)}`;
  // TODO:- CUSTOM DATE PICKER
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onCancel, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Cancel', method: onCancel },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onCancel()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  return (
    <React.Fragment>
      <HeadBox className='task-pandding'>
        <Formik
          initialValues={{
            operation: isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.stepName : '',
            face: isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.face : '',
            vendorName: isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.vendorName : '',
            expectedDate: isEdit ? currentTask?.nodeInfo?.expectedDate : '',
            expected: isEdit ? currentTask?.expected : '',
          }}
          enableReinitialize={true}
          validationSchema={validationDialogSchema}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              workOrder: id,
              mainTaskType: 'out-sourced',
              taskListType: taskListType,
            };
            if (isEdit && currentTask?.id) {
              updateTaskWorkorder({ ...finalValues, id: currentTask?.id });
            } else {
              addTaskWorkorder(finalValues);
            }
            localStorage.removeItem('isRework');
            // props.addScheduleData((prevData) => {
            //   const updateData = prevData.find((data) =>
            //     // prevData?.id ? prevData?.id : data.mid === prevData?.id ? prevData?.id : values.mid
            //     {
            //       if (prevData?.id) {
            //         return prevData?.id === values.mid;
            //       } else {
            //         return data.mid === values.mid;
            //       }
            //     }
            //   );
            //   if (_.isEmpty(updateData)) {
            //     return [
            //       ...prevData,
            //       {
            //         ...values,
            //         requestNumber: `MR-${
            //           1000 + (props?.requestCount || 0) + (props?.requestCount ? 1 : 1)
            //         }`,
            //       },
            //     ];
            //   } else {
            //     const filteredData = prevData.map((data) => {
            //       if (data?.mid === values?.mid) {
            //         return {
            //           ...data,
            //           ...values,
            //         };
            //       } else if (updateData?.id === data?.id && !!updateData?.id && !!data?.id) {
            //         return {
            //           ...data,
            //           ...values,
            //         };
            //       } else {
            //         return data;
            //       }
            //     });
            //     return filteredData;
            //     // const filteredData = prevData.filter((data) => {
            //     //   if (updateData?.id) {
            //     //     return updateData?.id !== data?.id;
            //     //   } else {
            //     //     return data?.mid !== values?.mid;
            //     //   }
            //     // });
            //     // return [
            //     //   ...filteredData,
            //     //   {
            //     //     ...values,
            //     //     requestNumber: `MR-${
            //     //       1000 + (props?.requestCount || 0) + (props?.requestCount ? 1 : 1)
            //     //     }`,
            //     //   },
            //     // ];
            //   }
            // });
            // if (!currentSchedule?.requestNumber) {
            //   props.changeReqCount((prev) => prev + 1);
            // }
            // props.setCurrentSchedule();
            // props.handleClose();
          }}
        >
          {({ errors, touched, values, handleChange, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {/* <Typography className='title-typography'> Enter Tools Details</Typography> */}
              <Grid container spacing={2}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography className='field-title'>
                    Operation
                    <Require />
                  </Typography>
                  <InputField
                    error={!errors.operation || !touched.operation ? false : true}
                    placeholder='Enter Operation'
                    fullWidth
                    onChange={handleChange}
                    name='operation'
                    value={values?.operation}
                    // InputProps={{
                    //   endAdornment: touched.name && errors.name && icon,
                    // }}
                  />
                  {touched.operation && errors.operation && (
                    <Typography className='validation-text'>
                      {typeof errors.operation === 'string' ? errors.operation : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography className='field-title'>
                    Face
                    <Require />
                  </Typography>
                  <InputField
                    error={!errors.face || !touched.face ? false : true}
                    placeholder='Enter Face'
                    fullWidth
                    onChange={handleChange}
                    name='face'
                    value={values?.face}
                    // InputProps={{
                    //   endAdornment: touched.face && errors.face && icon,
                    // }}
                  />
                  {touched.face && errors.face && (
                    <Typography className='validation-text'>
                      {typeof errors.face === 'string' ? errors.face : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography className='field-title'>
                    Vendor Name <Require />{' '}
                  </Typography>
                  <InputField
                    error={!errors.vendorName || !touched.vendorName ? false : true}
                    placeholder='Enter Vendor Name'
                    fullWidth
                    onChange={handleChange}
                    name='vendorName'
                    value={values.vendorName}
                    // InputProps={{
                    //   endAdornment: touched.vendorname && errors.vendorname && icon,
                    // }}
                  />
                  {touched.vendorName && errors.vendorName && (
                    <Typography className='validation-text'>
                      {typeof errors.vendorName === 'string' ? errors.vendorName : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={'5px'}>
                <Grid item xs={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Expected Time (hh:mm)
                    <Require />
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      hideTabs
                      className='date-picker'
                      openTo='year'
                      views={['hours', 'minutes']}
                      value={values.expected || defaultDateTime}
                      inputFormat='hh:mm A'
                      onChange={(newValue: any) => {
                        setFieldValue('expected', newValue?.toDate());
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder='Select time'
                            className='date-picker-fieldset'
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      name='expected'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Expected Date
                    <Require />
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // disablePast={isEdit ? false : false}
                      // hideTabs
                      orientation='portrait'
                      dayOfWeekFormatter={(day) => `${day}`}
                      className='date-picker'
                      // openTo='year'
                      views={['year', 'day', 'month']}
                      closeOnSelect={false}
                      value={values?.expectedDate || dayjs(new Date())}
                      inputFormat='DD/MM/YYYY'
                      onChange={(newValue: any) => {
                        setFieldValue('expectedDate', newValue?.toDate());
                      }}
                      components={{
                        ActionBar: ActionList,
                        // OpenPickerIcon: ClockIcon,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ['today', 'cancel', 'clear'],
                        },
                      }}
                      // renderInput={(params) => {
                      //   return <TextField {...params} placeholder='Select Date' />;
                      // }}
                      renderInput={(params) => {
                        if (values?.expectedDate) {
                          return (
                            <TextField
                              {...params}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                            />
                          );
                        } else {
                          delete params['inputProps'];
                          return (
                            <TextField
                              {...params}
                              disabled={true}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                              error={false}
                            />
                          );
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    name='expectedDate'
                    render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                  />
                </Grid>
              </Grid>
              <Box className='button-box'>
                <Box sx={{ padding: '20px' }}>
                  <CustomModalButton
                    sx={{ width: '116px' }}
                    variant='outlined'
                    className='blue-color edit-btn'
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);
                      localStorage.removeItem('isRework');
                    }}
                  >
                    Cancel
                  </CustomModalButton>
                </Box>
                <Box
                  sx={{
                    padding: '20px',
                  }}
                >
                  <CustomButton variant='contained' className={'blue-bg'} type='submit'>
                    Save Changes
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  );
};
export default OutsourcedDetailsConfirm;
