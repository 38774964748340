import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { partApi } from '../api/part';
import { part, tool, toolCategoryTypeMap } from '.././queryKeys/catalogue';
import { PartErrI } from '../Types/part';
import { ToolErrI } from '../Types/tool';

export const useGetPart = (id, isEnable = true) => {
  return useQuery([part.PART, id], partApi.getSinglePart, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetDeletedPart = (id, isEnable = true) => {
  return useQuery([tool.TOOL + 'DELETED', id], partApi.getSingleDeletedPart, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetPartSingle = (id, isEnable = true) => {
  return useQuery([part.PART, id], partApi.getSingleParts, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetParts = (isEnabled = false, page, toolCategoryTypes) => {
  return useQuery([part.PARTS, page, toolCategoryTypes], partApi.getParts, {
    // initialData: { parts: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullGetParts = (isEnabled) => {
  return useQuery([part.PARTS + 'FULL'], partApi.getFullParts, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res) => res?.parts,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullToolHolder = (isEnabled) => {
  return useQuery([part.PARTS + 'FULL'], partApi.getFullToolHolder, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullToolType = (isEnabled) => {
  return useQuery([tool.TOOL_TYPE_FULL + 'FULL'], partApi.getFullToolType, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullToolAccessories = (isEnabled) => {
  return useQuery([tool.TOOL_ACCESSORIES_FULL + 'FULL'], partApi.getFullToolAccessories, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useFullToolInsert = (isEnabled) => {
  return useQuery([tool.TOOL_INSERT_FULL + 'FULL'], partApi.getFullToolInsert, {
    initialData: { parts: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddPart = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.addPart, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries([tool.TOOL]);
      // queryClient.invalidateQueries([tool.TOOL_INSERT_FULL]);
      const data = res.data;
      const toolCategoryType = toolCategoryTypeMap[data?.toolCategoryType || 'Tool Holder'];
      // navigate(`/tools`, { state: { activeTab: '1' } });
      navigate(`/tools`, { state: { activeTab: toolCategoryType } });
    },
    onError: (err: ToolErrI) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useUpdatePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.updatePart, {
    onSuccess: (res: any, id) => {
      const data = res.data;
      queryClient.invalidateQueries([tool.TOOLS]);
      queryClient.invalidateQueries([tool.TOOL, id]);
      const toolCategoryType = toolCategoryTypeMap[data?.toolCategoryType || 'Tool Holder'];
      // navigate(`/tools`, { state: { activeTab: '1' } });
      navigate(`/tools`, { state: { activeTab: toolCategoryType } });
      // navigate(
      //   materialType == 'Part'
      //     ? `/part-details/${data?.part?.id}`
      //     : `/raw-material-details/${data?.part?.id}`
      // );
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useRestorePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.restorePart, {
    onSuccess: () => {
      queryClient.invalidateQueries([part.PARTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useHardDeletePart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.hardDeletePart, {
    onSuccess: () => {
      queryClient.invalidateQueries([part.PARTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
  });
};

export const useDeletePart = (isPart = true) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(partApi.deletePart, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.removeQueries([part.PART, id]);
      navigate(`/customer-Order`, { state: { activeTab: isPart ? '1' : '4' } });
    },
  });
};

export const useDeleteTool = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.deleteTool, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.invalidateQueries([part.PART, id]);
      // toast.success(data?.message);
      navigate(`/tools`, { state: { activeTab: '1' } });
    },
  });
};

export const useHardDeleteTool = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.hardDeleteTool, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.invalidateQueries([part.PART, id]);
      navigate(`/deleted-items`);
    },
  });
};
export const useRestoreDeleteTool = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(partApi.restoreTool, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([part.PARTS]);
      queryClient.invalidateQueries([part.PART, id]);
      navigate(`/deleted-items`);
    },
  });
};
export const useValidateToolName = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(partApi.validateToolName, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    //toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  });
};
