import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TableComponent from '../commonComponents/Table';
import moment from 'moment';

interface Props {
  taskData: any;
}

const TaskDetailsView = (props: Props) => {
  const { taskData } = props;
  const columnData = taskData?.taskresponses?.response?.measurements?.[0]?.dimensions || [];

  const measurementsCols = [{ field: 'measurements', headerName: 'Measurements', flex: 1 }]?.concat(
    columnData?.map((data) => {
      return {
        field: data?.name,
        headerName: data?.name,
        flex: 1,
      };
    })
  );

  const measurementsRows = taskData?.taskresponses?.response?.measurements?.map((obj, index) => {
    const measurements = {
      id: index + 1,
      no: index + 1,
      measurements: obj.title,
    };

    obj?.dimensions.forEach((dim) => {
      measurements[dim.name] = dim.value;
    });

    return measurements;
  });

  const taskMeasurementsData = {
    columns: measurementsCols,
    rows: measurementsRows,
  };

  return (
    <React.Fragment>
      <Grid className='details-sub-mr1'>
        <Typography id='modal-modal-title' className='details-sub-title'>
          Task : {taskData?.nodeInfo?.stepName ? taskData?.nodeInfo?.stepName : '-'}
        </Typography>
      </Grid>
      <Grid container xs={12} spacing={2} className='details' mt={'10px'}>
        <Grid item xs={4} display={'flex'}>
          <Box className='details-color'>
            <Typography className='details-mr1'>Face</Typography>
            <Typography className='details-mr1'>Operation</Typography>
            <Typography className='details-mr1'>Machine</Typography>
            <Typography className='details-mr1'>Operator</Typography>
          </Box>
          <Box className='details-mr3'>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.face ? taskData?.nodeInfo?.face : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.stepName ? taskData?.nodeInfo?.stepName : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.machine?.name ? taskData?.nodeInfo?.machine?.name : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.worker?.name ? taskData?.nodeInfo?.worker?.name : '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4} display={'flex'}>
          <Box>
            <Typography className='details-mr1'>Tool Category</Typography>
            <Typography className='details-mr1'>Tools</Typography>
            <Typography className='details-mr1'>Accessories</Typography>
            <Typography className='details-mr1'>Approved by</Typography>
          </Box>
          <Box>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.toolCategory?.name
                ? taskData?.nodeInfo?.toolCategory?.name
                : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.tool?.name ? taskData?.nodeInfo?.tool?.name : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.accessories?.name ? taskData?.nodeInfo?.accessories?.name : '-'}
            </Typography>
            <Typography className='details-mr6'>-</Typography>
          </Box>
          {/* </Grid> */}
        </Grid>
        <Grid item xs={4} display={'flex'}>
          <Box>
            <Typography className='details-mr1'>Started on</Typography>
            <Typography className='details-mr1'>Completed on</Typography>
            <Typography className='details-mr1'>Expected Time</Typography>
            <Typography className='details-mr1'>Time Taken</Typography>
          </Box>
          <Box>
            <Typography className='details-mr6'>
              {taskData ? moment(taskData?.startTime).format('hh:mm A, DD MMM, YYYY') : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData ? moment(taskData?.endTime).format('hh:mm A, DD MMM, YYYY') : '-'}
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.expectedTime
                ? (taskData?.expectedTime / (1000 * 60 * 60)).toFixed(1)
                : '-'}{' '}
              hrs
            </Typography>
            <Typography className='details-mr6'>
              {taskData?.actualTime ? (taskData?.actualTime / (1000 * 60 * 60)).toFixed(1) : '-'}{' '}
              hrs
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={12} display={'flex'}>
          <Box pl={'15px'}>
            <Typography className='details-mr1'>Instruction</Typography>
          </Box>
          <Box>
            <Typography className='details-mr6'>
              {taskData?.nodeInfo?.instructions ? taskData?.nodeInfo?.instructions : '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* //! new one */}
      <Grid container display={'flex'} mt={'20px'} mb={'10px'}>
        {taskData?.tickets?.map((issue, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Grid item container xs={12} className='process-header checklist-details'>
                <Grid item xs={12} textAlign={'center'}>
                  <Typography className='process-header-field-title'>Issue</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className='border-items checklist-details'>
                <Grid item xs={6} textAlign={'center'} className='field-title'>
                  <Typography className='details-mr9'>Nature of issue</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                  <Typography className='details-mr10'>{issue?.typeOfIssue}</Typography>
                </Grid>

                <Grid item xs={6} textAlign={'center'} className='field-title'>
                  <Typography className='details-mr9'>Reported by</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                  <Typography className='details-mr10'>{issue?.createBy?.name}</Typography>
                </Grid>

                <Grid item xs={6} textAlign={'center'} className='field-title'>
                  <Typography className='details-mr9'>Reported On</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                  <Typography className='details-mr10'>
                    {issue?.createdAt
                      ? moment(issue?.createdAt).format('hh:mm A, DD MMM, YYYY')
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'} className='field-title'>
                  <Typography className='details-mr9'>Resolved by</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                  <Typography className='details-mr10'>{issue?.updateBy?.name}</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'} className='field-title'>
                  <Typography className='details-mr9'>Resolved on</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                  <Typography className='details-mr10'>
                    {issue?.updatedAt
                      ? moment(issue?.updatedAt).format('hh:mm A, DD MMM, YYYY')
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {taskMeasurementsData?.rows?.length > 0 && (
        <Grid container display={'flex'} sx={{ padding: '30px !important' }}>
          <TableComponent
            tableData={taskMeasurementsData || []}
            isOptionShow={true}
            isEditStock={true}
            isShowFiveRecords={true}
            moduleName={'tool'}
            redirectPage={'/add-tool'}
            isFilter={false}
            iSColumnsButton={false}
          />
        </Grid>
      )}
      <Box pl={'15px'}>
        <Typography className='details-mr1'>Evidence</Typography>
      </Box>
      <Grid container spacing={2} sx={{ m: 2 }}>
        {taskData?.nodeInfo?.uploadedData?.length > 0 &&
          taskData?.nodeInfo?.uploadedData?.map((data, ind) => {
            return (
              <Grid item md={6} key={ind}>
                <img src={data?.url} alt='No images found' width='85%' />
              </Grid>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

export default TaskDetailsView;
