import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Divider, FormControl, Select, Typography, MenuItem } from '@mui/material';
import CustomButton from '../commonComponents/CustomButton';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import Require from '../commonComponents/Required';
import { useFullGetChecklists } from '../../hooks/checklistHooks';
import { useGetFullRawMaterials } from '../../hooks/rawMaterialHooks';
import { useParams } from 'react-router-dom';
import { useGetWorkorder, useUpdateWorkorder } from '../../hooks/workorderHooks';
import { useFullGetDesktopUsers } from '../../hooks/userManagementHooks';
import UploadDocument from '../commonComponents/UploadDocument';

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const HeadBox = styled(Box)(() => ({
  height: 'calc(85vh - 10px) !important',
  overflowY: 'auto',

  // Media query for screens with a height between 425px and 500px
  '@media (min-height: 425px) and (max-height: 500px)': {
    height: 'calc(75vh - 113px) !important',
  },

  // Media query for screens with a height between 501px and 600px
  '@media (min-height: 501px) and (max-height: 600px)': {
    height: 'calc(70vh - 10px) !important',
  },

  // Media query for screens with a height between 601px and 700px
  '@media (min-height: 601px) and (max-height: 700px)': {
    height: 'calc(79vh - 5px) !important',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.addworkorderbutton-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  // '.rm-footer-padding': {
  //   padding: '16px 0px 10px 20px',
  // },
  '.border': {
    border: '1px solid #8A8A8A',
    padding: '20px 10px 18px 30px',
    marginTop: '10px',
  },
  '.fildborder': {
    border: '1px solid #8A8A8A',
    borderColor: '#8A8A8A',
    borderRadius: '4px',
    padding: '7px 20px 7px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
  },
  '.work-divider-mr-1': {
    margin: '25px 0px 20px 0px',
  },
  '.button-mr-2': {
    margin: '25px 0px 20px 0px',
  },
  '.borderMargin': { padding: '10px 02px 0px 0px' },
  '.buttonFlex': {
    display: 'flex',
    padding: '0px 0px',
  },
  '.title-basic': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '20px 0px 18px 0px !important',
    fontWeight: '600 !important',
  },
  '.details': {
    // display: 'flex',
    // padding: '0px 0px'
  },
  '.details-color': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 10px 0px',
    color: '#8A8A8A',
  },
  '.details-mr1': {
    fontSize: 14,
    fontWeight: 500,
    border: '1px solid rgba(205, 205, 205, 1)',
    height: '30px',
    color: '#020202',
    padding: '8px 5px 0px 5px',
    backgroundColor: 'rgba(246, 246, 246, 1)',
  },
  '.details-box-mr2': {
    border: '1px solid rgba(205, 205, 205, 1)',
    height: '80px',
    color: '#020202',
    padding: '7px 5px 0px 5px',
    backgroundColor: 'rgba(246, 246, 246, 1)',
    // display: 'flex',
    // mr: '10px',
    // justifyContent: 'space-between',
  },
  '.details-mr2': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 18px 0px',
    color: '#020202',
  },
  '.MuiDivider-root': {
    margin: '0px 0px !important',
  },
  '.work-divider-mr-2': {
    paddingLeft: '1px',
    paddingTop: '0px',
    margin: '0px 0px !important',
  },
  '.table-mr1': {
    margin: '0px 0px 10px 0px',
  },
  '.mr-18': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.mr-19': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 20px 0px !important',
    fontWeight: '600 !important',
  },
  '.menu-item-span': {
    color: '#9E9E9E !important',
  },
  '.mr-23': {
    marginRight: '315px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.box-product': {
    fontSize: '14px !important',
    marginBottom: '5px',
    color: 'rgba(29, 27, 27, 1)',
    fontWeight: 600,
  },
  '.box-product-mr2': {
    fontSize: '12px !important',
    color: 'rgba(29, 27, 27, 1)',
    fontWeight: 500,
  },
  '.mr-31': {
    marginRight: '299px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.mr-30': {
    marginTop: '30px',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
}));
const NewWorkOrder = () => {
  const { id } = useParams();
  const { data: getChecklists } = useFullGetChecklists(true);
  const { data: getRawMaterials } = useGetFullRawMaterials(true);
  const { data: getWorkOrder } = useGetWorkorder(id, !!id);
  const singleWorkOrder = getWorkOrder;
  const { mutate: updateWorkOrder } = useUpdateWorkorder();
  const { data: desktopUsers } = useFullGetDesktopUsers(true);
  const breadCrumbData = [
    {
      title: 'Work Orders',
      link: '/customer-Order',
    },
    {
      title: `New Work Order`,
      link: '/add-customer-order',
    },
  ];
  return (
    <>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`Work Order`}
        backToLink='/catalogue'
        activeTab='4'
      />
      <HeadBox className='main-component-padding'>
        <Typography className='title-typography'> Enter Customer Order Details</Typography>
        <Grid container item xs={12} spacing={2} className='' mt={'10px'}>
          <Grid item xs={4} md={6} lg={4} className='details'>
            <Typography className='details-color'>Work Order Name</Typography>
            <Typography className='details-mr1'>
              {_.get(singleWorkOrder?.workOrder, 'title', '')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={6} lg={4} className='details'>
            <Typography className='details-color'>Customer Name</Typography>
            <Typography className='details-mr1'>
              {_.get(singleWorkOrder, 'workOrder.customer', '')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={6} lg={4} className='details'>
            <Typography className='details-color'>Remarks</Typography>
            <Typography className='details-mr1'>
              {_.get(singleWorkOrder, 'workOrder.remarks', '')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={11} spacing={2} className='' mt={'10px'}>
          <Grid item xs={12} className='details' display={'flex'} ml={'1px'} container spacing={2}>
            <Grid xs={12}>
              <Typography className='details-color'>Product Details</Typography>
            </Grid>
            {singleWorkOrder?.workOrder?.productDetails?.map((data, index) => {
              return (
                <Grid display={'flex'} ml={'0px'} mr={'15px'} key={index}>
                  <Grid className='details-box-mr2'>
                    <Typography className='box-product'>{data?.product}</Typography>
                    <Typography className='box-product-mr2'>
                      Quatity:<span className='product-header'>{data?.quantity}</span>
                    </Typography>
                    <Typography className='box-product-mr2'>
                      Promised Date:{' '}
                      <span className='product-header'>
                        {data?.deliveryDatePromised
                          ? new Date(data?.deliveryDatePromised)?.toLocaleDateString('en-US')
                          : '-'}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* //! for future use */}
        {/* <Grid container spacing={2}>
            {' '}
            <Grid item xs={12} className='work-divider-mr-1'>
              <Divider />{' '}
            </Grid>{' '}
          </Grid> */}
        {/* <Grid>
            <Typography className='title-typography'> Product Details</Typography>
            <Grid container item xs={5.1} spacing={2} className='table-mr1' mt={'10px'}>
              <Grid item xs={4} md={6} lg={4} className='details'>
                <Typography className='details-mr2'>Product</Typography>
              </Grid>
              <Grid item xs={4} md={6} lg={4} className='details'>
                <Typography className='details-mr2'>Quantity</Typography>
              </Grid>
              <Grid item xs={4} md={6} lg={4} className='details'>
                <Typography className='details-mr2'>Delivery Date Promised</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {' '}
              <Grid item xs={5.1} className='work-divider-mr-2'>
                <Divider />{' '}
              </Grid>{' '}
            </Grid>
          </Grid> */}
        {/* <Grid>
            <Grid container item xs={5.1} spacing={2} className='table-mr1' mt={'10px'}>
              <Grid item xs={4} md={6} lg={4} className=''>
                <Typography className='details-color'>product</Typography>
              </Grid>
              <Grid item xs={4} md={6} lg={4} className=''>
                <Typography className='details-mr2'>10</Typography>
                <Typography className='details-mr2'>10</Typography>
                <Typography className='details-mr2'>10</Typography>
                <Typography className='details-mr2'>10</Typography>
              </Grid>
              <Grid item xs={4} md={6} lg={4} className=''>
                <Typography className='details-mr2'>11/11/11</Typography>
              </Grid>
            </Grid>
          </Grid> */}
        <Grid container spacing={2}>
          {' '}
          <Grid item xs={12} className='work-divider-mr-1'>
            <Divider />{' '}
          </Grid>{' '}
        </Grid>

        <Formik
          initialValues={{
            // partMaterialType: isEdit ? singlePart?.partMaterialType,
            // drawingfiles: '',
            qcChecklist: '',
            // checklist: '',
            rawMaterial: '',
            approver: '',
            drawingFiles: [
              // {
              //   name: 'String',
              //   url: 'String',
              //   fileType: 'String',
              // },
            ],
          }}
          enableReinitialize={true}
          //   validationSchema={SignupSchema}
          // onSubmit={(values) => {
          // const isVariantAdd = window.location.pathname.startsWith('/part/add-variant');
          // setOpenModal(true);
          // setValue({
          //   ...values,
          // });
          // useUpdateWorkorder
          onSubmit={(values) => {
            updateWorkOrder({ id, ...values });
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    Drawing files
                    <Require />
                  </Typography>
                  {/* //! ol file upload */}
                  {/* <UploadDocument
                    name={values?.drawingFiles}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isAttachment={true}
                    rawMaterialName='Attachment'
                  /> */}
                  <Box className='manufacturingOrderHead'>
                    <UploadDocument
                      name={values?.drawingFiles}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isAttachment={true}
                      createFileName='drawingFiles'
                    />
                  </Box>
                  {/* <Button
                    component='label'
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgba(239, 245, 255, 1)',
                    }}
                  >
                    <input
                      type='file'
                      hidden
                      onChange={handleChange}
                      placeholder='Enter Details'
                      name='drawingfiles'
                      // value={values?.drawingFiles}
                      // value={values?.batch?.[index]?.document?.[index]?.name}
                    />
                    <Box className='uploadAttachmentIcon'>
                      <Box className='uploadAttachmentIconChild'>Upload Attachment</Box>
                      <Box className='uploadAttachmentIconChild2'>
                        <FileUploadOutlinedIcon />
                      </Box>
                    </Box>
                  </Button> */}
                  {/* {touched.drawingfiles && errors.drawingfiles && (
                    <Typography className='validation-text'>
                      {typeof errors.drawingfiles === 'string' ? errors.drawingfiles : ''}
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    QC Checklist
                    <Require />
                  </Typography>
                  {/* <InputField
                        error={!errors.qcChecklist || !touched.qcChecklist ? false : true}
                        placeholder='Enter QC Checklist'
                        fullWidth
                        onChange={handleChange}
                        name='qcChecklist'
                        value={values.qcChecklist}
                        // InputProps={{
                        //   endAdornment: touched.number && errors.number && icon,
                        // }}
                      /> */}
                  <FormControl fullWidth>
                    <Selects
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={values?.qcChecklist}
                      name='qcChecklist'
                      variant='outlined'
                      //onChange={handleChange}
                      onChange={(e) => {
                        const currentChecklist = getChecklists.find(
                          (data) => data.title === e.target.value
                        );
                        //setChecklistId(currentChecklist?.id);
                        setFieldValue('qcChecklistId', currentChecklist?.id);
                        setFieldValue('qcChecklist', e.target.value);
                      }}
                      displayEmpty
                    >
                      <MenuItem value='' disabled>
                        <span className='menu-item-span'>Select checklist</span>
                      </MenuItem>
                      {getChecklists?.map((qcChecklist, ind) => {
                        return (
                          <MenuItem value={qcChecklist?.id} key={ind}>
                            {qcChecklist?.title}
                          </MenuItem>
                        );
                      })}
                    </Selects>
                  </FormControl>
                  {touched.qcChecklist && errors.qcChecklist && (
                    <Typography className='validation-text'>
                      {typeof errors.qcChecklist === 'string' ? errors.qcChecklist : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>Raw Material</Typography>
                  {/* <InputField
                        error={!errors.rawMaterial || !touched.rawMaterial ? false : true}
                        placeholder='Enter Raw Material'
                        fullWidth
                        onChange={handleChange}
                        name='rawMaterial'
                        value={values.rawMaterial}
                        // InputProps={{
                        //   endAdornment: touched.quantity && errors.quantity && icon,
                        // }}
                      /> */}
                  <Selects
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    fullWidth
                    value={values.rawMaterial}
                    name='rawMaterial'
                    variant='outlined'
                    //onChange={handleChange}
                    onChange={(e) => {
                      const currentrawMaterial = getRawMaterials?.data.find(
                        (data) => data.name === e.target.value
                      );
                      //setChecklistId(currentChecklist?.id);
                      setFieldValue('rawMaterialId', currentrawMaterial?.id);
                      setFieldValue('rawMaterial', e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value=''>
                      <span className='menu-item-span'>Select rawMaterials</span>
                    </MenuItem>
                    {/* <MenuItem>
                            <Link onClick={() => navigate('/add-checklist')} underline='none'>
                              + Create new checklist
                            </Link>
                          </MenuItem> */}
                    {getRawMaterials?.data?.map((rawMaterials, ind) => {
                      return (
                        <MenuItem value={rawMaterials.id} key={ind}>
                          {rawMaterials.name}
                        </MenuItem>
                      );
                    })}
                  </Selects>
                  {touched.rawMaterial && errors.rawMaterial && (
                    <Typography className='validation-text'>
                      {typeof errors.rawMaterial === 'string' ? errors.rawMaterial : ''}
                    </Typography>
                  )}
                  {touched.rawMaterial && errors.rawMaterial && (
                    <Typography className='validation-text'>
                      {typeof errors.rawMaterial === 'string' ? errors.rawMaterial : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={'5px'}>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Approver
                    <Require />
                  </Typography>
                  {/* <InputField
                        placeholder='Enter Approver'
                        fullWidth
                        onChange={handleChange}
                        name='approver'
                        value={values.approver}
                      /> */}
                  <Selects
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    fullWidth
                    value={values.approver}
                    name='approver'
                    variant='outlined'
                    onChange={handleChange}
                    // ! for future support
                    // onChange={(e) => {
                    //   const currentChecklist = getChecklists.find(
                    //     (data) => data.title === e.target.value
                    //   );
                    //   //setChecklistId(currentChecklist?.id);
                    //   setFieldValue('checklistId', currentChecklist?.id);
                    //   setFieldValue('checklist', e.target.value);
                    // }}
                    displayEmpty
                  >
                    {desktopUsers?.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data?.id}>
                          <span>{data?.name}</span>
                        </MenuItem>
                      );
                    })}
                  </Selects>
                  {touched.approver && errors.approver && (
                    <Typography className='validation-text'>
                      {/* {typeof errors.approver === 'string' ? errors.approver : ''} */}
                      {errors.approver}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box className='addworkorderbutton-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    variant='contained'
                    className={!(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    type='submit'
                  >
                    {'continue'}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </HeadBox>
    </>
  );
};

export default NewWorkOrder;
