import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useAddMobileUser, useCompletionMobileUser } from '../hooks/workerManagementHooks';
import ErrorPopup from './ErrorPopup';
import { getEndTime, getStartTime } from '../Utils/convertTime';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: '572px',
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface propI {
  isOpen: boolean;
  onClose: () => void;
  workerData: { [key: string]: any };
}
const WorkerConfirm = (props: propI) => {
  const { onClose: handleClose, isOpen, workerData } = props;

  let { id } = useParams();
  if (!id) {
    id = workerData?.name;
  }
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const newDaysData = workerData?.workingDays?.join(',');
  const { mutate: addUser, isError: isAddMobileError, error: addMobileError } = useAddMobileUser();
  const {
    mutate: completionUser,
    isError: iscompleteMobileUserError,
    error: completeMobileUserError,
  } = useCompletionMobileUser(true);
  React.useEffect(() => {
    if (isAddMobileError || iscompleteMobileUserError) {
      isAddMobileError
        ? setErrorMessage(addMobileError?.data?.message)
        : setErrorMessage(completeMobileUserError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isAddMobileError, iscompleteMobileUserError]);
  return (
    // <div>
    //   {/* <Button onClick={handleOpen}>Open modal</Button> */}

    // </div>
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please Confirm the worker details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>worker details</CustomSubTitle>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <CustomTypography>Worker Name </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'workerName', '')}
                  </Typography>
                  <CustomTypography>Department </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'departmentName', '')}
                  </Typography>
                  <CustomTypography>Skill Level </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'skillLevel', '')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CustomTypography>Worker ID </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'uniqId', '')}
                  </Typography>
                  {/* //! for future use */}
                  {/* <CustomTypography>Line </CustomTypography> */}
                  {/* <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'lineName', '')}
                  </Typography> */}

                  <CustomTypography>Mobile No </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(workerData, 'mobile', '')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className='section-one'>
              <CustomSubTitle>Email ID</CustomSubTitle>
              <CustomTypography>Email ID for login </CustomTypography>
              <Typography className='span-color pl-sub-text'>
                {_.get(workerData, 'email', '-')}
              </Typography>
            </Box>

            <CustomSubTitle>working Schedule</CustomSubTitle>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                {' '}
                <CustomTypography>Start time </CustomTypography>
                <Typography className='span-color pl-sub-text'>
                  {getStartTime(workerData?.startTime)}
                </Typography>
                <CustomTypography>Working Days</CustomTypography>
                <Typography className='span-color pl-sub-text'>{newDaysData}</Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomTypography>End Time </CustomTypography>
                <Typography className='span-color pl-sub-text'>
                  {getEndTime(workerData?.endTime)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                const userPayload = {
                  name: workerData?.workerName,
                  department: workerData?.department,
                  line: workerData?.line,
                  skillLevel: workerData?.skillLevel,
                  // workingSchedule: {
                  ...(workerData?.shift === 'custom'
                    ? { isCustom: true }
                    : { shift: workerData?.shift }),
                  startTime: workerData?.startTime,
                  endTime: workerData?.endTime,
                  isDraft: workerData?.isDraft,
                  // },
                  mobile: workerData?.mobile,
                  uniqId: workerData?.uniqId,
                  isVerified: workerData?.isVerified,
                  isCompletion: workerData?.isCompletion,
                  ...(workerData?.email && { email: workerData?.email }),
                  workingDays: workerData?.workingDays,
                };
                completionUser({
                  ...userPayload,
                  id,
                });
                // id
                //   ? updateUser({ ...userPayload, id })
                //   : addUser({ ...userPayload, password: 'Password@123' });
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {errorMessage && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
    </Modal>
  );
};
export default WorkerConfirm;
