import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const CardWrapper = styled(Card)(() => ({
  boxShadow: 'none',
  borderRadius: '8px',
  background: '#F6F6F6',
  padding: '14px 16px 12px 14px',
  margin: '10px 10px 10px 2px',
  '.MuiCardContent-root': {
    padding: '0px',
  },
  '.MuiCardContent-root:last-child': {
    paddingBottom: '0px',
  },
  '.title': {
    color: '#0160B9',
    fontWeight: 600,
    fontSize: '14px',
  },
  '.comment-text': {
    fontSize: '14px',
    color: '#020202',
  },
  '.value': {
    color: '#777676',
  },
  '.ml-35': {
    marginLeft: '35px',
  },
  '.due-by': {
    color: '#1D1B1B',
  },
  '.mr-12': {
    marginRight: '12px',
  },
  '.inner-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
interface dataI {
  data: {
    comment: string;
    createBy: {
      name: string;
    };
  };
}
const TicketCommitCard = (props: dataI) => {
  const { data } = props;
  return (
    <CardWrapper>
      <CardContent>
        <Box className='inner-content'>
          <Box>
            <Typography className='title'>
              {/* Clint P :Make new workorder. Did we not discuss a later ? */}
              {data?.createBy?.name}
            </Typography>
            <Typography className='comment-text'>{data?.comment}</Typography>
          </Box>
        </Box>
      </CardContent>
    </CardWrapper>
  );
};

export default TicketCommitCard;
