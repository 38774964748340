import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { finishedGood, part } from '../queryKeys/catalogue';
import { PartErrI } from '../Types/part';
import { FinishedGoodApi } from '../api/finishedGoods';
import { FinshedGoodsErrI } from '../Types/finshedgoods';

export const useGetFinishedGoods = (isEnabled, page) => {
  return useQuery([finishedGood.FINISHEDGOODS, page], FinishedGoodApi.getFinishedGoods, {
    // initialData: { data: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddFinishedGoods = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(FinishedGoodApi.addFinishedGoods, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([finishedGood.FINISHEDGOODS]);
      queryClient.invalidateQueries([finishedGood.FINISHEDGOODSFULL]);
      const data = res;
      navigate(`/finished-goods`, { state: { activeTab: '3' } });
    },
    onError: (err: FinshedGoodsErrI) => {
      return err;
      // ! TODO: for future implementation toast
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useUpdateFinishedGoods = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(FinishedGoodApi.updateFinishedGoods, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([finishedGood.FINISHEDGOODS]);
      queryClient.invalidateQueries([finishedGood.FINISHEDGOOD, id]);
      navigate(`/finished-goods`, { state: { activeTab: '3' } });
    },
    onError: (err: any) => {
      return err;
      // ! TODO: for future implementation toast
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useGetFullFinishedGoods = (isEnabled) => {
  return useQuery([finishedGood.FINISHEDGOODS + 'FULL'], FinishedGoodApi.getFullFinishedGoods, {
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // ! TODO: for future implementation toast
    // onSuccess: () => {
    // const data = res?.data;
    // },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetFinishedGoodsSingle = (id, isEnable = true) => {
  return useQuery([finishedGood.FINISHEDGOODS, id], FinishedGoodApi.getSingleFinishedGoods, {
    enabled: isEnable,
    // ! TODO: for future implementation toast
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteFinishedGoods = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(FinishedGoodApi.deleteFinishedGoods, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([finishedGood.FINISHEDGOOD]);
      // queryClient.invalidateQueries([finishedGood.FINISHEDGOODS, id]);
      // toast.success(data?.message);
      navigate(`/finished-goods`, { state: { activeTab: '3' } });
    },
    // ! TODO: for future implementation toast
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useGetDeletedFinishedGoods = (id, isEnable = true) => {
  return useQuery(
    [finishedGood.FINISHED_GOODS_DELETED + 'DELETED', id],
    FinishedGoodApi.getSingleDeletedFinishedGoods,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useHardDeleteFinishedGoods = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(FinishedGoodApi.hardDeletefinishedGood, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([finishedGood.FINISHEDGOOD]);
      queryClient.invalidateQueries([finishedGood.FINISHEDGOODS, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // ! TODO: for future implementation toast
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useRestoreFinishedGoods = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(FinishedGoodApi.restorefinishedGood, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([finishedGood.FINISHEDGOOD]);
      queryClient.invalidateQueries([finishedGood.FINISHEDGOODS, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // ! TODO: for future implementation toast
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useValidateFinishedGoodsName = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(FinishedGoodApi.validateFinishedGoodsName, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    //toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  });
};
