import { DepartmentErrI, DepartmentI } from '../Types/department';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addDepartment = async (data): Promise<DepartmentI | DepartmentErrI> => {
  return post(`/customer/department`, data).then((res) => res.data);
};
export const getSingleDepartment = async (data): Promise<DepartmentI | DepartmentErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/department/${id}`).then((res) => res.data);
};
export const getFullDepartment = async () => {
  return get(`/customer/department/full-list`);
};
export const getDepartments = async (data): Promise<any> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/department`, payload).then((res) => res.data);
};
export const updateDepartment = async (data): Promise<DepartmentI | DepartmentErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/department/${id}`, data).then((res) => res.data);
};
export const deleteDepartment = async (id) => {
  return deleteRequest(`/customer/department/${id}`);
};
export const restoreDepartment = async (id) => {
  return patch(`/customer/department/restore/${id}`);
};
export const getSingleDeletedDepartment = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/department/deleted/${id}`);
};
export const hardDeleteDepartment = async (id) => {
  return deleteRequest(`/customer/department/hard-delete/${id}`);
};
export const departmentApi = {
  addDepartment,
  getSingleDepartment,
  getDepartments,
  getFullDepartment,
  deleteDepartment,
  updateDepartment,
  restoreDepartment,
  getSingleDeletedDepartment,
  hardDeleteDepartment,
};
