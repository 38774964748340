const part = {
  PART: 'PART',
  PARTS: 'PARTS',
  PARTSFULL: 'PARTSFULL',
};
const tool = {
  TOOL: 'TOOL',
  TOOLS: 'TOOLS',
  TOOL_HOLDER_FULL: 'TOOL_HOLDER_FULL',
  TOOL_TYPE_FULL: 'TOOL_TYPE_FULL',
  TOOL_ACCESSORIES_FULL: 'TOOL_ACCESSORIES_FULL',
  TOOL_INSERT_FULL: 'TOOL_INSERT_FULL',
};
const taskWorkOrder = {
  TASKWORKORDER: 'TASKWORKORDER',
  TASKWORKORDERS: 'TASKWORKORDERS',
  TASKWORKORDERSFULL: 'TASKWORKORDERSFULL',
};
const accessory = {
  ACCESSORY: 'ACCESSORY',
  ACCESSORYS: 'ACCESSORYS',
  ACCESSORYSFULL: 'ACCESSORYSFULL',
};
const customer = {
  CUSTOMER: 'CUSTOMER',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMERS_CONFIRM: 'CUSTOMERS_CONFIRM',
  CUSTOMERS_COMPLETE: 'CUSTOMERS_COMPLETE',
  CUSTOMERSFULL: 'CUSTOMERSFULL',
};
const raw = {
  RAW: 'RAW',
  RAWS: 'RAWS',
  RAWSFULL: 'RAWSFULL',
};
const finishedGood = {
  FINISHEDGOOD: 'FINISHEDGOOD',
  FINISHEDGOODS: 'FINISHEDGOODS',
  FINISHEDGOODSFULL: 'FINISHEDGOODSFULL',
  FINISHED_GOODS_DELETED: 'FINISHED_GOODS_DELETED',
};
const consumable = {
  CONSUMABLE: 'CONSUMABLE',
  CONSUMABLES: 'CONSUMABLES',
  CONSUMABLESFULL: 'CONSUMABLESFULL',
};
const rawMaterial = {
  RAWMATERIAL: 'RAWMATERIAL',
  RAWMATERIALS: 'RAWMATERIALS',
  RAWMATERIALSFull: 'RAWMATERIALSFull',
  RAW_MATERIALS_DELETED: 'RAW_MATERIALS_DELETED',
};

const product = {
  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',
  PRODUCTSFULL: 'PRODUCTSFULL',
};

const supplier = {
  SUPPLIER: 'SUPPLIER',
  SUPPLIERS: 'SUPPLIERS',
};

const searchOptions = {
  SEARCH: 'SEARCH',
};
const finshedGood = {
  FINSHEDGOOD: 'FINSHEDGOOD',
  FINSHEDGOODS: 'FINSHEDGOODS',
};

const toolCategoryTypeMap = {
  'Tool Holder': '1',
  'Tool Type': '8',
  'Tool Accessories': '9',
  Insert: '10',
};

export {
  part,
  rawMaterial,
  product,
  supplier,
  searchOptions,
  finshedGood,
  accessory,
  customer,
  consumable,
  finishedGood,
  raw,
  taskWorkOrder,
  tool,
  toolCategoryTypeMap,
};
