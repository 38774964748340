import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CustomButton = styled(Button)(() => ({
  padding: '10px 16px 10px 16px !important',
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#1976d2',
  '&.MuiButton-outlined': {
    border: '1px solid rgba(25, 118, 210, 0.5)',
  },
}));

export default CustomButton;
