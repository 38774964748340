import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import {
  useDeleteConsumables,
  useGetConsumablesSingle,
  useGetDeletedConsumables,
  useHardDeleteConsumables,
  useRestoreConsumables,
} from '../../hooks/consumablesHooks';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 0px !important',
    fontWeight: '600 !important',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
}));

const ConsumablesDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: getConsumables } = useGetConsumablesSingle(id, !!id && !isDeleted);
  const { data: getConsumablesDeleted } = useGetDeletedConsumables(id, !!id && isDeleted);
  const singleConsumables = isDeleted ? getConsumablesDeleted?.data : getConsumables?.data;

  // const Qty_over = singleConsumables?.avlStock - singleConsumables?.minimumStockOrder;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'consumables' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'consumables' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'consumables' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: deleteSingleConsumables,
    error: deleteConsumablesError,
    isError: isDeleteConsumablesError,
  }: any = useDeleteConsumables();
  React.useEffect(() => {
    if (isDeleteConsumablesError) {
      setErrorMessage(deleteConsumablesError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isDeleteConsumablesError]);
  const { mutate: hardDeleteConsumables } = useHardDeleteConsumables();
  const { mutate: restoreConsumables } = useRestoreConsumables();
  const { mutate: readNotification } = useUpdateNotification();
  const handleConsumablesDelete = () => {
    if (isDeleted) {
      hardDeleteConsumables(id);
    } else {
      deleteSingleConsumables(id);
    }
  };

  const handleConsumablesRestore = () => {
    if (isRestore) {
      restoreConsumables(id);
    } else {
      // nothing to restore
    }
  };
  let breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: `Consumables`,
      link: `/consumables`,
      activeTab: '5',
    },
    {
      title: _.get(singleConsumables, 'name', 'Consumables'),
      link: `/consumable-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleConsumables, 'name', ''),
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-consumable/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});
  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleConsumables, 'name', 'Consumables')}
          backToLink='/consumables'
          isDetailPage={true}
          activeTab='5'
          detailPageData={detailPageData}
          handleDelete={handleConsumablesDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handleConsumablesRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={6}>
                    <TextFiled>Consumables Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>Consumables Number </TextFiled>
                    <Typography className='span-color pl-sub-text basic-Details-Box-store-a'>
                      {_.get(singleConsumables, 'number', '')}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2.3}>
                    <TextFiled>Manucturer </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'manufacturer', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.3}>
                    <TextFiled>Quantity </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'quantity', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.3}>
                    <TextFiled>Date Bought </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {moment(_.get(singleConsumables, 'dateBought', '')).format(
                        'hh:mm A, DD MMM, YYYY'
                      )}
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Other Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={4}>
                    <TextFiled>Manucturer </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'manufacturer', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Quantity </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'quantity', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Date Bought </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {moment(_.get(singleConsumables, 'dateBought', '')).format(
                        'hh:mm A, DD MMM, YYYY'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className='usage-title'> Usage</Typography>
            </Grid>
            <Grid item xs={5.4} mt={'20px'}>
              <Grid item xs={11} container className='process-header checklist-details'>
                <Grid item xs={0.3} textAlign={'center'} ml={'20px'}>
                  <Typography className='process-header-field-title'>#</Typography>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Typography className='process-header-field-title'>Work Order Number</Typography>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Typography className='process-header-field-title'>Quantity</Typography>
                </Grid>
                <Grid item xs={3} textAlign={'center'}>
                  <Typography className='process-header-field-title'>Used On</Typography>
                </Grid>
              </Grid>
              {/* </Box> */}
              {/* {checklist?.checklistItems?.map((data, ind) => {
            return ( */}
              <Grid container item xs={11} className='border-items checklist-details'>
                <Grid
                  item
                  xs={0.3}
                  textAlign={'center'}
                  className='field-title'
                  mr={''}
                  ml={'20px'}
                >
                  <Box>
                    <Typography className='details-mr9'>1</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>Yes1</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>
                      {_.get(singleConsumables, 'quantity', '')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>Yes2</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={10.3}>
              <Box className='details-box-border padding-bottom'>
                <Box display={'flex'}>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Seriyal Number
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Work Order Number
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Used On
                    </Typography>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'category', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'number', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleConsumables, 'manufacturer', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Loading
          // loading={
          //   id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          // }
          background='white'
          loaderColor='#265AA8'
        />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
      </HeadBoxWrapper>
    </>
  );
};
export default ConsumablesDetails;
