import { PartErrI, PartFullListI, PartI, PartListErrI, PartListI } from '../Types/part';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addPart = async (data): Promise<PartI | PartErrI> => {
  delete data['supplierId'];
  return post(`/customer/tool`, data).then((res) => res.data);
};

export const getSinglePart = async (data): Promise<PartI | PartErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/tool/${id}`).then((res) => res.data);
};

export const getSingleDeletedPart = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/tool/deleted/${id}`);
};

export const updatePart = async (data): Promise<PartI | PartErrI> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/tool/${id}`, data).then((res) => res.data);
};

export const getSingleDeletedRawMaterial = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/raw-material/deleted/${id}`);
};

export const restorePart = async (id) => {
  return patch(`/customer/raw-material/restore/${id}`);
};

export const hardDeletePart = async (id) => {
  return deleteRequest(`/customer/raw-material/hard-delete/${id}`);
};

export const deletePart = async (id) => {
  return deleteRequest(`/customer/customer-order/${id}`);
};
export const deleteTool = async (id) => {
  return deleteRequest(`/customer/tool/${id}`);
};

export const getParts = async (
  data
): Promise<PartListI | undefined | { parts: { results: [] } } | PartListErrI> => {
  const page = data?.queryKey[1] || 1;
  const toolCategoryTypes = data?.queryKey[2] || 'toolHolder';
  const payload = { page, limit: 10, toolCategoryTypes };
  return get(`/customer/tool`, payload).then((res) => {
    return res.data;
  });
};
export const getAccessory = async (
  data
): Promise<PartListI | undefined | { parts: { results: [] } } | PartListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, type: 'Accessories' };
  return get(`/customer/part`, payload).then((res) => res.data);
};

export const getFullParts = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/tool/`).then((res) => res.data);
};

export const getFullToolHolder = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/tool/tool-holder-items/full-list`).then((res) => res.data);
};

export const getFullToolType = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/tool/tool-type-items/full-list`).then((res) => res.data);
};

export const getFullToolAccessories = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/tool/tool-accessories-items/full-list`).then((res) => res.data);
};

export const getFullToolInsert = async (): Promise<PartFullListI | { parts: [] }> => {
  return get(`/customer/tool/tool-insert-items/full-list`).then((res) => res.data);
};

export const getSingleParts = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/tool/${id}`).then((res) => res.data);
};
export const hardDeleteTool = async (id) => {
  return deleteRequest(`/customer/tool/hard-delete/${id}`);
};
export const restoreTool = async (id) => {
  return patch(`/customer/tool/restore/${id}`);
};
export const validateToolName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/tool/validate`, data);
};

export const partApi = {
  addPart,
  getSinglePart,
  updatePart,
  deletePart,
  getParts,
  getAccessory,
  getFullParts,
  hardDeletePart,
  restorePart,
  getSingleDeletedPart,
  getSingleParts,
  deleteTool,
  hardDeleteTool,
  restoreTool,
  getSingleDeletedRawMaterial,
  getFullToolHolder,
  getFullToolType,
  getFullToolAccessories,
  getFullToolInsert,
  validateToolName,
};
