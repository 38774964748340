import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ErrorMessage, FieldArray, Form, Formik, getIn } from 'formik';
import { Button, Divider, Select, Switch, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import CustomButton from '../commonComponents/CustomButton';
import { useParams } from 'react-router-dom';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Loading from 'react-fullscreen-loading';
import Plus from '../../assets/svg/Plus';
import 'rc-time-picker/assets/index.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useAddCustomer, useGetCustomerSingle, useUpdateCustomer } from '../../hooks/cusromerHooks';
import CustomerOrderConfirmModel from '../../Modals/CustomerOrderConfirmModel';

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const RawCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '150px',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#8A8A8A',
  '&.MuiButton-outlined': {
    border: 'border: 1px solid #8A8A8A',
  },
}));
const HeadBox = styled(Box)(() => ({
  height: '84.5vh !important',
  // Media query for screens with a height between 501px and 600px
  '@media (min-height: 501px) and (max-height: 600px)': {
    height: '70vh !important',
  },

  // Media query for screens with a height between 601px and 700px
  '@media (min-height: 601px) and (max-height: 700px)': {
    height: '79vh !important',
  },
  // Media query for screens with a height between 601px and 700px
  '@media (min-height: 701px) and (max-height: 778)': {
    height: '84vh !important',
  },
  '.product-details-box': {
    minHeight: '53vh',
    maxHeight: '40vh !important',
    overflowY: 'auto',
    // Media query for screens with a height between 601px and 700px
    '@media (min-height: 601px) and (max-height: 700px)': {
      minHeight: '37vh',
      maxHeight: '37vh !important',
    },
    // Media query for screens with a height between 601px and 700px
    '@media (min-height: 701px) and (max-height: 800px)': {
      minHeight: '43vh',
      maxHeight: '43vh !important',
    },
    '@media (min-height: 801px) and (max-height: 850px)': {
      minHeight: '47vh',
      maxHeight: '47vh !important',
    },
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.addrawbutton-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '10px',
    marginLeft: '15px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 18px 0px',
    color: '#000',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.rm-footer-padding': {
    padding: '14px 0px 10px 20px',
  },
  '.footer-padding': {
    padding: '10px 10px',
  },
  '.border': {
    border: '1px solid #8A8A8A',
    padding: '20px 10px 18px 30px',
    marginTop: '10px',
  },
  '.fildborder': {
    border: '1px solid #8A8A8A',
    borderColor: '#8A8A8A',
    borderRadius: '4px',
    padding: '7px 20px 7px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
  },
  '.button-mr-2': {
    margin: '25px 0px 20px 0px',
  },
  '.borderMargin': { padding: '10px 02px 0px 0px' },
  '.buttonFlex': {
    display: 'flex',
    padding: '0px 0px',
  },
  '.title-basic': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '20px 0px 18px 0px !important',
    fontWeight: '600 !important',
  },
  'mr-17': {
    height: '32px !important',
    lineHeight: '32px !important',
    fontSize: '18px !important',
    fontWeight: '600 !important',
  },
  '.mr-15': {
    marginLeft: '8px',
  },
  '.mr-16': {
    margin: '20px 0px 30px 0px',
  },
  '.mr-18': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.mr-19': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 20px 0px !important',
    fontWeight: '600 !important',
  },
  '.menu-item-span': {
    color: '#9E9E9E !important',
  },
  '.mr-23': {
    marginRight: '315px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.mr-31': {
    marginRight: '299px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.mr-30': {
    marginTop: '30px',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.remove-button': {
    display: 'flex',
    margin: '10px 10px 0px 17px',
    cursor: 'pointer',
    color: '#265AA8',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      margin: '45px 10px 0px 20px',
    },
  },
}));
const BoxWrapper = styled(Box)(() => ({
  paddingBottom: '40px',
  marginTop: '24px',
  '.remove': {
    display: 'flex',
    marginTop: '9px',
    cursor: 'pointer',
    color: '#265AA8',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.mr-14': {
    marginRight: '14px',
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
    // padding: '10px 12px 10px 12px !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const addCustomer = () => {
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Name is Required'),
    orderName: Yup.string().required('OrderName is Required'),
    // remarks: Yup.string(),
    // productDetails: Yup.array().of(
    //   Yup.object().shape({
    //     product: Yup.string().required('Product is required'),
    //   })
    // )
    //  product: Yup.string().required('product is Required'),
    //  quantity: Yup.string().required('quantity is Required'),
  });

  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => setOpenModal(false);
  const [value, setValue] = React.useState('');
  const [isEdit, setIsEdit] = useState(false);
  const {
    mutate: addCustomer,
    // error: addDepartmentError,
    // isError: isAddDepartmentError,
    isLoading,
  } = useAddCustomer();
  const {
    mutate: updateCustomer,
    // error: updateDepartmentError,
    // isError: isUpdateDepartmentError,
  } = useUpdateCustomer();
  interface IUser {
    name?: string;
    orderName?: string;
    remarks?: string;
    isproductDetailsTracking: boolean;
    productDetails?: any;
    id?: string;
    // label?: string;
    // color?: string;
  }
  const [isInventoryOpen, setIsInvenotryOpen] = React.useState(false);
  const handleInventoryOpen = () => setIsInvenotryOpen(true);
  const handleInventoryClose = () => setIsInvenotryOpen(false);
  const [open, setOpen] = React.useState(false);
  const { id = '' } = useParams();
  // const { data: getSuppliers } = useGetFullSuppliers(true);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { data: getCustomerOrder, isSuccess } = useGetCustomerSingle(id, !!id);
  const singleCustomerOrder = getCustomerOrder?.data;
  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  const breadCrumbData = [
    {
      title: 'Customer Order',
      link: '/customer-Order',
    },
    {
      title: `${isEdit ? singleCustomerOrder?.name : 'Create new Customer Order'}`,
      link: `/${isEdit ? `edit-customer-order/${id}` : 'add-customer-order'}`,
    },
  ];

  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  const icon = <AiOutlineInfoCircle color='red' />;
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'Edit' : 'Add new'} Customer Order`}
        backToLink='/customer-Order'
        activeTab='1'
      />
      <HeadBox className='main-component-padding height-100'>
        <Formik
          initialValues={{
            name: isEdit ? singleCustomerOrder?.name : '',
            orderName: isEdit ? singleCustomerOrder?.orderName : '',
            remarks: isEdit ? singleCustomerOrder?.remarks : '',
            productDetails: isEdit
              ? singleCustomerOrder?.productDetails?.map((data: any) => ({
                  ...data,
                  // supplier: data?.supplier?.id || '',
                }))
              : [
                  {
                    // dateBought: '',
                    // isInspection: false,
                  },
                ],
            // name: '',
            // number: '',
            // remarks: '',
            // productDetails: [
            //   {
            //     product: '',
            //     quantity: '',
            //     deliveryDateRequested: '',
            //   },
            // ],
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          // onSubmit={(values) => {
          //   id ? updateCustomer({ ...values, id }) : addCustomer(values);
          // }}
          onSubmit={(values: any) => {
            setOpenModal(true);
            setValue({
              ...values,
            });
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Enter Basic Details </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    Customer Name
                    <Require />
                  </Typography>

                  <InputField
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter Name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values?.name}
                    InputProps={{
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>
                      {typeof errors.name === 'string' ? errors.name : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    Customer Order name
                    <Require />
                  </Typography>
                  <InputField
                    error={!errors.orderName || !touched.orderName ? false : true}
                    placeholder='Enter Order name'
                    fullWidth
                    onChange={handleChange}
                    name='orderName'
                    value={values?.orderName}
                    InputProps={{
                      endAdornment: touched.orderName && errors.orderName && icon,
                    }}
                  />
                  {touched.orderName && errors.orderName && (
                    <Typography className='validation-text'>
                      {typeof errors.orderName === 'string' ? errors.orderName : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>Remarks</Typography>
                  <InputField
                    placeholder='Enter Remarks'
                    fullWidth
                    name='remarks'
                    value={values?.remarks}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: touched.remarks && errors.remarks && icon,
                    }}
                  />
                  {touched.remarks && errors.remarks && (
                    <Typography className='validation-text'>
                      {typeof errors.remarks === 'string' ? errors.remarks : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0}>
                {' '}
                <Grid item xs={12} className='divider-mr-1'>
                  <Divider />{' '}
                </Grid>{' '}
              </Grid>
              <FieldArray
                name='productDetails'
                render={({ remove, push }) => (
                  <Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography
                        display={'flex'}
                        justifyContent={'flexStart'}
                        className='title-typography'
                      >
                        Enter Product Details
                      </Typography>
                      {/* //! for future use */}
                      {/* <Box display={'flex'}>
                        <CustomButton
                          variant='outlined'
                          className=''
                          onClick={() => {
                            push({
                              quantity: '',
                              product: '',
                              deliveryDateRequested: '',
                            });
                          }}
                        >
                          <Plus fill={'rgba(1, 96, 185, 1)'} /> <span className='ml'></span>
                          Add product
                        </CustomButton>
                      </Box> */}
                    </Box>
                    <Box className='row product-details-box' mt={'0px'}>
                      <Grid spacing={2}>
                        {values?.productDetails?.length > 0 &&
                          [...values.productDetails]?.map((item, index) => {
                            const productDetails = `productDetails[${index}]`;
                            const touchedproductDetails = getIn(touched, productDetails);
                            const errorproductDetails = getIn(errors, productDetails);

                            return (
                              // <BoxWrapper >
                              <Grid item xs={12} mt={'20px'} key={index}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4} md={6} lg={4} spacing={2}>
                                    <Typography className='field-title'>
                                      Product
                                      <Require />
                                    </Typography>

                                    <InputField
                                      error={
                                        !errors.productDetails?.[index]?.product ||
                                        !touched.productDetails?.[index]?.product
                                          ? false
                                          : true
                                      }
                                      placeholder='Enter Product'
                                      fullWidth
                                      onChange={handleChange}
                                      name={`productDetails.${index}.product`}
                                      value={values?.productDetails?.[index]?.product}
                                      InputProps={{
                                        endAdornment:
                                          touched.productDetails?.[index]?.product &&
                                          errors.productDetails?.[index]?.product &&
                                          icon,
                                      }}
                                    />
                                    {touched.productDetails?.[index]?.product &&
                                      errors.productDetails?.[index]?.product && (
                                        <Typography className='validation-text'>
                                          Product is required.
                                          {errors.productDetails?.[index]?.product}
                                        </Typography>
                                      )}
                                  </Grid>
                                  <Grid item xs={4} md={6} lg={4} spacing={2}>
                                    <Typography className='field-title'>
                                      Quantity
                                      <Require />
                                    </Typography>

                                    <InputField
                                      error={
                                        !errors.productDetails?.[index]?.quantity ||
                                        !touched.productDetails?.[index]?.quantity
                                          ? false
                                          : true
                                      }
                                      placeholder='Enter Quantity'
                                      fullWidth
                                      onChange={handleChange}
                                      name={`productDetails.${index}.quantity`}
                                      value={values?.productDetails?.[index]?.quantity}
                                      InputProps={{
                                        endAdornment:
                                          touched.productDetails?.[index]?.quantity &&
                                          errors.productDetails?.[index]?.quantity &&
                                          icon,
                                      }}
                                    />
                                    {touched.productDetails?.[index]?.quantity &&
                                      errors.productDetails?.[index]?.quantity && (
                                        <Typography className='validation-text'>
                                          {errors.productDetails?.[index]?.quantity}
                                        </Typography>
                                      )}
                                  </Grid>
                                  <Grid item xs={3.8} md={6} lg={4}>
                                    <Typography className='field-title'>
                                      Delivery Date Requested
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        disablePast={isEdit ? false : false}
                                        orientation='portrait'
                                        dayOfWeekFormatter={(day) => `${day}`}
                                        className='date-picker'
                                        views={['year', 'day', 'month']}
                                        closeOnSelect={false}
                                        value={
                                          values?.productDetails?.[index]?.deliveryDateRequested ||
                                          dayjs(new Date())
                                        }
                                        inputFormat='DD/MM/YYYY'
                                        onChange={(newValue: any) => {
                                          setFieldValue(
                                            `productDetails.${index}.deliveryDateRequested`,
                                            newValue?.toDate()
                                          );
                                        }}
                                        components={{
                                          ActionBar: ActionList,
                                          // OpenPickerIcon: ClockIcon,
                                        }}
                                        componentsProps={{
                                          actionBar: {
                                            actions: ['today', 'cancel', 'clear'],
                                          },
                                        }}
                                        // ! for future use
                                        // renderInput={(params) => {
                                        //   return <TextField {...params} placeholder='Select Date' />;
                                        // }}
                                        renderInput={(params) => {
                                          if (
                                            values?.productDetails?.[index]?.deliveryDateRequested
                                          ) {
                                            return (
                                              <TextField
                                                {...params}
                                                placeholder='Select Date'
                                                className='date-picker-fieldset fieldset-p-10'
                                              />
                                            );
                                          } else {
                                            delete params['inputProps'];
                                            return (
                                              <TextField
                                                {...params}
                                                disabled={true}
                                                placeholder='Select Date'
                                                className='date-picker-fieldset fieldset-p-10'
                                                error={false}
                                              />
                                            );
                                          }
                                        }}
                                      />
                                    </LocalizationProvider>
                                    <ErrorMessage
                                      name={`productDetails.${index}.deliveryDateRequested`}
                                      render={(msg) => (
                                        <Typography className='validation-text'>{msg}</Typography>
                                      )}
                                    />
                                  </Grid>
                                  {/* <Grid
                                    item
                                    xs={1}
                                    className='remove-card-box'
                                    sx={{ paddingTop: '10px !important' }}
                                  >
                                    <Box className='remove-card'>
                                      <Box onClick={() => remove(index)} className='remove'>
                                        <Typography>Remove</Typography>
                                        <GridCloseIcon />
                                      </Box>
                                    </Box>
                                  </Grid> */}
                                  <Box className='remove-button'>
                                    <CustomButton
                                      variant='outlined'
                                      className='remove'
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </CustomButton>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                      <Box display={'flex'} className='button-mr-2'>
                        <CustomButton
                          variant='outlined'
                          className=''
                          onClick={() => {
                            push({
                              // quantityBought: '',
                              quantity: '',
                              product: '',
                              deliveryDateRequested: '',
                            });
                          }}
                        >
                          <Plus fill={'rgba(1, 96, 185, 1)'} /> <span className='ml'></span>
                          {/* <AddIcon /> */}
                          Add product
                        </CustomButton>
                      </Box>
                    </Box>
                  </Box>
                )}
              />

              <Box className='addrawbutton-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    {isEdit ? 'Save Changes' : 'submit'}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </HeadBox>
      {openModal && (
        <CustomerOrderConfirmModel partData={value} isOpen={openModal} handleClose={handleClose} />
      )}
      <Loading loading={id && !isSuccess ? true : false} background='white' loaderColor='#265AA8' />
    </React.Fragment>
  );
};
export default addCustomer;
