import React from 'react';
import Modal from '@mui/material/Modal';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HeadBox = styled(Box)(() => ({
  '.title-text': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
    paddingLeft: '33px',
    textTransform: 'capitalize',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
}));

const AddProductModal = ({ open, handleClose }: any) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
  const isxlScreen = useMediaQuery(theme.breakpoints.only('xl'));
  const style = {
    position: 'absolute' as const,
    top: '10%',
    right: '2%',
    overflow: 'auto',
    width: isMdScreen ? '70%' : isLgScreen ? '75%' : isxlScreen ? '80%' : '80%',
    maxHeight: '78%',
    display: 'block',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '30px 30px 20px 30px',
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <HeadBox>
            <Box display={'flex'} alignItems={'center'}>
              <ArrowBackIcon className='back-arrow' onClick={() => handleClose()} />
              <Typography className='title-text'>Add new product</Typography>
            </Box>
            <Divider />
          </HeadBox>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default AddProductModal;
