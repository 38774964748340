import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CustomLoginButton from '../commonComponents/CustomLoginButton';
import { useSearchParams } from 'react-router-dom';
import { useResetPassword } from '../../hooks/authHooks';
import HighMarkError from '../../assets/svg/HighMarkError';
import CloseIcon from '@mui/icons-material/Close';
import Vector from '../../assets/svg/Vector';
import WorkFlow from '../../assets/svg/WorkFlow';
import Require from '../commonComponents/Required';

const LoginSchema = Yup.object().shape({
  newPassword: Yup.string().required('PassWord is required'),
  ConfirmPassword: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },

  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
    borderRadius: '4px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  // background: 'linear-gradient(180deg, #265AA8 0%, #AACCFF 104.19%)',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  /* height: '100vh', */
  // overflow: 'auto',
  '.left-contained': {
    background: '#0160B9',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  '.right-contained': {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.vector-workflow-box': {
    marginTop: '114.92px',
    paddingRight: '12%',
    // paddingLeft: '21%',
  },
  '.workFlow-title-box': {
    position: 'absolute',
    bottom: '86px',
  },
  '.workFlow-title': {
    color: '#ffffff',
    fontSize: '56px',
    fontWeight: 700,
    lineHeight: '72px',
  },
  // '.login-box': {
  //   //height: '450px',
  //   width: '503px',
  //   justifyContent: 'center',
  // },
  '.head-title': {
    fontSize: '24px',
    fontWeight: '600',
  },
  '.head-sub-title': {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8A8A8A',
  },
  // '.login-box': {
  //   //height: '450px',
  //   width: '503px',
  //   justifyContent: 'center',
  // },
  '.head-image': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '101px',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 6px 0px',
    color: '#020202',
    //marginTop: '15px',
  },
  '.back-color': {
    background: '#FFFFFF',
    width: '400px',
    borderRadius: '10px',
  },
  '.login-btn-box': {
    display: 'flex',
    marginTop: '24px',
  },
  '.left-box': {
    width: '10px',
    height: '84px',
    background: '#CC0000',
  },
  '.right-box': {
    width: '493px',
    height: '52px',
    background: '#F5CCCC',
  },
  '.close-icon': {
    cursor: 'pointer',
  },
}));

const SetupPassword = () => {
  // const parmas = useParams();
  const [searchParams] = useSearchParams();
  const [errorBox, setErrorBox] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleClose = () => setErrorBox(false);
  const handleOpen = () => setErrorBox(true);
  //   const { mutate: addLogin, isError } = useLogin(handleOpen);
  const token: any = searchParams.get('token');
  const name: any = searchParams.get('name');

  const {
    mutate: setPassword,
    isError: isSetErrorPassword,
    error: setErrorPassword,
  } = useResetPassword(handleOpen);

  React.useEffect(() => {
    if (isSetErrorPassword) {
      setErrorMessage(setErrorPassword?.data?.message);
    }
  }, [isSetErrorPassword]);

  const icon = <AiOutlineInfoCircle color='red' />;
  return (
    <React.Fragment>
      <HeadBox>
        <Formik
          initialValues={{
            newPassword: '',
            ConfirmPassword: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values: any) => {
            const updateValue: any = {
              newPassword: values?.newPassword,
              token: token,
            };
            setPassword(updateValue);
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, isValid, dirty }) => (
            <Grid container>
              <Grid item xs={6} className='left-contained'>
                <Box className='vector-workflow-box'>
                  <Box display={'flex'}>
                    <Box>
                      <Vector />
                    </Box>
                    <Box ml={'13px'} pt={'17px'}>
                      <WorkFlow />
                    </Box>
                  </Box>
                  <Box className='workFlow-title-box'>
                    <Typography className='workFlow-title'>Powerful</Typography>
                    <Typography className='workFlow-title'>Automation</Typography>
                    <Typography className='workFlow-title'>Made Simple.</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} className='right-contained'>
                <Form onSubmit={handleSubmit}>
                  <Box className='login-box'>
                    <Box>
                      <Typography className='head-title'>Welcome, {name}</Typography>
                      <Typography className='head-sub-title'>Setup your unique password</Typography>
                    </Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box>
                          <Typography className='field-title' mt={'33px'}>
                            New Password
                            <Require />
                          </Typography>

                          <Input
                            placeholder='Enter your new password'
                            fullWidth
                            type='password'
                            className='back-color'
                            onChange={handleChange}
                            name='newPassword'
                            InputProps={{
                              endAdornment: touched.newPassword && errors.newPassword && icon,
                            }}
                          />
                          {touched.newPassword && errors.newPassword && (
                            <Typography className='validation-text'>
                              {typeof errors?.newPassword === 'string' && errors.newPassword}
                            </Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography className='field-title' mt={'25px'}>
                            Confirm Password
                            <Require />
                          </Typography>
                          <Input
                            placeholder='Confirm new password'
                            fullWidth
                            type='password'
                            className='back-color'
                            onChange={handleChange}
                            name='ConfirmPassword'
                            InputProps={{
                              endAdornment:
                                touched.ConfirmPassword && errors.ConfirmPassword && icon,
                            }}
                          />
                          {touched.ConfirmPassword && errors.ConfirmPassword && (
                            <Typography className='validation-text'>
                              {typeof errors?.ConfirmPassword === 'string' &&
                                errors.ConfirmPassword}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    {/* <Box className='login-btn-box'>
                  <CustomLoginButton
                    type='submit'
                    className={!(isValid && dirty) ? 'disable-btn' : 'yellow-bg'}
                  >
                    Setup Password
                  </CustomLoginButton>
                </Box> */}
                    <Box className='login-btn-box'>
                      {errorBox ? (
                        <>
                          <Box display={'flex'}>
                            <Box className='left-box'></Box>
                            <Box className='right-box' p={2}>
                              <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                  <Box display={'flex'}>
                                    <HighMarkError />
                                    <Typography className='field-tit' ml={2}>
                                      {/* You have entered incorrect password */}
                                      {errorMessage}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography className='field-title' ml={6}>
                                      Please try again or contact the admin
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box onClick={() => handleClose()} className='close-icon'>
                                  <CloseIcon />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>
                          <CustomLoginButton
                            type='submit'
                            className={!(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                          >
                            Setup Password
                          </CustomLoginButton>
                        </>
                      )}
                    </Box>
                  </Box>
                </Form>
              </Grid>
            </Grid>
          )}
        </Formik>
      </HeadBox>
    </React.Fragment>
  );
};
export default SetupPassword;
