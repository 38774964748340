import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import {
  Typography,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Switch,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import Require from '../pages/commonComponents/Required';
import { useParams } from 'react-router-dom';
import 'rc-time-picker/assets/index.css';
import CustomButton from '../pages/commonComponents/CustomButton';
import { useAddTaskWorkOrder, useUpdateTaskWorkOrder } from '../hooks/taskWorkOrderHooks';
import { useGetFullAccessory } from '../hooks/accessoryHooks';
import { useGetFullMachines } from '../hooks/machineHooks';
import { useFullGetMobileUsers } from '../hooks/workerManagementHooks';
import UploadDocument from '../pages/commonComponents/UploadDocument';
import TimePic from '../pages/Processes/TimePic';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import {
  useFullToolAccessories,
  useFullToolHolder,
  useFullToolInsert,
  useFullToolType,
} from '../hooks/partHooks';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ToolHolderDropdownWithRadioButton from '../pages/commonComponents/ToolHolderDropdown';

const validationDialogSchema = Yup.object().shape({
  face: Yup.string().required('Face is Required'),
  instructions: Yup.string().required('Instructions is Required'),
  // toolCategory: Yup.string().required('Tool Category is Required'),
  expected: Yup.string().required('Expected Time is Required'),
  operation: Yup.string().required('Operation is Required'),
  operator: Yup.string().required('Operator is Required'),
});

const HeadBox = styled(Box)(() => ({
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.task-pandding': {
    padding: '20px 56px 18px 25px !important',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  '.footer-padding': {
    padding: '16px 5px 12px 20px !important',
  },
  '.fildborder': {
    borderRadius: '4px',
    padding: '10px 20px 10px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
    backgroundColor: '#F6F6F6',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.task-mr-23': {
    marginRight: '70px',
    fontSize: 14,
  },
  '.task-mr-25': {
    marginRight: '15px',
    fontSize: 14,
  },
  '.task-mr-24': {
    marginRight: '60px',
    fontSize: 14,
  },
  '.task-delete-icon': {
    cursor: 'pointer',
    marginLeft: '50px',
  },
  '.mr-30': {
    marginTop: '30px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    height: '28px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.uploadreferencesIcon-3': {
    color: '#020202',
  },
  '.edit-stock-text': {
    fontWeight: 600,
    fontSize: '14px !important',
    color: '#0160B9',
    paddingLeft: '8px',
  },
  '.field-title-new': {
    fontSize: '14px',
    fontWeight: 400,
    color: '#00000',
  },
  '.arrow': {
    cursor: 'pointer',
  },
  '.selectedSearchBox': {
    color: '#000000!important',
    border: '1px solid #8a8a8a',
    padding: '8px 10px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  '.parent-search-box': {
    border: '1px solid #8a8a8a',
    padding: '8px 10px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    color: '#C0C0C0 !important',
  },
  '.parent-check': {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    width: '95%',
    boxShadow: '0px 0px 3px #727272',
    padding: '10px',
  },
  '.parent-check-box': {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    width: '91.5%',
    boxShadow: '0px 0px 3px #727272',
    padding: '10px',
  },
  '.task-type-input': {
    width: '95%',
    padding: '8px',
    backgroundColor: '#f6f6f6',
    border: '1px solid #cdcdcd',
    borderRadius: '5px',
    fontSize: '14px !important',
    color: 'black !important',
  },
  '.type-menu-scroll': {
    height: '200px',
    overflowY: 'auto',
  },
  '.type_list': {
    display: 'flex',
    borderBottom: '1px solid #dedede',
    padding: '12px 10px',
  },
  '.selected-search-box': {
    color: '#000000',
    border: '1px solid #8a8a8a',
    padding: '8px 10px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
}));
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : 'rgba(0, 177, 60, 1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    fontSize: '14px !important',
    height: '40px',
    padding: '0px 12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
interface PropI {
  handleClose: () => void;
  taskListType: string;
  currentTask: any;
}
const TaskDetailsConfirm = ({ currentTask, taskListType, handleClose }: PropI) => {
  const { id } = useParams();
  const { data: getAccessory } = useGetFullAccessory(true);
  const { data: getToolHolder } = useFullToolHolder(true);
  const { data: getToolType } = useFullToolType(true);
  const { data: getToolAccessories } = useFullToolAccessories(true);
  const { data: getToolInsert } = useFullToolInsert(true);
  const params = { isVerified: true, isCompletion: true };
  const { data: getAllUsers } = useFullGetMobileUsers(true, params);
  const { data: getAllMachine } = useGetFullMachines();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [arrowAccessories, setArrowAccessories] = React.useState(false);
  const [arrowHolder, setArrowHolder] = React.useState<boolean>(false);
  const [arrowToolType, setArrowToolType] = React.useState<boolean>(false);
  const [arrowToolInsert, setArrowToolInsert] = React.useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [selectedHolderId, setSelectedHolderId] = useState('');
  const [selectedToolTypeId, setSelectedToolTypeId] = useState('');
  const [selectedToolInsertId, setSelectedToolInsertId] = useState('');
  const [searchData, setSearchData] = useState<any[]>([]);
  const [searchHolderData, setSearchHolderData] = useState<any[]>([]);
  const [searchTypeData, setSearchTypeData] = useState<any[]>([]);
  const [searchInsertData, setSearchInsertData] = useState<any[]>([]);
  const [search, setSearch] = useState([]);
  const [searchHolder, setSearchHolder] = useState([]);
  const [searchToolType, setSearchToolType] = useState([]);
  const [searchToolInsert, setSearchToolInsert] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);

  const handleSearch = (event) => {
    const query = event.target.value;
    const updatedList = search?.filter((data: any) => {
      return data?.toolCategoryId?.name?.toLowerCase()?.includes(query?.toLowerCase());
    });
    setSearchData(updatedList);
  };

  const handleSearchForToolHolder = (event) => {
    const query = event.target.value;
    const updatedList = searchHolder?.filter((data: any) => {
      return data?.toolCategoryId?.name?.toLowerCase()?.includes(query?.toLowerCase());
    });
    setSearchHolderData(updatedList);
  };

  const handleSearchForToolType = (event) => {
    const query = event.target.value;
    const updatedList = searchToolType?.filter((data: any) => {
      return data?.toolCategoryId?.name?.toLowerCase()?.includes(query?.toLowerCase());
    });
    setSearchTypeData(updatedList);
  };

  const handleSearchForToolInsert = (event) => {
    const query = event.target.value;
    const updatedList = searchToolInsert?.filter((data: any) => {
      return data?.toolCategoryId?.name?.toLowerCase()?.includes(query?.toLowerCase());
    });
    setSearchInsertData(updatedList);
  };

  const handleChangeCheck = (id: any) => {
    setSelectedIds((prevSelectedId) => {
      if (prevSelectedId?.includes(id)) {
        return prevSelectedId?.filter((val) => val !== id);
      } else {
        return [...prevSelectedId, id];
      }
    }); // Toggle the selectedId state
  };

  // const selectedType = searchData?.find((data) => data?.toolTypeItemId?.id === selectedId);
  const selectedToolHolder = searchHolderData?.find((data) => {
    return data?.id === selectedHolderId;
  });

  const selectedToolType = searchTypeData?.find((data) => {
    return data?.id === selectedToolTypeId;
  });

  const selectedToolInsert = searchInsertData?.find((data) => {
    return data?.id === selectedToolInsertId;
  });

  // useEffect(() => {
  //   if (getToolType?.toolTypeItemId?.id) {
  //     setSelectedId(getToolType?.toolTypeItemId?.id);
  //   }
  // }, [getToolType?.toolTypeItemId?.id]);

  useEffect(() => {
    if (currentTask?.nodeInfo?.toolAccessoriesItemId?.length > 0) {
      setSelectedIds(currentTask?.nodeInfo?.toolAccessoriesItemId);
    }
  }, [currentTask?.nodeInfo?.toolAccessoriesItemId?.length > 0]);

  useEffect(() => {
    if (currentTask?.nodeInfo?.toolHolderItemId) {
      setSelectedHolderId(currentTask?.nodeInfo?.toolHolderItemId);
    }
  }, [currentTask?.nodeInfo?.toolHolderItemId]);

  useEffect(() => {
    if (currentTask?.nodeInfo?.toolTypeItemId) {
      setSelectedToolTypeId(currentTask?.nodeInfo?.toolTypeItemId);
    }
  }, [currentTask?.nodeInfo?.toolTypeItemId]);

  useEffect(() => {
    if (currentTask?.nodeInfo?.toolInsertItemId) {
      setSelectedToolInsertId(currentTask?.nodeInfo?.toolInsertItemId);
    }
  }, [currentTask?.nodeInfo?.toolInsertItemId]);

  useEffect(() => {
    if (getToolAccessories?.length > 0) {
      setSearchData(getToolAccessories);
      setSearch(getToolAccessories);
    }
  }, [getToolAccessories?.length > 0]);

  useEffect(() => {
    if (getToolHolder?.length > 0) {
      setSearchHolderData(getToolHolder);
      setSearchHolder(getToolHolder);
    }
  }, [getToolHolder?.length > 0]);

  useEffect(() => {
    if (getToolType?.length > 0) {
      setSearchTypeData(getToolType);
      setSearchToolType(getToolType);
    }
  }, [getToolType?.length > 0]);

  useEffect(() => {
    if (getToolInsert?.length > 0) {
      setSearchInsertData(getToolInsert);
      setSearchToolInsert(getToolInsert);
    }
  }, [getToolInsert?.length > 0]);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  // const { data: getChecklists } = useFullGetChecklists(true);

  const [isEdit, setIsEdit] = React.useState(false);
  React.useEffect(() => {
    if (currentTask) {
      setIsEdit(true);
    }
  }, [currentTask]);

  const {
    mutate: addTaskWorkorder,
    isError: isAddTaskWorkorderError,
    error: addTaskWorkorderError,
    isLoading,
  } = useAddTaskWorkOrder();
  const {
    mutate: updateTaskWorkorder,
    isError: isUpdatekorderError,
    error: updateWorkorderError,
  } = useUpdateTaskWorkOrder();

  // const { data: toolCategoryData } = useGetFactoryToolCategory();
  // const { data: toolDetails } = useGetToolDetails(toolId, !!toolId);
  React.useEffect(() => {
    if (isAddTaskWorkorderError || isUpdatekorderError) {
      isAddTaskWorkorderError
        ? setErrorMessage(addTaskWorkorderError?.data?.message)
        : setErrorMessage(updateWorkorderError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddTaskWorkorderError, isUpdatekorderError]);

  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  return (
    <React.Fragment>
      {/* <Box sx={{ height: '100vh', overflow: 'hidden' }}> */}
      <HeadBox
        onClick={() => {
          setArrowAccessories(false);
          setArrowHolder(false);
          setArrowToolType(false);
          setArrowToolInsert(false);
        }}
      >
        <Formik
          initialValues={{
            operation: isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.stepName : '',
            face: isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.face : '',
            // tool: isEdit && currentTask?.nodeInfo?.tool ? currentTask?.nodeInfo?.tool : '',
            operator: isEdit && currentTask?.nodeInfo?.worker ? currentTask?.nodeInfo?.worker : '',
            machine: isEdit && currentTask?.nodeInfo?.machine ? currentTask?.nodeInfo?.machine : '',
            instructions:
              isEdit && currentTask?.nodeInfo ? currentTask?.nodeInfo?.instructions : '',
            // toolCategory:
            //   isEdit && currentTask?.nodeInfo?.toolCategory
            //     ? currentTask?.nodeInfo?.toolCategory
            //     : '',
            accessories:
              isEdit && currentTask?.nodeInfo?.accessories
                ? currentTask?.nodeInfo?.accessories
                : '',
            toolHolderItemId:
              isEdit && currentTask?.nodeInfo?.toolHolderItemId
                ? currentTask?.nodeInfo?.toolHolderItemId
                : '',
            toolTypeItemId:
              isEdit && currentTask?.nodeInfo?.toolTypeItemId
                ? currentTask?.nodeInfo?.toolTypeItemId
                : '',
            toolAccessoriesItemId:
              isEdit && currentTask?.nodeInfo?.toolAccessoriesItemId
                ? currentTask?.nodeInfo?.toolAccessoriesItemId
                : [],
            toolInsertItemId:
              isEdit && currentTask?.nodeInfo?.toolInsertItemId
                ? currentTask?.nodeInfo?.toolInsertItemId
                : '',
            isProgramFileName:
              isEdit && currentTask?.nodeInfo?.isProgramFileName
                ? currentTask?.nodeInfo?.isProgramFileName
                : false,
            iApprovalNeeded:
              isEdit && currentTask?.nodeInfo?.iApprovalNeeded
                ? currentTask?.nodeInfo?.iApprovalNeeded
                : false,
            isInspectionRecordNeeded:
              isEdit && currentTask?.nodeInfo?.isInspectionRecordNeeded
                ? currentTask?.nodeInfo?.isInspectionRecordNeeded
                : false,
            expected: isEdit ? currentTask?.expected : '',
            dimensions:
              isEdit && currentTask?.nodeInfo?.dimensions?.length > 0
                ? currentTask?.nodeInfo?.dimensions
                : [''],
            uploadedData:
              isEdit && currentTask?.nodeInfo?.uploadedData?.length > 0
                ? currentTask?.nodeInfo?.uploadedData
                : [],
          }}
          enableReinitialize={true}
          validationSchema={validationDialogSchema}
          // onSubmit={(values: any) => {

          //   id
          //     ? updateTaskWorkorder({ ...values, id })
          //     : addTaskWorkorder({
          //         ...values,
          //         // type: 'Manufacturing Order', // You can include additional properties if needed
          //       });
          // }}
          onSubmit={(values: any) => {
            if (values?.isInspectionRecordNeeded === true) {
              values;
            } else {
              delete values['dimensions'];
              values;
            }
            const dimensions = values?.dimensions?.map((data) => {
              return {
                _id: data,
              };
            });
            const finalTaskData = {
              ...values,
              dimensions,
              toolHolderItemId: selectedToolHolder?.id,
              toolTypeItemId: selectedToolType?.id,
              toolInsertItemId: selectedToolInsert?.id,
              toolAccessoriesItemId: selectedIds,
              workOrder: id,
              mainTaskType: 'in-house',
              taskListType: taskListType,
            };
            if (isEdit && currentTask?.id) {
              updateTaskWorkorder({ ...finalTaskData, id: currentTask?.id });
            } else {
              addTaskWorkorder(finalTaskData);
            }
            localStorage.removeItem('isRework');
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              {/* <Typography className='title-typography'> Enter Tools Details</Typography> */}
              <Box
                sx={{
                  height: '55vh',
                  padding: '20px',
                  overflow: 'auto',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} lg={4}>
                    <Typography className='field-title'>
                      Operation
                      <Require />
                    </Typography>
                    <InputField
                      error={!errors.operation || !touched.operation ? false : true}
                      placeholder='Enter Operation'
                      fullWidth
                      onChange={handleChange}
                      name='operation'
                      value={values?.operation}
                    />
                    <ErrorMessage
                      name='operation'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Typography className='field-title'>
                      Face
                      <Require />
                    </Typography>
                    <InputField
                      error={!errors.face || !touched.face ? false : true}
                      placeholder='Enter Face Name'
                      fullWidth
                      onChange={handleChange}
                      name='face'
                      value={values?.face}
                    />
                    {touched.face && errors.face && (
                      <Typography className='validation-text'>
                        {typeof errors.face === 'string' ? errors.face : ''}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Typography className='field-title'>
                      Operator
                      <Require />
                    </Typography>
                    <FormControl fullWidth>
                      <Selects
                        error={!errors.operator || !touched.operator ? false : true}
                        name='operator'
                        variant='outlined'
                        value={values?.operator}
                        onChange={handleChange}
                        displayEmpty
                      >
                        <MenuItem value=''>
                          <span className='menu-item-span'>Select Operator</span>
                        </MenuItem>
                        {getAllUsers?.map((data, ind) => {
                          return (
                            <MenuItem value={data?.id} key={ind}>
                              {data?.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>
                    </FormControl>
                    {/* <InputField
                    error={!errors.operator || !touched.operator ? false : true}
                    placeholder='Enter Tool operator'
                    fullWidth
                    onChange={handleChange}
                    name='operator'
                    value={values?.operator}
                    InputProps={{
                      endAdornment: touched.operator && errors.operator && icon,
                    }}
                  /> */}
                    {touched.operator && errors.operator && (
                      <Typography className='validation-text'>
                        {typeof errors.operator === 'string' ? errors.operator : ''}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={'5px'}>
                  <Grid item xs={4} md={4} lg={4}>
                    <Typography className='field-title'>Machine</Typography>
                    <FormControl fullWidth>
                      <Selects
                        name='machine'
                        variant='outlined'
                        value={values?.machine}
                        onChange={(e) => {
                          const currentMachine = getAllMachine?.find(
                            (data) => data.name === e.target.value
                          );
                          setFieldValue('machineId', currentMachine?.id);
                          setFieldValue('machine', e.target.value);
                        }}
                        displayEmpty
                      >
                        <MenuItem value=''>
                          <span className='menu-item-span'>Select Machine</span>
                        </MenuItem>
                        {getAllMachine?.map((data, ind) => {
                          return (
                            <MenuItem value={data?.id} key={ind}>
                              {data?.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography className='field-title'>
                      Instructions
                      <Require />
                    </Typography>
                    <InputField
                      error={!errors.instructions || !touched.instructions ? false : true}
                      placeholder='Enter Instruction'
                      fullWidth
                      onChange={handleChange}
                      name='instructions'
                      value={values?.instructions}
                      sx={{ fontSize: '14px' }}
                    />
                    {touched.instructions && errors.instructions && (
                      <Typography className='validation-text'>
                        {typeof errors.instructions === 'string' ? errors.instructions : ''}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={'5px'}>
                  <Grid item xs={6} md={6} lg={6} sx={{ position: 'relative' }}>
                    {/* <Typography className='field-title-new'>
                      Tool Holder
                      <Box
                        className='parent-search-box'
                        onClick={(e) => {
                          e.stopPropagation();
                          setArrow(!arrow);
                        }}
                      >
                        Search Tool Holder
                        <Typography className='arrow'>
                          {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </Typography>
                      </Box>
                    </Typography>
                    {arrow && (
                      <Box
                        className='parent-check'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          className='task-type-input'
                          type='text'
                          placeholder='Search Tool Accessories'
                          onChange={handleSearch}
                        />
                        <Box className='type-menu-scroll'>
                          {searchData?.map((data: any, index) => {
                            return (
                              <div key={index}>
                                <div className='type_list'>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={data.id === selectedId}
                                          onChange={() => {
                                            handleChangeRadio(data.id);
                                          }}
                                        />
                                      }
                                      label={
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '10px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '67px',
                                              height: '45px',
                                            }}
                                          >
                                            {data?.uploadedImages[0]?.url && (
                                              <img
                                                src={data?.uploadedImages[0]?.url}
                                                alt=''
                                                width={'67px'}
                                                height={'45px'}
                                              />
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              flexDirection: 'column',
                                            }}
                                          >
                                            <div
                                              style={{
                                                flexDirection: 'column',
                                                fontSize: '14px !important',
                                                fontWeight: '600',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '60%',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                              }}
                                            >
                                              {data?.toolCategoryId?.name}
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                gap: 5,
                                                fontSize: '14px !important',
                                              }}
                                            >
                                              <div>Length:</div>
                                              <div>10</div>
                                              <div>Size:</div>
                                              <div>10</div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </FormGroup>
                                  {data?.toolTypeItemId?.name}
                                </div>
                              </div>
                            );
                          })}
                        </Box>
                      </Box>
                    )} */}
                    <ToolHolderDropdownWithRadioButton
                      title='Tool Holder'
                      searchPlaceholder='Search Tool Holder'
                      searchData={searchHolderData}
                      setSearchData={setSearchHolderData}
                      onSelect={setSelectedHolderId}
                      arrow={arrowHolder}
                      setArrow={setArrowHolder}
                      handleSearch={handleSearchForToolHolder}
                      selectedToolName={selectedToolHolder}
                      setArrowAccessories={setArrowAccessories}
                      setArrowHolder={setArrowHolder}
                      setArrowToolType={setArrowToolType}
                      setArrowToolInsert={setArrowToolInsert}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <ToolHolderDropdownWithRadioButton
                      title='Tool Type'
                      searchPlaceholder='Search Tool Type'
                      searchData={searchTypeData}
                      setSearchData={setSearchTypeData}
                      onSelect={setSelectedToolTypeId}
                      arrow={arrowToolType}
                      setArrow={setArrowToolType}
                      handleSearch={handleSearchForToolType}
                      selectedToolName={selectedToolType}
                      setArrowAccessories={setArrowAccessories}
                      setArrowHolder={setArrowHolder}
                      setArrowToolType={setArrowToolType}
                      setArrowToolInsert={setArrowToolInsert}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={'5px'}>
                  <Grid item xs={6} md={6} lg={6} sx={{ position: 'relative' }}>
                    <Typography className='field-title-new'>
                      Tool Accessories
                      <Box
                        className='selected-search-box'
                        onClick={(e) => {
                          e.stopPropagation();
                          setArrowAccessories(!arrowAccessories);
                          setArrowHolder(false);
                          setArrowToolType(false);
                          setArrowToolInsert(false);
                        }}
                      >
                        {selectedIds?.length > 0 ? (
                          searchData
                            ?.filter((data) => selectedIds?.includes(data?.id))
                            ?.map((data) => data?.toolCategoryId?.name)
                            ?.join(', ')
                        ) : (
                          <Typography
                            className='placeholder'
                            style={{ color: '#C0C0C0', fontSize: '14px' }}
                          >
                            Search Tool Accessories
                          </Typography>
                        )}

                        <Typography className='arrow'>
                          {arrowAccessories ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </Typography>
                      </Box>
                    </Typography>
                    {arrowAccessories && (
                      <Box
                        className='parent-check-box'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          className='task-type-input'
                          type='text'
                          placeholder='Search Tool Accessories'
                          onChange={handleSearch}
                        />
                        <Box className='type-menu-scroll'>
                          {searchData?.map((data: any, index) => {
                            return (
                              <div key={index}>
                                <div className='type_list'>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={selectedIds?.includes(data.id)}
                                          onChange={() => {
                                            handleChangeCheck(data.id);
                                            // setArrow(false);
                                          }}
                                        />
                                      }
                                      label={
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '10px',
                                          }}
                                        >
                                          <div
                                            style={{
                                              // padding: '10px',
                                              width: '67px',
                                              height: '45px',
                                            }}
                                          >
                                            {data?.uploadedImages[0]?.url && ( // Check if image is available
                                              <img
                                                src={data?.uploadedImages[0]?.url}
                                                alt=''
                                                width={'67px'}
                                                height={'45px'}
                                              />
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              flexDirection: 'column',
                                            }}
                                          >
                                            <div
                                              style={{
                                                flexDirection: 'column',
                                                fontSize: '14px !important',
                                                // lineHeight: '1px !important',
                                                fontWeight: '600',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                // overflow: 'hidden',
                                                // width: '60%',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                              }}
                                            >
                                              {data?.toolCategoryId?.name}
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                // flexDirection: 'column',
                                                gap: 5,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize: '14px !important',
                                                }}
                                              >
                                                Length:
                                              </div>
                                              <div
                                                style={{
                                                  fontSize: '14px !important',
                                                }}
                                              >
                                                {data?.length ? data?.length : '-'}
                                              </div>
                                              <div
                                                style={{
                                                  fontSize: '14px !important',
                                                  paddingLeft: '20px',
                                                }}
                                              >
                                                Size:
                                              </div>
                                              <div
                                                style={{
                                                  fontSize: '14px !important',
                                                }}
                                              >
                                                {data?.size ? data?.size : '-'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </FormGroup>
                                  {data?.toolTypeItemId?.name}
                                </div>
                              </div>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <ToolHolderDropdownWithRadioButton
                      title='Insert'
                      searchPlaceholder='Search Insert'
                      searchData={searchInsertData}
                      setSearchData={setSearchInsertData}
                      onSelect={setSelectedToolInsertId}
                      arrow={arrowToolInsert}
                      setArrow={setArrowToolInsert}
                      handleSearch={handleSearchForToolInsert}
                      selectedToolName={selectedToolInsert}
                      setArrowAccessories={setArrowAccessories}
                      setArrowHolder={setArrowHolder}
                      setArrowToolType={setArrowToolType}
                      setArrowToolInsert={setArrowToolInsert}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: '16px' }}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography className='field-title'>Accessories</Typography>
                    <FormControl fullWidth>
                      <Selects
                        // error={!errors.priorityLevel || !touched.priorityLevel ? false : true}
                        // onChange={handleChange}
                        name='accessories'
                        variant='outlined'
                        value={values?.accessories}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   const currentaccessories = getAccessory?.results.find(
                        //     (data) => data.name === e.target.value
                        //   );
                        //   setFieldValue('accessoriesId', currentaccessories?.id);
                        //   setFieldValue('accessories', e.target.value);
                        // }}
                        displayEmpty
                      >
                        <MenuItem value=''>
                          <span className='menu-item-span'>Select Accessories</span>
                        </MenuItem>
                        {getAccessory?.results?.map((accessories, ind) => {
                          return (
                            <MenuItem value={accessories.id} key={ind}>
                              {accessories.name}
                            </MenuItem>
                          );
                        })}
                      </Selects>
                    </FormControl>
                    {/* {touched.accessories && errors.accessories && (
                      <Typography className='validation-text'></Typography>
                    )} */}
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography className='field-title' mt={'20px'}>
                      Expected Time (hh:mm)
                      <Require />
                    </Typography>
                    <TimePic
                      time={values.expected}
                      setFieldValue={setFieldValue}
                      setFieldName={'expected'}
                      customWidth={true}
                    />
                    {touched.expected && errors.expected && (
                      <Typography className='validation-text'>
                        {typeof errors.expected === 'string' ? errors.expected : ''}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} lg={4}>
                    {/* <Typography className='field-title'>
               Operation
               <Require />
             </Typography> */}
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      className='fildborder mr-30'
                    >
                      <Typography className='task-mr-24'>Program File Name</Typography>

                      <Box className=''>
                        {/* <Typography className=''>Batch Tracking</Typography> */}
                        <AntSwitch
                          name='isProgramFileName'
                          // onChange={handleChange}
                          onChange={(event, checked) => {
                            setFieldValue('isProgramFileName', checked);
                          }}
                          value={values?.isProgramFileName}
                          // defaultChecked
                          inputProps={{ 'aria-label': 'ant design' }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      className='fildborder mr-30'
                    >
                      <Typography className='task-mr-23'>Approval Needed</Typography>

                      <Box className=''>
                        <AntSwitch
                          name='iApprovalNeeded'
                          onChange={(event, checked) => {
                            setFieldValue('iApprovalNeeded', checked);
                          }}
                          value={values?.iApprovalNeeded}
                          // defaultChecked
                          inputProps={{ 'aria-label': 'ant design' }}
                          // checked={
                          //   typeof values?.isBatchTracking === 'string'
                          //     ? false
                          //     : values?.isBatchTracking === true
                          // }
                          // onChange={handleChange}
                          // onChange={(e: any) => {
                          //   // handleChecked(e);
                          //   // e.target.checked;
                          //   setFieldValue('isBatchTracking', e.target.checked);
                          // }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      className='fildborder mr-30'
                    >
                      <Typography className='task-mr-25'>Inspection Record Needed</Typography>

                      <Box className=''>
                        <AntSwitch
                          name='isInspectionRecordNeeded'
                          onChange={(event, checked) => {
                            setFieldValue('isInspectionRecordNeeded', checked);
                          }}
                          value={values?.isInspectionRecordNeeded}
                          // defaultChecked
                          inputProps={{ 'aria-label': 'ant design' }}
                        />
                      </Box>
                    </Stack>
                    {/* {touched.operator && errors.operator && (
                    <Typography className='validation-text'>
                      {typeof errors.operator === 'string' ? errors.operator : ''}
                    </Typography>
                  )} */}
                  </Grid>
                  {values?.isInspectionRecordNeeded && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <FieldArray name='dimensions'>
                        {({ push, remove }) => (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box>
                              {values?.dimensions?.map((p, index) => {
                                return (
                                  <Box key={index}>
                                    <Box>
                                      <InputField
                                        placeholder='Enter Dimension'
                                        onChange={handleChange}
                                        name={`dimensions[${index}]`}
                                        value={values.dimensions?.[index]}
                                        type='text'
                                        sx={{ mt: 1 }}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '18%',
                                cursor: 'pointer',
                              }}
                            >
                              <Box
                                sx={{ color: '#0160B9', fontWeight: 600 }}
                                // variant='outlined'
                                onClick={() => {
                                  if (values.dimensions.length) {
                                    push('');
                                  }
                                }}
                              >
                                Add +
                              </Box>
                              {/* <Button
                            variant='contained'
                            className='yellow-bg ml-20'
                            onClick={() => {
                              remove();
                            }}
                          >
                            Remove
                          </Button> */}
                            </Box>
                          </Box>
                        )}
                      </FieldArray>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={4} md={4} lg={4} mt={'15px'}>
                  <Typography className='field-title'>References</Typography>
                  {/* <Button
                  component='label'
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#F6F6F6',
                    border: '1px dotted rgba(166, 166, 166, 1)',
                  }}
                >
                  <input type='file' hidden onChange={handleChange} placeholder='Enter Details' />
                  <Box className='uploadreferencesIcon-1'>
                    <Box className='uploadreferences-2'>
                      <FileUploadOutlinedIcon />
                    </Box>
                    <Box className='uploadreferencesIcon-3'>Upload Attachment</Box>
                  </Box>
                </Button> */}
                  <UploadDocument
                    name={values?.uploadedData}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isAttachment={false}
                    createFileName='uploadedData'
                    isTaskUpload={true}
                    acceptTypes='.docx, video/mp4,video/x-m4v,video/* , image/* '
                  />
                </Grid>
              </Box>
              <Box className='button-box'>
                {/* <Box className='footer-padding'> */}
                <Box sx={{ paddingRight: '25px' }}>
                  <CustomModalButton
                    sx={{ width: '116px' }}
                    variant='outlined'
                    className='blue-color edit-btn'
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);
                      localStorage.removeItem('isRework');
                    }}
                  >
                    Cancel
                  </CustomModalButton>
                </Box>
                <Box sx={{ paddingRight: '25px' }}>
                  <CustomButton
                    sx={{ width: '116px' }}
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    disabled={isLoading}
                    variant='contained'
                    // className={'blue-bg'}
                    type='submit'
                  >
                    Save
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </HeadBox>
      {/* </Box> */}
    </React.Fragment>
  );
};
export default TaskDetailsConfirm;
