import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircleWavyCheck from '../../assets/svg/CircleWavyCheck';

interface Props {
  title: string;
  height?: string;
  Component?: any;
  tableData?: any;
  page?: any;
  setPage?: any;
  data?: any;
  allLink?: any;
  contant?: string;
  isTableBottomPadding?: boolean;
  isDashboardShowFiveRecords?: boolean;
}

const GlobalWrapper = ({
  height,
  title,
  Component,
  tableData,
  page,
  setPage,
  data,
  allLink,
  contant,
  isTableBottomPadding,
  isDashboardShowFiveRecords,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));
  return (
    <React.Fragment>
      <Box
        className={isTableBottomPadding ? 'parent-table-wrapper' : 'parent-table-wrapper-new'}
        sx={{ height: height }}
      >
        <Box className='parent-wrapper-one'>
          <Typography className='wrapper-title'> {title} </Typography>
          {contant !== 'table' && data?.length > 0 && (
            <Typography
              className='wrapper-view'
              onClick={() => {
                navigate(allLink);
              }}
            >
              View all
            </Typography>
          )}
        </Box>
        {title === 'My Actions' ? (
          <Grid container spacing={2} minHeight={'107px'}>
            {data?.length > 0 ? (
              data?.map((actions, index) => {
                return (
                  <Grid item md={4} key={index}>
                    <Component actions={actions} number={index} />
                  </Grid>
                );
              })
            ) : (
              <Box
                sx={{
                  height: isLGScreen ? '255px' : '215px',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                  gap: '5px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#1AD04C',
                    fontSize: '14px',
                    display: 'flex',
                    textAlign: 'center',
                  }}
                >
                  All Actions Completed
                </Typography>
                <Box className='CircleWavyCheckIcon'>
                  <CircleWavyCheck />
                </Box>
              </Box>
            )}
          </Grid>
        ) : title == 'Latest Issues' ? (
          <>
            <Grid
              container
              spacing={2}
              minHeight={data?.length === 0 ? (isLGScreen ? '127px' : '109px') : '107px'}
            >
              {data?.length > 0 ? (
                data?.map((issueData, index) => {
                  return (
                    <Grid item md={4} key={index}>
                      <Component issueData={issueData} />
                    </Grid>
                  );
                })
              ) : (
                <Box
                  sx={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                    gap: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#1AD04C',
                      fontSize: '14px',
                      display: 'flex',
                      textAlign: 'center',
                      height: '21px !important',
                    }}
                  >
                    No issues reported
                  </Typography>
                  <Box className='CircleWavyCheckIcon'>
                    <CircleWavyCheck />
                  </Box>
                </Box>
              )}
            </Grid>
          </>
        ) : (
          <Box>
            <Component
              tableData={tableData}
              isShowFiveRecords={true}
              isDashboardWidth={false}
              page={page}
              setPag={setPage}
              isTableBottomPadding={isTableBottomPadding}
              isDashboardShowFiveRecords={isDashboardShowFiveRecords}
            />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default GlobalWrapper;
