import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import './../../src/style/modal.css';
import ChangePasswordModal from './ChangePasswordModal';
import ShowProfile from './ShowProfile';
import { useLogout } from '../hooks/authHooks';
const style = {
  position: 'absolute',
  top: '110px',
  right: '40px',
  bgcolor: 'background.paper',
  borderRadius: '32px',
  boxShadow: 24,
  p: 4,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '250px',
    padding: '5px',
    fontSize: '16px !important',
    fontWeight: 500,
    borderRadius: '10px !important',
  },
}));
interface propI {
  open: boolean;
  handleClose: () => void;
}

const ProfileModal = (props: propI) => {
  const { open, handleClose } = props;
  const [changePassword, setChangePassword] = React.useState(false);
  const { mutate: logout } = useLogout();
  const handleChangePasswordOpen = () => setChangePassword(true);
  const handleChangePasswordClose = () => setChangePassword(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const handleShowProfileOpen = () => setShowProfile(true);
  const handleShowProfileClose = () => setShowProfile(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Button
                  variant='outlined'
                  className='button-box'
                  onClick={() => {
                    handleShowProfileOpen();
                  }}
                >
                  Profile
                </Button>
                {showProfile && (
                  <ShowProfile
                    open={showProfile}
                    handleOpen={handleShowProfileOpen}
                    handleClose={handleShowProfileClose}
                  />
                )}
              </Box>
              <Box m={2}>
                <Button
                  variant='outlined'
                  className='button-box'
                  onClick={() => {
                    handleChangePasswordOpen();
                  }}
                >
                  Change Password
                </Button>
                {changePassword && (
                  <ChangePasswordModal
                    open={changePassword}
                    handleClose={handleChangePasswordClose}
                    handleProfileClose={handleClose}
                  />
                )}
              </Box>
              <Box m={2}>
                <Button
                  variant='contained'
                  className='button-box yellow-bg'
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  );
};
export default ProfileModal;
