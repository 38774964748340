import React from 'react';
import './cardInfo.css';
import { Box, Grid, Typography } from '@mui/material';

interface Props {
  data: any;
  title: string;
}
const CardInfo = ({ data, title }: Props) => {
  return (
    <React.Fragment>
      <Box className='parent-Card-info'>
        <Typography className='info-title'> {title}</Typography>
        <Grid container spacing={0}>
          <Grid item xs={6} className='grid-info-1'>
            <Typography className='card-value'>
              {/* {data?.todayFinalQuantityProducedCount ? data?.todayFinalQuantityProducedCount : '-'}{' '} */}
              <span className='percentageChange'>
                {data?.percentageChange ? data?.percentageChange : '-'}
              </span>
            </Typography>
            <Typography className='info-description'>Today</Typography>
          </Grid>
          <Grid item xs={6} className='grid-info-2'>
            <Typography className='card-value'>
              {data?.yesterdayFinalQuantityProducedCount
                ? data?.yesterdayFinalQuantityProducedCount
                : '-'}
            </Typography>
            <Typography className='info-description'>Yesterday</Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default CardInfo;
