import axios from 'axios';
import { startsWith } from 'lodash';
import { cookie } from './Cookies/cookie';
import 'react-toastify/dist/ReactToastify.css';

// const defaultHeaders = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json',
// };
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': '*',
};
const authHeader = () => {
  const token = cookie.get('token');
  return { Authorization: `Bearer ${token}` };
};

const url = (path, params) => {
  const sections = path.split(':');
  const sectionsWithParams = sections.map((section) =>
    startsWith(section, '/') ? section : params[section]
  );
  const pathWithParams = sectionsWithParams.join('');
  // eslint-disable-next-line prettier/prettier
  return process.env.REACT_APP_API_URL + pathWithParams;
};

const getHeaders = (auth) => {
  let headers = { ...defaultHeaders };

  if (auth) {
    headers = { ...headers, ...authHeader() };
  }
  return headers;
};

const apiService = axios.create({});

export const get = (path, params = {}, auth = true) =>
  apiService.get(url(path, params), { params, headers: getHeaders(auth) });

export const post = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, { headers: getHeaders(auth) });

export const put = (path, params = {}, auth = true) =>
  apiService.put(url(path, params), params, { headers: getHeaders(auth) });

export const patch = (path, params = {}, auth = true) =>
  apiService.patch(url(path, params), params, { headers: getHeaders(auth) });

export const deleteRequest = (path, params = {}, auth = true) =>
  //   apiService.delete(url(path, params), { headers: getHeaders(auth) }, params);
  apiService.delete(url(path, params), { headers: getHeaders(auth) });

export const upload = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, {
    headers: { ...getHeaders(auth), 'content-type': 'multipart/form-data' },
  });

export const download = (path, params = {}, auth = true) =>
  apiService.get(url(path, params), {
    responseType: 'blob',
    params,
    headers: getHeaders(auth),
  });

apiService.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response && error.response.data && error.response.data.message) {
      //   toast.error(error.response.data.message);
    }

    if (error && error.response && error.response.status === 401) {
      cookie.remove('token');
      window.location.href = '/';
    } else {
      return Promise.reject(error.response);
    }
    return Promise.reject(error.response);
  }
);
