import { get, post, patch, deleteRequest, put } from '../Utils/index';

export const addFactorySchedule = async (data) => {
  return put(`/customer/factory-schedule`, data);
};
export const getFactorySchedule = async () => {
  return get(`/customer/factory-schedule`);
};

// !  tool category api start

export const getFactoryToolCategory = async () => {
  return get(`/customer/tool-category/full-list`);
};
export const getToolDetails = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/tool/full-list?toolCategorySearch=${id}`);
};
export const addFactoryToolCategory = async (data) => {
  return post(`/customer/tool-category`, data);
};
export const updateFactoryToolCategory = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/tool-category/${id}`, data);
};
export const deleteFactoryToolCategory = async (id) => {
  return deleteRequest(`/customer/tool-category/${id}`);
};

//!  tool category api end
// ! settings api here

export const getSetting = async () => {
  return get(`/customer/setting`);
};
export const addSetting = async (data) => {
  return post(`/customer/setting`, data);
};

export const updateFactorySchedule = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/factory-schedule/${id}`, data);
};
export const deleteFactorySchedule = async (id) => {
  return deleteRequest(`/customer/factory-schedule/${id}`);
};
export const factoryScheduleApi = {
  addFactorySchedule,
  getFactorySchedule,
  updateFactorySchedule,
  deleteFactorySchedule,
  getFactoryToolCategory,
  addFactoryToolCategory,
  updateFactoryToolCategory,
  deleteFactoryToolCategory,
  addSetting,
  getSetting,
  getToolDetails,
};
