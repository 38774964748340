const workorder = {
  WORKORDER: 'WORKORDER',
  WORKORDERS: 'WORKORDERS',
  WORKORDERS_COMPLETED: 'WORKORDERS_COMPLETED',
  WORKORDERS_DRAFT: 'WORKORDERS_DRAFT',
  WORKORDER_ONGOING: 'WORKORDER_ONGOING',
  SINGLE_WORKORDER: 'SINGLE_WORKORDER',
  WORKORDER_NUMBER: 'WORKORDER_NUMBER',
};

export { workorder };
