import {
  FinishedGoodErrI,
  FinishedGoodListErrI,
  FinishedGoodListI,
  FinishedGoodI,
  FinishedGoodFullListI,
} from '../Types/finishedGoods';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addFinishedGoods = async (data): Promise<FinishedGoodI | FinishedGoodErrI> => {
  delete data['supplierId'];
  return post(`/customer/finished-good`, data).then((res) => res.data);
};

export const getFinishedGoods = async (
  data
): Promise<FinishedGoodListI | undefined | { data: { results: [] } } | FinishedGoodListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/finished-good`, payload).then((res) => {
    return res.data;
  });
};

export const updateFinishedGoods = async (data): Promise<FinishedGoodI | FinishedGoodErrI> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/finished-good/${id}`, data).then((res) => res.data);
};

export const getFullFinishedGoods = async (): Promise<
  FinishedGoodFullListI | { suppliers: [] }
> => {
  return get(`/customer/finished-good`).then((res) => res.data);
};

export const getSingleFinishedGoods = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/finished-good/${id}`).then((res) => res.data);
};

export const deleteFinishedGoods = async (id) => {
  return deleteRequest(`/customer/finished-good/${id}`);
};

export const getSingleDeletedFinishedGoods = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/finished-good/deleted/${id}`);
};

export const hardDeletefinishedGood = async (id) => {
  return deleteRequest(`/customer/finished-good/hard-delete/${id}`);
};
export const restorefinishedGood = async (id) => {
  return patch(`/customer/finished-good/restore/${id}`);
};
export const validateFinishedGoodsName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/finished-good/validate`, data);
};

export const FinishedGoodApi = {
  getFinishedGoods,
  addFinishedGoods,
  updateFinishedGoods,
  getSingleFinishedGoods,
  getFullFinishedGoods,
  deleteFinishedGoods,
  hardDeletefinishedGood,
  restorefinishedGood,
  getSingleDeletedFinishedGoods,
  validateFinishedGoodsName,
};
