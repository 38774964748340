import React from 'react';

const Image = () => {
  return (
    <React.Fragment>
      <svg
        width='18'
        height='14'
        viewBox='0 0 18 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.875 1.375C0.875 1.375 0.875 0.857233 1.24112 0.491117C1.24112 0.491117 1.60723 0.125 2.125 0.125H15.875C15.875 0.125 16.3928 0.125 16.7589 0.491117C16.7589 0.491117 17.125 0.857232 17.125 1.375V12.625C17.125 12.625 17.125 13.1428 16.7589 13.5089C16.7589 13.5089 16.3928 13.875 15.875 13.875H2.125C2.125 13.875 1.60723 13.875 1.24112 13.5089C1.24112 13.5089 0.875 13.1428 0.875 12.625V1.375ZM2.125 1.375V12.625H15.875V1.375H2.125Z'
          fill='#0160B9'
        />
        <path
          d='M12.7534 8.51219L16.0581 11.8169C16.1753 11.9341 16.3342 11.9999 16.5 11.9999C16.6658 11.9999 16.8247 11.9341 16.9419 11.8169C17.0592 11.6997 17.125 11.5407 17.125 11.3749C17.125 11.2092 17.0592 11.0502 16.9419 10.933L13.6373 7.6283C13.4644 7.45277 13.2328 7.35578 13.2328 7.35578C13.0011 7.25879 12.75 7.25879 12.75 7.25879C12.4989 7.25879 12.2672 7.35578 12.2672 7.35578C12.0356 7.45277 11.8594 7.6317 11.8594 7.6317L10.2534 9.23768L10.25 9.24107L6.76225 5.7533C6.58941 5.57777 6.35777 5.48078 6.35777 5.48078C6.12613 5.38379 5.875 5.38379 5.875 5.38379C5.62387 5.38379 5.39223 5.48078 5.39223 5.48078C5.16059 5.57777 4.98438 5.7567 4.98438 5.7567L1.05829 9.68275C0.941084 9.79996 0.875 9.95917 0.875 10.1249C0.875 10.1349 0.875241 10.145 0.875722 10.155C0.883185 10.3102 0.94819 10.457 1.05806 10.5669C1.17527 10.6841 1.33424 10.7499 1.5 10.7499C1.66576 10.7499 1.82473 10.6841 1.94194 10.5669L5.875 6.63379L5.87837 6.63719L9.35938 10.1182C9.53559 10.2971 9.76723 10.3941 9.76723 10.3941C9.99887 10.4911 10.25 10.4911 10.25 10.4911C10.5011 10.4911 10.7328 10.3941 10.7328 10.3941C10.9644 10.2971 11.1373 10.1216 11.1373 10.1216L12.75 8.50879L12.7534 8.51219Z'
          fill='#0160B9'
        />
        <path
          d='M12.125 4.8125C12.125 5.33027 11.7052 5.75 11.1875 5.75C10.6698 5.75 10.25 5.33027 10.25 4.8125C10.25 4.29473 10.6698 3.875 11.1875 3.875C11.7052 3.875 12.125 4.29473 12.125 4.8125Z'
          fill='#0160B9'
        />
      </svg>
    </React.Fragment>
  );
};

export default Image;
