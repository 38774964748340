import React from 'react';
import { Box, Typography } from '@mui/material';
import './cardInfo.css';
import moment from 'moment';

interface Props {
  title: string;
  actions: any;
  number: number;
}
const ActionsCard = ({ title, actions, number }: Props) => {
  return (
    <React.Fragment>
      <Box className='actions-parent-one'>
        <Typography className='action-title'>
          {number + 1}. {actions?.documentName}
        </Typography>
        <Typography className='action-sub-title'>
          Document Number : <span className='action-value'>{actions?.requestNumber}</span>
        </Typography>
        <Typography className='action-sub-title'>
          Action Needed : <span className='action-value'>{actions?.actionNeeded}</span>
        </Typography>
        <Typography className='action-sub-title'>
          Waiting Since :{' '}
          <span className='action-value'>
            {actions?.waitingSince
              ? moment(actions?.waitingSince).format('hh:mm A, DD MMM, YYYY')
              : '-'}
          </span>
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default ActionsCard;
