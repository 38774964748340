import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface NotificationI {
  id: string;
  message: string;
  redirectionLink: string;
  title: string;
  userId: string;
}
const HeadBox = styled(Box)(() => ({
  border: '1px solid #D2D1D1',
  borderRadius: '10px',
  padding: '20px 40px',
  marginBottom: '20px',
  '.horizontal-divide': {
    marginLeft: '22px',
    marginRight: '22px',
  },
}));
const RecentActivityCard = ({ notificationData }: any) => {
  const navigate = useNavigate();
  const maintenanceData = notificationData?.machine?.maintenanceDetails.find(
    (data) => data?._id == notificationData?.maintenance
  );
  return (
    <HeadBox>
      <Typography>
        <span
          className='notification-link'
          onClick={() => navigate(notificationData?.redirectionLink)}
        >
          {notificationData?.message}
        </span>
        {/* <span> created</span> */}
      </Typography>
      {(notificationData?.module === 'work_request' ||
        notificationData?.module === 'production_request') && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.workorder?.requestNumber}</span>
          <span className='horizontal-divide'>|</span>

          <span>{notificationData?.workorder?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Quantity {notificationData?.workorder?.totalQuantity}</span>
          <span className='horizontal-divide'>|</span>
          <span>
            Started on {moment(notificationData?.workorder?.startDate).format('DD-MM-YYYY')}
          </span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {/* {notificationData?.module === 'part' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.part?.name}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )} */}
      {/* {notificationData?.module === 'process' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.process?.name}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )} */}
      {notificationData?.module === 'machine' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.machine?.name}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {/* {notificationData?.module === 'product' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.product?.name}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )} */}
      {notificationData?.module === 'issue_mapping' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'user' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'tool' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'accessories' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'consumables' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'finished_goods' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'order_management' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'maintenance Request' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.user?.name}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'work_order_request' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'production Request' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'raw_material' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
      {notificationData?.module === 'maintenance_request' && (
        <Typography sx={{ marginTop: '10px' }}>
          <span>{notificationData?.requestNumber}</span>
          <span className='horizontal-divide'>|</span>
          {/* <span>{notificationData?.taskId?.nodeInfo?.stepName}</span> */}
          <span>{notificationData?.title}</span>
          <span className='horizontal-divide'>|</span>
          <span>Created At {moment(notificationData?.createdAt).format('DD-MM-YYYY')}</span>
          <span className='horizontal-divide'>|</span>
          <span>{notificationData?.createdBy?.name}</span>
        </Typography>
      )}
    </HeadBox>
  );
};

export default RecentActivityCard;
