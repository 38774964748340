import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import './../../src/style/modal.css';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px',
  },
  '.parent-close': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '.title-reWork': {
    fontSize: '18px',
    fontWeight: 600,
  },
}));
interface propI {
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  id?: string;
}

const ReWorkConfirm = (props: propI) => {
  const { open, handleClose, id } = props;
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box className='parent-close'>
              <CloseIcon onClick={handleClose} className='close-icon-small' />
            </Box>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Typography className='title-reWork'>
                  Add details to create the Rework Tasks?
                </Typography>
              </Box>

              <Box m={2} display={'flex'} justifyContent={'center'} mt={'20px'}>
                <CustomModalButton
                  variant='outlined'
                  className='button-box blue-bg ml-20'
                  //   onClick={() => {
                  //     if (isDeleteOrRestoreAll) {
                  //       handleDelete();
                  //       handleClose();
                  //     } else {
                  //       handleDelete(id);
                  //       handleClose();
                  //     }
                  //   }}
                  onClick={() => {
                    localStorage.setItem('isRework', 'true');
                    navigate(`/draft-work-order/${id}`, {
                      state: { activeTab: '2' },
                    });
                    handleClose();
                  }}
                >
                  Okey
                </CustomModalButton>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </>
  );
};
export default ReWorkConfirm;
