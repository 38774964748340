import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { taskWorkOrder } from '.././queryKeys/catalogue';
import { PartErrI } from '../Types/part';
import { taskWorlOrderApi } from '../api/taskWorkOrder';
import { workorder } from '../queryKeys/workorders';

export const useGetSinglTaskWorkOrder = (id, isEnable = true) => {
  return useQuery([taskWorkOrder.TASKWORKORDER, id], taskWorlOrderApi.getSingleTaskWorkOrder, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetTaskWorkOrder = (isEnabled, page, id, taskListType) => {
  return useQuery(
    [taskWorkOrder.TASKWORKORDERS, page, id, taskListType],
    taskWorlOrderApi.getTaskWorkOrder,
    {
      // initialData: { parts: { results: [] } },
      enabled: isEnabled,
      select: (res: any) => res?.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      //   //toast.success(data.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useAddTaskWorkOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.addTaskWorkOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      const data = res.data;
      // toast.success(data.message);
      navigate(`/draft-work-order/${data?.data?.workOrder}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useHandoverItemsTaskWorkOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.handoverItemsTaskWorkOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      const data = res.data;
      navigate(`/draft-work-order/${data?.data?.workOrder}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useReturnItemsTaskWorkOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.returnItemsTaskWorkOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      const data = res.data;
      // toast.success(data.message);
      navigate(`/draft-work-order/${data?.data?.workOrder}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useUpdateTaskWorkOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.updateTaskWorkOrder, {
    onSuccess: (res: any, id) => {
      const data = res.data;
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDER, id]);
      navigate(`/draft-work-order/${data?.workOrder}`);
      // navigate(
      //   materialType == 'Part'
      //     ? `/part-details/${data?.part?.id}`
      //     : `/raw-material-details/${data?.part?.id}`
      // );
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
// ! new one api
export const useUpdateApprovalNeeded = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.updateApprovalNeeed, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      queryClient.refetchQueries([workorder.WORKORDER, id]);
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDER, id]);
      // navigate(`/work-order`, { state: { activeTab: '1' } });
      // navigate(
      //   materialType == 'Part'
      //     ? `/part-details/${data?.part?.id}`
      //     : `/raw-material-details/${data?.part?.id}`
      // );
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useUpdateOutSourced = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.updateOutSourced, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([workorder.WORKORDER], id);
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDER, id]);
      // navigate(`/work-order`, { state: { activeTab: '1' } });
      // navigate(
      //   materialType == 'Part'
      //     ? `/part-details/${data?.part?.id}`
      //     : `/raw-material-details/${data?.part?.id}`
      // );
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteTaskWorkOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(taskWorlOrderApi.deleteTaskWorkOrder, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([taskWorkOrder.TASKWORKORDERS]);
      queryClient.removeQueries([taskWorkOrder.TASKWORKORDERS, id]);
      // toast.success(data.message);
      navigate(`/draft-work-order/${data?.data?.workOrder?.id}`);
    },
    // onError: (err) => {
    //   return err;
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};
