import PropTypes from 'prop-types';
import { Typography, Box, Radio, FormGroup, FormControlLabel } from '@mui/material';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

const ToolHolderDropdownWithRadioButton = ({
  title,
  searchPlaceholder,
  searchData,
  onSelect,
  handleSearch,
  selectedToolName,
  arrow = false,
  setArrow,
  setArrowAccessories,
  setArrowHolder,
  setArrowToolType,
  setArrowToolInsert,
}) => {
  const newPlaceholder = searchPlaceholder.replace(/Search/g, 'Select');

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography className='field-title-new'>
        {title}
        {title ? (
          <Box
            className='selected-search-box'
            onClick={(e) => {
              e.stopPropagation();
              setArrow(!arrow);
              setArrowAccessories(false);
              if (title == 'Tool Holder') {
                setArrowToolType(false);
                setArrowToolInsert(false);
                setArrowAccessories(false);
              } else if (title == 'Tool Type') {
                setArrowHolder(false);
                setArrowToolInsert(false);
                setArrowAccessories(false);
              } else {
                setArrowHolder(false);
                setArrowToolType(false);
                setArrowAccessories(false);
              }
            }}
          >
            {selectedToolName?.toolCategoryId?.name ? (
              <>
                {selectedToolName.toolCategoryId.name}
                {/* <Typography className='arrow'>
                  {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Typography> */}
              </>
            ) : (
              <Typography className='placeholder' style={{ color: '#C0C0C0', fontSize: '14px' }}>
                {newPlaceholder}
              </Typography>
            )}
            <Typography className='arrow'>
              {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </Typography>
          </Box>
        ) : (
          <Box
            className='parent-search-box'
            onClick={(e) => {
              e.stopPropagation();
              setArrow(!arrow);
            }}
          >
            {searchPlaceholder}
            <Typography className='arrow'>
              {arrow ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </Typography>
          </Box>
        )}
      </Typography>
      {arrow && (
        <Box
          className='parent-check'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            className='task-type-input'
            type='text'
            placeholder={searchPlaceholder}
            onChange={handleSearch} // Use the handleSearch function for onChange event
          />
          <Box className='type-menu-scroll'>
            {searchData?.map((data, index) => (
              <div key={index}>
                <div className='type_list'>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={data.id === selectedToolName?.id}
                          onChange={() => {
                            onSelect(data.id);
                            setArrow(false);
                          }}
                        />
                      }
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '10px' }}>
                            {data?.uploadedImages[0]?.url && (
                              <img
                                src={data?.uploadedImages[0]?.url}
                                alt=''
                                width='67px'
                                height='45px'
                              />
                            )}
                          </div>
                          <div>
                            <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                              {data?.toolCategoryId?.name}
                            </Typography>
                            <div style={{ display: 'flex', gap: '20px' }}>
                              <Typography variant='body2' display={'flex'}>
                                Length: {data?.length ? data?.length : '-'}
                              </Typography>
                              <Typography variant='body2'>
                                Size: {data?.size ? data?.size : '-'}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

ToolHolderDropdownWithRadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  selectedToolName: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  searchData: PropTypes.array.isRequired,
  setSearchData: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  arrow: PropTypes.any.isRequired,
  setArrow: PropTypes.any.isRequired,
  setArrowAccessories: PropTypes.any.isRequired,
  setArrowHolder: PropTypes.any.isRequired,
  setArrowToolType: PropTypes.any.isRequired,
  setArrowToolInsert: PropTypes.any.isRequired,
};

export default ToolHolderDropdownWithRadioButton;
