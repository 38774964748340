import { machineErrI, machineI } from '../Types/machine';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addMachine = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/machine`, data);
};

export const validateMachineName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/machine/validate`, data);
};

export const validateMachineNumber = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/machine/validate-number`, data);
};

export const getSingleMachine = async (data): Promise<machineI | machineErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/machine/${id}`).then((res) => res.data);
};
export const getSingleDeletedMachine = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/machine/deleted/${id}`);
};
export const updateMachine = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/machine/${id}`, data);
};
export const deleteMachine = async (id) => {
  return deleteRequest(`/customer/machine/${id}`);
};
export const getMachines = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/machine`, payload);
};
export const getFullMachines = async () => {
  return get(`/customer/machine/full-list`);
};
export const restoreMachine = async (id) => {
  return patch(`/customer/machine/restore/${id}`);
};
export const hardDeleteMachine = async (id) => {
  return deleteRequest(`/customer/machine/hard-delete/${id}`);
};
export const machineApi = {
  addMachine,
  validateMachineName,
  validateMachineNumber,
  getSingleMachine,
  updateMachine,
  deleteMachine,
  getMachines,
  getFullMachines,
  restoreMachine,
  hardDeleteMachine,
  getSingleDeletedMachine,
};
