import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import { useDeleteFinishedGoods, useGetFinishedGoodsSingle } from '../../hooks/finishedGoodsHooks';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 0px !important',
    fontWeight: '600 !important',
  },
}));

const FinshedGoodsDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: getFinishedGoods } = useGetFinishedGoodsSingle(id, !!id);
  const singleFinishedGoods = getFinishedGoods?.data;
  const Qty_over = singleFinishedGoods?.avlStock - singleFinishedGoods?.minimumStockOrder;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'finishedGoods' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'finishedGoods' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'finishedGoods' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: deleteSingleFinishedGoods,
    error: deleteFinishedGoodsError,
    isError: isDeleteFinishedGoodsError,
  }: any = useDeleteFinishedGoods();
  React.useEffect(() => {
    if (isDeleteFinishedGoodsError) {
      setErrorMessage(deleteFinishedGoodsError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isDeleteFinishedGoodsError]);
  const { mutate: readNotification } = useUpdateNotification();
  const handleFinishedGoodsDelete = () => {
    if (isDeleted) {
      // hardDeleteFinishedGoods(id);
    } else {
      deleteSingleFinishedGoods(id);
    }
  };

  let breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: `Finished Goods`,
      link: `/finished-goods`,
      activeTab: '1',
    },
    {
      title: _.get(singleFinishedGoods, '', 'Finished Goods'),
      link: `/finshedgoods-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleFinishedGoods, 'name', ''),
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-finishedgoods/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});
  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleFinishedGoods, '', 'Finished Goods')}
          backToLink='/finished-goods'
          isDetailPage={true}
          activeTab='1'
          detailPageData={detailPageData}
          handleDelete={handleFinishedGoodsDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          // handleRestore={handlePartRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={6}>
                    <TextFiled>Finished Goods Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleFinishedGoods, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>Finished Goods Number </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleFinishedGoods, 'number', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Other Details
                </Typography>
                <Grid container display={'flex'}>
                  {/* //! for future use */}
                  {/* <Grid item xs={4}>
                    <TextFiled>Manucturer </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleFinishedGoods, 'manufacturer', '')}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={4}>
                    <TextFiled>Quantity </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleFinishedGoods, 'quantity', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Date Bought </TextFiled>
                    <Typography className='span-color pl-sub-text basic-Details-Box-store-fg'>
                      {singleFinishedGoods?.dateBought
                        ? moment(_.get(singleFinishedGoods, 'dateBought', '-')).format(
                            'hh:mm A, DD MMM, YYYY'
                          )
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className='usage-title'> View Report</Typography>
            </Grid>
          </Grid>
        </Box>
        <Loading
          // loading={
          //   id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          // }
          background='white'
          loaderColor='#265AA8'
        />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
      </HeadBoxWrapper>
    </>
  );
};
export default FinshedGoodsDetails;
