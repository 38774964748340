import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { partApi } from '../api/part';
import { accessoryApi } from '../api/accessory';
import { accessory, part } from '../queryKeys/catalogue';
import { PartErrI } from '../Types/part';

export const useGetAccessory = (isEnabled, page) => {
  return useQuery([accessory.ACCESSORYS, page], accessoryApi.getAccessory, {
    // initialData: { data: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddAccessory = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(accessoryApi.addAccessory, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([accessory.ACCESSORYS]);
      queryClient.invalidateQueries([accessory.ACCESSORYSFULL]);
      const data = res;
      navigate(`/accessories`, { state: { activeTab: '2' } });
    },
    onError: (err: PartErrI) => {
      return err;
    },
  });
};

export const useUpdateAccessory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(accessoryApi.updateAccessory, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([accessory.ACCESSORYS]);
      queryClient.invalidateQueries([accessory.ACCESSORY, id]);
      navigate(`/accessories`, { state: { activeTab: '2' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useGetFullAccessory = (isEnabled) => {
  return useQuery([accessory.ACCESSORYS + 'FULL'], accessoryApi.getFullAccessory, {
    // initialData: { suppliers: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    onSuccess: () => {
      // const data = res?.data;
    },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetAccessorySingle = (id, isEnable = true) => {
  return useQuery([accessory.ACCESSORY, id], accessoryApi.getSingleAccessory, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteAccessory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(accessoryApi.deleteAccessory, {
    onSuccess: (res, id) => {
      // const data = res.data;
      // ! new update for future use
      queryClient.invalidateQueries([accessory.ACCESSORYS]);
      // queryClient.invalidateQueries([accessory.ACCESSORY, id]);
      // toast.success(data?.message);
      navigate(`/accessories`, { state: { activeTab: '2' } });
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useGetDeletedAccessory = (id, isEnable = true) => {
  return useQuery([accessory.ACCESSORYS + 'DELETED', id], accessoryApi.getSingleDeletedAccessory, {
    enabled: isEnable,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useHardDeleteAccessory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(accessoryApi.hardDeleteAccessory, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([accessory.ACCESSORYS]);
      queryClient.invalidateQueries([accessory.ACCESSORY, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useRestoreAccessory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(accessoryApi.restoreAccessory, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([accessory.ACCESSORYS]);
      queryClient.invalidateQueries([accessory.ACCESSORY, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useValidateAccessoryName = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(accessoryApi.validateAccessoryName, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    //toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  });
};
