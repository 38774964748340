import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { RxDotFilled } from 'react-icons/rx';
import TicketCommitCard from '../pages/tickets/TicketsCommitCard';
import CommitInput from '../pages/commonComponents/CommitInput';
import {
  useDeleteTicket,
  useGetComments,
  useGetCommentsInfinite,
  useGetTicket,
  useTicketResolved,
} from '../hooks/ticketHooks';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import DeleteWarningModal from './DeleteWarningModal';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../queryKeys/userManagement';
import DeleteIcon_new from '../assets/svg/DeleteIcon_new';
import './../assets/css/modal.css';
import CommentIcon from '../assets/svg/CommentIcon';

const HorizontalDivider = styled(Divider)(() => ({
  margin: '0 !important',
}));

const HeadBox = styled(Box)(() => ({
  paddingBottom: '10px',

  '.head-action-text': {
    fontSize: '18px',
    fontWeight: 600,
    color: '#020202',
  },
  '.right-icon-text': {
    fontSize: '14px',
    color: '#0160B9',
    fontWeight: 600,
    paddingLeft: '5px',
  },
  '.issue-type': {
    color: '#8A8A8A',
    fontSize: '14px',
    marginBottom: '4px',
  },
  '.assign-text': {
    fontSize: '14px',
    fontWeight: 700,
    color: '#777676',
  },
  '.commit-box': {
    height: '250px',
    overflow: 'scroll',
    margin: '20px 24px',
  },
  '.underline': {
    textDecoration: 'underline',
  },
  '.file-wrapper': {
    marginTop: '10px',
  },
  '.comment-message-number': {
    fontSize: '14px',
    color: '#8A8A8A',
  },
  '.voice-note': {
    width: '100%',
  },
  '.comment-box': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
  },
  '.comment-card-box': {
    padding: '0px 24px 24px',
  },
  '.padding-box': {
    padding: '16px 24px 16px 24px',
  },
  '.mx-24': {
    margin: '0px 24px',
  },
}));

interface propI {
  open: boolean;
  handleClose: () => void;
  id: string;
  ticketInfo: {
    action?: string;
    assignedTo?: string;
    createdAt?: string;
    createdBy?: string;
    id?: string;
    no?: number;
    status?: string;
    ticketOrigin?: string;
    typeOfIssue?: string;
    reasonOfIssue?: string;
    images?: { [key: string]: string };
  };
}
const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const TicketCommentModal = (props: propI) => {
  const { open, handleClose, id, ticketInfo } = props;
  const queryClient = useQueryClient();
  const userInfo: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const isAssignedToMe = props?.ticketInfo?.assignedTo?.includes(userInfo?.data?.user?.name);
  const isCreatedByMe = props?.ticketInfo?.createdBy === userInfo?.data?.user?.name;
  const { data: getTicket }: any = useGetTicket(id);
  const images = getTicket?.ticket?.uploadedData;
  const { mutate: completeTask } = useTicketResolved();

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  // const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
  // const isxlScreen = useMediaQuery(theme.breakpoints.only('xl'));

  const style = {
    position: 'absolute' as const,
    top: '10%',
    left: isMdScreen ? '22%' : '35.9%',
    overflow: 'auto',
    height: 'auto',
    // transform: 'translate(-50%, -50%)',
    // top: '50%',
    // left = '50%',
    width: '600px',
    maxHeight: '78%',
    display: 'block',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    // padding: '16px 30px 20px 30px',
  };
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const { data: getComments } = useGetComments(id);
  const { data: getInfiniteComments, fetchNextPage }: any = useGetCommentsInfinite(id);
  const infiniteComments = getInfiniteComments?.map(
    (currentPage) => currentPage?.data?.comments?.results
  );
  const fetchedComments = infiniteComments?.flat()?.reverse();
  const totalComments = getInfiniteComments?.[0]?.data?.comments?.totalResults;

  const navigate = useNavigate();
  const [chats, setChats] = useState(getComments);
  const [deleteRecord, setDeleteRecord] = useState(false);
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);
  const messagesEndRef = useRef<any>();
  const { mutate: deleteTicket } = useDeleteTicket();
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };
  const handleSendMessage = () => {
    const updatedChats = chats;
    setChats(updatedChats);
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <HeadBox>
            <Box>
              <Box>
                {!isCreatedByMe ? (
                  <Box className='padding-box'>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography className='head-action-text'>
                        {_.get(ticketInfo, 'action', '-')}
                      </Typography>
                    </Box>
                    <Box mt={'19px'} mb={1}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className='issue-type'>Type of issue</Typography>
                          <Typography className='span-color'>
                            {ticketInfo?.typeOfIssue || '-'}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className='issue-type'>Reason of issue</Typography>
                          <Typography className='span-color'>
                            {ticketInfo?.reasonOfIssue || '-'}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box></Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className='padding-box'
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography className='head-action-text'>
                        {_.get(ticketInfo, 'action', '-')}
                      </Typography>
                    </Box>
                    <Box>
                      <Box display={'flex'} alignItems={'center'} pr={1}>
                        <RxDotFilled size={'24px'} color={'#F29E38'} />
                        <Typography className='due-by'>Pending</Typography>
                      </Box>
                      {/* <VerticalDivider orientation='vertical' /> */}
                      <Box className='table-icons' pl={1} mt={2}>
                        {/* <Box
                          pr={'10px'}
                          onClick={() => navigate(`/edit-ticket/${id}`)}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <EditIcon_new /> <Typography className='right-icon-text'>Edit</Typography>
                        </Box> */}
                        <Box
                          onClick={() => handleDeleteOpen()}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <DeleteIcon_new />{' '}
                          <Typography className='right-icon-text'>Delete</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* //! TODO for future use */}
                {/* <Box display={'flex'}>
                  <Box display={'flex'} alignItems={'center'} pr={1}>
                    <RxDotFilled size={'32px'} color={'#F29E38'} />
                    <Typography className='due-by'>Pending</Typography>
                  </Box>
                  <VerticalDivider orientation='vertical' />
                  <Box className='table-icons' pl={1}>
                    <Box pr={'10px'} onClick={() => navigate(`/edit-ticket/${id}`)}>
                      <EditIcon />
                    </Box>
                    <Box onClick={() => handleDeleteOpen()}>
                      <DeleteIcon />
                    </Box>
                  </Box>
                </Box> */}
              </Box>
              <HorizontalDivider />
              {/* //! TODO for future use */}
              {/* <Box mb={1}>
                <Typography color={'#265AA8'} mb={1}>
                  Voice Note
                </Typography>
                <audio id='player' controls className='voice-note'></audio>
              </Box>
              <HorizontalDivider /> */}
              {/* Todo */}
              {/* <Box p={3}>
                <AudioPlayers />
              </Box> */}
              <CustomTypography>References</CustomTypography>
              {/* <Box display={'flex'} width={'100%'} flexWrap={'wrap'}> */}
              <Grid container className='pl-sub-text'>
                {images?.map((data, ind) => {
                  return (
                    <Grid item xs={3} key={ind}>
                      <Box className='file-wrapper'>
                        {data?.fileType === 'video' && (
                          <video controls src={data?.url} width={'120px'} height={'80px'} />
                        )}
                        {data?.fileType === 'image' && (
                          <img src={data?.url} width={'120px'} height={'80px'} />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
                <CircularProgress
                  className='margin-top-10px'
                  variant={isLoading ? 'indeterminate' : 'determinate'}
                />
              </Grid>
              {/* </Box> */}

              <HorizontalDivider />
              {/* //! TODO for future use */}
              {/* <Box display={'flex'} justifyContent={'space-between'}>
                <Typography className='assign-text'>
                  assigned to{' '}
                  <span className='underline'>
                    {ticketInfo?.assignedTo?.split(',')?.[0] || '-'}
                  </span>
                  , Backup -{' '}
                  <span className='underline'>
                    {ticketInfo?.assignedTo?.split(',')?.[1] || '-'}
                  </span>
                </Typography>
                <Typography className=''>
                  Created on {_.get(ticketInfo, 'createdAt', '-')}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                <Box>
                  <Typography className=''>{_.get(ticketInfo, 'ticketNo', '-')}</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <ChatIcon />
                  <Typography className='comment-message-number' ml={1}>
                    {totalComments ? totalComments : '0'}
                  </Typography>
                </Box>
              </Box> */}
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <CustomTypography>Assigned to</CustomTypography>
                    <Typography className='span-color pl-sub-text'>
                      {ticketInfo?.assignedTo?.split(',')?.[0] || '-'}
                    </Typography>
                    <CustomTypography>Workorder Number</CustomTypography>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(ticketInfo, 'ticketNo', '-')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTypography>Backup</CustomTypography>
                    <Typography className='span-color pl-sub-text'>
                      {ticketInfo?.assignedTo?.split(',')?.[1] || '-'}
                    </Typography>
                    <CustomTypography> Created on</CustomTypography>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(ticketInfo, 'createdAt', '-')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className='pl-sub-text comment-box'>
                      <CommentIcon />
                      <Typography className='comment-message-number' ml={1}>
                        {totalComments ? totalComments : '0'} Comments
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                className='commit-box'
                onScroll={(e) => {
                  if (e.currentTarget.scrollTop === 0) {
                    fetchNextPage();
                  }
                }}
              >
                {fetchedComments?.map((data, ind) => {
                  return (
                    <Box key={ind}>
                      <TicketCommitCard data={data} />
                      {ind === fetchedComments?.length - 1 && <div ref={messagesEndRef}></div>}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className='comment-card-box'>
              <CommitInput
                id={id}
                handleSendMessage={handleSendMessage}
                setIsLoading={setIsLoading}
                allFiles={images}
              />
            </Box>
            {isAssignedToMe && (
              <>
                {/* <Divider /> */}
                <Box display={'flex'} justifyContent={'end'}>
                  <CustomModalButton
                    variant='contained'
                    className={
                      getTicket?.ticket?.ticketStatus === 'closed'
                        ? 'disable-btn mx-24'
                        : 'blue-bg mx-24'
                    }
                    disabled={getTicket?.ticket?.ticketStatus === 'closed'}
                    onClick={() => {
                      if (getTicket?.ticket?.ticketStatus === 'pending') {
                        completeTask(id);
                      }
                    }}
                  >
                    Resolve Task
                  </CustomModalButton>
                </Box>
              </>
            )}
          </HeadBox>
          {deleteRecord && id && (
            <DeleteWarningModal
              open={deleteRecord}
              handleOpen={handleDeleteOpen}
              handleClose={handleDeleteClose}
              handleDelete={deleteTicket}
              id={id}
            />
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default TicketCommentModal;
