import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import {
  useDeleteDesktopUser,
  useGetDeletedDesktopUser,
  useGetDesktopUser,
  useHardDeleteDesktopUser,
  useRestoreDesktopUser,
} from '../../hooks/userManagementHooks';
import * as _ from 'lodash';
import CustomModalButton from '../commonComponents/CustomModalButton';
import ResetPassword from '../../Modals/ResetPassword';
import Loading from 'react-fullscreen-loading';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import './../../assets/css/detailsInformationBox.css';

// New Style using ClassName

const HeadBoxWaper = styled(Box)(() => ({
  '.btn-margin': {
    margin: '24px 0px 0px 24px',
  },
}));
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));
const UserDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = useLocation();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const { mutate: readNotification } = useUpdateNotification();
  //desktop user
  const {
    data: singleDesktopUser,
    isSuccess: isDesktopSuccess,
    isError,
  } = useGetDesktopUser(id, !!id && !isDeleted);
  const { data: getDesktopUserDeleted, isSuccess: isSuccessDeleted } = useGetDeletedDesktopUser(
    id,
    !!id && isDeleted
  );

  const currentUser = isDeleted ? getDesktopUserDeleted?.user : singleDesktopUser?.user;
  const { mutate: deleteSingleDesktopUser } = useDeleteDesktopUser();
  const { mutate: hardDeleteDesktopUser } = useHardDeleteDesktopUser();
  const { mutate: restoreDesktopUser } = useRestoreDesktopUser();
  // const modulePermission = singleDesktopUser?.userPermission?.map(
  //   (access: any) => access.userModule
  // );
  const handleDesktopUserDelete = () => {
    if (isDeleted) {
      hardDeleteDesktopUser(id);
    } else {
      deleteSingleDesktopUser(id);
    }
  };

  const handleDesktopUserRestore = () => {
    if (isRestore) {
      restoreDesktopUser(id);
    } else {
      // nothing to restore
    }
  };

  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '1',
    },
    {
      title: _.get(currentUser, 'name', `-`),
      link: `/desktop-user-details/${id}`,
    },
  ];

  const detailPageData = [
    {
      title: 'edit',
      // link: `/edit-mobile-user/${id}`,
      link: `/edit-desktop-user/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];

  // const { mutate: deleteDesktopUser } = useDeleteDesktopUser();
  // const { mutate: deleteMobileUser } = useDeleteMobileUser();
  // const handleDelete = () => {
  //   if (state?.isMobile) {
  //     deleteMobileUser(id);
  //   } else {
  //     deleteDesktopUser(id);
  //   }
  // };

  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(currentUser, 'name', `-`)}
        backToLink='/user-management'
        activeTab='1'
        isDetailPage={true}
        detailPageData={detailPageData}
        isStatusShow={true}
        isOnline={false}
        // handleDelete={handleDelete}
        handleDelete={handleDesktopUserDelete}
        isDeleted={isDeleted}
        isRestore={isRestore}
        handleRestore={handleDesktopUserRestore}
      />
      <HeadBoxWaper className='main-padding'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className='details-box-border padding-bottom'>
              <Typography id='modal-modal-title' className='details-sub-title'>
                Basic details
              </Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <TextFiled>Name </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(currentUser, 'name', '')}
                  </Typography>
                  <TextFiled>Department </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(currentUser?.department, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextFiled>ID </TextFiled>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(currentUser, 'uniqId', '')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className='details-box-border padding-bottom'>
              <Typography id='modal-modal-title' className='details-sub-title'>
                Email ID
              </Typography>
              <TextFiled>Email ID for login </TextFiled>
              <Typography className='span-color pl-sub-text'>
                {_.get(currentUser, 'email', '')}
              </Typography>
              <Box>
                <CustomModalButton className='blue-bg btn-margin' onClick={handleModalOpen}>
                  Reset Password
                </CustomModalButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className='details-box-border padding-bottom'>
              <Typography id='modal-modal-title' className='details-sub-title'>
                {' '}
                Module Access
              </Typography>
              <TextFiled>Role</TextFiled>
              <Typography className='span-color pl-sub-text'>
                {_.get(currentUser?.userRole, 'name', '')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* <Divider />
        <Box>
          <Typography id='modal-modal-title' className='title-typography'>
            Module Access
          </Typography>
          <Typography className='gray'>
            Role:
            <Typography className='span-color'>{_.get(currentUser?.userRole, 'name', '')}</Typography>
          </Typography>
        </Box>
        <Divider />

        <Box>
          <Typography id='modal-modal-title' className='title-typography'>
            Email ID
          </Typography>
          <Typography className='gray'>
            Email ID for login:{' '}
            <Typography className='span-color'>{_.get(currentUser, 'email', '')}</Typography>
          </Typography>
        </Box>
        <Box className='mt-45'>
          <CustomModalButton className='yellow-bg' onClick={handleModalOpen}>
            Reset Password
          </CustomModalButton>
        </Box> */}
        {isModalOpen && (
          <ResetPassword
            isOpen={isModalOpen}
            handleOpen={handleModalOpen}
            handleClose={handleModalClose}
            data={currentUser}
          />
        )}
        <Loading
          // loading={!isDesktopSuccess ? (!isError ? true : false) : false}
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isDesktopSuccess)
              ? !isError
                ? true
                : false
              : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWaper>
    </React.Fragment>
  );
};
export default UserDetails;
