const dashboard = {
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_ISSUE_LIST: 'DASHBOARD_ISSUE_LIST',
  DASHBOARD_ACTIVITY_LIST: 'DASHBOARD_ACTIVITY_LIST',
  DASHBOARD_MACHINE_LIST: 'DASHBOARD_MACHINE_LIST',
  DASHBOARD_ANALYTICS_LIST: 'DASHBOARD_ANALYTICS_LIST',
  DASHBOARD_PRODUCTION_LIST: 'DASHBOARD_PRODUCTION_LIST',
  DASHBOARD_ACTIONS_LIST: 'DASHBOARD_ACTIONS_LIST',
};
export { dashboard };
