import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CustomLoginButton = styled(Button)(() => ({
  padding: '10px 16px 10px 16px !important',
  borderRadius: '4px !important',
  fontSize: '16px !important',
  fontWeight: '500 !important',
  textTransform: 'capitalize',
}));

export default CustomLoginButton;
