import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import { useDeletePart, useHardDeletePart, useRestorePart } from '../../hooks/partHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import { useGetCustomerSingle } from '../../hooks/cusromerHooks';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 0px !important',
    fontWeight: '600 !important',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.field-title': {
    marginLeft: '10px',
  },
  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: '10px',
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
}));

const CustomerDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: getCustomer } = useGetCustomerSingle(id, !!id);
  const singleCustomer = getCustomer?.data;
  // const { data: getPartDeleted, isSuccess: isSuccessDeleted } = useGetDeletedPart(
  //   id,
  //   !!id && isDeleted
  // );
  // const singleCustomer = isDeleted ? getPartDeleted?.part : getPart?.part;
  // const Qty_over = singleCustomer?.avlStock - singleCustomer?.minimumStockOrder;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: deletesingleCustomer,
    error: deletePartError,
    isError: isDeletePartError,
  }: any = useDeletePart();
  React.useEffect(() => {
    if (isDeletePartError) {
      setErrorMessage(deletePartError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isDeletePartError]);
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: restorePart } = useRestorePart();
  const { mutate: readNotification } = useUpdateNotification();
  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeletePart(id);
    } else {
      deletesingleCustomer(id);
    }
  };

  const handlePartRestore = () => {
    if (isRestore) {
      restorePart(id);
    } else {
      // nothing to restore
    }
  };
  let breadCrumbData = [
    {
      title: 'Customer Order',
      link: '/customer-Order',
    },
    // {
    //   title: `Tools`,
    //   link: `/catalogue`,
    //   activeTab: '1',
    // },
    {
      title: _.get(singleCustomer, 'name', ''),
      link: `/customer-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleCustomer, 'name', ''),
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-customer-order/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});
  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleCustomer, 'name', 'Tool')}
          backToLink='/customer-Order'
          isDetailPage={true}
          activeTab='1'
          detailPageData={detailPageData}
          handleDelete={handlePartDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handlePartRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={6}>
                    <TextFiled>Customer Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleCustomer, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>Customer Order Number </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleCustomer, 'orderNumber', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFiled>Remarks </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleCustomer, 'remarks', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography className='usage-title'> Usage</Typography>
            </Grid> */}

            {/* <Grid item xs={7}>
              <Box className='details-box-border padding-bottom'>
                <Box display={'flex'}>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Product
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Delivery Date Requested
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography id='modal-modal-title' className='details-sub-title'>
                      Delivery Date Promised
                    </Typography>
                  </Grid>
                </Box>
                {singleCustomer?.productDetails.map((data, ind) => (
                  <Grid container item xs={12} key={ind}>
                    <Grid item xs={2.25}>
                      <Typography className='span-color pl-sub-text'>{data?.product}</Typography>
                    </Grid>
                    <Grid item xs={2.25}>
                      <Typography className='span-color pl-sub-text'>{data?.quantity}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className='span-color pl-sub-text'>
                        {data?.deliveryDateRequested}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className='span-color pl-sub-text'>
                        {data?.deliveryDatePromised}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid> */}
          </Grid>
          <Grid item xs={5.4} mt={'30px'}>
            <Grid item xs={8} container className='process-header checklist-details'>
              <Grid item xs={0.4} textAlign={'center'}>
                <Typography className='process-header-field-title'>Product</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'center'}>
                <Typography className='process-header-field-title'>Quantity</Typography>
              </Grid>
              <Grid item xs={4} textAlign={'center'}>
                <Typography className='process-header-field-title'>
                  Delivery Date Requested
                </Typography>
              </Grid>
              <Grid item xs={3} textAlign={'center'}>
                <Typography className='process-header-field-title'>
                  Delivery Date Promised
                </Typography>
              </Grid>
            </Grid>
            {/* </Box> */}
            {singleCustomer?.productDetails.map((data, ind) => (
              <Grid container item xs={8} className='border-items checklist-details' key={ind}>
                <Grid item xs={0.2} textAlign={'center'} className='field-title' mr={''}>
                  <Box>
                    <Typography className='details-mr9'>{data?.product}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>{data?.quantity}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>
                      {data?.deliveryDateRequested
                        ? moment(data?.deliveryDateRequested).format('hh:mm A, DD MMM, YYYY')
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} textAlign={'center'}>
                  <Box>
                    <Typography className='details-mr10'>
                      {data?.deliveryDateRequested
                        ? moment(data?.deliveryDateRequested).format('hh:mm A, DD MMM, YYYY')
                        : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Loading
          // loading={
          //   id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          // }
          background='white'
          loaderColor='#265AA8'
        />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
      </HeadBoxWrapper>
    </>
  );
};
export default CustomerDetails;
