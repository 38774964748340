import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styled from '@emotion/styled';
import TableComponent from '../commonComponents/Table';
import { useLocation } from 'react-router-dom';
import CommentIcon from '../../assets/svg/CommentIcon';
import { useDeleteTicket, useGetTickets, useTicketResolved } from '../../hooks/ticketHooks';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import { RxDotFilled } from 'react-icons/rx';
import TicketCommentModal from '../../Modals/TicketCommentModal';
import moment from 'moment';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import ErrorPopup from '../../Modals/ErrorPopup';
import IssuesConfirmModal from '../../Modals/IssuesConfirmNew';

const GridWrapper = styled(Grid)(() => ({
  '.tab-list-box': {
    marginBottom: '18px',
  },
  '.tab-head': {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  '.tab-panels': {
    padding: '2px 0px 0px 0px',
  },
  '.grid-table': {
    padding: '24px 0px 0px 0px',
  },
  '.tab-panel-box': {
    borderBottom: '1px solid #DEDEDE',
  },
  '.action': {
    color: '#0160B9',
    fontWeight: '600',
  },
}));

const MyTickets = () => {
  const { state } = useLocation();
  const [value, setValue] = React.useState('1');
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { mutate: completeTask } = useTicketResolved();
  const [currentTicketInfo, setCurrentTicketInfo] = useState<{
    action?: string;
    assignedTo?: string;
    createdAt?: string;
    createdBy?: string;
    id?: string;
    no?: number;
    status?: string;
    ticketOrigin?: string;
  }>({});
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);
  const [commitOpen, setCommitOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const [id, setId] = useState(false);
  const handleResolveOpen = () => setResolveOpen(true);
  const handleResolveClose = () => setResolveOpen(false);
  const handleCommitOpen = () => setCommitOpen(true);
  const handleCommitClose = () => setCommitOpen(false);
  const [type, setType] = useState('pending');
  const [page, setPage] = useState(1);
  const PagePath = { page, limit: 10, type };
  const {
    data: ticketListInfo,
    isLoading: isTicketLoading,
    isFetching: isTicketFetching,
    isSuccess: ticketSuccess,
  } = useGetTickets(true, PagePath);
  const ticketList = ticketListInfo?.results;

  const {
    mutate: deleteTicket,
    isError: isDeleteError,
    error: tikitDeleteError,
  } = useDeleteTicket(value);
  const AssignedToMeCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.3 },
    { field: 'issueType', headerName: 'Issue Type', flex: 1 },
    { field: 'ticketNo', headerName: 'Issue Number', flex: 1 },
    { field: 'Source', headerName: 'Source', flex: 1 },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 0.9,
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Resolved on',
      flex: 1,
    },
    {
      field: 'Assignee',
      headerName: 'Resolved By',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            {/* //! TODO for future use */}
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '1' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
                setCurrentId(params.row.id);
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];

  const createdByMeCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.4 },
    { field: 'assignedTo', headerName: 'Assigned to', flex: 1 },
    {
      field: 'ticketOrigin',
      headerName: 'Ticket Origin',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box pr={'25px'} display='flex' alignItems={'center'}>
              {params.row.status === 'pending' ? (
                <>
                  <RxDotFilled fontSize={'24px'} color={'#FFC229'} /> {params.row.status}
                </>
              ) : (
                <Box pl={'25px'}>{params.row.status}</Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 200,
      width: 127,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'16px'}
              onClick={() => {
                setCurrentId(params.row.id);
                setCurrentTicketInfo(params.row);
                handleCommitOpen();
              }}
            >
              <CommentIcon />
            </Box>
            {/* //! TODO for future use */}
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '3' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];

  const AllTicketsCols = [
    { field: 'no', headerName: 'Sr.No', flex: 0.3 },
    { field: 'issueType', headerName: 'Issue Type', flex: 1 },
    { field: 'ticketNo', headerName: 'Issue Number', flex: 1 },
    { field: 'Source', headerName: 'Source', flex: 1 },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 0.9,
    },
    // {
    //   field: 'ticketOrigin',
    //   headerName: 'Ticket Origin',
    //   flex: 1,
    // },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Box className='table-icons'>
    //         <Box pr={'22px'} display='flex' alignItems={'center'}>
    //           {params.row.status === 'pending' ? (
    //             <>
    //               <RxDotFilled fontSize={'24px'} color={'#FFC229'} /> {params.row.status}
    //             </>
    //           ) : (
    //             <Box pl={'22px'}>{params.row.status}</Box>
    //           )}
    //         </Box>
    //       </Box>
    //     );
    //   },
    // },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
    },
    {
      field: 'Assignee',
      headerName: 'Assignee',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            {params?.row?.Assignee ? (
              <>
                <Box
                  // p={'16px'}
                  // justifyContent={'space-between !important'}
                  padding={'0px 10px 0px 0px'}
                  className='action'
                  onClick={() => {
                    // completeTask(params?.row?.id);
                    setCurrentId(params.row.id);
                    handleResolveOpen();
                  }}
                >
                  Resolve
                </Box>
              </>
            ) : (
              <Box pr={'16px'} className='action'>
                Assign
              </Box>
            )}
            {/* //! TODO for future use */}
            {/* <Box
              pr={'16px'}
              onClick={() =>
                navigate(`/edit-ticket/${params.row.id}`, { state: { activeTab: '1' } })
              }
            >
              <EditIcon_new />
            </Box> */}
            <Box
              padding={'4px 0px 0px 0px !important'}
              onClick={() => {
                setCurrentId(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];
  const AllTicketsRows = ticketList?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      Assignee: `Assigned to ${data.primaryAssignee?.name || '-'}`,
      issueType: data?.typeOfIssueId?.issueType,
      createdBy: data?.createBy?.name,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      ticketStatus: data?.ticketStatus,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      images: data?.images,
      Source: '-',
    };
  });
  const AssignedToMeRows = ticketList?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      Assignee: `Assigned to ${data.primaryAssignee?.name || '-'}`,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      status: data?.ticketStatus,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      createdBy: data?.createBy?.name,
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      Source: '-',
      images: data?.images,
      issueType: data?.typeOfIssueId?.issueType,
    };
  });
  const createdByMeRows = ticketList?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: startIndex + ind + 1,
      assignedTo: `${data.primaryAssignee?.name || '-'}`,
      ticketOrigin: data.ticketOrigin,
      action: data.action,
      status: data?.ticketStatus,
      createdBy: data?.createBy?.name,
      createdAt: data?.createdAt ? moment(data?.createdAt)?.format('hh:mm A, DD MMM, YYYY') : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      typeOfIssue: data?.natureOfIssue,
      reasonOfIssue: data?.typeOfIssue,
      ticketNo: data?.ticketNo,
      images: data?.images,
    };
  });

  const AllTicketsTableData = {
    columns: AllTicketsCols || [],
    rows: AllTicketsRows || [],
    detailsRedirection: '/ticket-details',
  };

  const AssignedToMeTableData = {
    columns: AssignedToMeCols || [],
    rows: AssignedToMeRows || [],
    detailsRedirection: '/ticket-details',
  };

  const CreatedByMeTableData = {
    columns: createdByMeCols || [],
    rows: createdByMeRows || [],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BtnAllTickets = {
    title: 'Report Issue',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };

  const BtnAssignedToMe = {
    title: 'Create Ticket',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };

  const BtnCreatedByMe = {
    title: 'Create Ticket',
    linkData: [
      {
        menuItem: 'Create Ticket',
        to: '/add-ticket',
      },
    ],
  };
  useEffect(() => {
    if (isDeleteError) {
      tikitDeleteError && setErrorMessage(tikitDeleteError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isDeleteError, tikitDeleteError]);
  return (
    <React.Fragment>
      <BreadcrumbNavigation breadCrumbData={null} pageTitle={`Issues`} backToLink={null} />
      <GridWrapper container>
        <Grid item xs={12}>
          <Box className='main-padding'>
            <TabContext value={value}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className='tab-list-box tab-panel-box'>
                    <TabList onChange={handleChange} aria-label='lab API tabs example'>
                      <Tab
                        label='Current Issues'
                        value='1'
                        className='tab-head'
                        onClick={() => setType('pending')}
                      />
                      <Tab
                        label='Resolved Issues'
                        value='2'
                        className='tab-head'
                        onClick={() => setType('closed')}
                      />
                      {/* //! for future use tabs */}
                      {/* <Tab
                        label='Created by me'
                        value='3'
                        className='tab-head'
                        onClick={() => setType('createdByMe')}
                      /> */}
                    </TabList>
                  </Box>
                </Grid>
              </Grid>
              <TabPanel value='1' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <TableComponent
                      data={BtnAllTickets}
                      isHideDropDown={true}
                      tableData={AllTicketsTableData}
                      isOptionShow={true}
                      isLoading={isTicketLoading || isTicketFetching}
                      page={page}
                      setPage={setPage}
                      listName={'Issues List'}
                      paginationData={ticketListInfo}
                      isNoEntityShow={AllTicketsTableData?.rows?.length <= 0 && ticketSuccess}
                      moduleName={'Ticket'}
                      redirectPage={'/add-ticket'}
                      totalResults={ticketListInfo?.totalResults}
                      isFilter={false}
                      iSColumnsButton={false}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value='2' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                    <TableComponent
                      data={BtnAssignedToMe}
                      isHideDropDown={true}
                      tableData={AssignedToMeTableData}
                      isOptionShow={false}
                      isLoading={isTicketLoading || isTicketFetching}
                      page={page}
                      setPage={setPage}
                      paginationData={ticketListInfo}
                      listName={'Resolved Issues'}
                      isNoEntityShow={AssignedToMeTableData?.rows?.length <= 0 && ticketSuccess}
                      moduleName={'Resolved Issues'}
                      redirectPage={'/add-ticket'}
                      totalResults={ticketListInfo?.totalResults}
                      isFilter={false}
                      iSColumnsButton={false}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              {/* //! for future use this tab */}
              {/* <TabPanel value='3' className='tab-panels'>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                    <TableComponent
                      data={BtnCreatedByMe}
                      isHideDropDown={true}
                      tableData={CreatedByMeTableData}
                      isOptionShow={true}
                      isLoading={isTicketFetching || isTicketLoading}
                      page={page}
                      setPage={setPage}
                      paginationData={ticketListInfo}
                      listName={'Created by me List'}
                      isNoEntityShow={CreatedByMeTableData?.rows?.length <= 0 && ticketSuccess}
                      moduleName={'Ticket'}
                      redirectPage={'/add-ticket'}
                      totalResults={ticketListInfo?.totalResults}
                      isFilter={false}
                      iSColumnsButton={false}
                    />
                  </Grid>
                </Grid>
              </TabPanel> */}
            </TabContext>
          </Box>
        </Grid>
        {value === '1' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTicket}
            id={currentId}
          />
        )}
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {value === '2' && deleteRecord && currentId && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={deleteTicket}
            id={currentId}
          />
        )}
        {commitOpen && (
          <TicketCommentModal
            open={commitOpen}
            ticketInfo={currentTicketInfo}
            id={currentId}
            handleClose={handleCommitClose}
          />
        )}
        {resolveOpen && (
          <IssuesConfirmModal
            isOpen={resolveOpen}
            // ticketInfo={currentTicketInfo}
            id={currentId}
            setResolveOpen={setResolveOpen}
            handleClose={handleResolveClose}
          />
        )}
      </GridWrapper>
    </React.Fragment>
  );
};

export default MyTickets;
