import React from 'react';

const DocumentRemoveIcon = () => {
  return (
    <div>
      <svg
        width='18'
        height='20'
        viewBox='0 0 18 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.25 3.5H0.75C0.335786 3.5 0 3.83579 0 4.25C0 4.66421 0.335786 5 0.75 5H17.25C17.6642 5 18 4.66421 18 4.25C18 3.83579 17.6642 3.5 17.25 3.5Z'
          fill='#8A8A8A'
        />
        <path
          d='M6 8.75V14.75C6 15.1642 6.33579 15.5 6.75 15.5C7.16421 15.5 7.5 15.1642 7.5 14.75V8.75C7.5 8.33579 7.16421 8 6.75 8C6.33579 8 6 8.33579 6 8.75Z'
          fill='#8A8A8A'
        />
        <path
          d='M10.5 8.75V14.75C10.5 15.1642 10.8358 15.5 11.25 15.5C11.6642 15.5 12 15.1642 12 14.75V8.75C12 8.33579 11.6642 8 11.25 8C10.8358 8 10.5 8.33579 10.5 8.75Z'
          fill='#8A8A8A'
        />
        <path
          d='M3 18.5V4.25C3 3.83579 2.66421 3.5 2.25 3.5C1.83579 3.5 1.5 3.83579 1.5 4.25V18.5C1.5 19.1213 1.93934 19.5607 1.93934 19.5607C2.37868 20 3 20 3 20H15C15.6213 20 16.0607 19.5607 16.0607 19.5607C16.5 19.1213 16.5 18.5 16.5 18.5V4.25C16.5 3.83579 16.1642 3.5 15.75 3.5C15.3358 3.5 15 3.83579 15 4.25V18.5H3Z'
          fill='#8A8A8A'
        />
        <path
          d='M5.15901 1.15901C4.5 1.81802 4.5 2.75 4.5 2.75V4.25C4.5 4.66421 4.83579 5 5.25 5C5.66421 5 6 4.66421 6 4.25V2.75C6 2.43934 6.21967 2.21967 6.21967 2.21967C6.43934 2 6.75 2 6.75 2H11.25C11.5607 2 11.7803 2.21967 11.7803 2.21967C12 2.43934 12 2.75 12 2.75V4.25C12 4.66421 12.3358 5 12.75 5C13.1642 5 13.5 4.66421 13.5 4.25V2.75C13.5 1.81802 12.841 1.15901 12.841 1.15901C12.182 0.5 11.25 0.5 11.25 0.5H6.75C5.81802 0.5 5.15901 1.15901 5.15901 1.15901Z'
          fill='#8A8A8A'
        />
      </svg>
    </div>
  );
};

export default DocumentRemoveIcon;
