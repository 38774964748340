/* eslint-disable prettier/prettier */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import { useGetSinglTaskWorkOrder } from '../hooks/taskWorkOrderHooks';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1075,
  height: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '50px 20px 30px 30px !important',
  },
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface partI {
  isOpen: boolean;
  handleClose: () => void;
  singleTasks: any;
  taskData: any;
  handleInventory: () => void;
  setInventoryModalData: any;
}
const TaskDetailModal = (props: partI) => {
  const { isOpen, handleClose, taskData} = props;
  const id = taskData
  const { data: getTask } = useGetSinglTaskWorkOrder(id, !!id);
  const singleTask = getTask?.data;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Task</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={1} lg={2}>
                  <CustomTypography>Face </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={1} lg={2}>
                  <CustomTypography>Operation </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={1} lg={2}>
                  <CustomTypography>Machine </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'machine', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Operator </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Tools </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Accessories </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'accessories', '')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={1} lg={2}>
                  <CustomTypography>Instruction </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'instructions', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Time </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Program File </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'face', '')}
                  </Typography>
                </Grid>
                <Grid item xs={2} lg={2}>
                  <CustomTypography>Inspection </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(singleTask?.nodeInfo, 'isInspectionRecordNeeded', '')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default TaskDetailModal;
