import React from 'react';
import { Box, Typography } from '@mui/material';
import './cardInfo.css';
import _ from 'lodash';

interface Props {
  title: string;
  data: any;
}
const Card = ({ title, data }: Props) => {
  return (
    <React.Fragment>
      <Box className='parent-one'>
        <Typography className='info-title-card'>{title}</Typography>
        <Typography className='card-value'>{data ? data : 0}</Typography>
      </Box>
    </React.Fragment>
  );
};

export default Card;
