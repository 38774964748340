import { AccessoryListErrI, AccessoryListI, AccessoryI, AccessoryErrI } from '../Types/accessory';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addCustomer = async (data): Promise<AccessoryI | AccessoryErrI> => {
  delete data['supplierId'];
  return post(`/customer/customer-order`, data).then((res) => res.data);
};
export const getCustomerOrder = async (
  data
): Promise<AccessoryListI | undefined | { data: { results: [] } } | AccessoryListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 5 };
  return get(`/customer/customer-order?&workOrderStatus=0&approvedStatus=0`, payload).then(
    (res) => {
      return res.data;
    }
  );
};
export const getCustomerConfirmation = async (
  data
): Promise<AccessoryListI | undefined | { data: { results: [] } } | AccessoryListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/customer-order?&workOrderStatus=0&approvedStatus=1`, payload).then(
    (res) => {
      return res.data;
    }
  );
};
export const getCustomerCompletedorders = async (
  data
): Promise<AccessoryListI | undefined | { data: { results: [] } } | AccessoryListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/customer-order?&workOrderStatus=1&approvedStatus=1`, payload).then(
    (res) => {
      return res.data;
    }
  );
};
export const updateCustomer = async (data): Promise<any> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/customer-order/${id}`, data).then((res) => res.data);
};
export const updateCustomerApprove = async (data): Promise<any> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/customer-order/approve/${id}`, data).then((res) => res.data);
};
export const getFullCustomer = async (): Promise<any | { data: [] }> => {
  return get(`/customer/accessory`).then((res) => res.data);
};
export const getSingleCustomer = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/customer-order/${id}`).then((res) => res.data);
};
export const deleteCustomer = async (id) => {
  return deleteRequest(`/customer/customer-order/${id}`);
};

export const customerApi = {
  getCustomerOrder,
  getCustomerConfirmation,
  getCustomerCompletedorders,
  updateCustomerApprove,
  addCustomer,
  updateCustomer,
  getFullCustomer,
  getSingleCustomer,
  deleteCustomer,
};
