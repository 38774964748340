import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Button, Grid, TextField, styled } from '@mui/material';
import { Form, Formik } from 'formik';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { useUpdateFinalApproval } from '../hooks/workorderHooks';

const styleConfirm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 842,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '20px 25px 18px 25px !important',
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
  },
  '.tool-box-confirm': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
  },
  '.buttonFlex': {
    display: 'flex',
  },
  '.mr-15': {
    marginLeft: '8px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.field-title-in': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.fildleft': {
    marginLeft: '17px',
    marginTop: '20px',
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
    marginRight: '140px',
  },
  '.uploadreferencesIcon-3': {
    textTransform: 'capitalize',
    fontSize: '10px',
    color: '#020202',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    height: '0px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.fildborder': {
    borderRadius: '4px',
    padding: '8.5px 20px 8.5px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
    border: '1px solid rgba(138, 138, 138, 1)',
  },
  '.task-mr-23': {
    marginRight: '130px',
    fontSize: 14,
  },
  '.title-head-box1': {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    padding: '16px 2px 0px 2px !important',
  },
};
const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const RawCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '150px',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#8A8A8A',
  '&.MuiButton-outlined': {
    border: 'border: 1px solid rgba(38, 90, 168, 1)',
  },
}));
const CustomTypography = styled(Typography)(() => ({
  color: 'rgba(2, 2, 2, 1)',
  fontWeight: '400',
  fontSize: '14px !important',
  padding: '20px 0px 0px 30px',
}));
function ActionList(props: PickersActionBarProps) {
  const { onAccept, onClear, onSetToday } = props;
  const actions = [
    { text: 'Apply', method: onAccept },
    { text: 'Clear', method: onClear },
    { text: 'Today', method: onSetToday },
  ];
  return (
    <Box className='datepicker-btn-wrapper-factory-layout'>
      <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
        {actions[1].text}
      </Button>
      <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
        {actions[0].text}
      </Button>
    </Box>
  );
}
interface partI {
  isOpen: boolean;
  isTaskApproval?: boolean;
  handleClose: () => void;
  id?: string;
  taskId?: string;
  mainTaskType?: string;
  setReWorkTask?: any;
}
const FinalApprovalConfirm = (props: partI) => {
  const { isOpen, handleClose, setReWorkTask, id } = props;

  const { mutate: updateFinalApproval } = useUpdateFinalApproval();
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Formik
        initialValues={{
          isFinalApproved: 0,
          isRework: 0,
          remarks: '',
          finalQuantityProduced: null,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          const finalValues = { ...values, id: id };
          const { isRework, ...withoutRework } = finalValues;
          if (finalValues.isFinalApproved === 1) {
            updateFinalApproval(withoutRework);
          } else {
            updateFinalApproval(finalValues);
          }
          if (values?.isRework) {
            handleClose();
            setReWorkTask(true);
          } else {
            navigate('/work-order', { state: { activeTab: '2' } });
            handleClose();
          }
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <Box sx={styleConfirm}>
              <Box>
                <Box className='title-head-box'>
                  <Typography className='top-up-name'>Final Approval</Typography>
                  <CloseIcon onClick={handleClose} className='close-icon' />
                </Box>
                <Box className='modal-body'>
                  <Grid className='buttonFlex' mt={'5px'} mb={'10px'} ml={'20px'} mr={'10px'}>
                    <RawCustomButton
                      sx={{
                        border:
                          values?.isFinalApproved == 1 ? '2px solid #265AA8' : '1px solid #265AA8',
                      }}
                      variant='outlined'
                      onClick={(newValue: any) => {
                        setFieldValue(`isFinalApproved`, 1);
                      }}
                    >
                      Yes
                    </RawCustomButton>
                    <RawCustomButton
                      sx={{
                        border:
                          values?.isFinalApproved == 2 ? '2px solid #265AA8' : '1px solid #265AA8',
                      }}
                      variant='outlined'
                      className='mr-15'
                      onClick={(newValue: any) => {
                        setFieldValue(`isFinalApproved`, 2);
                      }}
                    >
                      No
                    </RawCustomButton>
                  </Grid>
                  {values?.isFinalApproved === 2 && (
                    <Box>
                      <Typography className='fildleft'> Add Rework Task</Typography>
                      <Grid className='buttonFlex' mt={'5px'} mb={'10px'} ml={'20px'} mr={'10px'}>
                        <RawCustomButton
                          sx={{
                            border:
                              values?.isRework == 1 ? '2px solid #265AA8' : '1px solid #265AA8',
                          }}
                          variant='outlined'
                          onClick={(newValue: any) => {
                            setFieldValue(`isRework`, 1);
                          }}
                        >
                          Yes
                        </RawCustomButton>
                      </Grid>
                    </Box>
                  )}

                  <Grid item xs={12} container rowSpacing={2} columnSpacing={2} sx={{ m: 0 }}>
                    <Grid item xs={6}>
                      <Typography className='field-title'>Remarks</Typography>
                      <InputField
                        placeholder='Enter Remarks'
                        fullWidth
                        onChange={handleChange}
                        name='remarks'
                        value={values?.remarks}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className='field-title'>Final quantity produced</Typography>
                      <InputField
                        placeholder='Enter Final quantity Produced'
                        fullWidth
                        onChange={handleChange}
                        type='number'
                        name='finalQuantityProduced'
                        value={values?.finalQuantityProduced}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <Box className='tool-box-confirm'>
                  <CustomModalButton variant='contained' type='submit' className='blue-bg ml-20'>
                    Confirm
                  </CustomModalButton>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default FinalApprovalConfirm;
