import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import './../../src/style/modal.css';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px',
  },
}));
interface propI {
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  handleDelete: (id?: string | undefined) => void;
  id?: string;
  data?: any;
}

const ApproveQualityModal = (props: propI) => {
  const { open, handleClose, handleDelete, data } = props;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Typography>Are you sure you want to provide QC approval for the WO?</Typography>
              </Box>

              <Box m={2} display={'flex'} justifyContent={'space-between'}>
                <CustomModalButton
                  variant='outlined'
                  className='button-box'
                  onClick={() => handleClose()}
                >
                  NO
                </CustomModalButton>
                <CustomModalButton
                  variant='outlined'
                  className='button-box blue-bg ml-20'
                  onClick={() => {
                    handleDelete(data);
                    handleClose();
                  }}
                >
                  Yes
                </CustomModalButton>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  );
};
export default ApproveQualityModal;
