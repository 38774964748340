import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import { GridCloseIcon } from '@mui/x-data-grid';

const HeadBoxWrapper = styled(Box)(() => ({
  '.close-icon': {
    cursor: 'pointer',
  },
  '.close-btn-wrapper': {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
}));
interface PropI {
  isOpen: boolean;
  handleClose: () => void;
  imageInfo: {
    fileType?: string;
    name?: string;
    url?: string;
  };
}
const ViewImage = (props: PropI) => {
  const { isOpen, handleClose, imageInfo } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 510,
    height: imageInfo?.fileType === 'application/pdf' ? '700px' : 'auto',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '30px 30px 40px 30px',
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-modal-description'
    >
      <HeadBoxWrapper sx={style}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box fontWeight={600}>{_.get(imageInfo, 'name', '')}</Box>

          {/* <Box fontWeight={600}>
            {(_.get(imageInfo, 'name', '-').startsWith('#') ? '' : '#') +
              _.get(imageInfo, 'name', '-').replace(/\.(png|jpeg|jpg)$/i, '')}
          </Box> */}
          <Box>
            <GridCloseIcon onClick={handleClose} className='close-icon' />
          </Box>
        </Box>
        {imageInfo?.fileType === 'application/pdf' ? (
          <Box style={{ position: 'relative', height: '100%' }}>
            <object width='100%' height='100%' data={`${imageInfo?.url}`}></object>
          </Box>
        ) : (
          <>
            <img src={_.get(imageInfo, 'url', '-')} width={'500px'} />
          </>
        )}

        {/* <Box className='close-btn-wrapper'>
          <Button variant='outlined' onClick={() => handleClose()}>
            Close
          </Button>
        </Box> */}
      </HeadBoxWrapper>
    </Modal>
  );
};
export default ViewImage;
