import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import {
  useGetSingleRawMaterials,
  useDeleteRawMaterial,
  useGetDeletedRawMaterial,
  useRestoreRawMaterial,
  useHardDeleteRawMaterial,
} from '../../hooks/rawMaterialHooks';
import QuantityTable from './QuantityTable';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.css-mhc70k-MuiGrid-root>.MuiGrid-item': {
    paddingTop: '0px',
  },
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '0px 0px 38px 0px !important',
    fontWeight: '600 !important',
  },
  '.usage': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '50px 0px 30px 25px !important',
    fontWeight: '600 !important',
  },
  '.table': {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  '.mr-20': {
    marginTop: '25px',
  },
  '.mr-24': {
    marginLeft: '15px',
  },
  '.box-mr-1': {
    marginTop: '35px',
  },
  '.mr-21': {
    padding: '0px 0px 0px 24px',
  },
  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 0px 24px',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },
  '.divider-color, .MuiDivider-root': {
    margin: '10px 0px !important',
  },

  '.border-items': {
    // border: '1px solid #DEDEDE !important',
    // columnRule: '1px solid #DEDEDE !important',
    // borderLeft: '1px solid #DEDEDE',
    // borderRight: '1px solid #DEDEDE',
    // // bborderTop: '1px solid #DEDEDE',
    // borderBottom: '1px solid #DEDEDE',
    // // paddingTop: '8px !important',
    // padding: '8px 0px 10px 0px',
    // marginLeft: '25px',
  },
  '.rawdetail-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '40px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    bottom: '10px',
    left: '0px',
    width: '100%',
  },
}));

const RawMaterialDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { mutate: restoreRawMaterial } = useRestoreRawMaterial();
  const { mutate: hardDeleteRawMaterial } = useHardDeleteRawMaterial();
  const { data: getRawMaterial } = useGetSingleRawMaterials(id, !!id && !isDeleted);
  const { data: getRawMaterialDeleted } = useGetDeletedRawMaterial(id, !!id && isDeleted);
  const singleRawMaterial = isDeleted ? getRawMaterialDeleted?.data : getRawMaterial?.data;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'rawMaterial' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'rawMaterial' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'rawMaterial' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: deleteSingleRawMaterial,
    error: deleteRawMaterialError,
    isError: isDeleteRawMaterialError,
  }: any = useDeleteRawMaterial();
  React.useEffect(() => {
    if (isDeleteRawMaterialError) {
      setErrorMessage(deleteRawMaterialError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isDeleteRawMaterialError]);
  const { mutate: readNotification } = useUpdateNotification();
  const handleRawMaterialDelete = () => {
    if (isDeleted) {
      hardDeleteRawMaterial(id);
    } else {
      deleteSingleRawMaterial(id);
    }
  };
  const handleRawMaterialRestore = () => {
    if (isRestore) {
      restoreRawMaterial(id);
    } else {
      // nothing to restore
    }
  };

  let breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: `Raw Material`,
      link: `/raw-materials`,
      activeTab: '4',
    },
    {
      title: _.get(singleRawMaterial, 'name', ''),
      link: `/raw-material-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleRawMaterial, 'name', ''),
        link: `/deleted-items/raw-material-details/${id}`,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-raw-material/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});
  // ! for future use
  // const {
  //   data: rawMaterialInfo,
  //   isLoading: isRawMaterialLoading,
  //   isFetching: isRawMaterialFetching,
  //   // error: rawMaterialError,
  //   // isError: isRawMaterialError,
  //   isSuccess: isRawMaterialSuccess,
  // }: any = useGetRawMaterials(page);

  // const rawMaterialList = rawMaterialInfo?.results || [];
  // const rawRows = rawMaterialList?.map((data, ind) => {
  //   const startIndex = (1) * 10;
  //   return {
  //     id: data.id,
  //     srNo: startIndex + ind + 1,
  //     name: data?.name,
  //     number: data?.number,
  //     supplier: data?.batch?.[0]?.supplier?.name,
  //     quantity: data?.batch?.[0]?.quantityBought,
  //     dateBought: data?.batch?.[0]?.dateBought
  //       ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY')
  //       : '-',
  //     testcartificate: data?.batch?.[0]?.document?.[0]?.name,
  //     // quantity: data?.quantity,
  //     // supplier: data?.supplier?.name,
  //     // dateBought: data?.dateBought ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
  //     // isUsageTracking: data?.isUsageTracking,
  //     // usageDetails: data?.usageDetails.quantityUsed,
  //     // document: data?.document?.document,
  //     // quantityBought: data?.quantityBought,
  //     // isInspection: data?.isInspection,
  //     // batch: data?.supplier?.name,
  //     updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
  //   };
  // });
  const data = [
    {
      name: 'dddd',
      phone: '525262',
    },
  ];
  const consumablesCols = [
    {
      field: '',
      headerName: '#',
      flex: 0.2,
    },
    {
      field: 'phone',
      headerName: 'Name',
      flex: 1,
    },
    // {
    //   field: 'name',
    //   headerName: 'Name',
    //   flex: 1,
    // },
  ];

  const test = {
    columns: consumablesCols,
    rows: [],
  };
  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleRawMaterial, 'name', '')}
          backToLink={isDeleted ? '/deleted-items' : '/raw-materials'}
          isDetailPage={true}
          activeTab='4'
          detailPageData={detailPageData}
          handleDelete={handleRawMaterialDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handleRawMaterialRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Grid item xs={12}>
          <Typography className='usage-title'> Raw Material</Typography>
        </Grid>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={2}>
                    <TextFiled>Raw Material Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <TextFiled>Raw Material Number </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'number', '')}
                    </Typography>
                  </Grid>
                  {/* //! for future use */}
                  {/* <Grid item xs={2}>
                    <TextFiled>Reorder Point </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'reorderPoint', '')}
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} className=''>
              {singleRawMaterial?.batch.map((data, ind) => (
                <Box className='details-box-border padding-bottom box-mr-1' key={ind}>
                  <Box display={'flex'}>
                    <Grid item xs={12}>
                      <Typography id='modal-modal-title' className='details-sub-title'>
                        Batch
                      </Typography>
                    </Grid>
                  </Box>
                  <Grid container display={'flex'}>
                    <Grid item xs={2}>
                      <TextFiled>Supplier Name </TextFiled>
                      <Typography className='span-color pl-sub-text'>
                        {data?.supplier?.name ? data?.supplier?.name : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextFiled>Quantity Bought </TextFiled>
                      <Typography className='span-color pl-sub-text'>
                        {data?.quantityBought}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextFiled>Date Bought </TextFiled>
                      <Typography className='span-color pl-sub-text'>
                        {moment(_.get(singleRawMaterial?.batch?.[0], 'dateBought', '')).format(
                          'hh:mm A, DD MMM, YYYY'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextFiled>Inspection done </TextFiled>
                      <Typography className='span-color pl-sub-text'>
                        {/* {data?.isInspection === true ? 'YES' : 'NO'} */}
                        {data?.isInspection === true
                          ? 'YES'
                          : data?.isInspection === false
                          ? 'NO'
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className='usage'> Usage Details</Typography>
                  </Grid>
                  <Grid container display={'flex'}></Grid>
                  <Box className=''>
                    <Grid container item xs={7} className='process-header'>
                      <Grid item xs={4.3}>
                        <Typography className='process-header-field-title'>Date Used</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className='process-header-field-title'>
                          Quantity Used
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <QuantityTable key={ind} data={data} />
                  <Grid container item xs={7} className='mr-20'>
                    <Grid item xs={4.5}>
                      <Typography className='process-header-field-title mr-24'>
                        Remaing Quantity
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className='process-header-field-title'>
                        {' '}
                        {_.get(singleRawMaterial?.batch?.[0], 'quantityBought', '')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <TextFiled>Attachment</TextFiled>
                    <Box sx={{ p: 2, display: 'flex', gap: '10px' }}>
                      {data?.document?.length <= 0}
                      {data?.document?.length >= 0 &&
                        data?.document?.map((data, index) => {
                          return (
                            <Box key={index}>
                              {data?.url ? (
                                <img
                                  src={data?.url}
                                  alt='new-row-material'
                                  width={'339.44px'}
                                  height={'269.29px'}
                                />
                              ) : (
                                <Box pl={'10px'}>-</Box>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                    <Typography className='span-color pl-sub-text'>
                      {/* {_.get(singleRawMaterial, 'quantity', '')} */}
                      {/* {_.get(singleRawMaterial?.batch?.[0], 'document', '')} */}
                    </Typography>
                  </Grid>
                  {/* <Box display={'flex'}>
                    <Grid item xs={4} display={'flex'} className='mr-24'>
                      <TextFiled>Remaing Quantity</TextFiled>
                      <Typography className='span-color pl-sub-text mr-20'>
                        {_.get(singleRawMaterial?.batch?.[0], 'quantityBought', '')}
                      </Typography>
                    </Grid>
                  </Box> */}
                  <Grid item xs={7} className='mr-21'>
                    <Divider></Divider>
                  </Grid>
                </Box>
              ))}
            </Grid>
            {/* <Box className='rawdetail-box'>
              <Box className='rm-footer-padding'>
                <CustomButton className={'blue-bg'} variant='contained' type='submit'>
                  {'Add New Raw Material'}
                </CustomButton>
              </Box>
            </Box> */}
          </Grid>
        </Box>
        <Loading
          // loading={
          //   id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          // }
          background='white'
          loaderColor='#265AA8'
        />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
      </HeadBoxWrapper>
    </>
  );
};
export default RawMaterialDetails;
