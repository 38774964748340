import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import CustomButton from '../commonComponents/CustomButton';
import { useLocation, useParams } from 'react-router-dom';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import 'rc-time-picker/assets/index.css';
import Loading from 'react-fullscreen-loading';
import CloseIcon from '@mui/icons-material/Close';
import AccessoriesConfirm from '../../Modals/AccessoriesConfirm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import { useGetAccessorySingle, useValidateAccessoryName } from '../../hooks/accessoryHooks';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Accessory name is Required')
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  number: Yup.string().when('isAutomatic', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('Accessory Number name is Required'),
  }),

  quantity: Yup.number().typeError('only number allowed'),
  manufacturer: Yup.string()
    .trim()
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  dateBought: Yup.string().trim(),
});
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
    fontSize: '14px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 14px',
    fontSize: '14px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  height: '82vh !important',
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px !important',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: '14px !important',
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
      fontSize: '14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
      fontSize: '14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
}));

const AddAccessory = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isInventoryOpen, setIsInvenotryOpen] = React.useState(false);
  const handleInventoryOpen = () => setIsInvenotryOpen(true);
  const handleInventoryClose = () => setIsInvenotryOpen(false);
  const handleClose = () => setOpenModal(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({});
  const [inventoryModalData, setInventoryModalData] = useState({});
  const isVariant = window.location.pathname.startsWith('/part/add-variant');
  const [closeWarning, setCloseWarning] = useState(true);
  const { id } = useParams<string>();

  //get Part Single List
  const { data: getAccessory, isSuccess } = useGetAccessorySingle(id, !!id);
  const singleAccessory = getAccessory?.data;
  const {
    mutate: validateAccessoryName,
    data: validateData,
    isLoading: isValidating,
  } = useValidateAccessoryName();
  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  React.useEffect(() => {
    if (isEdit && isSuccess) {
      if (singleAccessory?.name) {
        validateAccessoryName({
          name: singleAccessory.name,
          id,
        });
      }
    }
  }, [isEdit, isSuccess, singleAccessory?.name, id]);
  const breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: 'Accessories',
      link: `/accessories`,
      activeTab: '2',
    },
    {
      title: `${isEdit ? singleAccessory?.name : 'Add Accessory'}`,
      link: `/${isEdit ? `edit-accessory/${id}` : 'add-Accessory'}`,
    },
  ];

  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  const icon = <AiOutlineInfoCircle color='red' />;
  const location = useLocation();
  const { state } = location;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const settingData = userRole?.data?.setting;

  const isAutomatic = settingData?.find((data) => {
    return data?.event === 'Accessories' && data?.automatic;
  });
  const requestNumber = `${isAutomatic?.prefix ? isAutomatic?.prefix : 'AC'}-${
    1000 + (state?.requestCount || 0) + (state?.requestCount ? 1 : 1)
  }`;
  return (
    <React.Fragment>
      {!isVariant && (
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`${isEdit ? 'Edit' : 'Add new'}  Accessories`}
          backToLink='/accessories'
        />
      )}
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? singleAccessory?.name : '',
            number: isEdit ? singleAccessory?.number : requestNumber || '',
            quantity: isEdit ? singleAccessory?.quantity : '',
            manufacturer: isEdit ? singleAccessory?.manufacturer : '',
            dateBought: isEdit ? singleAccessory?.dateBought : '',
            procurementTime: isEdit ? singleAccessory?.procurementTime : '',
            isAutomatic: !!isAutomatic,
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const isVariantAdd = window.location.pathname.startsWith('/part/add-variant');
            setOpenModal(true);
            setValue({
              ...values,
              isVariantAdd,
              procurementTime: values?.procurementTime ? values?.procurementTime : '',
            });
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Enter Accessory Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    Accessory Name
                    <Require />
                  </Typography>
                  <Input
                    error={
                      (!validateData?.data.isValid && values?.name && !isValidating) ||
                      (!errors.name || !touched.name ? false : true)
                    }
                    placeholder='Enter Accessory Name'
                    fullWidth
                    onChange={(e) => {
                      validateAccessoryName({
                        name: e.target.value,
                        ...(id && { id: id }),
                      });
                      handleChange(e);
                    }}
                    value={values.name || ''}
                    name='name'
                    InputProps={{
                      style: { fontSize: 14 }, // Add this line
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {!validateData?.data.isValid && values?.name && !isValidating ? (
                    <Typography className='validation-text'>
                      {'Accessory Name already taken'}
                    </Typography>
                  ) : (
                    <ErrorMessage
                      name='name'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>
                    Accessory Number
                    <Require />
                  </Typography>
                  <InputField
                    error={!errors.number || !touched.number ? false : true}
                    placeholder='Enter Accessory Number'
                    fullWidth
                    name='number'
                    disabled={!!isAutomatic}
                    onChange={handleChange}
                    value={values.number || ''}
                    InputProps={{
                      endAdornment: touched.number && errors.number && icon,
                      readOnly: isAutomatic?.automatic,
                    }}
                  />
                  {touched.number && errors.number && (
                    <Typography className='validation-text'>
                      {typeof errors.number === 'string' ? errors.number : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'>Quantity</Typography>
                  <InputField
                    error={!errors.quantity || !touched.quantity ? false : true}
                    placeholder='Enter Accessory Quantity'
                    fullWidth
                    onChange={handleChange}
                    name='quantity'
                    value={values.quantity}
                    InputProps={{
                      endAdornment: touched.quantity && errors.quantity && icon,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={'5px'}>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Manufacturer
                  </Typography>
                  <InputField
                    placeholder='Enter Accessory Manufacturer'
                    fullWidth
                    onChange={handleChange}
                    name='manufacturer'
                    value={values.manufacturer}
                  />
                  {touched.manufacturer && errors.manufacturer && (
                    <Typography className='validation-text'>
                      {typeof errors.manufacturer === 'string' ? errors.manufacturer : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Date Bought
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast={isEdit ? false : false}
                      orientation='portrait'
                      dayOfWeekFormatter={(day) => `${day}`}
                      className='date-picker'
                      views={['year', 'day', 'month']}
                      closeOnSelect={false}
                      value={values?.dateBought}
                      inputFormat='DD/MM/YYYY'
                      onChange={(newValue: any) => {
                        setFieldValue('dateBought', newValue?.toDate());
                      }}
                      components={{
                        ActionBar: ActionList,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ['today', 'cancel', 'clear'],
                        },
                      }}
                      renderInput={(params) => {
                        if (values?.dateBought) {
                          return (
                            <TextField
                              {...params}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                            />
                          );
                        } else {
                          delete params['inputProps'];
                          return (
                            <TextField
                              {...params}
                              disabled={true}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                              error={false}
                            />
                          );
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    name='dateBought'
                    render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={'p-bottom'}>
                <Grid item xs={4} md={6} lg={4}></Grid>
              </Grid>
              <Grid container spacing={2} className='warning-tab'>
                <Grid item xs={12}>
                  {isVariant && closeWarning && (
                    <Box className='variant-warning-box'>
                      <Box display={'flex'}>
                        <AiOutlineInfoCircle className='outline-circle' />
                        <Typography className='warning-text'>
                          Make sure the part number is unique and is not the same as any other part{' '}
                        </Typography>
                      </Box>
                      <Box>
                        <CloseIcon
                          className='warning-close'
                          onClick={() => setCloseWarning(false)}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    variant='contained'
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    type='submit'
                  >
                    {isVariant ? 'Add Variant' : isEdit ? 'Save Changes' : 'Add New Accessory'}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Loading
          className='sa'
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
        {openModal && (
          <AccessoriesConfirm
            handleInventory={handleInventoryOpen}
            setInventoryModalData={setInventoryModalData}
            partData={value}
            isOpen={openModal}
            handleClose={handleClose}
          />
        )}
        {/* TODO: functionality future for Inventory use */}
        {/* {isInventoryOpen && (
          <AddAccessoryInventory
            data={inventoryModalData}
            isOpen={isInventoryOpen}
            handleClose={handleInventoryClose}
          />
        )} */}
      </HeadBox>
    </React.Fragment>
  );
};
export default AddAccessory;
