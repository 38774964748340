import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ErrorMessage, FieldArray, Form, Formik, getIn } from 'formik';
import { TextField, Typography, Divider, Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import * as Yup from 'yup';
import Loading from 'react-fullscreen-loading';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddProductModal from '../../Modals/AddProductModal';
import Plus from '../../assets/svg/Plus';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import ToolHolderConfirmModel from '../../Modals/ToolHolderConfirmModel';
import { useGetPartSingle, useValidateToolName } from '../../hooks/partHooks';
import UploadDocument from '../commonComponents/UploadDocument';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
    fontSize: '14px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const SighupSchema = Yup.object().shape({
  name: Yup.string().trim().required('Tool Holder Name is Required'),
  modelNo: Yup.string().required('Model No. is Required'),
  make: Yup.string(),
  grid: Yup.string(),
  sizeDetails: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number(),
        length: Yup.number(),
        quantity: Yup.number().required('Quantity is Required'),
      })
    )
    .min(1),
});

// New Style using ClassName
const HeadBox = styled(Box)(() => ({
  height: 'calc(83vh - 100px) !important',
  overflowY: 'auto',

  // Media query for screens with a height between 425px and 500px
  '@media (min-height: 425px) and (max-height: 500px)': {
    height: 'calc(75vh - 113px) !important',
  },

  // Media query for screens with a height between 501px and 600px
  '@media (min-height: 501px) and (max-height: 600px)': {
    height: 'calc(79vh - 113px) !important',
  },

  // Media query for screens with a height between 601px and 700px
  '@media (min-height: 601px) and (max-height: 700px)': {
    height: 'calc(81vh - 113px) !important',
  },

  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '99% !important',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important',
      fontSize: '14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
      fontSize: '14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
      fontSize: '14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
      fontSize: '14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.remove-button-p': {
    display: 'flex',
    margin: '45px 10px 0px 17px',
    cursor: 'pointer',
    color: '#265AA8',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      margin: '45px 10px 0px 0px',
    },
  },
}));

const AddToolInsert = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [value, setValue] = useState({});
  const handleModelClose = () => setOpenModal(false);
  const [sizeDetailsRemoveId, setSizeDetailsRemoveId] = useState<any>([]);
  const location = useLocation();
  const { state } = location;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const settingData = userRole?.data?.setting;
  const isAutomatic = settingData?.find((data) => {
    return data?.event === 'Insert' && data?.automatic;
  });
  const requestNumber = `${isAutomatic?.prefix ? isAutomatic?.prefix : 'IN'}-${
    1000 + (state?.requestCount || 0) + (state?.requestCount ? 1 : 1)
  }`;
  const { data: getParts, isSuccess } = useGetPartSingle(id, !!id);
  const {
    mutate: validateToolName,
    data: validateToolData,
    isLoading: isValidating,
  } = useValidateToolName();

  const singleTool: {
    name: string;
    modelNo: string;
    make: string;
    supplier: string;
    prNumber: string;
    purchaseDate: string;
    grade: string;
    sizeDetails: any;
    setSizeDetailsRemoveId: any;
  } = getParts?.data;
  const editResponse = singleTool?.sizeDetails?.map((data: any) => {
    return {
      ...data,
      length: data.length ? data.length : 0,
      size: data.size ? data.size : 0,
    };
  });
  const icon = <AiOutlineInfoCircle color='red' />;

  const breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: 'Tool Insert',
      link: '/tools',
      activeTab: '10',
    },
    {
      title: `${isEdit ? singleTool?.name : 'Add Tool Insert'}`,
      link: `/${isEdit ? `edit-tool-insert/${id}` : 'add-tool-insert'}`,
      activeTab: '10',
    },
  ];

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  React.useEffect(() => {
    if (isEdit && isSuccess) {
      if (singleTool?.name) {
        validateToolName({
          name: singleTool.name,
          toolCategoryType: 'Insert',
          id,
        });
      }
    }
  }, [isEdit, isSuccess, singleTool?.name, id]);

  const [IsOpenProductModal, setIsOpenProductModal] = React.useState(false);
  const handleOpen = () => setIsOpenProductModal(true);
  const handleClose = () => setIsOpenProductModal(false);

  // Default start time
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  // TODO:- CUSTOM DATE PICKER
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onCancel, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Cancel', method: onCancel },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onCancel()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          height: 'calc(100vh - 5px)',
          overflow: 'hidden',
        }}
      >
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`${id ? 'Edit Insert' : 'Add Insert'}`}
          backToLink='/tools'
          activeTab={'10'}
        />
        <HeadBox>
          <Formik
            initialValues={{
              name: isEdit ? singleTool?.name : '',
              modelNo: isEdit ? singleTool?.modelNo : requestNumber || '',
              make: isEdit ? singleTool?.make : '',
              supplier: isEdit ? singleTool?.supplier : '',
              prNumber: isEdit ? singleTool?.prNumber : '',
              purchaseDate: isEdit ? singleTool?.purchaseDate : '',
              grade: isEdit ? singleTool?.grade : '',
              sizeDetails: isEdit
                ? editResponse
                : [{ size: '', length: '', quantity: '', uploadedImages: [] }],
            }}
            validationSchema={SighupSchema}
            enableReinitialize={true}
            onSubmit={(values: any) => {
              setOpenModal(true);
              setValue({
                ...values,
                toolCategoryType: 'Insert',
                ...(!!id && { sizeDetailsRemoveId }),
              });
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              isValid,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box className='main-custom-component-padding'>
                  <Typography
                    display={'flex'}
                    m={'0px !important'}
                    justifyContent={'flexStart'}
                    className='title-typography'
                  >
                    {' '}
                    Enter Insert Details{' '}
                  </Typography>
                </Box>
                <Grid container spacing={2} className='main-custom-component-padding'>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Insert Name
                      <Require />
                    </Typography>
                    <Input
                      error={
                        (!validateToolData?.data.isValid && values?.name && !isValidating) ||
                        (!errors.name || !touched.name ? false : true)
                      }
                      placeholder='Enter Insert Name'
                      fullWidth
                      onChange={(e) => {
                        validateToolName({
                          name: e.target.value,
                          toolCategoryType: 'Insert',
                          ...(id && { id: id }),
                        });
                        handleChange(e);
                      }}
                      value={values.name || ''}
                      name='name'
                      InputProps={{
                        style: { fontSize: 14 }, // Add this line
                        endAdornment: touched.name && errors.name && icon,
                      }}
                    />
                    {!validateToolData?.data.isValid && values?.name && !isValidating ? (
                      <Typography className='validation-text'>
                        {'Insert Name already taken'}
                      </Typography>
                    ) : (
                      <ErrorMessage
                        name='name'
                        render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Model No.
                      <Require />
                    </Typography>
                    <Input
                      error={!errors.modelNo || !touched.modelNo ? false : true}
                      placeholder='Enter Model Number'
                      fullWidth
                      onChange={handleChange}
                      name='modelNo'
                      disabled={!!isAutomatic}
                      value={values.modelNo || ''}
                      InputProps={{
                        endAdornment: touched.modelNo && errors.modelNo && icon,
                        readOnly: isAutomatic?.automatic,
                      }}
                    />
                    <ErrorMessage
                      name='modelNo'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>Make</Typography>
                    <Input
                      error={!errors.make || !touched.make ? false : true}
                      placeholder='Enter Make'
                      fullWidth
                      onChange={handleChange}
                      name='make'
                      value={values.make || ''}
                      InputProps={{
                        endAdornment: touched.make && errors.make && icon,
                      }}
                    />
                    <ErrorMessage
                      name='title'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </Grid>
                  {IsOpenProductModal && (
                    <AddProductModal open={IsOpenProductModal} handleClose={handleClose} />
                  )}
                </Grid>
                <Grid container spacing={2} className='main-custom-component-padding'>
                  <Grid item xs={4}>
                    <Typography className='field-title'>Supplier</Typography>
                    <Input
                      placeholder='Enter Supplier'
                      fullWidth
                      onChange={handleChange}
                      name='supplier'
                      value={values.supplier || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>PR Number</Typography>
                    <Input
                      placeholder='Enter PR Number'
                      fullWidth
                      onChange={handleChange}
                      name='prNumber'
                      value={values.prNumber || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>Date of Purchase</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast={isEdit ? false : false}
                        dayOfWeekFormatter={(day) => `${day}`}
                        className='date-picker'
                        views={['year', 'day', 'month']}
                        closeOnSelect={false}
                        value={values.purchaseDate || dayjs(new Date())}
                        inputFormat='DD/MM/YYYY'
                        onChange={(newValue: any) => {
                          setFieldValue('purchaseDate', newValue?.toDate());
                        }}
                        components={{
                          ActionBar: ActionList,
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ['today', 'cancel', 'clear'],
                          },
                        }}
                        renderInput={(params) => {
                          if (values?.purchaseDate) {
                            return (
                              <TextField
                                {...params}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                              />
                            );
                          } else {
                            delete params['inputProps'];
                            return (
                              <TextField
                                {...params}
                                disabled={true}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                                error={false}
                              />
                            );
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {IsOpenProductModal && (
                    <AddProductModal open={IsOpenProductModal} handleClose={handleClose} />
                  )}
                </Grid>
                <Grid container spacing={2} className='main-custom-component-padding'>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Grade
                      <Require />
                    </Typography>
                    <Input
                      error={!errors?.grade || !touched?.grade ? false : true}
                      placeholder='Enter Grade'
                      fullWidth
                      onChange={handleChange}
                      name='grade'
                      value={values.grade || ''}
                      InputProps={{
                        endAdornment: touched.grade && errors.grade && icon,
                      }}
                    />
                    <ErrorMessage
                      name='title'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0} className='main-component-divider-padding '>
                  {' '}
                  <Grid item xs={12} className='divider-mr-1'>
                    <Divider />{' '}
                  </Grid>{' '}
                </Grid>
                <Box className='main-component-size-details-padding'>
                  <FieldArray
                    name='sizeDetails'
                    render={({ remove, push }) => (
                      <Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography
                            display={'flex'}
                            m={'0px !important'}
                            justifyContent={'flexStart'}
                            className='title-typography'
                          >
                            {' '}
                            Enter Size Details{' '}
                          </Typography>
                        </Box>
                        <Box className='row' mt={'0px'}>
                          <Box>
                            {values?.sizeDetails?.length > 0 &&
                              [...values.sizeDetails]?.map((sizeItem, index) => {
                                const sizeDetails = `sizeDetails[${index}]`;
                                const touchedsizeDetails = getIn(touched, sizeDetails);
                                const errorsizeDetails = getIn(errors, sizeDetails);
                                return (
                                  <Box mt={'20px'} key={index}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4} md={4} lg={2.75}>
                                        <Typography className='field-title'>Size</Typography>
                                        <InputField
                                          type='number'
                                          placeholder='Enter Size'
                                          fullWidth
                                          onChange={handleChange}
                                          name={`sizeDetails.${index}.size`}
                                          value={values?.sizeDetails?.[index]?.size}
                                          InputProps={{
                                            style: { fontSize: 14 },
                                            endAdornment:
                                              touched.sizeDetails?.[index]?.size &&
                                              errors.sizeDetails?.[index]?.size &&
                                              icon,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4} lg={2.75}>
                                        <Typography className='field-title'>Length</Typography>
                                        <InputField
                                          type='number'
                                          placeholder='Enter Length'
                                          fullWidth
                                          onChange={handleChange}
                                          name={`sizeDetails.${index}.length`}
                                          value={values?.sizeDetails?.[index]?.length}
                                          InputProps={{
                                            style: { fontSize: 14 },
                                            endAdornment:
                                              touched.sizeDetails?.[index]?.length &&
                                              errors.sizeDetails?.[index]?.length &&
                                              icon,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4} lg={2.75}>
                                        <Typography className='field-title'>
                                          Quantity
                                          <Require />
                                        </Typography>

                                        <InputField
                                          type='number'
                                          placeholder='Enter Quantity'
                                          fullWidth
                                          onChange={handleChange}
                                          name={`sizeDetails.${index}.quantity`}
                                          value={values?.sizeDetails?.[index]?.quantity}
                                          InputProps={{
                                            style: { fontSize: 14 },
                                            endAdornment:
                                              touched.sizeDetails?.[index]?.quantity &&
                                              errors.sizeDetails?.[index]?.quantity &&
                                              icon,
                                          }}
                                        />
                                        {touched.sizeDetails?.[index]?.quantity &&
                                          errors.sizeDetails?.[index]?.quantity && (
                                            <Typography className='validation-text'>
                                              {errors.sizeDetails?.[index]?.quantity}
                                            </Typography>
                                          )}
                                      </Grid>
                                      <Grid item xs={4} md={4} lg={2.75}>
                                        <Typography className='field-title'>
                                          Image
                                          <Require />
                                        </Typography>

                                        <label htmlFor='Attach'>
                                          <Box className='manufacturingOrderHead'>
                                            <UploadDocument
                                              name={values?.sizeDetails?.[index]?.uploadedImages}
                                              handleChange={handleChange}
                                              setFieldValue={setFieldValue}
                                              isAttachment={true}
                                              createFileName={`sizeDetails.${index}.uploadedImages`}
                                              acceptTypes='.jpg, .jpeg, .png, .pdf'
                                            />
                                          </Box>
                                        </label>
                                      </Grid>
                                      <Box className='remove-button-p'>
                                        <CustomButton
                                          className='remove'
                                          onClick={() => {
                                            remove(index);
                                            setSizeDetailsRemoveId((prev: any) => [
                                              ...prev,
                                              sizeItem?.id,
                                            ]);
                                          }}
                                        >
                                          <DeleteIcon_new />
                                        </CustomButton>
                                      </Box>
                                    </Grid>
                                  </Box>
                                );
                              })}
                          </Box>
                          <Box
                            display={'flex'}
                            sx={{ paddingTop: '25px !important' }}
                            className='button-mr-2'
                          >
                            <CustomButton
                              variant='outlined'
                              className=''
                              onClick={() => {
                                push({
                                  size: '',
                                  length: '',
                                  quantity: '',
                                  uploadedImages: [],
                                });
                              }}
                            >
                              <Plus fill={'rgba(1, 96, 185, 1)'} /> <span className='ml'></span>
                              Add Size
                            </CustomButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
                <Box>
                  <Box className='button-box'>
                    <Box className='footer-padding'>
                      <CustomButton
                        className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                        variant='contained'
                        type='submit'
                      >
                        {`${id ? 'Update Insert' : 'Add Insert'}`}
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Loading
            loading={id && !isSuccess ? true : false}
            background='white'
            loaderColor='#265AA8'
          />
          {openModal && (
            <ToolHolderConfirmModel
              partData={value}
              isOpen={openModal}
              handleClose={handleModelClose}
            />
          )}
          {isErrorModalOpen && (
            <ErrorPopup
              isOpen={isErrorModalOpen}
              handleClose={handleErrorModalClose}
              errorDetails={errorMessage}
            />
          )}
        </HeadBox>
      </Box>
    </React.Fragment>
  );
};
export default AddToolInsert;
