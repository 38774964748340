import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import {
  Button,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  TableCell,
  Checkbox,
} from '@mui/material';
import * as Yup from 'yup';
import CustomButton from '../commonComponents/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import 'rc-time-picker/assets/index.css';
import Loading from 'react-fullscreen-loading';
import CloseIcon from '@mui/icons-material/Close';
import AddAccessoryInventory from '../../Modals/AddAccessoryInventory';
import AccessoriesConfirm from '../../Modals/AccessoriesConfirm';
import _ from 'lodash';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AddSetting from '../Settings/AddSetting';
import { useAddSetting, useGetSetting } from '../../hooks/factoryScheduleHooks';
import { useGetAccessorySingle } from '../../hooks/accessoryHooks';

const SignupSchema = Yup.object().shape({
  // name: Yup.string()
  //   .trim()
  //   .required('Accessory name is Required')
  //   .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  // number: Yup.number().required('only number allowed'),
  // quantity: Yup.number().typeError('only number allowed'),
  // manufacturer: Yup.string()
  //   .trim()
  //   .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  // dateBought: Yup.string().trim(),
});

const HeadBox = styled(Box)(() => ({
  height: '82vh !important',
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.dividermr': {
    marginTop: '80px',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.fildborder': {
    border: '1px solid #8A8A8A',
    borderColor: '#8A8A8A',
    borderRadius: '4px',
    padding: '12px 12px 10px 12px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
}));
const TabContainer = styled(TableContainer)(() => ({
  borderRadius: '8px 8px 0px 0px !important',
  border: '1px solid #DEDEDE !important',
}));

const OtherSetting = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isInventoryOpen, setIsInvenotryOpen] = React.useState(false);
  const handleInventoryOpen = () => setIsInvenotryOpen(true);
  const handleInventoryClose = () => setIsInvenotryOpen(false);
  const handleClose = () => setOpenModal(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({});
  const [inventoryModalData, setInventoryModalData] = useState({});
  const isVariant = window.location.pathname.startsWith('/part/add-variant');
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const TableDataCell = styled(TableCell)(() => ({
    padding: isMdScreen ? '8px 8px 8px 16px' : '8px 16px 8px 16px',
  }));

  const { mutate: addSettingData } = useAddSetting();
  const { data: getSettingData } = useGetSetting();

  const StyledTableRow = styled(TableRow)(() => ({
    '.MuiTableCell-root': {
      // borderBottom: 'none !important',
    },
    '&:nth-of-type(odd)': {
      // backgroundColor: '#F4F9FF',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const SettingModule = [
    'Tool',
    'Accessories',
    'Finished Goods',
    'Raw Material',
    'Consumable',
    'Customer',
    'Customer Order',
    'Work Order',
    'Tool type',
    'Tool Holder',
    'Tool Accessories',
    'Insert',
  ];
  const settingsArr = SettingModule?.map((notification) => {
    return {
      event: notification,
      automatic: false,
      manual: false,
      prefix: '',
    };
  });
  const settingsRowData = SettingModule?.map((notification, ind) => {
    return {
      id: ind,
      notification,
    };
  });
  const getSettingsData = getSettingData?.setting?.setting?.map((data, index) =>
    _.omit(data, ['_id'])
  );

  // const [supplier, setSupplier] = useState<{ [key: string]: any }>({});
  const [closeWarning, setCloseWarning] = useState(true);
  const { id } = useParams<string>();
  const navigate = useNavigate();

  //get Part Single List
  const { data: getAccessorys, isSuccess } = useGetAccessorySingle(id, !!id);
  const singleAccessory = getAccessorys?.data;
  // const { data: getAccessory } = useGetAccssorySingle(true);
  // const accessorysList: any = getAccessory?.map((accessory) => {
  //   return {
  //     name: accessory.name,
  //     number: accessory.number,
  //     quantity: accessory.quantity,
  //     manufacturer: accessory.manufacturer,
  //     dateBought: accessory.dateBought,
  //     id: accessory.id,
  //   };
  // });
  // const accessorys = accessorysList?.map((supplier) => {
  //   return {
  //     ...supplier,
  //     label: supplier.name,
  //     color: '#000',
  //   };
  // });
  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  const breadCrumbData = [];

  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  const icon = <AiOutlineInfoCircle color='red' />;
  return (
    <React.Fragment>
      {!isVariant && (
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={'Other Settings'}
          backToLink={null}
        />
      )}
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            setting: getSettingsData ? getSettingsData : settingsArr,
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            addSettingData(values);
          }}
        >
          {({ handleSubmit, setFieldValue, handleChange, values }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Settings for numbering</Typography>
              {/* //! new start */}
              <Box
                height={'auto'}
                mb={isMdScreen ? '8%' : '2%'}
                width={isMdScreen ? '100%' : '80%'}
              >
                <TabContainer>
                  <Table sx={{ minWidth: 700 }} aria-label='customize table'>
                    <TableHead className='background-head'>
                      <TableRow>
                        <TableDataCell>Event</TableDataCell>
                        <TableDataCell align='left'>Prefix</TableDataCell>
                        <TableDataCell align='left'>Automatic</TableDataCell>
                        <TableDataCell align='left'>Manual</TableDataCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {settingsRowData.map((row) => {
                        return (
                          <StyledTableRow key={row.notification}>
                            <TableDataCell className='module-text'>
                              {row.notification}
                            </TableDataCell>
                            <TableDataCell className='module-text'>
                              {/* Add Prefix input */}
                              <TextField
                                type='text'
                                size='small'
                                name={`setting[${row.id}].prefix`}
                                value={values?.setting?.[row.id]?.prefix || ''}
                                onChange={(e) =>
                                  setFieldValue(`setting[${row.id}].prefix`, e.target.value)
                                }
                              />
                            </TableDataCell>
                            <TableDataCell className='module-text'>
                              <Checkbox
                                name={`setting[${row.id}].automatic`}
                                checked={values?.setting[row.id]?.automatic || '' ? true : false}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.checked) {
                                    setFieldValue(`setting[${row.id}].automatic`, true);
                                    setFieldValue(`setting[${row.id}].manual`, false);
                                  } else {
                                    const filteredObject = _.pickBy(
                                      values?.setting[row.id],
                                      function (value, key) {
                                        return ['edit', 'delete'].includes(key);
                                      }
                                    );
                                    const isAnyRightTrue = Object.values(
                                      filteredObject || {}
                                    ).includes(true);
                                    if (!isAnyRightTrue) {
                                      setFieldValue(`setting[${row.id}].automatic`, false);
                                    }
                                  }
                                }}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                              />
                            </TableDataCell>
                            <TableDataCell className='module-text'>
                              <Checkbox
                                name={`setting[${row.id}].automatic`}
                                checked={values?.setting[row.id]?.manual || '' ? true : false}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.checked) {
                                    setFieldValue(`setting[${row.id}].manual`, true);
                                    setFieldValue(`setting[${row.id}].automatic`, false);
                                  } else {
                                    const filteredObject = _.pickBy(
                                      values?.setting[row.id],
                                      function (value, key) {
                                        return ['edit', 'delete'].includes(key);
                                      }
                                    );
                                    const isAnyRightTrue = Object.values(
                                      filteredObject || {}
                                    ).includes(true);
                                    if (!isAnyRightTrue) {
                                      setFieldValue(`setting[${row.id}].manual`, false);
                                    }
                                  }
                                }}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                              />
                            </TableDataCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TabContainer>
              </Box>

              {/* //! new end */}
              {/* <Grid container item xs={10} spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <Box className='fildborder'>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={(e: any) => {
                        setFieldValue('isBatchTracking', e.target.checked);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'></Typography>
                  <Box className='fildborder'>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={(e: any) => {
                        setFieldValue('isBatchTracking', e.target.checked);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <Typography className='field-title'></Typography>
                  <Box className='fildborder'>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ 'aria-label': 'ant design' }}
                      onChange={(e: any) => {
                        setFieldValue('isBatchTracking', e.target.checked);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid> */}
              {/* <Typography className='title-typography'> Tool Category</Typography> */}
              <Grid container spacing={2} className='warning-tab'>
                <Grid item xs={12}>
                  {isVariant && closeWarning && (
                    <Box className='variant-warning-box'>
                      <Box display={'flex'}>
                        <AiOutlineInfoCircle className='outline-circle' />
                        <Typography className='warning-text'>
                          Make sure the part number is unique and is not the same as any other part{' '}
                        </Typography>
                      </Box>
                      <Box>
                        <CloseIcon
                          className='warning-close'
                          onClick={() => setCloseWarning(false)}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box className='button-box'>
                {/* <Box className='footer-padding'> */}
                <Box>
                  <CustomButton variant='contained' className={'blue-bg'} type='submit'>
                    {isEdit ? 'Save Changes' : 'Save Settings'}{' '}
                  </CustomButton>
                </Box>
              </Box>
              <Grid container spacing={2} mt={0}>
                {' '}
                <Grid item xs={12} className='divider-mr-1'>
                  <Divider />{' '}
                </Grid>{' '}
              </Grid>
            </Form>
          )}
        </Formik>
        <AddSetting />

        <Loading
          className='sa'
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />

        {openModal && (
          <AccessoriesConfirm
            handleInventory={handleInventoryOpen}
            setInventoryModalData={setInventoryModalData}
            partData={value}
            isOpen={openModal}
            handleClose={handleClose}
          />
        )}
        {isInventoryOpen && (
          <AddAccessoryInventory
            data={inventoryModalData}
            isOpen={isInventoryOpen}
            handleClose={handleInventoryClose}
          />
        )}
      </HeadBox>
    </React.Fragment>
  );
};
export default OtherSetting;
