import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { consumable } from '../queryKeys/catalogue';
import { PartErrI } from '../Types/part';
import { consumablesApi } from '../api/consumables';

export const useGetConsumablesSingle = (id, isEnable = true) => {
  return useQuery([consumable.CONSUMABLE, id], consumablesApi.getSingleConsumables, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetConsumables = (isEnabled, page) => {
  return useQuery([consumable.CONSUMABLES, page], consumablesApi.getConsumables, {
    // initialData: { parts: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddConsumables = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(consumablesApi.addConsumables, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([consumable.CONSUMABLES]);
      queryClient.invalidateQueries([consumable.CONSUMABLESFULL]);
      const data = res;
      navigate(`/consumables`, { state: { activeTab: '5' } });
    },
    onError: (err: PartErrI) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useUpdateConsumables = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(consumablesApi.updateConsumables, {
    onSuccess: (res, id) => {
      const data = res;
      // queryClient.invalidateQueries([consumable.CONSUMABLES]);
      queryClient.invalidateQueries([consumable.CONSUMABLE, id]);
      navigate(`/consumables`, { state: { activeTab: '5' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useDeleteConsumables = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(consumablesApi.deleteConsumables, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([consumable.CONSUMABLES]);
      queryClient.invalidateQueries([consumable.CONSUMABLES, id]);
      // toast.success(data?.message);
      navigate(`/consumables`, { state: { activeTab: '5' } });
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useGetDeletedConsumables = (id, isEnable = true) => {
  return useQuery(
    [consumable.CONSUMABLES + 'DELETED', id],
    consumablesApi.getSingleDeletedConsumables,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useHardDeleteConsumables = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(consumablesApi.hardDeleteConsumables, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([consumable.CONSUMABLES]);
      queryClient.invalidateQueries([consumable.CONSUMABLES, id]);
      navigate(`/deleted-items`);
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useRestoreConsumables = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(consumablesApi.restoreConsumable, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([consumable.CONSUMABLES]);
      queryClient.invalidateQueries([consumable.CONSUMABLES, id]);
      navigate(`/deleted-items`);
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useValidateConsumablesName = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(consumablesApi.validateConsumablesName, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    //toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  });
};
