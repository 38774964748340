import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FieldArray, Form, Formik } from 'formik';
import { Button, Divider, Select, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import CustomButton from '../commonComponents/CustomButton';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { useLocation, useParams } from 'react-router-dom';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useGetPartSingle } from '../../hooks/partHooks';
import AddInventory from '../../Modals/AddInventory';
import 'rc-time-picker/assets/index.css';
import Loading from 'react-fullscreen-loading';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import ToolConfirm from '../../Modals/ToolConfirm';
import { useQcApproval, useQualityInspection } from '../../hooks/workorderHooks';
import ApproveQualityModal from '../../Modals/ApproveQuality';
import UploadDocument from '../commonComponents/UploadDocument';

const formSchema = Yup.object().shape({
  remarks: Yup.string().required('Please fill the remarks'),
});

const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const QualityCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '100%',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#8A8A8A',
  '&.MuiButton-outlined': {
    border: 'border: 1px solid rgba(38, 90, 168, 1)',
  },
}));
const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));

const HeadBox = styled(Box)(() => ({
  height: '82vh',
  padding: '0px 5px 18px 56px',
  position: 'relative',
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.parent-quantity-form': {
    height: 'calc(100vh - 265px)',
    overflow: 'auto',
    width: '100%',
  },
  '.reject': {
    border: '1px solid #0160B9',
    color: '#0160B9',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.description': {
    color: '#8A8A8A',
    fontSize: '14px',
    paddingLeft: '20px',
  },
  '.button-box': {
    position: 'absolute',
    width: '99%',
    left: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.quality-field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 10px 6px 20px',
    color: 'rgba(1, 96, 185, 1)',
  },
  '.quality-drowdown-title': {
    display: 'flex !important',
    justifyContent: 'space-between',
    marginTop: '6px',
  },
  '.quality-drowdown-title-mr-1': {
    // padding: '0px 10px 6px 500px',
    // marginLeft: '500px',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    /* text-transform: math-auto !important */
    height: '28px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.uploadreferencesIcon-3': {
    color: '#020202',
  },
  '.workqc-divider-mr-1': {
    margin: '25px 0px 20px 0px',
  },
  '.inspection-border': {
    border: '1px solid rgba(222, 222, 222, 1)',
    padding: '10px 0px 15px 0px',
    margin: '10px 0px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.mr-15': {
    marginLeft: '8px',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
}));

const QualityCheck = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isInventoryOpen, setIsInvenotryOpen] = React.useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [finalValues, setFinalValues] = useState({});
  const handleDeleteClose = () => {
    setIsApprove(false);
  };
  const handleInventoryOpen = () => setIsInvenotryOpen(true);
  const handleInventoryClose = () => setIsInvenotryOpen(false);
  const handleClose = () => setOpenModal(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({});
  const [inventoryModalData, setInventoryModalData] = useState({});
  const isVariant = window.location.pathname.startsWith('/part/add-variant');
  const { state } = useLocation();
  const { data: getQulityInspectionData } = useQualityInspection(true, state?.id);
  const { mutate: qcApprovalData } = useQcApproval();
  // Convert productDetails to measurements format
  const measurements =
    getQulityInspectionData?.data?.dimensions?.length > 0
      ? (() => {
          const firstProductDetail = getQulityInspectionData?.data.productDetails[0];
          if (!firstProductDetail) return [];

          const { quantity } = firstProductDetail;

          const dimensions =
            getQulityInspectionData?.data.dimensions?.map((dimension) => ({
              name: dimension,
              value: dimension,
            })) ?? [];

          return Array.from({ length: quantity }, (_, index) => ({
            title: `Inspection ${index + 1}`,
            dimensions: [...dimensions],
          }));
        })()
      : [];
  const { id } = useParams<string>();
  const [showAdditionInfo, setShowAdditionInfo] = useState(-1);

  //get Part Single List
  const { data: getParts, isSuccess } = useGetPartSingle(id, !!id);
  const singlePart = getParts?.data;

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);
  const breadCrumbData = [
    {
      title: 'Workorders',
      link: '/new-work-order',
    },
    {
      title: ' Quality Check',
      link: `/new-work-order`,
      activeTab: '1',
    },
    // {
    //   title: `${isEdit ? singlePart?.name : isVariant ? 'Add Variant' : 'Add Tool'}`,
    //   link: `/${isEdit ? `edit-part/${id}` : isVariant ? `/add-variant/${id}` : 'add-Tool'}`,
    // },
  ];

  const initialData = getQulityInspectionData?.data?.qcChecklist?.checklistItems?.map((item) => ({
    name: item.name,
    responseType: item.responseType,
    options: item.options,
    answer: '',
  }));
  const icon = <AiOutlineInfoCircle color='red' />;
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);
  return (
    <React.Fragment>
      {!isVariant && (
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`${isEdit ? 'Edit' : ''}  Quality Check Task`}
          backToLink='/work-order'
        />
      )}
      <HeadBox>
        <Formik
          initialValues={{
            remarks: '',
            measurements: measurements,
            checklistItems: initialData,
            responseEvidences: [],
          }}
          enableReinitialize={true}
          validationSchema={formSchema}
          onSubmit={(values) => {
            const finalData = {
              qualityCheckTaskResponse: {
                ...values,
                id: state?.id,
              },
            };
            setIsApprove(true);
            setFinalValues(finalData);

            // qcApprovalData(finalData);
            // const isVariantAdd = window.location.pathname.startsWith('/part/add-variant');
            // setOpenModal(true);
            // setValue({
            //   ...values,
            // });
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Box className='parent-quantity-form'>
                <Typography className='title-typography'>
                  {getQulityInspectionData?.data?.qcChecklist?.title}
                </Typography>
                {/* //! old Data for checkList */}
                {/* <Grid container xs={10} spacing={2}>
                  {getQulityInspectionData?.data?.qcChecklist?.checklistItems?.map(
                    (data, index) => {
                      return (
                        <Grid item xs={4} md={6} lg={4} spacing={2} key={index}>
                          <Typography className='field-title'>{data?.name}</Typography>
                          {data?.responseType == 'Radio' && (
                            <Box className='buttonFlex' sx={{ display: 'flex', gap: '15px' }}>
                              {data?.options?.map((data, index) => {
                                return (
                                  <QualityCustomButton
                                    key={index}
                                    variant='outlined'
                                    // onClick={(newValue: any) => {
                                    //   setFieldValue(`batch.${index}.isInspection`, true);
                                    // }}
                                    // disabled={values?.batch?.[index]?.isInspection === true}
                                  >
                                    {data}
                                  </QualityCustomButton>
                                );
                              })}
                            </Box>
                          )}
                          {data?.responseType == 'Numeric Input' && (
                            <InputField
                              // error={!errors.visualCheck || !touched.visualCheck ? false : true}
                              placeholder='Numeric Input'
                              fullWidth
                              type='number'
                              onChange={handleChange}
                              value={values.visualCheck}
                              InputProps={{
                                endAdornment: touched.visualCheck && errors.visualCheck && icon,
                              }}
                            />
                          )}
                          {data?.responseType == 'Alpha Numeric Input' && (
                            <InputField
                              placeholder='Alpha Numeric Input'
                              fullWidth
                              type='text'
                              onChange={handleChange}
                              // InputProps={{
                              //   endAdornment: touched.visualCheck && errors.visualCheck && icon,
                              // }}
                            />
                          )}
                        </Grid>
                      );
                    }
                  )}
                </Grid> */}
                {/* //! new test here */}
                <Grid container xs={12} spacing={2}>
                  {values?.checklistItems?.map((item, index) => (
                    <Grid item xs={4} md={4} lg={4} spacing={2} key={index}>
                      <Typography className='field-title'>{item.name}</Typography>
                      {item.responseType === 'Radio' && (
                        <Box sx={{ display: 'flex', gap: '15px' }}>
                          {item?.options?.map((option, optionIndex) => (
                            <>
                              <label key={optionIndex} style={{ width: '100%' }}>
                                <QualityCustomButton
                                  sx={{
                                    border:
                                      values?.checklistItems?.[index]?.answer == 'Yes' &&
                                      option == 'Yes'
                                        ? '2px solid #265AA8'
                                        : values?.checklistItems?.[index]?.answer == 'No' &&
                                          option == 'No'
                                        ? '2px solid #265AA8'
                                        : '',
                                  }}
                                  key={index}
                                  fullWidth
                                  variant='outlined'
                                  onClick={(newValue: any) => {
                                    setFieldValue(`checklistItems.[${index}].answer`, option);
                                  }}
                                >
                                  {option}
                                </QualityCustomButton>
                              </label>
                            </>
                          ))}
                        </Box>
                      )}
                      {item.responseType === 'Alpha Numeric Input' && (
                        <InputField
                          name={`checklistItems[${index}].answer`}
                          onChange={handleChange}
                          placeholder='Alpha Numeric Input'
                          fullWidth
                          type='text'
                        />
                      )}
                      {item.responseType === 'Numeric Input' && (
                        <InputField
                          onChange={handleChange}
                          name={`checklistItems[${index}].answer`}
                          placeholder='Numeric Input'
                          fullWidth
                          type='number'
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} className='workqc-divider-mr-1'>
                    <Divider />
                  </Grid>
                </Grid>
                <Typography className='title-typography'>Evidence & Remarks</Typography>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={4} md={6} lg={4} mt={'30px'}>
                    <UploadDocument
                      name={values?.responseEvidences}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isAttachment={false}
                      createFileName='responseEvidences'
                      isTaskUpload={true}
                      acceptTypes='.pdf, jpg, .jpeg, .png'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className='field-title'>
                      Remarks
                      <Require />
                    </Typography>
                    <InputField
                      error={!errors.remarks || !touched.remarks ? false : true}
                      placeholder='Enter Tool visualCheck'
                      fullWidth
                      onChange={handleChange}
                      name='remarks'
                      value={values.remarks}
                      InputProps={{
                        endAdornment: touched.remarks && errors.remarks && icon,
                      }}
                    />
                    {touched.remarks && errors.remarks && (
                      <Typography className='validation-text'>
                        {typeof errors.remarks === 'string' ? errors.remarks : ''}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} className='workqc-divider-mr-1'>
                    <Divider />
                  </Grid>
                </Grid>
                <Typography className='title-typography'>Enter Measurements</Typography>
                {/* //! start */}

                {/* {Array.from(
                  { length: getQulityInspectionData?.data?.productDetails?.[0]?.quantity },
                  (_, index) => index
                )?.map((data, index) => {
                  return (
                    <Grid container xs={12} spacing={0} key={index} className='inspection-border'>
                      <Grid xs={12} className='quality-drowdown-title'>
                        <Typography className='quality-field-title'>
                          Inspection {index + 1}
                        </Typography>

                        <Button
                          className=''
                          onClick={() => {
                            if (index == showAdditionInfo) {
                              setShowAdditionInfo(-1);
                            } else {
                              setShowAdditionInfo(index);
                            }
                          }}
                        >
                          {' '}
                          {showAdditionInfo ? (
                            <IoIosArrowUp
                              color='rgba(2, 2, 2, 1)'
                              size={20}
                              className='quality-drowdown-title-mr-1'
                            />
                          ) : (
                            <IoIosArrowDown
                              color='rgba(2, 2, 2, 1)'
                              size={20}
                              className='quality-drowdown-title-mr-1'
                            />
                          )}{' '}
                        </Button>
                      </Grid>
                      <Grid md={12}>
                        <Typography className='description'>Enter Dimensions</Typography>
                      </Grid>

                      {showAdditionInfo === index && (
                        <>
                          <Grid container item spacing={2} p={2}>
                            {getQulityInspectionData?.data?.dimensions?.map((data, ind) => {
                              return (
                                <Grid item md={2} key={ind}>
                                  <Typography className='field-title'> {data} </Typography>
                                  <InputField
                                    error={
                                      !errors.visualCheck || !touched.visualCheck ? false : true
                                    }
                                    placeholder='Enter Dimension'
                                    fullWidth
                                    onChange={handleChange}
                                    name='visualCheck'
                                    value={values.visualCheck}
                                    InputProps={{
                                      endAdornment:
                                        touched.visualCheck && errors.visualCheck && icon,
                                    }}
                                  />

                                  {touched.visualCheck && errors.visualCheck && (
                                    <Typography className='validation-text'>
                                      {typeof errors.visualCheck === 'string'
                                        ? errors.visualCheck
                                        : ''}
                                    </Typography>
                                  )}
                                </Grid>
                              );
                            })}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  );
                })} */}
                <FieldArray name='measurements'>
                  {({ push, remove }) => (
                    <div>
                      {values?.measurements?.map((measurement, index) => (
                        <div key={index}>
                          <FieldArray name={`measurements[${index}].dimensions`}>
                            {({ push: pushDimension, remove: removeDimension }) => (
                              <div>
                                <div>
                                  <Grid
                                    container
                                    xs={12}
                                    spacing={0}
                                    key={index}
                                    className='inspection-border'
                                  >
                                    <Grid xs={12} className='quality-drowdown-title'>
                                      <Typography className='quality-field-title'>
                                        {measurement?.title}
                                      </Typography>

                                      <Button
                                        className=''
                                        onClick={() => {
                                          if (index == showAdditionInfo) {
                                            setShowAdditionInfo(-1);
                                          } else {
                                            setShowAdditionInfo(index);
                                          }
                                        }}
                                      >
                                        {' '}
                                        {showAdditionInfo ? (
                                          <IoIosArrowUp
                                            color='rgba(2, 2, 2, 1)'
                                            size={20}
                                            className='quality-drowdown-title-mr-1'
                                          />
                                        ) : (
                                          <IoIosArrowDown
                                            color='rgba(2, 2, 2, 1)'
                                            size={20}
                                            className='quality-drowdown-title-mr-1'
                                          />
                                        )}{' '}
                                      </Button>
                                    </Grid>
                                    <Grid md={12}>
                                      <Typography className='description'>
                                        Enter Dimensions
                                      </Typography>
                                    </Grid>

                                    {showAdditionInfo === index && (
                                      <>
                                        <Grid container item spacing={2} p={2}>
                                          {measurement?.dimensions?.map((dimension, ind) => {
                                            return (
                                              <Grid item md={2} key={ind}>
                                                <Typography className='field-title'>
                                                  {dimension?.name}
                                                </Typography>
                                                {/* <InputField
                                                  name={`measurements[${index}].dimensions[${ind}].name`}
                                                  // value={measurements[index].dimensions[ind]?.name}
                                                  error={
                                                    !errors.visualCheck || !touched.visualCheck
                                                      ? false
                                                      : true
                                                  }
                                                  placeholder='Enter Dimension'
                                                  fullWidth
                                                  onChange={handleChange}
                                                /> */}
                                                <InputField
                                                  name={`measurements[${index}].dimensions[${ind}].value`}
                                                  placeholder='Enter Dimension value'
                                                  type='number'
                                                  fullWidth
                                                  onChange={handleChange}
                                                />

                                                {/* {touched.visualCheck && errors.visualCheck && (
                                                  <Typography className='validation-text'>
                                                    {typeof errors.visualCheck === 'string'
                                                      ? errors.visualCheck
                                                      : ''}
                                                  </Typography>
                                                )} */}
                                                {/* <button
                                                  type='button'
                                                  onClick={() => removeDimension(ind)}
                                                >
                                                  Remove Dimension
                                                </button> */}
                                              </Grid>
                                            );
                                          })}
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </div>
                                {/* <button
                                  type='button'
                                  onClick={() => pushDimension({ name: '', value: '' })}
                                >
                                  Add Dimension
                                </button> */}
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      ))}
                      {/* <button type='button' onClick={() => push({ title: '', dimensions: [] })}>
                        Add Measurement
                      </button> */}
                    </div>
                  )}
                </FieldArray>
              </Box>

              {/* //! end */}
              {/* <Grid container spacing={2} className='warning-tab'>
                <Grid item xs={12}>
                  {isVariant && closeWarning && (
                    <Box className='variant-warning-box'>
                      <Box display={'flex'}>
                        <AiOutlineInfoCircle className='outline-circle' />
                        <Typography className='warning-text'>
                          Make sure the part number is unique and is not the same as any other part{' '}
                        </Typography>
                      </Box>
                      <Box>
                        <CloseIcon
                          className='warning-close'
                          onClick={() => setCloseWarning(false)}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid> */}
              {/* <Grid display={'flex'} spacing={0} sx={{ border: '1px solid green' }}> */}
              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton variant='contained' className={'blue-bg'} type='submit'>
                    {'Approve'}
                  </CustomButton>
                </Box>
              </Box>
              {/* </Grid> */}
            </Form>
          )}
        </Formik>
        <Loading
          className='sa'
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />

        {openModal && (
          <ToolConfirm
            // handleInventory={handleInventoryOpen}
            // setInventoryModalData={setInventoryModalData}
            partData={value}
            isOpen={openModal}
            handleClose={handleClose}
          />
        )}
        {isInventoryOpen && (
          <AddInventory
            data={inventoryModalData}
            isOpen={isInventoryOpen}
            handleClose={handleInventoryClose}
          />
        )}

        <ApproveQualityModal
          open={isApprove}
          handleClose={handleDeleteClose}
          handleDelete={qcApprovalData}
          data={finalValues}
          // handleOpen={handleDeleteOpen}
          // id={id}
        />
      </HeadBox>
    </React.Fragment>
  );
};
export default QualityCheck;
