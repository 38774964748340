import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { productApi } from '../api/products';
import { product } from '../queryKeys/catalogue';

export const useGetProduct = (id, isEnabled = true) => {
  return useQuery([product.PRODUCT, id], productApi.getSingleProduct, {
    enabled: isEnabled,
    select: (data) => data.data?.product,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetDeletedProduct = (id: any, isEnabled = true) => {
  return useQuery([product.PRODUCT + 'DELETED', id], productApi.getSingleDeletedProduct, {
    enabled: isEnabled,
    select: (data) => data.data?.product,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetProducts = (isEnabled, page) => {
  return useQuery([product.PRODUCTS, page], productApi.getProducts, {
    enabled: isEnabled,
    select: (res) => res?.data?.products,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      return err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useFullGetProducts = (isEnabled) => {
  return useQuery([product.PRODUCTS + 'FULL'], productApi.getFullProducts, {
    enabled: isEnabled,
    select: (res) => res?.data?.products,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useAddProduct = (isRedirect = false) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(productApi.addProduct, {
    onSuccess: (res) => {
      const data = res.data;
      // toast.success(data.message);
      if (!isRedirect) {
        const materialType = data?.part?.partMaterialType;
        materialType == 'Accessories';
        navigate(`/catalogue`, { state: { activeTab: '2' } });
      }
      queryClient.invalidateQueries([product.PRODUCTSFULL]);
      queryClient.invalidateQueries([product.PRODUCTS]);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useUpdateProduct = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(productApi.updateProduct, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([product.PRODUCTS]);
      queryClient.invalidateQueries([product.PRODUCT, id]);
      // toast.success(data.message);
      navigate(`/catalogue`, { state: { activeTab: '2' } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(productApi.deleteProduct, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([product.PRODUCTS]);
      queryClient.removeQueries([product.PRODUCT, id]);
      // toast.success(data.message);
      navigate(`/catalogue`, { state: { activeTab: '2' } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useRestoreProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(productApi.restoreProduct, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([product.PRODUCTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      // const materialType = data?.part?.partMaterialType;
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useHardDeleteProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(productApi.hardDeleteProduct, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([product.PRODUCTS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
