import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { useAddComment, useUpdateTicket } from '../../hooks/ticketHooks';
import FileUploadIcon from '../../assets/svg/FileUploadIcon';
import { useUploadFile } from '../../hooks/uploadHook';

const CommitInput = ({ id, handleSendMessage, setIsLoading, allFiles }: any) => {
  const [addComment, setAddComment] = useState<string>('');
  const handleChange = (e) => {
    setAddComment(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e?.keyCode === 13) {
      const add = { comment: addComment, ticketId: id };
      comment(add);
      handleSendMessage();
    }
  };
  const {
    mutate: uploadFile,
    data,
    isSuccess: isProfilePicUploadSuccess,
    isLoading,
  } = useUploadFile();

  const commentsDataFiles = data?.data?.data?.map((fileDetails) => {
    const currentType = fileDetails?.mimetype?.includes('video') ? 'video' : 'image';
    return {
      fileType: currentType,
      url: fileDetails?.location,
      name: fileDetails?.originalname,
    };
  });

  const { mutate: updateTicket } = useUpdateTicket('1', true);
  // const type = data?.data?.data[0]?.mimetype;
  // const uploadPics = data?.data?.data[0]?.location;
  // const imgName = data?.data?.data[0]?.originalname;
  const handleFile = (e) => {
    const fileData = e.target.files;
    const formData = new FormData();
    const multipleFile: any = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file', fileData[i]);
      formData.append('fileName', fileData[i].name);
      formData.append('postfix', 'ticket-comment');
      const currData = formData;
      multipleFile.push(currData);
    }
    return uploadFile(formData);
  };
  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    }
    if (isProfilePicUploadSuccess) {
      const oldFiles = allFiles?.map((fileDetails) => {
        return {
          fileType: fileDetails?.fileType,
          url: fileDetails?.url,
        };
      });
      const allDocs = [...(oldFiles || []), ...commentsDataFiles];
      updateTicket({ uploadedData: allDocs, id });
      setIsLoading(false);
    }
  }, [isProfilePicUploadSuccess, isLoading]);

  const { mutate: comment, isSuccess } = useAddComment();
  useEffect(() => {
    if (isSuccess) {
      setAddComment('');
    }
  }, [isSuccess]);
  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #8A8A8A',
        borderRadius: '4px',
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Type Comment'
        value={addComment}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      <IconButton sx={{ padding: '0px 5px' }}>
        <label htmlFor='imageUpload'>
          <FileUploadIcon />
        </label>
      </IconButton>
      <input
        type='file'
        accept='video/mp4,video/x-m4v,video/* , image/*'
        alt='add-image'
        multiple
        id='imageUpload'
        hidden
        onChange={(e) => {
          handleFile(e);
        }}
      />
      <Button
        sx={{
          fontSize: '14px',
          fontWeight: '600',
          padding: '0px',
          textTransform: 'capitalize',
        }}
        onClick={() => {
          const add = { comment: addComment, ticketId: id };
          comment(add);
          handleSendMessage();
        }}
      >
        Post
      </Button>
    </Paper>
  );
};

export default CommitInput;
