import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import CustomModalButton from './CustomModalButton';
import { useLocation } from 'react-router-dom';
import RestoreWarningModal from '../../Modals/RestoreWarningModal';
import ErrorPopup from '../../Modals/ErrorPopup';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import SearchComponent from '../../generalComponents/SearchComponent';
import UserInfo from '../../generalComponents/UserInfo';
import EditIcon_new from '../../assets/svg/EditIcon_new';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import Plus from '../../assets/svg/Plus';
interface breadCrumbData {
  title: string;
  link: string;
  activeTab?: string;
}
interface breadCrumbModel {
  pageTitle: string;
  backToLink: string | null;
  breadCrumbData: breadCrumbData[] | null;
  isDetailPage?: boolean;
  detailPageData?: breadCrumbData[];
  hideDivider?: boolean;
  isStatusShow?: boolean;
  isOnline?: boolean;
  handleDelete?: any;
  hideDelete?: boolean;
  isDeleted?: boolean;
  isRestore?: boolean;
  handleRestore?: any;
  activeTab?: string;
  isDeleteEnable?: boolean;
  isUpdateIconShow?: boolean;
  isDeleteIconShow?: boolean;
  isAddIconShow?: boolean;
  errMsg?: string;
}
const BreadcrumbDivider = styled(Divider)(() => ({
  borderColor: '#DEDEDE !important',
  margin: '0px !important',
}));
const BreadcrumbNavigation: React.FC<breadCrumbModel> = (props) => {
  const {
    breadCrumbData,
    pageTitle,
    backToLink,
    isDetailPage = false,
    detailPageData = [],
    hideDivider = false,
    hideDelete = false,
    handleDelete,
    handleRestore,
    activeTab,
    isDeleteEnable = true,
    errMsg = '',
    isUpdateIconShow = true,
    isDeleteIconShow = true,
    isAddIconShow = true,
  } = props;
  let editLink: string | undefined = '';
  // let deleteLink: string | undefined = '';
  let variantLink: string | undefined = '';
  if (isDetailPage) {
    if (detailPageData?.length > 0) {
      editLink = detailPageData?.find((data) => data.title === 'edit')?.link;
      // deleteLink = detailPageData?.find((data) => data.title === 'delete')?.link;
      variantLink = detailPageData?.find((data) => data.title === '+ Create Variant')?.link;
    }
  }
  const location = useLocation();
  const isDeletedItemsPage = location.pathname.startsWith('/deleted-items');
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const BreadCrumbWrapper = styled(Box)(() => ({
    '.title-text': {
      fontSize: isMdScreen ? '18px' : '24px',
      fontWeight: '600',
      color: '#020202',
      paddingLeft: backToLink ? '20px' : '0px',
      textTransform: 'capitalize',
      maxWidth: isMdScreen ? '250px' : '450px',
    },
    '.back-arrow': {
      cursor: 'pointer',
    },
    '.iconText': {
      fontSize: '14px',
      color: '#265AA8',
      fontWeight: 600,
    },
    '.icon-text-wrapper': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    '.mr-23px': {
      marginRight: '23px',
    },
    '.icon': {
      color: '#265AA8',
      paddingRight: '8px',
      fontSize: '15px !important',
    },
    '.online-status': {
      paddingLeft: '12px',
      alignItems: 'center !important',
      '.css-1012bww-MuiChip-root': {
        borderRadius: '4px !important',
        height: '24px !important',
      },
    },
    '.ml-26': {
      marginLeft: '26px',
    },
    '.last-link': {
      color: 'grey',
    },
  }));
  const BreadCrumbSubWrapper = styled(Box)(() => ({
    '.title-text': {
      fontSize: '24px',
      fontWeight: '600',
      color: '#020202',
      paddingLeft: backToLink ? '20px' : '0px',
      textTransform: 'capitalize',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '.back-arrow': {
      cursor: 'pointer',
    },
    '.iconText': {
      fontSize: '14px',
      color: '#265AA8',
      fontWeight: 600,
      paddingLeft: '8px',
    },
    '.icon-text-wrapper': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
    },
    '.mr-23px': {
      marginRight: '23px',
    },
    '.icon': {
      color: '#265AA8',
      fontSize: '15px !important',
    },
    '.online-status': {
      paddingLeft: '12px',
    },
    '.ml-26': {
      marginLeft: '26px',
    },
    '.last-link': {
      color: 'grey',
    },
  }));
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const navigate = useNavigate();
  const breadCrumbs = breadCrumbData?.map((breadCrumb, index) => (
    <Link
      underline='hover'
      key={index}
      className={`breadcrumb-link ${breadCrumbData?.length - 1 === index ? 'last-link' : ''}`}
      color='#0160B9'
      onClick={() =>
        navigate(breadCrumb.link, {
          state: { activeTab: breadCrumb?.activeTab ? breadCrumb?.activeTab : '1' },
        })
      }
    >
      {breadCrumb.title}
    </Link>
  ));
  //Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false);
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);
  const [restoreRecord, setRestoreRecord] = useState(false);
  const handleRestoreOpen = () => setRestoreRecord(true);
  const handleRestoreClose = () => setRestoreRecord(false);
  const { id } = useParams();
  //Delete Error warning
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalOpen = () => setIsErrorModalOpen(true);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const enableDeleteWarning = () => {
    if (!isDeleteEnable) {
      setErrorMessage(errMsg);
      handleErrorModalOpen();
    } else {
      handleDeleteOpen();
    }
  };
  return (
    <>
      <BreadCrumbWrapper>
        <Box
          className='main-padding-new'
          display='flex'
          alignItems='center'
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems='center'>
            {backToLink && (
              <ArrowBackIcon
                className='back-arrow'
                onClick={() => {
                  if (backToLink === 'back') {
                    navigate(-1);
                  } else if (backToLink === 'backTwoStep') {
                    navigate(-2);
                  } else {
                    navigate(backToLink, { state: { activeTab } });
                  }
                }}
              />
            )}
            <Box display={'flex'}>
              <Tooltip title={pageTitle}>
                <Typography className='title-text'>{pageTitle}</Typography>
              </Tooltip>
              {props.isStatusShow && (
                <Stack direction='row' spacing={1} className='online-status'>
                  {/* //! TODO : for future use */}
                  {/* <Chip
                    avatar={<RxDotFilled color={`${props.isOnline ? 'green' : 'red'}`} />}
                    label={`${props.isOnline ? 'Online' : 'Offline'}`}
                    variant='outlined'
                  /> */}
                </Stack>
              )}
            </Box>
          </Box>
          <Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
            <SearchComponent />
            <UserInfo userData={userData} />
          </Box>
          {/* //! TODO : for future use */}
          {/* {(isDetailPage || isDeletedItemsPage) && (
            <Box display={'flex'}>
              {!props.isDeleted && detailPageData.length > 2 && isAddIconShow && (
                <Box
                  className='icon-text-wrapper mr-23px'
                  onClick={() => navigate(variantLink || '')}
                >
                  <AddIcon className={'icon'} />
                  <Typography className={'iconText'}>Create Variant</Typography>
                </Box>
              )}
              {isUpdateIconShow && !props.isDeleted && (
                <Box className='icon-text-wrapper mr-23px' onClick={() => navigate(editLink || '')}>
                  <EditOutlinedIcon className={'icon'} />
                  <Typography className={'iconText'}>Edit</Typography>
                </Box>
              )}
              {!hideDelete && !props.isDeleted && isDeleteIconShow && (
                <Box className='icon-text-wrapper' onClick={() => enableDeleteWarning()}>
                  <DeleteOutlinedIcon className={'icon'} />
                  <Typography className={'iconText'}>Delete</Typography>
                </Box>
              )}
              {isErrorModalOpen && (
                <ErrorPopup
                  isOpen={isErrorModalOpen}
                  handleClose={handleErrorModalClose}
                  errorDetails={errorMessage}
                />
              )}
              {props.isRestore && (
                <CustomModalButton variant='outlined' onClick={() => handleRestoreOpen()}>
                  Restore
                </CustomModalButton>
              )}
              {props.isDeleted && (
                <CustomModalButton className='yellow-bg ml-26' onClick={() => handleDeleteOpen()}>
                  Delete
                </CustomModalButton>
              )}
            </Box>
          )} */}
        </Box>
        {deleteRecord && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={handleDelete}
            id={id}
          />
        )}
        {restoreRecord && (
          <RestoreWarningModal
            open={restoreRecord}
            handleOpen={handleRestoreOpen}
            handleClose={handleRestoreClose}
            handleDelete={handleRestore}
            id={id}
          />
        )}
      </BreadCrumbWrapper>
      {!hideDivider && <BreadcrumbDivider />}
      {breadCrumbData && (
        <BreadCrumbSubWrapper className='main-sub-padding'>
          <Box>
            {/* //! TODO : for future use */}
            <Breadcrumbs aria-label='breadcrumb'>/ {breadCrumbs}</Breadcrumbs>
          </Box>
          <Box mt={3} display={'flex'} justifyContent={'end'}>
            {(isDetailPage || isDeletedItemsPage) && (
              <Box display={'flex'}>
                {isUpdateIconShow && !props.isDeleted && (
                  <Box
                    className='icon-text-wrapper mr-23px'
                    onClick={() => navigate(editLink || '')}
                  >
                    <EditIcon_new />
                    <Typography className={'iconText'}>Edit</Typography>
                  </Box>
                )}
                {!hideDelete && !props.isDeleted && isDeleteIconShow && (
                  <Box className='icon-text-wrapper mr-23px' onClick={() => enableDeleteWarning()}>
                    <DeleteIcon_new />
                    <Typography className={'iconText'}>Delete</Typography>
                  </Box>
                )}
                {!props.isDeleted && detailPageData.length > 2 && isAddIconShow && (
                  <Box className='icon-text-wrapper ' onClick={() => navigate(variantLink || '')}>
                    <Plus fill='#0160B9' />
                    <Typography className={'iconText'}>Create Variant</Typography>
                  </Box>
                )}
                {isErrorModalOpen && (
                  <ErrorPopup
                    isOpen={isErrorModalOpen}
                    handleClose={handleErrorModalClose}
                    errorDetails={errorMessage}
                  />
                )}
                {props.isRestore && (
                  <CustomModalButton className='blue-color' onClick={() => handleRestoreOpen()}>
                    Restore
                  </CustomModalButton>
                )}
                {props.isDeleted && (
                  <CustomModalButton className='blue-bg ml-26' onClick={() => handleDeleteOpen()}>
                    Delete
                  </CustomModalButton>
                )}
              </Box>
            )}
          </Box>
        </BreadCrumbSubWrapper>
      )}
    </>
  );
};
export default BreadcrumbNavigation;
