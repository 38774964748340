import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { rawMaterialApi } from '../api/rawMaterials';
import { rawMaterial } from '../queryKeys/catalogue';
import { useNavigate } from 'react-router-dom';

export const useGetRawMaterials = (isEnabled, page) => {
  return useQuery([rawMaterial.RAWMATERIALS, page], rawMaterialApi.getRawMaterials, {
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetFullRawMaterials = (isEnabled) => {
  return useQuery([rawMaterial.RAWMATERIALS + 'FULL'], rawMaterialApi.getFullRawMaterials, {
    // initialData: { suppliers: [] },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    onSuccess: () => {
      // const data = res?.data;
    },
    // onError: () => {
    //   // const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddRawMaterials = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(rawMaterialApi.addRawMaterials, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([rawMaterial.RAWMATERIALS]);
      queryClient.invalidateQueries([rawMaterial.RAWMATERIALSFull]);
      const data = res;
      navigate(`/raw-materials`, { state: { activeTab: '4' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useUpdateRawMaterials = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(rawMaterialApi.updateRawMaterials, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([rawMaterial.RAWMATERIALS]);
      queryClient.invalidateQueries([rawMaterial.RAWMATERIAL, id]);
      navigate(`/raw-materials`, { state: { activeTab: '4' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useGetSingleRawMaterials = (id, isEnable = true) => {
  return useQuery([rawMaterial.RAWMATERIAL, id], rawMaterialApi.getSingleRawMaterials, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: any) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteRawMaterial = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(rawMaterialApi.deleteRawMaterial, {
    onSuccess: (res, id) => {
      // const data = res.data;
      // ! for future use
      // queryClient.invalidateQueries([rawMaterial.RAWMATERIAL]);
      // queryClient.invalidateQueries([rawMaterial.RAWMATERIALS, id]);
      // toast.success(data?.message);
      navigate(`/raw-materials`, { state: { activeTab: '4' } });
    },
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useGetDeletedRawMaterial = (id, isEnable = true) => {
  return useQuery(
    [rawMaterial.RAW_MATERIALS_DELETED + 'DELETED', id],
    rawMaterialApi.getSingleDeletedRawMaterial,
    {
      enabled: isEnable,
      select: (data) => data.data,
      // onSuccess: (res: any) => {
      //   const data = res?.data;
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useHardDeleteRawMaterial = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(rawMaterialApi.hardDeleteRawMaterial, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([rawMaterial.RAWMATERIAL]);
      queryClient.invalidateQueries([rawMaterial.RAWMATERIALS, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // ! TODO: for future implementation toast
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};

export const useRestoreRawMaterial = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(rawMaterialApi.restoreRawMaterial, {
    onSuccess: (res, id) => {
      // const data = res.data;
      queryClient.invalidateQueries([rawMaterial.RAWMATERIAL]);
      queryClient.invalidateQueries([rawMaterial.RAWMATERIALS, id]);
      // toast.success(data?.message);
      navigate(`/deleted-items`);
    },
    // ! TODO: for future implementation toast
    // onError: () => {
    //   // const errData = err.data;
    //   // toast.error(errData?.message);
    // },
  });
};
export const useValidateRawMaterialName = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(rawMaterialApi.validateRawMaterialName, {
    // onSuccess: (res) => {
    // queryClient.invalidateQueries([machine.MACHINES]);
    // const data = res.data;
    //toast.success(data?.message);
    // navigate(`/machine-details/${data?.machine.id}`);
    // navigate(`/resources`, { state: { activeTab: '1' } });
    // },
    // onError: (err: any) => {
    // const errData = err?.data;
    // },
  });
};
