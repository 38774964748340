import { AccessoryListErrI, AccessoryListI, AccessoryI, AccessoryErrI } from '../Types/accessory';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const addAccessory = async (data): Promise<AccessoryI | AccessoryErrI> => {
  delete data['supplierId'];
  return post(`/customer/accessory`, data).then((res) => res.data);
};
export const getAccessory = async (
  data
): Promise<AccessoryListI | undefined | { data: { results: [] } } | AccessoryListErrI> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/accessory`, payload).then((res) => {
    return res.data;
  });
};
export const updateAccessory = async (data): Promise<AccessoryI | AccessoryListErrI> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/accessory/${id}`, data).then((res) => res.data);
};
export const getFullAccessory = async (): Promise<any | { data: [] }> => {
  return get(`/customer/accessory`).then((res) => res.data);
};
export const getSingleAccessory = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/accessory/${id}`).then((res) => res.data);
};
export const deleteAccessory = async (id) => {
  return deleteRequest(`/customer/accessory/${id}`);
};
export const getSingleDeletedAccessory = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/accessory/deleted/${id}`);
};
export const hardDeleteAccessory = async (id) => {
  return deleteRequest(`/customer/accessory/hard-delete/${id}`);
};
export const restoreAccessory = async (id) => {
  return patch(`/customer/accessory/restore/${id}`);
};
export const validateAccessoryName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/accessory/validate`, data);
};

export const accessoryApi = {
  getAccessory,
  addAccessory,
  updateAccessory,
  getFullAccessory,
  getSingleAccessory,
  deleteAccessory,
  hardDeleteAccessory,
  restoreAccessory,
  getSingleDeletedAccessory,
  validateAccessoryName,
};
