import { cloneDeep } from 'lodash';
import { upload } from '../Utils/index';
export const addFile = async (data) => {
  const postFix = cloneDeep(data.get('postfix'));
  data.delete('postfix');
  if (postFix) {
    return upload(`/customer/upload/files/${postFix}`, data);
  } else {
    return upload(`/customer/upload/files`, data);
  }
};

export const uploadApi = {
  addFile,
};
