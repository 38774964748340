import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {
  useAddDepartment,
  useGetDepartment,
  useUpdateDepartment,
} from '../../hooks/departmentHooks';
import ErrorPopup from '../../Modals/ErrorPopup';
import Loading from 'react-fullscreen-loading';

const DepartmentSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Department name is Required')
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

// New Style using ClassName

const HeadBox = styled(Box)(() => ({
  height: '84vh ',
  '.footer-padding': {
    padding: '16px 56px 16px 20px',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
}));

const AddDepartment = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { data: department, isSuccess } = useGetDepartment(id, !!id === true);

  const departmentName: string | undefined = department?.name;
  const {
    mutate: addDepartment,
    error: addDepartmentError,
    isError: isAddDepartmentError,
    isLoading,
  } = useAddDepartment();
  const {
    mutate: updateDepartment,
    error: updateDepartmentError,
    isError: isUpdateDepartmentError,
  } = useUpdateDepartment();

  const breadCrumbData = [
    {
      title: 'Factory Layout',
      link: '/factory-layout',
    },
    {
      title: `${isEdit ? 'Edit' : 'Create New'} Department`,
      link: '',
    },
  ];
  const icon = <AiOutlineInfoCircle color='red' />;

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
    if (isAddDepartmentError || isUpdateDepartmentError) {
      isAddDepartmentError
        ? setErrorMessage(addDepartmentError?.data?.message)
        : setErrorMessage(updateDepartmentError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [id, isAddDepartmentError, isUpdateDepartmentError]);

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'Edit' : 'Create new'} Department`}
        backToLink='/factory-layout'
        activeTab='2'
      />
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? departmentName : '',
          }}
          enableReinitialize
          validationSchema={DepartmentSchema}
          onSubmit={(values) => {
            id ? updateDepartment({ ...values, id }) : addDepartment(values);
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Department Name
                    <Require />
                  </Typography>
                  <Input
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter Department Name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values.name}
                    InputProps={{
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>{errors.name}</Typography>
                  )}
                </Grid>
              </Grid>

              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    disabled={isLoading}
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    Save
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBox>
      <Loading loading={id && !isSuccess ? true : false} background='white' loaderColor='#265AA8' />
    </React.Fragment>
  );
};
export default AddDepartment;
