import React from 'react';

const ActionInActiveIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 6.75H12C12.4142 6.75 12.75 6.41421 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25H6C5.58579 5.25 5.25 5.58579 5.25 6C5.25 6.41421 5.58579 6.75 6 6.75Z'
        fill='#020202'
      />
      <path
        d='M6 9.75H12C12.4142 9.75 12.75 9.41421 12.75 9C12.75 8.58579 12.4142 8.25 12 8.25H6C5.58579 8.25 5.25 8.58579 5.25 9C5.25 9.41421 5.58579 9.75 6 9.75Z'
        fill='#020202'
      />
      <path
        d='M6 12.75H9C9.41421 12.75 9.75 12.4142 9.75 12C9.75 11.5858 9.41421 11.25 9 11.25H6C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75Z'
        fill='#020202'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 18H11.6894L11.6906 18C11.6906 18 11.9861 18.0005 12.2595 17.8882C12.2595 17.8882 12.5342 17.7754 12.7446 17.566L17.5647 12.746C17.5647 12.746 17.7754 12.5342 17.8882 12.2595C17.8882 12.2595 18.001 11.9849 18 11.688L18 1.5C18 1.5 18 0.87868 17.5607 0.43934C17.5607 0.43934 17.1213 0 16.5 0H1.5C1.5 0 0.87868 0 0.43934 0.43934C0.43934 0.43934 0 0.87868 0 1.5V16.5C0 16.5 0 17.1213 0.43934 17.5607C0.43934 17.5607 0.87868 18 1.5 18ZM16.5 11.6893L11.6893 16.5H1.5V1.5H16.5L16.5 11.6893Z'
        fill='#020202'
      />
      <path
        d='M11.25 12V17.1844C11.25 17.5986 11.5858 17.9344 12 17.9344C12.4142 17.9344 12.75 17.5986 12.75 17.1844V12.75H17.1844C17.5986 12.75 17.9344 12.4142 17.9344 12C17.9344 11.5858 17.5986 11.25 17.1844 11.25H12C11.5858 11.25 11.25 11.5858 11.25 12Z'
        fill='#020202'
      />
    </svg>
  );
};

export default ActionInActiveIcon;
