import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '50px 20px 30px 30px !important',
  },
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface partI {
  isOpen: boolean;
  handleClose: () => void;
  partData: any;
  add?: any;
  update?: any;
}
const ToolConfirm = (props: partI) => {
  const { isOpen, handleClose, partData, add, update } = props;
  const [showStockErr, setShowStockErr] = React.useState(false);
  const { id } = useParams();
  const updatePartData = {
    name: partData?.name,
    number: partData?.number,
    quantity: partData?.quantity,
    manufacturer: partData?.manufacturer,
    dateBought: partData?.dateBought,
    category: partData?.category,
    remarks: partData?.remarks,
    condition: partData?.condition,
    // ...(partData?.supplierPartNumber && { supplierPartNumber: partData?.supplierPartNumber }),
    // ...(partData?.minimumStockOrder && { minimumStockOrder: partData?.minimumStockOrder }),
    // ...(partData?.supplierId && { supplier: partData?.supplierId }),
    // ...(partData?.procurementTime && { procurementTime: partData?.procurementTime }),
    // avlStock: partData?.avlStock,
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the Tools details</Typography>
            {/* <CloseIcon onClick={handleClose} className='close-icon' /> */}
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>Basic Details</CustomSubTitle>
              <Grid container item xs={10} spacing={2}>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Tool Name </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Tool Category </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'toolCategory', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Tool Number </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'number', '')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={2}>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Manufacturer </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'manufacturer', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Quantity </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'quantity', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Date Bought </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {' '}
                    {moment(partData?.dateBought).format('hh:mm A, DD MMM, YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='tool-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color edit-btn'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={async () => {
                if (!showStockErr) {
                  id || partData
                    ? typeof add === 'function' && update({ ...updatePartData, id: id })
                    : typeof add === 'function' && add(updatePartData);
                } else {
                  setShowStockErr(true);
                }
                // handleClose();
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ToolConfirm;
