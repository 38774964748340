import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { Checkbox, FormGroup, Grid } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
};

const BoxWrapper = styled(Box)(() => ({
  '.button-box': {
    width: '150px',
  },
}));

interface propI {
  open: boolean;
  handleClose: () => void;
  id?: string;
  taskId: string;
  title: string;
  taskDetailData: any;
  handleItemsTask: any;
  handleSubmit?: (data: any) => void; // Function to handle form submission
}

const HandoverItemsModal = (props: propI) => {
  const navigate = useNavigate();
  const { open, handleClose, taskDetailData, handleItemsTask, taskId, title } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [toolHolderItemId, setToolHolderItemId] = useState('');
  const [toolTypeItemId, setToolTypeItemId] = useState('');
  const [toolInsertItemId, setToolInsertItemId] = useState('');
  const [toolAccessoriesItemId, setToolAccessoriesItemId] = useState<string[]>([]);
  const [accessories, setAccessories] = useState('');
  const [toolHolderItemId2, setToolHolderItemId2] = useState(
    ((taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Handover' ||
      taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Return') &&
      title === 'Handover an Item') ||
      (taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Return' &&
        title === 'Return an Item')
      ? true
      : false
  );
  const [toolAccessories, setToolAccessories] = useState(
    ((taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Handover' ||
      taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Return') &&
      title === 'Handover an Item') ||
      (taskDetailData?.toolHolderItemId?.toolhistories?.itemStatus === 'Return' &&
        title === 'Return an Item')
      ? true
      : false
  );

  const [toolTypeItemId2, setToolTypeItemId2] = useState(
    ((taskDetailData?.toolTypeItemId?.toolhistories?.itemStatus === 'Handover' ||
      taskDetailData?.toolTypeItemId?.toolhistories?.itemStatus === 'Return') &&
      title === 'Handover an Item') ||
      (taskDetailData?.toolTypeItemId?.toolhistories?.itemStatus === 'Return' &&
        title === 'Return an Item')
      ? true
      : false
  );

  const [accessories2, setAccessories2] = useState(
    ((taskDetailData?.accessories?.toolhistories?.itemStatus === 'Handover' ||
      taskDetailData?.accessories?.toolhistories?.itemStatus === 'Return') &&
      title === 'Handover an Item') ||
      (taskDetailData?.accessories?.toolhistories?.itemStatus === 'Return' &&
        title === 'Return an Item')
      ? true
      : false
  );
  const [toolInsertItemId2, setToolInsertItemId2] = useState(
    ((taskDetailData?.toolInsertItemId?.toolhistories?.itemStatus === 'Handover' ||
      taskDetailData?.toolInsertItemId?.toolhistories?.itemStatus === 'Return') &&
      title === 'Handover an Item') ||
      (taskDetailData?.toolInsertItemId?.toolhistories?.itemStatus === 'Return' &&
        title === 'Return an Item')
      ? true
      : false
  );

  // Prepare the response object
  const handleFormSubmit = () => {
    const formData = {
      id: taskId,
      mainTaskType: 'in-house',
      ...(selectedIds?.length > 0 && { toolAccessoriesItemId: selectedIds }),
      ...(toolHolderItemId && { toolHolderItemId: toolHolderItemId }),
      ...(toolTypeItemId && { toolTypeItemId: toolTypeItemId }),
      ...(toolInsertItemId && { toolInsertItemId: toolInsertItemId }),
      ...(accessories && { accessories: accessories }),
      ...(toolAccessoriesItemId && { toolAccessoriesItemId: toolAccessoriesItemId }),
    };

    // Pass the data to the parent component if needed
    handleItemsTask(formData);

    // Close the modal
    handleClose();
  };

  const toggleArrayValue = (array, value) => {
    const index = array.indexOf(value);
    if (index === -1) {
      // Value is not present, add it
      array.push(value);
    } else {
      // Value is present, remove it
      array.splice(index, 1);
    }
    return array;
  };

  const isChecked = (id) => {
    return selectedIds.includes(id);
  };

  // Function to handle the checkbox click
  const handleCheckboxChange = (id) => {
    if (isChecked(id)) {
      // If ID is in the array, remove it
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    } else {
      // If ID is not in the array, add it
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <BoxWrapper>
            <Box m={2} display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant='h6'>{title}</Typography>
              </Box>
              <Box className='title-head-icon'>
                <GridCloseIcon onClick={handleClose} className='close-icon' />
              </Box>
            </Box>
            <Box textAlign={'center'}>
              <Box m={2}>
                <Grid container spacing={2} mt={'5px'}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <FormGroup>
                      {/* Checkbox for toolAccessoriesItemId */}
                      {taskDetailData.toolAccessoriesItemId?.length > 0 &&
                        taskDetailData.toolAccessoriesItemId?.map((item: any, key) => {
                          let isChecked =
                            ((item?.toolhistories?.itemStatus === 'Handover' ||
                              item?.toolhistories?.itemStatus === 'Return') &&
                              title === 'Handover an Item') ||
                            ((item?.toolhistories?.itemStatus === 'Return' ||
                              item?.toolhistories === 'No Assigned') &&
                              title === 'Return an Item')
                              ? true
                              : false;
                          if (title === 'Return an Item' && item?.toolhistories === 'No Assigned') {
                            isChecked = false;
                          }
                          return (
                            <Box
                              key={`toolAccessoriesItemId-${key}`}
                              sx={{
                                display: 'flex',
                                padding: '10px 0px',
                                justifyContent: 'space-between',
                              }}
                              borderBottom={'1px solid #DEDEDE'}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  <Checkbox
                                    id={`toolAccessoriesItemId-${key}`}
                                    name={`toolAccessoriesItemId-${key}`}
                                    checked={
                                      isChecked
                                        ? isChecked
                                        : toolAccessoriesItemId?.includes(item?.id)
                                    }
                                    disabled={
                                      ((item?.toolhistories?.itemStatus === 'Handover' ||
                                        item?.toolhistories?.itemStatus === 'Return') &&
                                        title === 'Handover an Item') ||
                                      ((item?.toolhistories?.itemStatus === 'Return' ||
                                        item?.toolhistories === 'No Assigned') &&
                                        title === 'Return an Item')
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      setToolAccessories(!toolAccessories);
                                      setToolAccessoriesItemId((prevIds) => {
                                        if (!prevIds.includes(item?.id)) {
                                          return [...prevIds, item?.id];
                                        }
                                        return prevIds?.filter((val) => val !== item?.id);
                                      });
                                      handleCheckboxChange(item?.id);
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant='body1' fontSize={'14px'} pl={'10px'}>
                                    {item?.toolCategoryId?.name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {taskDetailData?.toolAccessoriesItemId[0]?.uploadedImages?.[0]
                                  ?.url && (
                                  <img
                                    src={
                                      taskDetailData?.toolAccessoriesItemId[0]?.uploadedImages[0]
                                        ?.url
                                    }
                                    alt=''
                                    width='35px'
                                    height='35px'
                                  />
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      {/* Checkbox for toolHolderItemId */}
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '10px 0px',
                          justifyContent: 'space-between',
                          borderBottom: `${taskDetailData.toolHolderItemId && '1px solid #DEDEDE'}`,
                        }}
                      >
                        {taskDetailData.toolHolderItemId && (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box>
                                <Checkbox
                                  checked={toolHolderItemId2}
                                  disabled={
                                    ((taskDetailData?.toolHolderItemId?.toolhistories
                                      ?.itemStatus === 'Handover' ||
                                      taskDetailData?.toolHolderItemId?.toolhistories
                                        ?.itemStatus === 'Return') &&
                                      title === 'Handover an Item') ||
                                    ((taskDetailData?.toolHolderItemId?.toolhistories
                                      ?.itemStatus === 'Return' ||
                                      taskDetailData?.toolHolderItemId?.toolhistories ===
                                        'No Assigned') &&
                                      title === 'Return an Item')
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setToolHolderItemId2(!toolHolderItemId2);
                                    setToolHolderItemId(
                                      toolHolderItemId !== taskDetailData.toolHolderItemId.id
                                        ? taskDetailData.toolHolderItemId.id
                                        : ''
                                    );
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant='body1' fontSize={'14px'} pl={'10px'}>
                                  {taskDetailData.toolHolderItemId?.toolCategoryId?.name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {taskDetailData.toolHolderItemId?.uploadedImages?.[0]?.url && (
                                <img
                                  src={taskDetailData.toolHolderItemId?.uploadedImages[0]?.url}
                                  alt=''
                                  width='35px'
                                  height='35px'
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                      {/* Checkbox for toolTypeItemId */}
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '10px 0px',
                          justifyContent: 'space-between',
                          borderBottom: `${taskDetailData.toolTypeItemId && '1px solid #DEDEDE'}`,
                        }}
                      >
                        {taskDetailData.toolTypeItemId && (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box>
                                <Checkbox
                                  checked={toolTypeItemId2}
                                  disabled={
                                    ((taskDetailData.toolTypeItemId?.toolhistories?.itemStatus ===
                                      'Handover' ||
                                      taskDetailData.toolTypeItemId?.toolhistories?.itemStatus ===
                                        'Return') &&
                                      title === 'Handover an Item') ||
                                    ((taskDetailData.toolTypeItemId?.toolhistories?.itemStatus ===
                                      'Return' ||
                                      taskDetailData?.toolTypeItemId?.toolhistories ===
                                        'No Assigned') &&
                                      title === 'Return an Item')
                                  }
                                  onChange={() => {
                                    setToolTypeItemId2(!toolTypeItemId2);
                                    setToolTypeItemId(
                                      toolTypeItemId !== taskDetailData.toolTypeItemId.id
                                        ? taskDetailData.toolTypeItemId.id
                                        : ''
                                    );
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant='body1' fontSize={'14px'} pl={'10px'}>
                                  {taskDetailData.toolTypeItemId?.toolCategoryId?.name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {taskDetailData?.toolInsertItemId?.uploadedImages?.[0]?.url && (
                                <img
                                  src={taskDetailData?.toolInsertItemId?.uploadedImages[0]?.url}
                                  alt=''
                                  width='35px'
                                  height='35px'
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                      {/* Checkbox for toolInsertItemId */}
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '10px 0px',
                          justifyContent: 'space-between',
                          borderBottom: `${taskDetailData.toolInsertItemId && '1px solid #DEDEDE'}`,
                        }}
                      >
                        {taskDetailData.toolInsertItemId && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box>
                                <Checkbox
                                  checked={toolInsertItemId2}
                                  disabled={
                                    ((taskDetailData.toolInsertItemId?.toolhistories?.itemStatus ===
                                      'Handover' ||
                                      taskDetailData.toolInsertItemId?.toolhistories?.itemStatus ===
                                        'Return') &&
                                      title === 'Handover an Item') ||
                                    ((taskDetailData.toolInsertItemId?.toolhistories?.itemStatus ===
                                      'Return' ||
                                      taskDetailData?.toolInsertItemId?.toolhistories ===
                                        'No Assigned') &&
                                      title === 'Return an Item')
                                  }
                                  onChange={() => {
                                    setToolInsertItemId2(!toolInsertItemId2);
                                    setToolInsertItemId(
                                      toolInsertItemId !== taskDetailData.toolInsertItemId.id
                                        ? taskDetailData.toolInsertItemId.id
                                        : ''
                                    );
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant='body1' fontSize={'14px'} pl={'10px'}>
                                  {taskDetailData.toolInsertItemId?.toolCategoryId?.name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {taskDetailData?.toolInsertItemId?.uploadedImages?.[0]?.url && (
                                <img
                                  src={taskDetailData?.toolInsertItemId?.uploadedImages[0]?.url}
                                  alt=''
                                  width='35px'
                                  height='35px'
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                      {/* Checkbox for Accessories */}
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '10px 0px',
                          height: '40px',
                          justifyContent: 'space-between',
                          borderBottom: `${taskDetailData.accessories && '1px solid #DEDEDE'}`,
                        }}
                      >
                        {taskDetailData.accessories && (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box>
                                <Checkbox
                                  checked={accessories2}
                                  disabled={
                                    ((taskDetailData?.accessories?.toolhistories?.itemStatus ===
                                      'Handover' ||
                                      taskDetailData?.accessories?.toolhistories?.itemStatus ===
                                        'Return') &&
                                      title === 'Handover an Item') ||
                                    ((taskDetailData?.accessories?.toolhistories?.itemStatus ===
                                      'Return' ||
                                      taskDetailData?.accessories?.toolhistories ===
                                        'No Assigned') &&
                                      title === 'Return an Item')
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setAccessories2(!accessories2);
                                    setAccessories(
                                      accessories !== taskDetailData.accessories.id
                                        ? taskDetailData.accessories.id
                                        : ''
                                    );
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant='body1' fontSize={'14px'} pl={'10px'}>
                                  {taskDetailData.accessories?.name}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box>
                              {taskDetailData?.accessories?.uploadedImages?.[0]?.url && (
                                <img
                                  src={taskDetailData?.accessories?.uploadedImages[0]?.url}
                                  alt=''
                                  width='25px'
                                  height='25px'
                                />
                              )}
                            </Box> */}
                          </>
                        )}
                      </Box>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
              <Box m={2} display={'flex'} justifyContent={'space-between'}>
                <CustomModalButton
                  variant='outlined'
                  className='button-box'
                  onClick={() => {
                    navigate('/add-ticket');
                  }}
                >
                  Report issue
                </CustomModalButton>
                <CustomModalButton
                  variant='outlined'
                  className='button-box blue-bg ml-20'
                  onClick={handleFormSubmit}
                >
                  Confirm
                </CustomModalButton>
              </Box>
            </Box>
          </BoxWrapper>
        </Box>
      </Modal>
    </div>
  );
};

export default HandoverItemsModal;
