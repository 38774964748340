import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import * as _ from 'lodash';
import {
  useGetRole,
  useDeleteRole,
  useGetDeletedRole,
  useHardDeleteRole,
  useRestoreRole,
} from '../../hooks/userRoleHook';
import CheckboxFilledIcon from '../../assets/svg/CheckboxFilledIcon';
import CheckboxIcon from '../../assets/svg/CheckboxIcon';
import Loading from 'react-fullscreen-loading';
import './../../assets/css/detailsInformationBox.css';

// New Style using ClassName

const HeadBoxWarper = styled(Box)(() => ({
  marginBottom: '40px',
  '.title-style': {
    color: 'gray',
    fontSize: '14px !important',
    padding: '24px 0px 0px 24px',
  },
  '.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important',
  },
  '.MuiButtonBase-root-MuiCheckbox-root': {
    padding: '0px !important',
  },
  '.row-bg-color': {
    backgroundColor: '#C9C9C9',
  },
  '.customize-table': {
    border: '1px solid #DEDEDE !important',
    borderRadius: '4px 4px 0px 0px !important',
  },
  '.module-text': {
    width: 150,
    color: '#0160B9 !important',
    fontWeight: 600,
  },
  '.background-head': {
    background: '#F6F6F6',
  },
}));
const moduleMapping = {
  workOrder: 'Work Order',
  worker: 'Worker',
  machine: 'Machine',
  stores: 'Stores',
  issues: 'Issues',
  checklists: 'Checklists',
  orderManagement: 'Order Management',
  handoverItem: 'Handover Item',
  returnItem: 'Return Item',
};
const TabContainer = styled(TableContainer)(() => ({
  borderRadius: '8px 8px 0px 0px !important',
  border: '1px solid #DEDEDE !important',
}));
const RoleDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: roleDetails, isSuccess, isError } = useGetRole(id, true && !isDeleted);
  const { data: getRoleDeleted, isSuccess: isSuccessDeleted } = useGetDeletedRole(
    id,
    !!id && isDeleted
  );
  const singleRole = isDeleted ? getRoleDeleted : roleDetails;
  const { mutate: deleteSingleRole } = useDeleteRole();
  const { mutate: hardDeleteRole } = useHardDeleteRole();
  const { mutate: restoreRole } = useRestoreRole();

  const handleRoleDelete = () => {
    if (isDeleted) {
      hardDeleteRole(id);
    } else {
      deleteSingleRole(id);
    }
  };

  const handleRoleRestore = () => {
    if (isRestore) {
      restoreRole(id);
    } else {
      // nothing to restore
    }
  };

  const TableDataCell = styled(TableCell)(() => ({
    padding: '8px 16px 8px 16px',
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const breadCrumbData = [
    {
      title: 'User Management',
      link: '/user-management',
      activeTab: '3',
    },
    {
      title: _.get(singleRole?.userRole, 'name', `-`),
      link: `/role-details/${id}`,
      activeTab: '3',
    },
  ];

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-role/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];

  const modules = [
    'worker',
    'machine',
    'stores',
    'issues',
    'checklists',
    'workOrder',
    'orderManagement',
    'handoverItem',
    'returnItem',
  ];
  const moduleRights =
    singleRole?.userPermission &&
    modules?.map((module) => {
      const currentModule = singleRole?.userPermission?.find(
        (permission) => permission?.userModule === module
      );
      return currentModule;
    });

  const notificationModules = [
    'User',
    'Machine',
    'Stores',
    'Issue',
    'Checklist',
    'Customer Order',
    'Order confirmation',
    'Work Order',
  ];
  const notifications =
    singleRole?.notifications &&
    notificationModules?.map((notificationModule) => {
      const currentModule = singleRole?.notifications?.find(
        (permission) => permission?.event === notificationModule
      );
      return currentModule;
    });
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(singleRole?.userRole, 'name', `-`)}
        backToLink='/user-management'
        isDetailPage={true}
        detailPageData={detailPageData}
        // isStatusShow={true}
        // isOnline={false}
        activeTab='3'
        handleDelete={handleRoleDelete}
        isDeleted={isDeleted}
        isRestore={isRestore}
        handleRestore={handleRoleRestore}
        isDeleteEnable={singleRole?.userRole?.usersCount > 0 ? false : true}
        errMsg={'You can not able to delete this role, this role is assigned to many users'}
      />
      <HeadBoxWarper className='main-padding'>
        <Box className='details-box-border padding-bottom'>
          <Typography id='modal-modal-title' className='details-sub-title'>
            Basic details
          </Typography>
          <Typography className='title-style'>Role</Typography>
          <Typography className='span-color pl-sub-text'>
            {_.get(singleRole?.userRole, 'name', '')}
          </Typography>
        </Box>
        <Divider />
        <Typography className='title-typography'> Module Access </Typography>
        <Box height={'auto'} width={isMdScreen ? `90%` : `60%`}>
          <TabContainer>
            <Table aria-label='customized table' sx={{ minWidth: 700 }}>
              <TableHead className='background-head'>
                <TableRow>
                  <TableDataCell>Module</TableDataCell>
                  <TableDataCell align='left'>Create</TableDataCell>
                  <TableDataCell align='left'>Read</TableDataCell>
                  <TableDataCell align='left'>Update</TableDataCell>
                  <TableDataCell align='left'>Delete</TableDataCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {moduleRights?.map((row, ind) => {
                  return (
                    <StyledTableRow key={ind}>
                      <TableDataCell
                        component='th'
                        scope='row'
                        align='left'
                        className='module-text'
                      >
                        {moduleMapping[row?.userModule]}
                      </TableDataCell>
                      <TableDataCell align='left'>
                        {row?.add ? <CheckboxFilledIcon /> : <CheckboxIcon />}
                      </TableDataCell>
                      <TableDataCell align='left'>
                        {row?.view ? <CheckboxFilledIcon /> : <CheckboxIcon />}
                      </TableDataCell>

                      <TableDataCell align='left'>
                        {row?.userModule != 'handoverItem' && row?.userModule != 'returnItem' && (
                          <>{row?.edit ? <CheckboxFilledIcon /> : <CheckboxIcon />}</>
                        )}
                      </TableDataCell>
                      <TableDataCell align='left'>
                        {row?.userModule != 'handoverItem' && row?.userModule != 'returnItem' && (
                          <>{row?.delete ? <CheckboxFilledIcon /> : <CheckboxIcon />}</>
                        )}
                      </TableDataCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TabContainer>
          <Divider />
          <Typography className='title-typography'> Notifications Setting</Typography>
          <Box height={'auto'}>
            <TabContainer>
              <Table aria-label='customized table'>
                <TableHead className='background-head'>
                  <TableRow>
                    <TableDataCell>Event</TableDataCell>
                    {/* <TableDataCell>Email</TableDataCell> */}
                    <TableDataCell align='left'>Email</TableDataCell>
                    <TableDataCell align='left'>Dashboard</TableDataCell>
                    {/* <TableDataCell align='left'>Update</TableDataCell> */}
                    {/* <TableDataCell align='left'>Delete</TableDataCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notifications?.map((row, ind) => {
                    return (
                      <StyledTableRow key={ind}>
                        <TableDataCell component='th' scope='row' className='module-text'>
                          {row?.event}
                        </TableDataCell>
                        {/* <TableDataCell align='left'>
                          <Box display={'flex'} justifyContent='space-between'>
                            <Typography>{row?.type}</Typography>|
                          </Box>
                        </TableDataCell> */}
                        <TableDataCell>
                          {row?.email ? <CheckboxFilledIcon /> : <CheckboxIcon />}
                        </TableDataCell>
                        <TableDataCell>
                          {row?.dashboard ? <CheckboxFilledIcon /> : <CheckboxIcon />}
                        </TableDataCell>
                        {/* <TableDataCell align='left'>{row?.delete}</TableDataCell> */}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TabContainer>
          </Box>
        </Box>
        <Loading
          // loading={id && !isSuccess ? (!isError ? true : false) : false}
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWarper>
    </React.Fragment>
  );
};

export default RoleDetails;
