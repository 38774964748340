import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import {
  useDeleteTool,
  useGetDeletedPart,
  useGetPartSingle,
  useHardDeletePart,
  useRestoreDeleteTool,
} from '../../hooks/partHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import TableComponent from '../commonComponents/Table';
import Image from '../../assets/svg/ImageSvg';
import { toolCategoryTypeMap } from '../../queryKeys/catalogue';
import ViewImage from '../../Modals/ViewImage';
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 0px !important',
    fontWeight: '600 !important',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
}));

const ToolDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const {
    data: getPartDeleted,
    isSuccess: isSuccessDeleted,
    isLoading: isToolDeletedLoading,
    isFetching: isToolDeletedFetching,
  } = useGetDeletedPart(id, !!id && isDeleted);
  const {
    data: getParts,
    isSuccess: isToolSuccess,
    isError,
    isLoading: isToolLoading,
    isFetching: isToolFetching,
  } = useGetPartSingle(id, !!id && !isDeleted);

  const singleToolDetails = isDeleted ? getPartDeleted?.data : getParts?.data;

  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [imageInfo, setImageInfo] = React.useState({});
  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);
  const {
    mutate: deleteSinglePart,
    error: deletePartError,
    isError: isDeletePartError,
  }: any = useDeleteTool();
  React.useEffect(() => {
    if (isDeletePartError) {
      setErrorMessage(deletePartError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isDeletePartError]);
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: restorePart } = useRestoreDeleteTool();
  const { mutate: readNotification } = useUpdateNotification();
  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeletePart(id);
    } else {
      deleteSinglePart(id);
    }
  };

  const handlePartRestore = () => {
    if (isRestore) {
      restorePart(id);
    } else {
      // nothing to restore
    }
  };
  const toolCategoryType =
    toolCategoryTypeMap[singleToolDetails?.toolCategoryType || 'Tool Holder'];
  let breadCrumbData = [
    {
      title: 'Store',
      link: '/tools',
    },
    {
      title: `${singleToolDetails?.toolCategoryType}`,
      link: `/tools`,
      activeTab: toolCategoryType, // active
    },
    {
      title: _.get(singleToolDetails, 'name', ''),
      link: `/tool-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleToolDetails, 'name', ''),
        link: ``,
      },
    ];
  }

  const editLinks = {
    '1': `/edit-tool-holder/${id}`,
    '8': `/edit-tool-type/${id}`,
    '9': `/edit-tool-accessories/${id}`,
    '10': `/edit-tool-insert/${id}`,
  };
  const defaultLink = `/edit-tool/${id}`;
  const detailPageData = [
    {
      title: 'edit',
      link: editLinks[toolCategoryType] || defaultLink,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});

  const toolCols = [
    {
      field: 'srNo',
      headerName: '#',
      flex: 0.2,
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.5,
    },
    {
      field: 'length',
      headerName: 'Length',
      flex: 0.5,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.5,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            {params?.row?.uploadedImages?.length > 0 &&
              params?.row?.uploadedImages?.map((data, ind) => {
                return (
                  <Box key={ind} ml={1}>
                    <Box className='image-box'>
                      <Box
                        onClick={() => {
                          handleImageModalOpen();
                          setImageInfo(data);
                        }}
                      >
                        <Image />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        );
      },
    },
  ];

  const toolUsageCols = [
    {
      field: 'srNo',
      headerName: '#',
      flex: 0.2,
    },
    {
      field: 'handoverDate',
      headerName: 'Date checked out',
      flex: 0.5,
    },
    {
      field: 'returnDate',
      headerName: 'Date returned',
      flex: 0.5,
    },
    {
      field: 'workOrderName',
      headerName: 'Work Order Name',
      flex: 0.5,
    },
    {
      field: 'workOrderNo',
      headerName: 'Work Order No.',
      flex: 0.5,
    },
    {
      field: 'workerName',
      headerName: 'Worker name',
      sortable: false,
      flex: 0.5,
    },
  ];

  const toolRows =
    singleToolDetails?.sizeDetails?.length > 0 &&
    singleToolDetails?.sizeDetails?.map((data, ind) => {
      return {
        id: data?.id || ind,
        srNo: ind + 1,
        size: data?.size,
        length: data?.length,
        quantity: data?.quantity,
        status: data?.toolItemsStatus ? data?.toolItemsStatus : '-',
        uploadedImages: data?.uploadedImages ? data?.uploadedImages : [],
      };
    });

  const toolDetailsUsageRows =
    singleToolDetails?.sizeDetails?.length > 0 &&
    singleToolDetails?.sizeDetails?.flatMap((data, ind) => {
      return data?.toolHistories?.length > 0
        ? data?.toolHistories?.map((history, index) => ({
            ...data,
            srNo: index + 1,
            handoverDate: history?.handoverDate
              ? moment(history?.handoverDate).format('DD-MM-YY')
              : '-',
            returnDate: history?.returnedDate
              ? moment(history?.returnedDate).format('DD-MM-YY')
              : '-',
            workOrderName: history?.taskId?.workOrder?.title,
            workOrderNo: history?.taskId?.workOrder?.number,
            workerName: history?.taskId?.nodeInfo?.worker?.name,
            actions: '-', // Placeholder for actions, update accordingly
          }))
        : [];
    });

  const tableDataForTools = {
    columns: toolCols,
    rows: toolRows,
  };
  const tableDataForToolsUsage = {
    columns: toolUsageCols,
    rows: toolDetailsUsageRows,
  };
  const btnToolSinglePage = {
    title: '',
    isBtnShow: false,
    isBtnDisable: false,
    linkData: [
      {
        menuItem: '',
        to: '/',
      },
    ],
  };

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={_.get(singleToolDetails, '', 'Tool')}
        backToLink='/tools'
        isDetailPage={true}
        detailPageData={detailPageData}
        handleDelete={handlePartDelete}
        isDeleted={isDeleted}
        isRestore={isRestore}
        handleRestore={handlePartRestore}
        isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
        isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
        isAddIconShow={!!isAddIconShow || isAllPermission}
      />
      <HeadBoxWrapper className='main-padding'>
        <Box
          style={{
            border: '1px solid #CDCDCD',
            borderRadius: '3px',
            marginBottom: '25px',
          }}
        >
          <Box
            style={{
              fontSize: '16px',
              fontWeight: 600,
              background: '#F6F6F6',
              padding: '10px 20px',
            }}
          >
            Basic Details
          </Box>
          <Box style={{ borderTop: '1px solid #CDCDCD' }}></Box>
          <Box
            style={{
              padding: '20px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <TextFiled>
                  {`${
                    singleToolDetails?.toolCategoryType
                      ? singleToolDetails?.toolCategoryType + ' ' + 'Name'
                      : 'Tool Holder Name'
                  } `}{' '}
                </TextFiled>
                <Typography className='span-color'>
                  {singleToolDetails?.name ? singleToolDetails?.name : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <TextFiled>Model No.</TextFiled>
                <Typography className='span-color'>
                  {singleToolDetails?.modelNo ? singleToolDetails?.modelNo : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <TextFiled>Make</TextFiled>
                <Typography className='span-color'>
                  {singleToolDetails?.make ? singleToolDetails?.make : '-'}
                </Typography>
              </Grid>
              <Grid item xs={2.3} md={2.3}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <TextFiled>Supplier.</TextFiled>
                <Typography className='span-color'>
                  {singleToolDetails?.supplier ? singleToolDetails?.supplier : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <TextFiled>PR Number.</TextFiled>
                <Typography className='span-color'>
                  {singleToolDetails?.prNumber ? singleToolDetails?.prNumber : '-'}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <TextFiled>Date of Purchase</TextFiled>
                <Typography className='span-color'>
                  {' '}
                  {moment(_.get(singleToolDetails, 'purchaseDate', '')).format('DD MM, YYYY')}
                </Typography>
              </Grid>
            </Grid>
            {/* //!  here gread------------- */}
            {singleToolDetails?.toolCategoryType === 'Insert' && (
              <Grid item xs={2.3} md={2.3}>
                {' '}
                <TextFiled>Grade</TextFiled>
                <Typography className='span-color'>
                  {_.get(singleToolDetails, 'grade', '-')}
                </Typography>
              </Grid>
            )}
          </Box>
        </Box>
        {/* <Divider /> */}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TableComponent
              data={btnToolSinglePage}
              isHideDropDown={true}
              isLoading={
                isDeleted
                  ? isToolDeletedLoading || isToolDeletedFetching
                  : isToolLoading || isToolFetching
              }
              tableData={tableDataForTools}
              isOptionShow={true}
              isEditStock={false}
              isPlusBtnHide={true}
              width={100}
              listName={'Item Details'}
              isNoEntityShow={
                tableDataForTools?.rows?.length <= 0 && (isToolSuccess || isSuccessDeleted)
              }
              moduleName={'Item Details'}
              isFilter={false}
              iSColumnsButton={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TableComponent
              data={btnToolSinglePage}
              isHideDropDown={true}
              isLoading={
                isDeleted
                  ? isToolDeletedLoading || isToolDeletedFetching
                  : isToolLoading || isToolFetching
              }
              tableData={tableDataForToolsUsage}
              isOptionShow={true}
              isEditStock={true}
              isPlusBtnHide={true}
              width={100}
              listName={'Usage'}
              moduleName={'Usage'}
              isFilter={false}
              iSColumnsButton={false}
            />
          </Grid>
        </Grid>

        <Loading
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isToolSuccess)
              ? !isError
                ? true
                : false
              : false
          }
          background='white'
          loaderColor='#265AA8'
        />
        {isImageModalOpen && (
          <ViewImage
            imageInfo={imageInfo}
            isOpen={isImageModalOpen}
            handleClose={handleImageModalClose}
          />
        )}
      </HeadBoxWrapper>
    </React.Fragment>
  );
};

export default ToolDetailsPage;
