import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import {
  useDeleteDepartment,
  useGetDeletedDepartmernt,
  useGetDepartment,
  useHardDeleteDepartment,
  useRestoreDepartment,
} from '../../hooks/departmentHooks';
import * as _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import './../../assets/css/detailsInformationBox.css';

const HeadBoxWrapper = styled(Box)(() => ({
  paddingBottom: '40px',
}));

const TextFiled = styled(Typography)(() => ({
  color: '#020202',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const DepartmentDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: departmentDetails, isSuccess, isError } = useGetDepartment(id, !!id && !isDeleted);
  const { data: getDepartmentDeleted, isSuccess: isSuccessDeleted } = useGetDeletedDepartmernt(
    id,
    !!id && isDeleted
  );
  const singleDepartment = isDeleted ? getDepartmentDeleted?.department : departmentDetails;
  const { mutate: deleteDepartment } = useDeleteDepartment();
  const { mutate: hardDeleteDepartment } = useHardDeleteDepartment();
  const { mutate: restoreDepartment } = useRestoreDepartment();
  const workerList = departmentDetails?.users;

  const handleDepartmentDelete = () => {
    if (isDeleted) {
      hardDeleteDepartment(id);
    } else {
      deleteDepartment(id);
    }
  };

  const handleDepartmentRestore = () => {
    if (isRestore) {
      restoreDepartment(id);
    } else {
      // nothing to restore
    }
  };
  let breadCrumbData = [
    {
      title: 'Factory Layout',
      link: '/factory-layout',
      activeTab: '1',
    },
    {
      title: _.get(singleDepartment, 'name', ''),
      link: `/department-details/${id}`,
    },
  ];

  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleDepartment, 'name', ''),
        link: `/deleted-items/department-details/${id}`,
      },
    ];
  }
  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-department/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  return (
    <React.Fragment>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={_.get(singleDepartment, 'name', '')}
          backToLink={isDeleted ? '/deleted-items' : '/factory-layout'}
          isDetailPage={true}
          activeTab='2'
          detailPageData={detailPageData}
          handleDelete={handleDepartmentDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handleDepartmentRestore}
          isDeleteEnable={workerList?.length ? false : true}
          errMsg={`You can not able to delete this line, this line is assigned to many ${'workers'}`}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  List of Workers
                </Typography>
                <Grid container spacing={2}>
                  {workerList?.map((name, ind) => {
                    return (
                      <Grid item md={3} key={ind}>
                        <TextFiled> {name}</TextFiled>
                      </Grid>
                    );
                  })}
                  {!workerList?.length && (
                    <Grid item>
                      <TextFiled>No Worker Found</TextFiled>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loading
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWrapper>
    </React.Fragment>
  );
};
export default DepartmentDetails;
