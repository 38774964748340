import { Box } from '@mui/material';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TableBoxWrapper = styled(Box)(() => ({
  //   '.stock-btn': {
  //     margin: '20px 0px 0px 20px',
  //   },
  //   '.usage-title': {
  //     lineHeight: '32px !important',
  //     height: '32px !important',
  //     fontSize: '18px !important',
  //     margin: '30px 0px 3px 0px !important',
  //     fontWeight: '600 !important',
  //   },
  //   '.usage': {
  //     lineHeight: '32px !important',
  //     height: '32px !important',
  //     fontSize: '18px !important',
  //     margin: '30px 0px 3px 25px !important',
  //     fontWeight: '600 !important',
  //   },
  //   '.table': {
  //     border: '1px solid black',
  //     borderCollapse: 'collapse',
  //   },
  //   '.mr-20': {
  //     marginTop: '25px',
  //     marginLeft: '76px',
  //   },
  //   '.mr-21': {
  //     // marginTop: '25px',
  //     // marginLeft: '76px',
  //     padding: '0px 0px 0px 24px',
  //   },
  //   '.rawmatriyaltable': {
  //     display: 'flex',
  //   },
  //   '.process-header-field-title': {
  //     fontSize: 14,
  //     fontWeight: 400,
  //     padding: '0px 0px 0px 24px',
  //   },
  //   '.checklist-text': {
  //     fontSize: '18px',
  //     fontWeight: 600,
  //     marginBottom: '24px',
  //   },
  //   '.process-header': {
  //     height: '40px !important',
  //     // border: '1px solid #DEDEDE !important',
  //     // background: 'rgba(246, 246, 246, 1)',
  //     borderRadius: '8px 8px 0px 0px',
  //     alignItems: 'center',
  //   },
  '.table-padding': {
    // padding: '18px 56px 18px 46px !important',
  },
}));

const QuantityTable = ({ data }) => {
  return (
    <table border={1} className='quantityTable'>
      <tbody>
        <React.Fragment>
          {data?.usageDetails?.map((row: any, index: any) => {
            return (
              <tr key={index}>
                <td className='dateUsedRow'>{moment(row?.dateUsed).format('DD/MM/YY')}</td>
                <td className='qtyRow'>{row?.quantityUsed}</td>
              </tr>
            );
          })}
        </React.Fragment>
        {/* ))} */}
      </tbody>
    </table>
  );
};
QuantityTable.propTypes = {
  data: PropTypes.any,
};

export default QuantityTable;
