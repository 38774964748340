// import { useState } from 'react';
import { Menu, ProSidebar, SidebarHeader, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import * as _ from 'lodash';
import { useState } from 'react';
import WorkOrderActive from '../assets/svg/WorkOrderActive';
// import CustomerOrderActive from '../assets/svg/CustomerOrder';
import WorkOrderInActive from '../assets/svg/WorkOrderInActive';
import ResourceActive from '../assets/svg/ResourceActive';
import ResourceInActive from '../assets/svg/ResourceInActive';
import CatalogueActive from '../assets/svg/CatalogueActive';
import CatalogueInActive from '../assets/svg/CatalogueInActive';
import ChecklistActiveIcon from '../assets/svg/ChecklistActiveIcon';
import ChecklistInActiveIcon from '../assets/svg/ChecklistInActiveIcon';
import TicketActiveIcon from '../assets/svg/TicketActiveIcon';
import TicketInActiveIcon from '../assets/svg/TicketInActiveIcon';
import DashboardActiveIcon from '../assets/svg/DashboardActiveIcon';
import DashboardInActiveIcon from '../assets/svg/DashboardInActiveIcon';
import FactoryManagementActiveIcon from '../assets/svg/FactoryManagementActiveIcon';
import FactoryManagementInActiveIcon from '../assets/svg/FactoryManagementInActiveIcon';
import SubMenuItemIcon from '../assets/svg/SubMenuItemIcon';
import MenuActiveIconArrow from '../assets/svg/MenuActiveIconArrow';
// import { RiFileTextLine } from 'react-icons/ri';
import ActionActiveIcon from '../assets/svg/ActionActiveIcon';
import ActionInActiveIcon from '../assets/svg/ActionInActiveIcon';
import SmartShop from '../assets/images/SmartShop.png';

const Sidebar = ({ user }: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isStoreDropdownOpen, setIStoreDropdownOpen] = useState(false);
  const userRole = user;

  const SidebarWrapper = styled(Box)(() => ({
    position: 'fixed',
    width: '264px',
    top: '0',
    height: '100% !important',
    backgroundColor: '#265AA8',
    '.sidebar-header': {
      display: 'flex',
      // height: 'auto',
      height: '53px !important',
      // justifyContent: 'start',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '14.5px 0px 14.5px 0px',

      '@media(min-width: 425px) and (max-width: 1024px)': {
        height: '45px !important',
      },
    },
    '.menu-item': {
      color: '#fff ',
      fontSize: '16px !important',
      fontWeight: '400',
      lineHeight: '21px',
    },
    '.nav-link-box': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow':
      {
        borderColor: 'black !important',
        borderWidth: '0 2px 2px 0',
        padding: '4.5px !important',
        verticalAlign: 'middle',
        transition: 'transform 0.3s',
        transform: 'rotate(225deg) !important',
      },
    '.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow':
      {
        transform: 'rotate(45deg) !important',
        borderColor:
          isFactoryDataActive || isStoresDataActive ? 'white !important' : 'black !important',
      },
    '.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul': {
      paddingTop: isFactoryDataActive || isStoresDataActive ? '15px !important' : '0px !important',
    },
  }));

  function startsWithString(string, values) {
    return values.some((element) => {
      return string.startsWith(element);
    });
  }
  const location = useLocation().pathname;

  // const openInNewTab = (url: string | URL | undefined) => {
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };

  const isDashboardActive = ['/dashboard', '/recent-activity'].includes(location);
  const isActionsActive = ['/my-actions'].includes(location);

  const isWorkOrderActive =
    [
      '/work-order',
      '/add-manufacturing-order',
      '/work-order/add-procedure/',
      '/add-work-request',
      '/draft-work-order',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-manufacturing-order/',
      '/work-order/add-procedure/',
      '/work-order/edit-procedure/',
      '/work-order/view-procedure/',
      '/completion-report',
      '/edit-work-request',
      '/details-work-order',
      '/draft-work-order',
    ]);
  // const isCustomerOrderActive =
  //   ['/customer-Order', 'add-customer-order'].includes(location) || startsWithString(location, []);

  const isResourcesActive =
    [
      '/resources',
      '/add-machine',
      '/add-worker',
      '/add-variant-machine',
      '/duplicate-machine',
      '/worker-completion',
      // '/add-checklist',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-machine',
      '/edit-worker',
      '/edit-variant-machine',
      '/machine-details',
      '/worker-detail',
      '/duplicate-machine/',
      '/add-variant-machine',
      '/machine/add-variant',
      // '/edit-checklist/',
      // '/checklist-details-view/',
    ]);

  const isCatalogueActive =
    [
      // '/add-part',
      // '/add-raw-material',
      '/tools',
      '/accessories',
      '/consumables',
      '/finished-goods',
      '/raw-materials',
      '/other-seting',
      '/add-issue-mapping',
      // '/add-product',
      '/add-accessory',
      '/add-supplier',
      '/add-finshedgoods',
      // '/catalogue',
      '/add-variant',
      '/add-consumable',
      '/add-finishedgoods',
    ].includes(location) ||
    startsWithString(location, [
      // '/stores/catalogue',
      '/accessories',
      '/edit-finishedgoods',
      '/edit-consumable/',
      '/edit-accessory/',
      '/tool-details',
      '/edit-tool-holder',
      '/edit-tool-type',
      '/edit-tool-accessories',
      '/edit-tool-insert',
      '/add-tool-holder',
      '/accessory-details',
      '/add-raw-material',
      '/add-tool-type',
      '/add-tool-accessories',
      '/add-tool-insert',
      // '/edit-part',
      // '/part-details',
      '/edit-raw-material',
      // '/edit-supplier',
      // '/edit-product',
      '/raw-material-details',
      // '/add-variant/',
    ]);

  // const isProcessesActive =
  //   [
  //     '/processes',
  //     '/add-process',
  //     '/add-process-variant',
  //     '/variant-details',
  //     '/process-details',
  //   ].includes(location) ||
  //   startsWithString(location, [
  //     '/edit-process',
  //     '/edit-process-variant',
  //     '/variant-details',
  //     '/process-details',
  //   ]);
  const isChecklistActive =
    ['/checklists', '/add-checklist', '/checklist-details-view'].includes(location) ||
    startsWithString(location, ['/edit-checklist', '/checklist-details-view']);

  const isFactoryDataActive =
    [
      '/factory-layout',
      '/add-line',
      '/add-department',
      '/user-management',
      '/add-desktop-user',
      '/add-mobile-user',
      '/issue-mapping',
      '/factory-schedule',
      '/add-issue-mapping',
    ].includes(location) ||
    startsWithString(location, [
      '/edit-line/',
      '/line-details/',
      '/edit-department/',
      '/desktop-user-details',
      '/mobile-user-details',
      '/edit-mobile-user/',
      '/edit-desktop-user',
      '/issue-mapping',
      '/edit-issue',
      '/edit-factory-schedule',
      '/edit-role/',
      '/role-details',
      '/factory-schedule-details',
      '/add-role',
      '/department-details/',
      '/deleted-items',
      '/other-seting',
    ]);
  const isStoresDataActive =
    ['/stores'].includes(location) ||
    startsWithString(location, [
      '/tools',
      '/add-tool',
      '/edit-tool',
      '/tool-details',
      '/accessories',
      '/add-accessory',
      '/edit-accessory',
      '/accessory-details',
      '/consumables',
      '/add-consumable',
      '/edit-consumable',
      '/consumable-details',
      '/finished-goods',
      '/add-finishedgoods',
      '/edit-finishedgoods',
      '/finshedgoods-details',
      '/raw-materials',
      '/add-raw-material',
      '/edit-raw-material',
      '/raw-material-details',
    ]);

  const isToolDataActive =
    ['/tools'].includes(location) ||
    startsWithString(location, ['/tools', '/add-tool', '/edit-tool', '/tool-details']);

  const isAccessoriesDataActive =
    ['/accessories', '/add-accessory', '/edit-accessory'].includes(location) ||
    startsWithString(location, [
      '/accessories',
      '/add-accessory',
      '/edit-accessory',
      '/accessory-details',
    ]);

  const isConsumablesDataActive =
    ['/consumables', '/add-consumable', '/edit-consumable'].includes(location) ||
    startsWithString(location, [
      '/consumables',
      '/add-consumable',
      '/edit-consumable',
      '/consumable-details',
    ]);

  const isFinishedGoodsDataActive =
    ['/finished-goods', '/add-finishedgoods', '/edit-finishedgoods'].includes(location) ||
    startsWithString(location, [
      '/finished-goods',
      '/add-finishedgoods',
      '/edit-finishedgoods',
      '/finshedgoods-details',
    ]);
  const isRawMaterialsDataActive =
    ['/raw-materials', '/add-raw-material', '/edit-raw-material'].includes(location) ||
    startsWithString(location, [
      '/raw-materials',
      '/add-raw-material',
      '/edit-raw-material',
      '/raw-material-details',
    ]);

  const isTicketActive =
    ['/my-tickets', '/add-ticket'].includes(location) ||
    startsWithString(location, ['/ticket-test', '/edit-ticket']);

  const isFactoryLayoutActive =
    ['/factory-layout', '/add-line', '/add-department'].includes(location) ||
    startsWithString(location, [
      '/edit-line/',
      '/line-details/',
      '/edit-department/',
      '/department-details/',
    ]);

  const isUserManagementActvie =
    ['/user-management', '/add-desktop-user', '/add-mobile-user'].includes(location) ||
    startsWithString(location, [
      '/desktop-user-details',
      '/mobile-user-details',
      '/edit-mobile-user/',
      '/edit-desktop-user',
      '/edit-role/',
      '/role-details',
      '/add-role',
    ]);

  const isIssueMappingActive =
    ['/issue-mapping', 'add-issue-mapping'].includes(location) ||
    startsWithString(location, ['/issue-mapping', '/add-issue-mapping']);

  const isFactoryScheduleActive =
    ['/factory-schedule'].includes(location) ||
    startsWithString(location, ['/edit-factory-schedule', '/factory-schedule-details']);
  const isDeletedItemsActive =
    ['/deleted-items'].includes(location) || startsWithString(location, ['/deleted-items']);
  const isOtherSettingActive =
    ['/other-seting'].includes(location) || startsWithString(location, ['/other-seting']);

  const isAllPermission = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'all')
  );
  const isStoreAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'stores' && role?.view)
  );
  const isIssuesAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'issues' && role?.view)
  );
  const isCheckListsAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'checklists' && role?.view)
  );
  const isDashboardAllowed = true;
  const isWorkOrderAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'workOrder' && role?.view)
  );
  // const isCustomerOrderAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'orderManagement' && role?.view)
  // );
  // const isResourcesAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => ['machine', 'worker'].includes(role?.userModule))
  // );
  const isResourcesAllowed = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'machine' && role?.view)
  );
  const isResourcesAllowedWorker = !_.isEmpty(
    userRole?.data?.permission?.find((role) => role?.userModule === 'worker' && role?.view)
  );
  // const isCatalougueAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find(
  //     (role) => role?.userModule === 'part' || role?.userModule === 'product'
  //   )
  // );
  // const isCatalougueAllowed = true;
  // const isProcessesAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'process')
  // );
  // const isMyTicketsAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'MyTickets')
  // );
  // const isMyTicketsAllowed = true;
  // const isChecklistAllowed = true;
  // const isFactoryDataAllowed = !_.isEmpty(
  //   userRole?.data?.permission?.find((role) => role?.userModule === 'all')
  // );
  const isFactoryDataAllowed = userRole?.data?.user?.userType === 'administrator' ? true : false;

  return (
    <SidebarWrapper>
      <ProSidebar width={'15%'}>
        <SidebarHeader className='sidebar-header'>
          {/* <SidebarIcon /> */}
          <img src={SmartShop} height={'82px'} />
        </SidebarHeader>
        <Menu>
          {(isAllPermission || isDashboardAllowed) && (
            <MenuItem
              className={`menu-item ${isDashboardActive && 'white-color'}`}
              active={isDashboardActive}
              icon={isDashboardActive ? <DashboardActiveIcon /> : <DashboardInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isDashboardActive ? 'white-color' : 'black-color'
                }
                to='/dashboard'
              >
                <Box className='nav-link-box'>
                  Dashboard
                  {isDashboardActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isWorkOrderAllowed) && (
            <MenuItem
              className={`menu-item ${isActionsActive && 'white-color'}`}
              active={isActionsActive}
              icon={isActionsActive ? <ActionActiveIcon /> : <ActionInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isActionsActive ? 'white-color' : 'black-color'
                }
                to='/my-actions'
              >
                <Box className='nav-link-box'>
                  My Actions
                  {isActionsActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isResourcesAllowed || isResourcesAllowedWorker) && (
            <MenuItem
              className={`menu-item ${isResourcesActive && 'white-color'}`}
              active={isResourcesActive}
              icon={isResourcesActive ? <ResourceActive /> : <ResourceInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isResourcesActive ? 'white-color' : 'black-color'
                }
                to='/resources'
              >
                <Box className='nav-link-box'>
                  Resources
                  {isResourcesActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {/* //! for future use */}
          {/* {(isAllPermission || isProcessesAllowed) && (
            <MenuItem
              className={`menu-item ${isProcessesActive && 'white-color'}`}
              active={isProcessesActive}
              icon={isProcessesActive ? <ProcessActiveIcon /> : <ProcessInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isProcessesActive ? 'white-color' : 'black-color'
                }
                to='/processes'
              >
                <Box className='nav-link-box'>
                  Processes
                  {isProcessesActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )} */}
          {/* {(isAllPermission || isStoreAllowed) && (
            <MenuItem
              className={`menu-item ${isCatalogueActive && 'white-color'}`}
              active={isCatalogueActive}
              icon={isCatalogueActive ? <CatalogueActive /> : <CatalogueInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isCatalogueActive ? 'white-color' : 'black-color'
                }
                to='/catalogue'
              >
                <Box className='nav-link-box'>
                  Stores
                  {isCatalogueActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )} */}
          {(isAllPermission || isStoreAllowed) && (
            <SubMenu
              placeholder={undefined}
              open={isStoresDataActive || isStoreDropdownOpen}
              className={isStoresDataActive ? 'submenu-head-active white-color' : 'submenu-head'}
              title={`Stores`}
              icon={isStoresDataActive ? <CatalogueActive /> : <CatalogueInActive />}
              onClick={() => setIStoreDropdownOpen(!isStoreDropdownOpen)}
            >
              <MenuItem
                className={isToolDataActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Tools
                <Link to='/tools' />
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? 'white-color' : isFactoryLayoutActive ? 'blue-color' : 'black-color'
                  }
                  to='/factory-layout'
                /> */}
              </MenuItem>
              <MenuItem
                className={isAccessoriesDataActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Accessories
                <Link to='/accessories' />
              </MenuItem>
              <MenuItem
                className={isConsumablesDataActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Consumables
                <Link to='/consumables' />
              </MenuItem>
              <MenuItem
                className={isFinishedGoodsDataActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Finished Goods
                <Link to='/finished-goods' />
              </MenuItem>
              <MenuItem
                className={isRawMaterialsDataActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Raw Materials
                <Link to='/raw-materials' />
              </MenuItem>
            </SubMenu>
          )}
          {(isAllPermission || isIssuesAllowed) && (
            <MenuItem
              className={`menu-item ${isTicketActive && 'fw-bold'}`}
              active={isTicketActive}
              icon={isTicketActive ? <TicketActiveIcon /> : <TicketInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isTicketActive ? 'white-color' : 'black-color'
                }
                to='/my-tickets'
              >
                <Box className='nav-link-box'>
                  Issues
                  {isTicketActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {(isAllPermission || isCheckListsAllowed) && (
            <MenuItem
              className={`menu-item ${isChecklistActive && 'white-color'}`}
              active={isChecklistActive}
              icon={isChecklistActive ? <ChecklistActiveIcon /> : <ChecklistInActiveIcon />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isChecklistActive ? 'white-color' : 'black-color'
                }
                to='/checklists'
              >
                <Box className='nav-link-box'>
                  Checklists
                  {isChecklistActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}
          {/* TODO: temp disable Menu for future changes client side */}
          {/* {(isAllPermission || isCustomerOrderAllowed) && (
            <MenuItem
              className={`menu-item ${isCustomerOrderActive && 'white-color'}`}
              active={isCustomerOrderActive}
              icon={isCustomerOrderActive ? <CustomerOrderActive /> : <CustomerOderInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isCustomerOrderActive ? 'white-color' : 'black-color'
                }
                to='/customer-Order'
              >
                <Box className='nav-link-box'>
                  Order Management
                  {isCustomerOrderActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )} */}
          {(isAllPermission || isWorkOrderAllowed) && (
            <MenuItem
              className={`menu-item ${isWorkOrderActive && 'white-color'}`}
              active={isWorkOrderActive}
              icon={isWorkOrderActive ? <WorkOrderActive /> : <WorkOrderInActive />}
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'white-color' : isWorkOrderActive ? 'white-color' : 'black-color'
                }
                to='/work-order'
              >
                <Box className='nav-link-box'>
                  Work Orders
                  {isWorkOrderActive && <MenuActiveIconArrow />}
                </Box>
              </NavLink>
            </MenuItem>
          )}

          {/* {isAllPermission && (
            <MenuItem
              className='menu-item'
              active={location === '/inventory'}
              icon={<FiEdit2 size={'15px'} />}
            >
              <Link to='/inventory'>Inventory</Link>
            </MenuItem>
          )} */}

          {(isAllPermission || isFactoryDataAllowed) && (
            <SubMenu
              placeholder={undefined}
              open={isFactoryDataActive || isDropdownOpen}
              className={isFactoryDataActive ? 'submenu-head-active white-color' : 'submenu-head'}
              title={`Factory Management`}
              icon={
                isFactoryDataActive ? (
                  <FactoryManagementActiveIcon />
                ) : (
                  <FactoryManagementInActiveIcon />
                )
              }
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <MenuItem
                className={isFactoryLayoutActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Factory Layout
                <Link to='/factory-layout' />
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'white-color' : isFactoryLayoutActive ? 'blue-color' : 'black-color'
                  }
                  to='/factory-layout'
                />
              </MenuItem>
              <MenuItem
                className={isUserManagementActvie ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                User Management
                <Link to='/user-management' />
              </MenuItem>
              <MenuItem
                className={isIssueMappingActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Issue Mapping
                <Link to='/issue-mapping' />
              </MenuItem>
              <MenuItem
                className={isFactoryScheduleActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Factory Schedule
                <Link to='/factory-schedule' />
              </MenuItem>
              <MenuItem
                className={isOtherSettingActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Other Settings
                <Link to='/other-seting' />
              </MenuItem>
              <MenuItem
                className={isDeletedItemsActive ? 'submenu-item-active' : 'submenu-item'}
                icon={<SubMenuItemIcon />}
              >
                Deleted Items
                <Link to='/deleted-items' />
              </MenuItem>
            </SubMenu>
          )}
        </Menu>
      </ProSidebar>
    </SidebarWrapper>
  );
};

export default Sidebar;
