import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DateRange from '../Dashboard/DateRange';
import moment from 'moment';

interface PropI {
  Component?: any;
  data?: any;
  title: string;
  height?: string;
  isEditIcon?: boolean;
  isViewAll?: boolean;
  isChart?: boolean;
  isGrant?: boolean;
  isGrantSF?: boolean;
  isMutliCol?: boolean;
  viewAllLink?: string;
  isHeightMax?: boolean;
}
const CardHead = (props: PropI) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));
  const {
    Component,
    data,
    title,
    height,
    isHeightMax = false,
    isEditIcon = false,
    isViewAll = false,
    isChart = false,
    isGrant = false,
    isGrantSF = false,
    isMutliCol = false,
    viewAllLink = '',
  } = props;
  const CardWrapper = styled(Box)(() => ({
    padding: '18px 14px',
    border: '1px solid #DEDEDE',
    // boxShadow: '0px 2px 10px rgba(40, 40, 40, 0.1)',
    // borderRadius: '3px',
    '.card-header': {
      position: 'sticky',
      zIndex: '2',
      height: isGrant || isGrantSF ? (isMdScreen ? '100px' : '40px') : isLGScreen ? '35px' : '40px',
      alignItems: 'center',
    },
    '.header-box': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '.card-block': {
      ...(isHeightMax && { maxHeight: height }),
      height: height,
      overflow: !isChart ? 'auto' : isGrant ? 'auto' : 'hidden',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        height: '668px !important',
        ...(isHeightMax && { maxHeight: '668px !important' }),
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        height: '650px !important',
        ...(isHeightMax && { maxHeight: '650px !important' }),
      },
    },
    '.MuiCardContent-root': {
      padding: '0px',
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
    '.heading-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    '.head-title': {
      color: '#020202',
      fontWeight: 600,
      fontSize: '18px',
    },
    '.view-link': {
      color: '#265AA8',
      fontWeight: 600,
      fontSize: '14px',
      cursor: 'pointer',
    },
    '.grant-button': {
      marginRight: '10px',
      border: '1px solid #D2D1D1',
      color: '#242424',
      textTransform: 'none',
      borderRadius: '8px',
      marginTop: isGrant || isGrantSF ? (isMdScreen ? '10px' : '0px') : '0px',
    },
  }));

  const navigate = useNavigate();
  const currentDate: any = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  const [ganttDates, setGranttDatesState] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  // const { data: dashboardGanttCharts } = useGetDashboardGanttChartsHC(true, {
  //   startDate: ganttDates.startDate,
  //   endDate: ganttDates.endDate,
  // });
  // const WorkorderStatus = dashboardGanttCharts;
  const setGranttDates = (e, { startDate, endDate }) => {
    setGranttDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  //Gantt Dates SF
  const [ganttDatesSF, setGranttDatesStateSF] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  // const { data: dashboardGanttChartsSF } = useGetDashboardGanttCharts(true, {
  //   startDate: ganttDatesSF.startDate,
  //   endDate: ganttDatesSF.endDate,
  // });
  const setGranttDatesSF = (e, { startDate, endDate }) => {
    setGranttDatesStateSF({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  return (
    <CardWrapper>
      <Box className='card-header heading-wrapper'>
        <Box className='header-box'>
          <Box display={'flex'}>
            <Box>
              <Typography className='head-title'>{title}</Typography>
            </Box>
            {isGrant && (
              <Box mx={1}>
                <DateRange
                  setDatesState={setGranttDatesState}
                  dates={ganttDates}
                  setDates={setGranttDates}
                />
              </Box>
            )}
            {isGrantSF && (
              <Box mx={1}>
                <DateRange
                  setDatesState={setGranttDatesState}
                  dates={ganttDatesSF}
                  setDates={setGranttDatesSF}
                />
              </Box>
            )}
          </Box>
          {/* //! un-used components */}

          {/* {(isGrant || isGrantSF) && (
            <Box>
              <Button variant='outlined' className='grant-button'>
                <RxDotFilled color={'#93CE16'} fontSize={'28px'} />
                Ontime ({WorkorderStatus?.onTimeCount})
              </Button>
              <Button variant='outlined' className='grant-button'>
                <RxDotFilled color={'#F29E38'} fontSize={'28px'} />
                Delayed ({WorkorderStatus?.delayCount})
              </Button>
              <Button variant='outlined' className='grant-button'>
                <RxDotFilled color={'#CC0000'} fontSize={'28px'} />
                Needs attention ({WorkorderStatus?.needAttentionCount})
              </Button>
            </Box>
          )} */}

          {isViewAll && (
            <Box
              onClick={() => {
                viewAllLink && navigate(viewAllLink);
              }}
              className='view-link'
            >
              View All
            </Box>
          )}
        </Box>
      </Box>
      <CardContent>
        <Box className='card-block'>
          {!isChart &&
            !isMutliCol &&
            data?.map((currentCard: any, ind: number) => {
              return <Component key={ind} cardData={currentCard} />;
            })}
          {!isChart && isMutliCol && (
            <Grid container spacing={2}>
              {data?.map((currData: any, ind: React.Key | null | undefined) => {
                return (
                  <Grid item xs={6} key={ind}>
                    <Component />
                  </Grid>
                );
              })}
            </Grid>
          )}
          {/* //! un-used components */}
          {/* {isGrant && (
            <Grid container spacing={2}>
              <Grid item xs={12} key={'ind'}>
                <Component cardData={dashboardGanttCharts} dates={ganttDates} />
              </Grid>
            </Grid>
          )} */}
          {/* {isGrantSF && (
            <Grid container spacing={2}>
              <Grid item xs={12} key={'ind'}>
                <Component cardData={dashboardGanttChartsSF} dates={ganttDatesSF} />
              </Grid>
            </Grid>
          )} */}
          {isChart && (
            <Component
              isEditBtn={!['Top Downtimes', 'TAT of Workers', 'Scrap Generations'].includes(title)}
            />
          )}
        </Box>
      </CardContent>
    </CardWrapper>
  );
};

export default CardHead;
