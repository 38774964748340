import React, { useState } from 'react';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { Box, Grid } from '@mui/material';
import TableComponent from '../commonComponents/Table';
import { useGetActions } from '../../hooks/dashboardHooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const category = {
  WO: '/draft-work-order',
  IR: '/ticket-details',
};

function getCategory(selectedCategory) {
  const prefix = selectedCategory.split('-')[0].toUpperCase(); // Extract prefix and convert to uppercase
  return category[prefix] || null; // Return the corresponding URL or null if not found
}

const MyActions = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {
    data: actionsData,
    isLoading: isActionLoading,
    isFetching: isActionFetching,
  } = useGetActions(true, page, 10);

  const rows = actionsData?.results?.map((data, index) => {
    return {
      id: index + 1,
      actionId: data?.id,
      category: getCategory(data?.requestNumber),
      documentName: data?.documentName,
      number: data?.requestNumber,
      action: data?.actionNeeded,
      lastAction: data?.lastActionBy,
      status: data?.documentStatus,
      waitingSince: data?.waitingSince
        ? moment(data?.waitingSince).format('hh:mm A, DD MMM, YYYY')
        : '-',
    };
  });
  const columns = [
    { field: 'id', headerName: '#', width: '80' },
    {
      field: 'documentName',
      headerName: 'Document Name',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            onClick={() =>
              navigate(
                `${params?.row?.category ? params?.row?.category : ''}/${params?.row?.actionId}`
              )
            }
          >
            {params?.row?.documentName}
          </Box>
        );
      },
    },
    { field: 'number', headerName: 'Document Number', flex: 1 },
    { field: 'action', headerName: 'Action Needed', flex: 1 },
    { field: 'lastAction', headerName: 'Last Action By', flex: 1 },
    { field: 'status', headerName: 'Document Status', flex: 1 },
    { field: 'waitingSince', headerName: 'Waiting Since', flex: 1 },
  ];

  const currentWorkerData = {
    columns: columns,
    rows: rows,
  };
  const BtnParts = {
    isBtnShow: false,
    title: 'Add Actions',
    linkData: [
      {
        menuItem: 'Add New Tools',
        to: '/add-tool',
      },
    ],
  };
  return (
    <React.Fragment>
      <Box>
        <BreadcrumbNavigation breadCrumbData={null} pageTitle={`My Actions`} backToLink={null} />
        <Box className='main-padding'>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TableComponent
                data={BtnParts}
                isLoading={isActionLoading || isActionFetching}
                tableData={currentWorkerData}
                isOptionShow={true}
                isEditStock={true}
                page={page}
                paginationData={actionsData}
                setPage={setPage}
                listName={'My Actions'}
                isNoEntityShow={false}
                moduleName={'My Actions'}
                totalResults={actionsData?.totalResults}
                isHideDropDown={true}
                isFilter={false}
                iSColumnsButton={false}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default MyActions;
