import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import './../../assets/css/modal.css';
import { Formik } from 'formik';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import ErrorPopup from '../../Modals/ErrorPopup';
import { useAddCustomer, useUpdateCustomer } from '../../hooks/cusromerHooks';
import { useDeleteFactoryToolCategory } from '../../hooks/factoryScheduleHooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomYesModalButton = styled(Button)(() => ({
  padding: '10px 16px 10px !important',
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '40px',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'center',
}));
const TextFiled = styled(Typography)(() => ({
  color: 'rgba(2, 2, 2, 1)',
  fontSize: '18px !important',
  fontWeight: '600px',
  padding: '24px 0px 0px 0px',
}));
const InputField = styled(TextField)(() => ({
  borderRadius: 'rpx',
  width: '400px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      // borderColor: "yellow"
    },
    '&.Mui-focused fieldset': {
      // borderColor: "green"
    },
  },
}));
const InventoryBox = styled(Box)(() => ({
  '.note-title': {
    fontSize: '12px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
}));
const BoxWrapper = styled(Box)(() => ({
  '.conform-title': {
    fontSize: '18px !important',
    fontWeight: 600,
    padding: '1.5rem 8rem',
    textAlign: 'center',
  },
  '.sub-box-confirm': {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px',
  },
  '.title-head-icon': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 24px 0px 24px',
  },
}));

interface Prop {
  handleClose: () => void;
  isOpen: boolean;
  data?: any;
  deleteId?: any;
}
const SettingConfirmModal = (props: Prop) => {
  const { id } = useParams();
  const { handleClose, isOpen: open, deleteId } = props;
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [showStockErr, setShowStockErr] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { mutate: deleteToolCategory } = useDeleteFactoryToolCategory();

  const {
    mutate: addCustomer,
    isError: isaddCustomerError,
    error: addCustomerError,
    isLoading,
  } = useAddCustomer();
  const {
    mutate: updateCustomer,
    isError: isupdateCustomerError,
    error: updateCustomerError,
  } = useUpdateCustomer();

  React.useEffect(() => {
    if (isaddCustomerError || isupdateCustomerError) {
      isaddCustomerError
        ? setErrorMessage(addCustomerError?.data?.message)
        : setErrorMessage(updateCustomerError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isaddCustomerError, isupdateCustomerError]);
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Formik
          initialValues={{}}
          enableReinitialize={true}
          onSubmit={(values) => {
            if (props.data.isWorkOrder === 0) {
              updateCustomer({ ...values, ...props.data, isWorkOrder: 1 });
              setShowStockErr(true);
            }
          }}
        >
          {({ handleSubmit }) => (
            <InventoryBox sx={style}>
              <form onSubmit={handleSubmit}>
                <BoxWrapper>
                  <Box className='title-head-icon'>
                    <CloseIcon onClick={handleClose} className='close-icon' />
                  </Box>
                  <Box className='box-sub-info'>
                    {/* <Typography className='span-color'>{props?.data?.quantity || 0}</Typography> */}
                    <TextFiled className='conform-title'>
                      Are you sure you want to Sure to Delete Tool Category?
                    </TextFiled>

                    {showStockErr && (
                      <Typography className='validation-text'>
                        {/* {'Please Enter Valid Stock Quantity'} */}
                      </Typography>
                    )}
                  </Box>

                  <Box className='sub-box-confirm'>
                    <CustomYesModalButton
                      disabled={isLoading}
                      variant='outlined'
                      onClick={() => {
                        handleClose();
                      }}
                      className='ml-20'
                    >
                      Cancel
                    </CustomYesModalButton>
                    <CustomYesModalButton
                      disabled={isLoading}
                      variant='contained'
                      type='submit'
                      className='blue-bg ml-20'
                      onClick={() => {
                        deleteToolCategory(deleteId);
                      }}
                    >
                      Confirm
                    </CustomYesModalButton>
                  </Box>
                </BoxWrapper>
              </form>
            </InventoryBox>
          )}
        </Formik>
      </Modal>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </div>
  );
};
export default SettingConfirmModal;
