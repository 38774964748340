import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import './../../assets/css/detailsInformationBox.css';
import EditIcon_new from '../../assets/svg/EditIcon_new';
const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));
const HeadBoxWrapper = styled(Box)(() => ({
  '.iconText': {
    fontSize: '14px !important',
    color: '#0160B9 !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
  },
  '.icon-text-wrapper': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'center',
  },
  '.mr-23px': {
    marginRight: '23px',
  },
  '.pl-24px': {
    paddingLeft: '24px',
  },
  '.icon': {
    color: '#265AA8',
    paddingRight: '8px',
    fontSize: '20px !important',
  },
  '.reason': {
    padding: '0px 0px 0px 30px',
  },
  '.back-ground': {
    background: '#F6F6F6 !important',
    borderRadius: '4px 4px 0px 0px !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.see-more': {
    fontWeight: 600,
    fontSize: '14px',
    color: '#0160B9',
    marginLeft: '24px',
    cursor: 'pointer',
  },
}));

const IssueDetails = ({ issue, handleEdit }: any) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  return (
    <HeadBoxWrapper>
      <Box className='details-box-border'>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box className='back-ground'>
              <Box>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  {_.get(issue, 'issueType', '')}
                </Typography>
              </Box>
              <Box className='icon-text-wrapper mr-23px' onClick={() => handleEdit(issue?.id)}>
                <EditIcon_new />
                <Typography className={'iconText'}>Edit</Typography>
              </Box>
            </Box>

            <TextFiled>Owner</TextFiled>
            <Typography className='span-color pl-24px'>
              {_.get(issue?.owner, 'name', '')}
            </Typography>

            <TextFiled>Object</TextFiled>
            <Typography className='span-color pl-24px'>{_.get(issue, 'objectType', '')}</Typography>
          </Grid>
        </Grid>
        <Box>
          <TextFiled>Issue Reasons</TextFiled>
        </Box>
        {/* Old functionality */}
        {/* <Grid container>
          {issue?.reasons?.map((data, ind) => {
            return (
              <Grid item xs={6} md={4} lg={4} key={ind}>
                <Typography className='span-color reason'>
                  {ind + 1}. {data}
                </Typography>
              </Grid>
            );
          })}
        </Grid> */}
        <Box minHeight={issue?.reasons?.length >= 5 ? '100px' : '123px'}>
          {issue?.reasons?.slice(0, showAll ? issue?.reasons?.length : 5).map((data, ind) => {
            return (
              <Typography className='span-color reason' key={ind}>
                {ind + 1}. {data}
              </Typography>
            );
          })}
          {issue?.reasons?.length >= 5 && (
            <Typography className='see-more' onClick={toggleShowAll}>
              {showAll ? 'See Less' : 'See More'}
            </Typography>
          )}
        </Box>
      </Box>
    </HeadBoxWrapper>
  );
};

export default IssueDetails;
