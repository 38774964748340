import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useAddSupplier, useUpdateSupplier } from '../hooks/supplierHooks';
import ErrorPopup from './ErrorPopup';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));
interface supplierI {
  isOpen: boolean;
  handleClose: () => void;
  supplierData: any;
}
const SupplierConfirm = (props: supplierI) => {
  const { isOpen, handleClose, supplierData } = props;
  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: addSupplier,
    isError: isAddSupplierError,
    error: addSupplierError,
    isLoading,
  } = useAddSupplier();
  const {
    mutate: updateSupplier,
    isError: isupdateSupplierError,
    error: updateSupplierError,
  } = useUpdateSupplier();

  React.useEffect(() => {
    if (isAddSupplierError || isupdateSupplierError) {
      isAddSupplierError
        ? setErrorMessage(addSupplierError?.data?.message)
        : setErrorMessage(updateSupplierError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isAddSupplierError, isupdateSupplierError]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the Supplier Details</Typography>
            <CloseIcon onClick={handleClose} className='close-icon' />
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>Supplier Details</CustomSubTitle>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  {' '}
                  <CustomTypography>Supplier Name </CustomTypography>{' '}
                  <Typography className='span-color pl-sub-text'>
                    {_.get(supplierData, 'name', '')}
                  </Typography>
                  <CustomTypography>Code: </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(supplierData, 'code', '')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CustomTypography> Contact: </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(supplierData, 'contact', '')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='sub-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color edit-btn'
              onClick={() => {
                handleClose();
                //navigate('/edit-supplier/supplierId');
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              disabled={isLoading}
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                if (id) {
                  updateSupplier({ ...supplierData, id: id });
                } else {
                  addSupplier(supplierData);
                }
                // handleClose();
              }}
              //   onClick={() => navigate('/part-details/partId')}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
    </Modal>
  );
};
export default SupplierConfirm;
