import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
import ErrorPopup from './ErrorPopup';
import { useAddPart, useUpdatePart } from '../hooks/partHooks';
import ViewImage from './ViewImage';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 620,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
  },
  '.checklistItems-children': {
    overflow: 'auto',
    height: '200px',
  },
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface partI {
  isOpen: boolean;
  handleClose: () => void;
  partData: any;
}
const ToolHolderConfirmModel = (props: partI) => {
  const { isOpen, handleClose, partData } = props;
  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);
  const [imageInfo, setImageInfo] = React.useState({});

  const {
    mutate: addToolHolder,
    error: addToolHolderError,
    isError: isAddToolHolderError,
  } = useAddPart(true);
  const {
    mutate: updateToolHolder,
    error: updateToolHolderError,
    isError: isUpdateToolHolderError,
  } = useUpdatePart();

  React.useEffect(() => {
    if (isAddToolHolderError || isUpdateToolHolderError) {
      isAddToolHolderError
        ? setErrorMessage(addToolHolderError?.data?.message)
        : setErrorMessage(updateToolHolderError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [addToolHolderError, updateToolHolderError]);

  interface IUser {
    name?: string;
    modelNo?: string;
    make?: string;
    sizeDetails?: any;
    id?: string;
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>
              {`Please confirm the New ${
                partData?.toolCategoryType ? partData?.toolCategoryType : 'Tool Holder'
              }
              `}
            </Typography>
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>
                {' '}
                {`${partData?.toolCategoryType ? partData?.toolCategoryType : 'Tool Holder'}
              Details`}
              </CustomSubTitle>
              <Grid container item xs={10} spacing={2} lg={12}>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>
                    {' '}
                    {`${partData?.toolCategoryType ? partData?.toolCategoryType : 'Tool Holder'}
               Name`}
                  </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Model No.</CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'modelNo', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Make </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'make', '-')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={2} lg={12}>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>
                    {' '}
                    {`${partData?.supplier ? partData?.supplier : 'Supplier'}
               Name`}
                  </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'supplier', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>PR Number.</CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'prNumber', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Date of Purchase</CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {moment(_.get(partData, 'purchaseDate', '-')).format('DD-MM-YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className='section-one'>
              <Box
                sx={{
                  paddingTop: '20px',
                }}
              >
                <CustomSubTitle>Size Details</CustomSubTitle>
              </Box>
              <Box className='checklistItems-children'>
                {partData?.sizeDetails?.map((item, ind) => {
                  return (
                    <>
                      <Grid container item xs={10} spacing={2} key={ind} lg={12}>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Size</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {_.get(item, 'size', '')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Length</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {_.get(item, 'length', '-')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Quantity</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {_.get(item, 'quantity', '-')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Image</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            <img
                              style={{ cursor: 'pointer' }}
                              src={item?.uploadedImages?.[0]?.url}
                              alt='img'
                              width={35}
                              height={35}
                              onClick={() => {
                                if (item?.uploadedImages?.[0]) {
                                  handleImageModalOpen();
                                  setImageInfo(item?.uploadedImages?.[0]);
                                }
                              }}
                            />
                          </Typography>
                          {/* TODO:: image icon */}
                          {/* <Typography className='span-color pl-sub-text'>
                            <IoImageOutline
                              fontSize={'35px'}
                              cursor={'pointer'}
                              onClick={() => {
                                if (item?.uploadedImages?.[0]) {
                                  handleImageModalOpen();
                                  setImageInfo(item?.uploadedImages?.[0]);
                                }
                              }}
                            />
                          </Typography> */}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='tool-box' marginRight={5}>
            <CustomModalButton
              variant='outlined'
              className='blue-color edit-btn'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                id ? updateToolHolder({ ...partData, id }) : addToolHolder({ ...partData });
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isImageModalOpen && (
          <ViewImage
            imageInfo={imageInfo}
            isOpen={isImageModalOpen}
            handleClose={handleImageModalClose}
          />
        )}
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
    </Modal>
  );
};
export default ToolHolderConfirmModel;
