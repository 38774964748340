import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, styled } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { TfiPlus } from 'react-icons/tfi';
import CustomButton from '../commonComponents/CustomButton';
import {
  useAddFactoryToolCategory,
  useGetFactoryToolCategory,
  useUpdateFactoryToolCategory,
} from '../../hooks/factoryScheduleHooks';
import TableComponent from '../commonComponents/Table';
import EditIcon from '../../assets/svg/EditIcon';
import DeleteIcon from '../../assets/svg/DeleteIcon';
import SettingConfirmModal from './SettingConfirmModal';

const AddSetting = () => {
  const { data: toolCategoryData } = useGetFactoryToolCategory();
  const { mutate: addToolCategory } = useAddFactoryToolCategory();
  const { mutate: updateToolCategory } = useUpdateFactoryToolCategory();
  const [editSettingData, setEditSettingData] = React.useState<any>({});
  const [isEdit, setIsEdit] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>({});

  const [isDeleteShow, setDeleteShow] = useState(false);
  const handleClose = () => setDeleteShow(false);
  const handleEdit = (id) => {
    const currentIssue = toolCategoryData?.data?.find((issue) => issue.id === id);
    setEditSettingData(currentIssue);
    // setOpen(true);
  };

  const ToolCategory = [
    { field: 'no', headerName: 'Sr.No', flex: 0.4 },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 203,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'22px'}
              onClick={() => {
                if (params.row.id) {
                  handleEdit(params.row.id);
                }
              }}
            >
              <EditIcon />
            </Box>
            <Box
              onClick={() => {
                setDeleteId(params.row.id);
                setDeleteShow(true);
              }}
            >
              <DeleteIcon />
            </Box>
          </Box>
        );
      },
    },
  ];

  const ToolCategoryRows = toolCategoryData?.data?.map((data, ind) => {
    // const startIndex = (page - 1) * 10;
    return {
      id: data.id || 1,
      no: ind + 1,
      name: data?.name,
    };
  });

  // const btnToolCategory = {
  //   title: 'Add Tool Category',
  //   linkData: [
  //     {
  //       menuItem: 'Add New Tool Category',
  //       to: '/other-seting',
  //     },
  //   ],
  // };

  const tableDataForToolCategory = {
    columns: ToolCategory,
    rows: ToolCategoryRows,
    // detailsRedirection: '/customer-details',
  };

  const SignupSchema = Yup.object().shape({});
  useEffect(() => {
    if (!_.isEmpty(editSettingData)) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [editSettingData?.id]);
  const InputField = styled(TextField)(() => ({
    borderRadius: '4px',
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      height: '40px',
      padding: '0px 14px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
        borderRadius: '4px',
      },
    },
  }));

  return (
    <Box>
      <Formik
        initialValues={{
          newToolCategory: isEdit ? [editSettingData] : [{ name: '' }],
        }}
        enableReinitialize={true}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          if (isEdit) {
            updateToolCategory(values?.newToolCategory?.[0]);
          } else {
            addToolCategory(values);
          }
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit}>
            <Typography className='title-typography'> Tool Category</Typography>
            <Grid container item xs={10} spacing={2}>
              <Grid item xs={4} md={6} lg={4}>
                <Typography className='field-title'>Tool Category</Typography>

                <FieldArray name='newToolCategory'>
                  {({ push, remove }) => (
                    <Box>
                      <Box>
                        {values?.newToolCategory?.map((p, index) => {
                          return (
                            <Box key={index}>
                              <Box>
                                <InputField
                                  fullWidth
                                  placeholder='Enter Category'
                                  onChange={handleChange}
                                  name={`newToolCategory[${index}].name`}
                                  value={values.newToolCategory?.[index]?.name}
                                  type='text'
                                  sx={{ mt: 1 }}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      <Box>
                        <CustomButton
                          sx={{ marginTop: '10px' }}
                          variant='outlined'
                          className='mr-14'
                          onClick={() => {
                            if (values.newToolCategory.length) {
                              push({ name: '' });
                            }
                          }}
                        >
                          <TfiPlus size={'16px'} style={{ paddingRight: '10px' }} /> Add New
                          Category
                        </CustomButton>
                      </Box>
                    </Box>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Box className='button-box' sx={{ marginTop: '40px !important' }}>
              <Box className='footer-padding-save-tool-category'>
                <CustomButton variant='contained' className={'blue-bg'} type='submit'>
                  {isEdit ? 'Update Category' : 'Save Tool Category'}
                </CustomButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <TableComponent
            // data={btnToolCategory}
            isHideDropDown={true}
            tableData={tableDataForToolCategory}
            // isLoading={isCustomerOrderLoading || isCustomerOrderFetching}
            isOptionShow={true}
            isEditStock={true}
            // paginationData={customerInfo}
            page={1}
            isShowFiveRecords={true}
            setPage={1}
            listName={'Tool Category List'}
            isPlusBtnHide={true}
            // isNoEntityShow={tableDataForToolCategory?.rows?.length <= 0 && isCustomerSuccess}
            // moduleName={'Tool Category'}
            // redirectPage={'/other-seting'}
            // totalResults={customerInfo?.totalResults}
            isFilter={false}
            iSColumnsButton={false}
          />
        </Grid>
      </Grid>
      {isDeleteShow && (
        <SettingConfirmModal isOpen={isDeleteShow} handleClose={handleClose} deleteId={deleteId} />
      )}
    </Box>
  );
};

export default AddSetting;
