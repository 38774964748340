import { Box, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import * as _ from 'lodash';
import { useSearchData } from '../hooks/searchOptionHook';
import { useNavigate } from 'react-router-dom';
import { cookie } from '../Utils/Cookies/cookie';

function SearchComponent() {
  const [val, setVal] = useState<any>('');
  const [data, setData] = useState<any[]>([]);
  const [enableSearchAPI, setenableSearchAPI] = useState(true);
  const [personName, setPersonName] = React.useState<any>([]);
  const [countPerson, setCountPerson] = React.useState<any>('');
  const [touched, setTouched] = useState(false);

  const token = cookie.get('token');
  const { data: getAllSearchData, isLoading } = useSearchData(touched && data?.length === 0);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setenableSearchAPI(false);
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
    setCountPerson(event.target.value.length);
    const filterData = _.filter(getAllSearchData, (data, index) =>
      event.target.value.includes(data?.category)
    );
    setData(filterData);
  };

  useEffect(() => {
    if (!isLoading) {
      if (enableSearchAPI) {
        setData(getAllSearchData);
        setenableSearchAPI(false);
      }
      setenableSearchAPI(false);
    }
  }, [data.length, getAllSearchData?.length]);

  const GroupHeader = styled('div')(() => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: '#333333',
    backgroundColor: '#F4F9FF',
  }));

  const names: string[] = [
    'Workorders',
    'Part',
    'Product',
    'Raw Material',
    'Machine',
    'Process',
    'Workers',
  ];

  //! all relative dynamic paths for particuler parts,parts Etc...

  const category = {
    Workorders: '/completion-report',
    Tool: '/tool-details',
    Accessory: '/accessory-details',
    Consumable: '/consumable-details',
    'Raw Material': '/raw-material-details',
    'Finished Good': '/finshedgoods-details',
    Machine: '/machine-details',
    Process: '/process-details',
    Workers: '/worker-detail',
    'Desktop User': '/desktop-user-details',
  };
  const isChangeWidth =
    location.pathname.startsWith('/mobile-user-details') ||
    location.pathname.startsWith('/desktop-user-details');
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const BoxWrapper = styled(Box)(() => ({
    display: 'inline-flex',
    boxSizing: 'border-box',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid rgba(205, 205, 205, 1)',
    background: 'rgba(246, 246, 246, 1)',
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '0 !important',
    },
    '.dropdown': {
      background: '#F4F9FF',
      borderRadius: '0 4px 4px 0px',
      border: 'none !important',
      padding: '0px !important',
      width: personName?.length ? '160px' : '80px',
      height: '40px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '.autocomplete': {
      '& .MuiOutlinedInput-root': {
        padding: '0px !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiAutocomplete-inputFocused': {},
      width: isMdScreen ? '180px' : '300px',
      '& .MuiAutocomplete-input': {
        padding: '8px 0px 0px 0px !important',
      },
    },

    '.search-input': {
      width: isMdScreen ? '180px' : '300px',
      fontSize: '16px',
    },
    '.search-icon': {
      padding: '8px 0.5px 0px 6.5px',
      color: 'gray',
    },
    '.custom-checkbox': {
      '& .MuiSvgIcon-root': {
        fontSize: '70px !important',
        borderRadius: '50px !important',
      },
    },
    '.all-set': {
      border: '1px solid red',
      PaddingBottom: '10px',
    },
  }));
  return (
    <BoxWrapper display={'flex'}>
      <Autocomplete
        className='autocomplete'
        forcePopupIcon={false}
        id='grouped-demo'
        options={data}
        groupBy={(option: any) => option?.category}
        getOptionLabel={(option) => {
          return option.title || '';
        }}
        onChange={(e, value) => {
          navigate(`${category[value.category]}/${value?.value}`);
          setVal(value);
        }}
        open={touched}
        onBlur={() => setTouched(false)}
        onOpen={() => setTouched(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            className='search-input'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon className='search-icon' />
                </InputAdornment>
              ),
            }}
            placeholder='Search'
          />
        )}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <ul>{params.children}</ul>
          </li>
        )}
      />
    </BoxWrapper>
  );
}

export default SearchComponent;
