import { userErrI, userFullListI, userI } from '../Types/userManagement';
import { deleteRequest, get, patch, post } from '../Utils/index';

export const getSingleDesktopUser = async (data): Promise<userI | userErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/user/${id}`).then((res) => res.data);
};

export const addDesktopUser = async (data): Promise<userI | userErrI> => {
  return post(`/customer/user`, data).then((res) => res.data);
};
export const getDesktopUser = async (data): Promise<any> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/user`, payload).then((res) => res.data);
};
export const getFullDesktopUser = async (): Promise<userFullListI | { users: [] }> => {
  return get(`/customer/user/full-list`).then((res) => res.data);
};
export const getAllUsers = async (): Promise<userFullListI | { users: [] }> => {
  return get(`/customer/user/all-users-full-list`).then((res) => res.data);
};

export const getDesktopUserRoles = async () => {
  return get(`/customer/user-role`);
};

export const updateDesktopUser = async (data): Promise<userI | userErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/user/${id}`, data).then((res) => res.data);
};
export const deleteDesktopUser = async (id) => {
  return deleteRequest(`/customer/user/${id}`);
};
export const restoreDesktopUser = async (id) => {
  return patch(`/customer/user/restore/${id}`);
};
export const getSingleDeletedDesktopUser = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/user/deleted/${id}`);
};

export const hardDeleteDesktopUser = async (id) => {
  return deleteRequest(`/customer/user/hard-delete/${id}`);
};

export const userManagementApi = {
  addDesktopUser,
  getDesktopUser,
  getFullDesktopUser,
  getSingleDesktopUser,
  getDesktopUserRoles,
  deleteDesktopUser,
  updateDesktopUser,
  getAllUsers,
  hardDeleteDesktopUser,
  restoreDesktopUser,
  getSingleDeletedDesktopUser,
};
