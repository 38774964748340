import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';

const TaskWorkorder = ({ workoredrData }: any) => {
  const HeadBox = styled(Box)(() => ({
    height: '15vh !important',
    '.validation-text': {
      color: 'red',
      fontSize: '12px',
    },
    '.product-parent': {
      height: '150px',
      overflowY: 'auto',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        height: '100px',
      },
    },
    '.addworkorderbutton-box': {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '20px',
      boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
      position: 'absolute',
      bottom: '1px',
      left: '0px',
      width: '100%',
    },
    '.disable-btn': {
      background: '#C9C9C9',
    },
    '.rm-footer-padding': {
      padding: '16px 0px 10px 20px',
    },
    '.border': {
      border: '1px solid #8A8A8A',
      padding: '20px 10px 18px 30px',
      marginTop: '10px',
    },
    '.fildborder': {
      border: '1px solid #8A8A8A',
      borderColor: '#8A8A8A',
      borderRadius: '4px',
      padding: '7px 20px 7px 0px !important',
      display: 'flex',
      justifyContent: 'flex-end',
      lable: 'sadas',
    },
    '.work-divider-mr-1': {
      margin: '25px 0px 20px 0px',
    },
    '.button-mr-2': {
      margin: '25px 0px 20px 0px',
    },
    '.borderMargin': { padding: '10px 02px 0px 0px' },
    '.buttonFlex': {
      display: 'flex',
      padding: '0px 0px',
    },
    '.title-basic': {
      lineHeight: '32px !important',
      height: '32px !important',
      fontSize: '18px !important',
      margin: '20px 0px 18px 0px !important',
      fontWeight: '600 !important',
    },
    '.details': {
      display: 'flex',
    },
    '.details-color': {
      fontSize: 14,
      fontWeight: 500,
      color: '#8A8A8A',
    },
    '.details-mr1': {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: '8px',
      padding: '5px 0px 0px 0px',
      color: '#8A8A8A',
    },
    '.details-mr6': {
      fontSize: 14,
      fontWeight: 600,
      marginLeft: '8px',
      padding: '5px 0px 0px 0px',
      color: '#020202',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '.details-mr9': {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: '5px',
      padding: '0px 0px 0px 0px',
      color: '#020202',
    },
    '.details-mr7': {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: '8px',
      padding: '5px 0px 0px 0px',
      color: '#020202',
      width: '110px',
    },
    '.details-mr5': {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: '20px',
      color: 'rgba(29, 27, 27, 1)',
    },
    '.details-mr10': {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: '10px',
      color: 'rgba(29, 27, 27, 1)',
    },
    '.details-mr3': {
      marginLeft: '10px',
    },
    '.details-mr11': {
      marginTop: '20px',
    },
    '.details-mr4': {
      marginLeft: '10px',
    },
    '.details-border': {
      backgroundColor: 'rgba(234, 236, 238, 1)',
      borderRadius: '4px',
      padding: '5px 5px 5px 5px',
      display: 'flex',
      marginTop: '20px',
      marginLeft: '8px',
      // height: '26px',
    },
    '.details-border-mr2': {
      display: 'flex',
      marginLeft: '0px',
    },
    '.details-mr8': {
      backgroundColor:
        workoredrData?.workOrder?.workOrderStatus === 'qc-approval'
          ? '#FFCF95'
          : workoredrData?.workOrder?.workOrderStatus === 'final-approval'
          ? '#C4C4FA'
          : '#C5DCFF',
      marginLeft: '10px',
      height: '26px',
      borderRadius: '4px',
    },
    '.details-mr2': {
      fontSize: 14,
      fontWeight: 500,
      padding: '0px 0px 18px 0px',
      color: '#020202',
    },
    '.color-mr1': {
      color: '#265AA8',
      fontSize: 14,
      fontWeight: 600,
      marginLeft: '8px',
      padding: '7px 0px 0px 0px',
      textOverflow: 'ellipsis',
      overflowY: 'auto', // Enable vertical scroll
      overflowX: 'hidden', // Hide horizontal scroll
      height: '50px',
      whiteSpace: 'nowrap',
    },
    '.color-mr2': {
      color: '#020202',
      fontSize: 14,
      fontWeight: 600,
      marginLeft: '8px',
      padding: '7px 0px 0px 0px',
      textOverflow: 'ellipsis',
      overflowY: 'auto', // Enable vertical scroll
      overflowX: 'hidden', // Hide horizontal scroll
      height: '50px',
      whiteSpace: 'nowrap',
    },
    '.MuiDivider-root': {
      margin: '0px 0px !important',
    },
    '.work-divider-mr-2': {
      paddingLeft: '1px',
      paddingTop: '0px',
      margin: '0px 0px !important',
    },
    '.table-mr1': {
      margin: '0px 0px 10px 0px',
    },
    '.mr-18': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.mr-19': {
      lineHeight: '32px !important',
      height: '32px !important',
      fontSize: '18px !important',
      margin: '30px 0px 20px 0px !important',
      fontWeight: '600 !important',
    },
    '.menu-item-span': {
      color: '#9E9E9E !important',
    },
    '.mr-23': {
      marginRight: '315px',
      display: 'flex',
      justifyContent: 'start',
    },
    '.mr-31': {
      marginRight: '299px',
      display: 'flex',
      justifyContent: 'start',
    },
    '.mr-30': {
      marginTop: '30px',
    },
    '.date-picker-fieldset': {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#8A8A8A',
        },
      },
      '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '8.5px !important',
      },
      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '8.5px 14px !important',
      },
      '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
        borderRadius: '4px !important',
      },
    },
    '.date-picker': {
      width: '100%',
      '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '8.5px 14px !important',
      },
      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '8.5px 14px !important',
      },
      '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
        borderRadius: '4px !important',
      },
    },
  }));
  // const { data: getChecklists } = useFullGetChecklists(true);

  // const { data: getRawMaterials } = useGetFullRawMaterials(true);
  // const [maintenanceDetails, setMaintenanceDetails] = React.useState<any>();
  const [DetailsOpen, setDetailsOpen] = React.useState(false);
  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };
  const handleDetailsClickOpen = () => {
    setDetailsOpen(true);
  };
  // const { data: workoredrData, isSuccess } = useGetWorkorder(id, !!id);
  // ! for future use
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const {
  //   isDetailsOpen,
  //   DetailsHandleClose,
  //   editinhouseData,
  //   addScheduleData,
  //   currentSchedule,
  //   setCurrentSchedule,
  //   requestCount,
  //   changeRequestCount,
  // } = props;
  // const { maintenanceType } = currentSchedule || {};
  // const activeRadio = !maintenanceType
  //   ? false
  //   : maintenanceType == 'inhouse'
  //   ? false
  //   : maintenanceType == 'periodic'
  //   ? true
  //   : false;
  // const [selectedValue, setSelectedValue] = React.useState(activeRadio);
  const breadCrumbData = [
    {
      title: 'Work Orders',
      link: '/catalogue',
    },
    // {
    //   title: '',
    //   link: '/catalogue',
    //   activeTab: '4',
    // },
    {
      title: `'New Work Order'`,
      link: `'add-customer-order'`,
    },
  ];
  return (
    <>
      {/* <HeadBox className='main-component-padding height-100'> */}
      <HeadBox className='main-mr1'>
        <Grid>
          {/* <Typography className='title-typography'> Enter Customer Order Details</Typography> */}
          <Grid container item xs={12} spacing={0} className='details' mt={'5px'}>
            <Grid container item xs={4} md={6} lg={4} className=''>
              <Grid className='details-color'>
                <Typography className='details-mr1'>Work Order Number</Typography>
                <Typography className='details-mr1' marginTop={'2px'}>
                  Drawing
                </Typography>
                <Typography className='details-mr1' marginTop={'2px'}>
                  Raw Material
                </Typography>
                {/* <Typography className='details-mr1'>Drawing</Typography> */}
              </Grid>
              <Grid className='details-mr3' xs={6} md={6} lg={6}>
                <Typography className='details-mr6'>
                  {workoredrData?.workOrder?.number ? workoredrData?.workOrder?.number : '-'}
                </Typography>
                {/* <Typography className='color-mr1'>
                  {workoredrData?.workOrder?.drawingFiles.length > 0
                    ? workoredrData?.workOrder?.drawingFiles?.map((item) => item.name).join(', ')
                    : '-'}
                </Typography> */}
                <Typography className='details-mr6' marginTop={'2px'}>
                  {workoredrData?.workOrder?.drawingFiles.length > 0
                    ? workoredrData?.workOrder?.drawingFiles.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.name}
                          {index !== workoredrData?.workOrder?.drawingFiles.length - 1 && (
                            <br />
                          )}{' '}
                        </React.Fragment>
                      ))
                    : '-'}
                </Typography>
                <Typography className='details-mr6' marginTop={'2px'}>
                  {workoredrData?.workOrder?.rawMaterial?.name
                    ? workoredrData?.workOrder?.rawMaterial?.name
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={4} md={6} lg={4} className=''>
              <Grid xs={5} md={5} lg={5}>
                <Typography className='details-mr1'>Work Order Status</Typography>
                <Typography className='details-mr1 details-mr11'>Product & Quantity</Typography>
              </Grid>
              <Grid className='details-mr3' xs={4} md={5} lg={5}>
                <Box className='details-mr8'>
                  <Typography className='details-mr7'>
                    {workoredrData?.workOrder?.workOrderStatus === 'qc-approval'
                      ? 'QC Approval'
                      : workoredrData?.workOrder?.workOrderStatus === 'final-approval'
                      ? 'Final Approval'
                      : workoredrData?.workOrder?.workOrderStatus}
                  </Typography>
                </Box>
                <Box className='product-parent'>
                  {workoredrData?.workOrder?.productDetails?.map((data, index) => {
                    return (
                      <>
                        <Grid className='details-border' key={index}>
                          <Typography className='details-mr9'>{data?.product}</Typography>
                          <Grid className='details-border-mr2'>
                            <Typography className='details-mr5'>qty</Typography>
                            <Typography className='details-mr10'>{data?.quantity}</Typography>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Box>
              </Grid>
              {/* </Grid> */}
            </Grid>
            <Grid container item xs={4} md={6} lg={4} className=''>
              {/* <Grid container item xs={4} className='details'> */}
              <Grid>
                <Typography className='details-mr1'>Created By</Typography>
                <Typography className='details-mr1'>Submitted On</Typography>
                <Typography className='details-mr1'>Approved by</Typography>
                <Typography className='details-mr1'>Approved on</Typography>
              </Grid>
              <Grid className='details-mr4' xs={4} md={4} lg={6}>
                <Typography className='details-mr6'>
                  {workoredrData?.workOrder?.createBy?.name}
                </Typography>
                <Typography className='details-mr6'>
                  {new Date(workoredrData?.workOrder?.createdAt).toLocaleDateString('en-GB')}
                </Typography>
                <Typography className='details-mr6'>
                  {workoredrData?.workOrder ? workoredrData?.workOrder?.approved?.name : '-'}
                </Typography>
                <Typography className='details-mr6'>
                  {workoredrData?.workOrder?.approvedDate
                    ? moment(workoredrData?.workOrder?.approvedDate).format('hh:mm A, DD MMM, YYYY')
                    : '-'}
                </Typography>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Grid>
          {/* <Grid container item xs={2} mt={'20px'}>
              <Button
                component='label'
                placeholder='Enter Details'
                style={{
                  width: '100%',
                  height: '55.19%',
                  backgroundColor: '#C5DCFF',
                }}
              >
                <input
                  // type='file'
                  hidden
                  // onChange={handleChange}
                  placeholder='Enter Details'
                  // name={`batch.${index}.document.${index}.name`}
                  // value={values.attachInspectionReport}
                  // value={values?.batch?.[index]?.document?.[index]?.name}
                />
                <Box className=''>
                  <Box className=''>Draft</Box>
                  <Box className=''></Box>
                </Box>
              </Button>
            </Grid> */}
        </Grid>
        {/* <Grid container spacing={2}>
            <Grid item xs={12} className='work-divider-mr-1'>
              <Divider />
            </Grid>
          </Grid> */}
        {/* <Box display={'flex'} mt={'20px'} justifyContent={'space-between'}>
            <Typography
              display={'flex'}
              // mt={'50px'}
              // justifyContent={'flexStart'}
              className='title-typography'
            >
              Task List
            </Typography>
            <Box display={'flex'}>
              <CustomButton
                variant='contained'
                className={'blue-bg'}
                onClick={() => {
                  setMaintenanceDetails({});
                  handleDetailsClickOpen();
                }}
              >
                <Plus fill={'#FFFFFF'} /> <span className='ml'></span>
                Add New Task
              </CustomButton>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TaskDetailsModal
                requestCount={requestCount}
                // changeRequestCount={setRequestCount}
                editinhouseData={maintenanceDetails}
                isDetailsOpen={DetailsOpen}
                // addScheduleData={setScheduleData}
                currentSchedule={maintenanceDetails}
                setCurrentSchedule={setMaintenanceDetails}
                DetailsHandleClose={handleDetailsClose}
              />
            </Grid>
          </Grid> */}
        {/* {open && (
          <TaskConfirm
            handleInventory={handleOpen}
            // setInventoryModalData={setInventoryModalData}
            // partData={value}
            isOpen={open}
            handleClose={handleClose}
            // onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          />
        )} */}
      </HeadBox>
    </>
  );
};

export default TaskWorkorder;
