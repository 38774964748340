/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styled from '@emotion/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import moment from 'moment';
import ErrorPopup from '../../Modals/ErrorPopup';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import EditIcon_new from '../../assets/svg/EditIcon_new';
import TaskWorkorder from './TaskWorkorder';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';
import TaskDetailsModal from '../../Modals/TaskDetailsModal';
import CustomButton from '../commonComponents/CustomButton';
import Plus from '../../assets/svg/Plus';
import { useDeleteTaskWorkOrder, useGetTaskWorkOrder, useHandoverItemsTaskWorkOrder, useReturnItemsTaskWorkOrder } from '../../hooks/taskWorkOrderHooks';
import { IoImageOutline } from 'react-icons/io5';
import Pagination from '@mui/material/Pagination';
import { MdCircle } from 'react-icons/md';
import { MdOutlineCircle } from 'react-icons/md';
import TaskDetailModal from '../../Modals/TaskDetailsModel';
import { useGetWorkorder, useSubmitWorkorder } from '../../hooks/workorderHooks';
// ! icons --- here
import { RiFileList2Line } from 'react-icons/ri';
import WorkOrderApproval from '../../Modals/WorkOrderApproval';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import InhouseTask from '../../assets/svg/InhouseTask.svg';
import Outsourcedtask from '../../assets/svg/Outsourcedtask.svg';
import ViewImage from '../../Modals/ViewImage';
import HandoverItemsModal from '../../Modals/HandoverItemsModal';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';

const GridWrapper = styled(Grid)(() => ({
  '.tab-list-box': {
    borderBottom: '1px solid #DEDEDE',
    marginBottom: '26px',
  },
  '.tab-panel': {
    padding: '2px 0px 0px 0px',
  },
  '.grid-table': {
    padding: '24px 0px 0px 0px',
  },
  '.task-heading-list': {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      display: 'block',
    },
    '.css-k008qs': {
      '@media(min-width: 425px) and (max-width: 1024px)': {
        marginTop: '10px',
        justifyContent: 'end',
      },
    },
  },
}));

const HeadBoxWrapper = styled(Box)(() => ({
  height: 'calc(100vh - 120px)',
  overflow: 'scroll',
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '60px !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
    textOverflow: 'clip!important',
    whiteSpace: 'break-spaces !important',
    lineHeight: '1.2 !important',
    padding: '10px !important',
  },

  '.dimensions-parent': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '10px',
    marginLeft: '15px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 0px -6px',
    bottom: '0px',
    left: '0px',
    width: '100%',
  },
  '.footer-padding': {
    padding: '2px 0px',
    marginTop: '15px',
  },
  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.process-new-file': {
    fontSize: '14px',
    '@media(min-width: 1024px) and (max-width: 1440px)': {
      width: '60px',
    },
  },

  '.side-border': {
    height: '85px',
    width: '5px',
    borderRadius: '10px',
    backgroundColor: '#05994A',
    margin: '4px',
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
    paddingBottom: '0px !important',
  },
  '.pagination-one': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
  },
  '.draft-m1': {
    display: 'flex',
  },
  '.box-draft-m1': {
    display: 'flex',
  },
  '.field-title-2': {
    fontSize: '14px !important',
    fontWeight: 600,
    padding: '0px 0px 0px 5px',
    color: 'rgba(2, 2, 2, 1)',
  },
  '.field-title-4': {
    fontSize: 14,
    fontWeight: 600,
    padding: '30px 0px 0px 0px',
    color: 'rgba(1, 96, 185, 1)',
  },
  '.field-title-5': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 0px 0px',
    color: 'rgba(2, 2, 2, 1)',
  },
  '.field-title-6': {
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 0px 0px 0px',
    color: 'rgba(2, 2, 2, 1)',
  },
  '.field-title-7': {
    display: 'flex',
    gap: 8,
    fontSize: 25,
    fontWeight: 400,
    padding: '30px 0px 0px 0px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '.field-title-8': {
    fontSize: 25,
    fontWeight: 400,
    padding: '30px 0px 0px 0px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '.field-title-3': {
    fontSize: 14,
    fontWeight: 500,
    padding: '30px 0px 0px 0px',
    Align: 'center',
    color: '#020202',
  },
  '.status-header': {
    border: '2px solid #E3E3E3',
    borderRadius: '6px',
    padding: '6px',
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  '.parent-status': {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  '.tab-label': {
    width: '29px',
    height: '16px',
    margin: '0px 5px',

    fontSize: '13px',
    color: '#FFFFFF',
    borderRadius: '3px',
  },
  '.tab-label-background-gray': {
    backgroundColor: '#B7B4B4',
  },
  '.tab-label-background-blue': {
    backgroundColor: '#0160B9',
  },
}));

const DraftWorkorder = (props: any) => {
  const { state } = useLocation();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));

  const [taskListType, setTaskListType] = useState('normal');
  const [value, setValue] = useState(state?.activeTab ? state?.activeTab : '1');
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const handleDeleteOpen = () => setDeleteRecord(true);
  const handleDeleteClose = () => setDeleteRecord(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorModalOpen = () => setIsErrorModalOpen(true);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { mutate: readNotification } = useUpdateNotification();
  const handleOpensTask = () => setOpenTask(true);
  const handleCloseTask = () => setOpenTask(false);

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [isHandoverModalOpen, setIsHandoverModalOpen] = React.useState(false);
  const [isReturnItemsModalOpen, setIsReturnItemsModalOpen] = React.useState(false);
  const [imageInfo, setImageInfo] = React.useState({});
  const handleImageModalOpen = () => setIsImageModalOpen(true);
  const handleImageModalClose = () => setIsImageModalOpen(false);
  const handleHandoverModalOpen = () => setIsHandoverModalOpen(true);
  const handleHandoverModalClose = () => setIsHandoverModalOpen(false);
  const handleReturnItemsModalOpen = () => setIsReturnItemsModalOpen(true);
  const handleReturnItemsModalClose = () => setIsReturnItemsModalOpen(false);
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const roleData = userRole?.data?.user?.userPermission
  const handoverPermissions =roleData?.find((data)=> data?.userModule === "handoverItem" && data)
  const returnPermissions =roleData?.find((data)=> data?.userModule === "returnItem" && data)
  const allPermissions =roleData?.find((data)=> data?.userModule === "all" && data)
  const [openTask, setOpenTask] = useState(false);
  const [taskType, setTaskType] = useState('');
  const [showApproval, setShowApproval] = useState(false);
  const handleOpens = () => setShowApproval(true);
  const handleClose = () => setShowApproval(false);
  const [isInventoryOpen, setIsInvenotryOpen] = useState(false);
  const handleInventoryOpen = () => setIsInvenotryOpen(true);
  const handleInventoryClose = () => setIsInvenotryOpen(false);
  const [inventoryModalData, setInventoryModalData] = useState({});
  const params = useParams();
  const { id } = params;
  const [page, setPage] = useState(1);

  const { mutate: deleteTaskWorkOrder } = useDeleteTaskWorkOrder();
  const { mutate: submitWorkOrder } = useSubmitWorkorder();
  const { mutate: handoverItemsTaskWorkOrder } = useHandoverItemsTaskWorkOrder();
  const { mutate: returnItemsTaskWorkOrder } = useReturnItemsTaskWorkOrder();
  const {
    data: taskWorkOrderinfo,
  }: any = useGetTaskWorkOrder(true, page, id, taskListType);
  const taskList = taskWorkOrderinfo?.results || [];
  const [maintenanceDetails, setMaintenanceDetails] = useState<any>();
  const [DetailsOpen, setDetailsOpen] = useState(false);
  const handleDetailsClose = () => {
    setDetailsOpen(false);
  };
  const handleDetailsClickOpen = () => {
    setDetailsOpen(true);
  };
  const [taskId, setTaskId] = useState('');
  const [taskDetailData, setTaskDetailData] = useState<any>({});
  const { currentSchedule, requestCount } = props;
  const { maintenanceType } = currentSchedule || {};
  const activeRadio = !maintenanceType
    ? false
    : maintenanceType == 'inhouse'
    ? false
    : maintenanceType == 'periodic'
    ? true
    : false;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { data: workoredrData } = useGetWorkorder(id, !!id);
  
  const workorderNew = workoredrData?.workOrder

  useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
    value == 1
      ? setTaskListType('normal')
      : value == 2
      ? setTaskListType('rework')
      : value == 3
      ? setTaskListType('deviation')
      : setTaskListType('normal');
  }, [value]);
 
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={null}
        pageTitle={
          workoredrData?.workOrder?.title
            ? `${workoredrData?.workOrder?.number ? workoredrData?.workOrder?.number :'-'} : ${workoredrData?.workOrder?.title}`
            : '-'
        }
        backToLink={null}
      />
      <HeadBoxWrapper className='main-padding'>
        <GridWrapper container spacing={0}>
          <Grid item xs={12} height={isMdScreen ? '250px' : isLGScreen ? '140px' : '150px'}>
            <TaskWorkorder workoredrData={workoredrData} />
          </Grid>
          <Grid item xs={12}>
            <Box className=''>
              <TabContext value={value}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className='tab-list-box'>
                      <TabList onChange={handleChange} aria-label='lab API tabs example'>
                        <Tab
                          label={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              Tasks
                              <Box
                                className={`tab-label ${
                                  value == 1
                                    ? 'tab-label-background-blue'
                                    : 'tab-label-background-gray'
                                }`}
                              >
                                {' '}
                                {
                                  taskList?.filter((task) => task?.taskStatus === 'completed')
                                    ?.length
                                }
                                {'/'}
                                {value == 1 ? taskWorkOrderinfo?.totalResults : 0}
                              </Box>
                            </Box>
                          }
                          value='1'
                          className='tab-head'
                        />
                        <Tab
                          label={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              Rework
                              <Box
                                className={`tab-label ${
                                  value == 2
                                    ? 'tab-label-background-blue'
                                    : 'tab-label-background-gray'
                                }`}
                              >
                                {' '}
                                {value == 2 ? taskWorkOrderinfo?.totalResults : 0}
                              </Box>
                            </Box>
                          }
                          value='2'
                          className='tab-head'
                        />
                        <Tab
                          label={
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              Deviation
                              <Box
                                className={`tab-label ${
                                  value == 3
                                    ? 'tab-label-background-blue'
                                    : 'tab-label-background-gray'
                                }`}
                              >
                                {value == 3 ? taskWorkOrderinfo?.totalResults : 0}
                              </Box>
                            </Box>
                          }
                          value='3'
                          className='tab-head'
                        />
                      </TabList>
                    </Box>
                  </Grid>
                </Grid>
                <Box display={'flex'} mt={'10px'} mb={'10px'} justifyContent={'space-between'}>
                  <Typography
                    display={'flex'}
                    className='title-typography'
                  >
                    Task List
                  </Typography>
                  <Box className='task-heading-list'>
                    {taskWorkOrderinfo?.totalResults > 0 && (
                      <Box className='parent-status'>
                        <Typography sx={{ fontWeight: 600 }}>Summary :</Typography>
                        <Typography className='status-header'>
                          {' '}
                          <MdCircle color='#FFCF95' /> in-progress{' '}
                        </Typography>
                        <Typography className='status-header'>
                          {' '}
                          <MdCircle color='#1AD04C' /> Completed{' '}
                        </Typography>
                        <Typography className='status-header'>
                          {' '}
                          <MdCircle color='#F00505' /> Action needed{' '}
                        </Typography>
                        <Typography className='status-header'>
                          {' '}
                          <MdOutlineCircle /> Not started{' '}
                        </Typography>
                      </Box>
                    )}
                    {(workoredrData?.workOrder?.workOrderStatus == 'draft' ||
                      (workoredrData?.workOrder?.workOrderStatus === 'rework' &&
                        taskListType == 'rework')) && (
                      <Box display={'flex'}>
                        <CustomButton
                          variant='contained'
                          className={'blue-bg'}
                          onClick={() => {
                            setMaintenanceDetails({});
                            setTaskId('');
                            handleDetailsClickOpen();
                          }}
                        >
                          <Plus fill={'#FFFFFF'} /> <span className='ml'></span>
                          Add New Task
                        </CustomButton>
                      </Box>
                    )}
                  </Box>
                </Box>

                <TaskDetailsModal
                  requestCount={requestCount}
                  taskListType={taskListType}
                  changeRequestCount={(e) => {
                    console.log(e);
                  }}
                  setDetailsOpen={setDetailsOpen}
                  editinhouseData={maintenanceDetails}
                  isDetailsOpen={DetailsOpen}
                  addScheduleData={(e) => {
                    console.log(e);
                  }}
                  taskId={taskId}
                  setCurrentSchedule={setMaintenanceDetails}
                  DetailsHandleClose={handleDetailsClose}
                />
                <TabPanel value='1' className='tab-panel'>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Grid container item xs={12} className='process-header'>
                            <Grid item xs={0.5} textAlign={'center'}>
                              <Typography className='process-header-field-title'>#</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Face</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className='process-header-field-title'>
                                Operation Details
                              </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                              <Typography className='process-header-field-title'>
                                Tool Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Time</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-new-file'>Program File</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Inspection
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Status</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Action</Typography>
                            </Grid>
                          </Grid>
                          {taskList?.map((data, ind) => {
                            const handleClick = (e) => {
                              navigate(`/details-work-order/${workorderNew.id}`)
                              e.stopPropagation();
                            };
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                key={ind}
                                className='border-items'
                              >
                                <Grid item xs={0.5} className='draft-m1'>
                                  <Grid item xs={3.5} textAlign={'center'}>
                                    <Typography
                                      sx={{
                                        height: '85px',
                                        width: '5px',
                                        borderRadius: '10px',
                                        background:
                                          data?.mainTaskType == 'out-sourced'
                                            ? '#0160B9'
                                            : data?.taskStatus == 'completed'
                                            ? '#1AD04C'
                                            : data?.taskStatus == 'created'
                                            ? '#FFCF95'
                                            : data?.taskStatus == 'ongoing'
                                            ? '#FF9900'
                                            : '#000',
                                        m: '4px',
                                      }}
                                    ></Typography>
                                  </Grid>
                                  <Box textAlign={'center'}>
                                    <Typography className='field-title-3'>
                                      {data?.numericIndex}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box onClick={handleClick} sx={{cursor: 'pointer'}}>
                                    <Typography className='field-title-4'>
                                      {_.get(data, 'nodeInfo.face', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2} className='draft-m1'>
                                  <Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operation:</Typography>
                                      <Typography className='field-title-2'>
                                        {data?.nodeInfo?.stepName}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operator:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.worker, 'name', '-')}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Machine:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.machine, 'name', '-')}
                                      </Typography>
                                      
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'name', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'number', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'quantity', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2.5} className='draft-m1'>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolHolderItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolHolderItemId?.toolCategoryId && (
                                        <IoImageOutline
                                        cursor={'pointer'}
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolHolderItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolHolderItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolTypeItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolTypeItemId?.toolCategoryId && (
                                        <IoImageOutline
                                        cursor={'pointer'}
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolTypeItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolTypeItemId?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolInsertItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolInsertItemId?.toolCategoryId && (
                                        <IoImageOutline
                                        cursor={'pointer'}
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolInsertItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolInsertItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data?.nodeInfo?.accessories, 'name', '')}
                                    </Typography>
                                    {data?.nodeInfo?.toolAccessoriesItemId?.map(
                                      (item, index) =>
                                          <Typography key={index} className='field-title-2'>
                                            {item?.toolCategoryId?.name || '-'}{' '}
                                            {item?.uploadedImages?.length > 0 && (
                                              <IoImageOutline
                                              cursor={'pointer'}
                                                onClick={() => {
                                                  if (item?.uploadedImages?.length > 0) {
                                                    handleImageModalOpen();
                                                    setImageInfo(
                                                      item?.uploadedImages?.[0]
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>
                                    {data.expected
                                    ? moment(data.expected).format('hh:mm')
                                    : '-'}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>{data?.nodeInfo?.isProgramFileName?.toString() === 'false' ? 'N/A' : 'Yes'}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography className='field-title'>
                                    {data?.nodeInfo?.isInspectionRecordNeeded ? 'Yes' : 'N/A'}
                                  </Typography>
                                  <Box className='dimensions-parent'>
                                    {data?.nodeInfo?.dimensions?.map((data, index) => {
                                      return (
                                        <Typography key={index} className='field-title'>
                                          {data}
                                        </Typography>
                                      );
                                    })}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-7'>
                                      <IoImageOutline
                                        color={
                                          data?.nodeInfo?.uploadedData?.length <= 0 ? '#757575' : ''
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          data?.nodeInfo?.instructions
                                            ? 'Instructions : ' + data?.nodeInfo?.instructions
                                            : 'Instructions : ' + '-'
                                        }
                                      >
                                        <InfoOutlinedIcon />
                                      </Tooltip>
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  onClick={() => {
                                    if (
                                      ((data?.taskStatus == 'completed' &&
                                        data?.nodeInfo?.iApprovalNeeded &&
                                        data?.isApproved === 0) ||
                                        (data?.mainTaskType == 'out-sourced' &&
                                          data?.taskStatus == 'created')) &&
                                      data?.isListing &&
                                      workoredrData?.workOrder?.workOrderStatus !== 'draft' &&
                                      workoredrData?.workOrder?.isApproved === 1
                                    ) {
                                      handleOpens();
                                      setTaskType(data?.mainTaskType);
                                      setTaskId(data?.id);
                                    }
                                  }}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {data?.mainTaskType == 'in-house' ? (
                                      <Tooltip
                                        title={data?.mainTaskType == 'in-house' && 'In House Task'}
                                      >
                                        <Typography className='field-title-8'>
                                          <img src={InhouseTask} height={'23px'} width={'23px'} />
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={
                                          data?.mainTaskType == 'out-sourced' && 'Out Sourced Task'
                                        }
                                      >
                                        <Typography className='field-title-8'>
                                          <img
                                            src={Outsourcedtask}
                                            height={'26px'}
                                            width={'26px'}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    )}
                                    <Tooltip title={data?.taskStatus}>
                                      <Typography className='field-title-8'>
                                        {data?.taskStatus == 'completed' ? (
                                          <RiFileList2Line style={{ color: '#1AD04C' }} />
                                        ) :
                                        data?.taskStatus == 'created' ? (
                                          <RiFileList2Line style={{ color: '#FFCF95' }} />
                                        ) :
                                        data?.taskStatus == 'ongoing' ? (
                                          <RiFileList2Line style={{ color: '#FF9900' }} />
                                        ) : (
                                          ''
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                {(workoredrData?.workOrder?.workOrderStatus === 'draft') ? (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box
                                        pr={'10px'}
                                        onClick={() => {
                                          setTaskId(data.id);
                                          handleDetailsClickOpen();
                                        }}
                                      >
                                        <EditIcon_new />
                                      </Box>

                                      <Box
                                        onClick={() => {
                                          setCurrentId(data.id);
                                          handleDeleteOpen();
                                        }}
                                      >
                                        <DeleteIcon_new />
                                      </Box>
                                    </Box>
                                    {/* ----------------draft------------------  */}
                                    {(allPermissions?.userModule === 'all' || (handoverPermissions?.add && handoverPermissions?.view)) &&
                                     <Box className='table-icons'>
                                     {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                        handleHandoverModalOpen();
                                        const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                      })}>
                                        Handover
                                      </Box> :  <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600} >
                                        Handover
                                     </Box> }
                                     </Box>}
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) && 
                                      <Box className='table-icons'>
                                      {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                      handleReturnItemsModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Return
                                      </Box> :  <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Return
                                     </Box> }
                                    </Box>}
                                    <Box className='table-icons'>
                                      <Box pr={'10px'} pt={'10px'} pb={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                          navigate('/add-ticket');
                                      })}>
                                        Report issue
                                      </Box>
                                    </Box>
                                  </Grid>
                                ) : (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box pr={'10px'}>
                                        <EditIcon_new color='#757575' />
                                      </Box>
                                      <Box >
                                        <DeleteIcon_new color='#757575' />
                                      </Box>
                                    </Box>
                                    {(allPermissions?.userModule === 'all' || (handoverPermissions?.add && handoverPermissions?.view)) &&
                                    <Box className='table-icons'>
                                     {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                        handleHandoverModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Handover
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                        Handover
                                     </Box>}
                                    </Box>
                                    }
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) && <Box className='table-icons'>
                                      {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} 
                                        onClick={(()=>{
                                        handleReturnItemsModalOpen();
                                        const  obj = {
                                            toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                            toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                            toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                            toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                            accessories: data?.nodeInfo?.accessories,
                                          }
                                          setTaskId(data?.id);
                                          setTaskDetailData(obj);
                                        })}>
                                        Return
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Return
                                     </Box>}
                                    </Box>}
                                    <Box className='table-icons'>
                                      <Box pr={'10px'} pt={'10px'} pb={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                          navigate('/add-ticket');
                                      })}>
                                        Report issue
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })}
                          {taskWorkOrderinfo?.totalResults > 0 && (
                            <Box className='pagination-one'>
                              <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
                                Showing 1 to 5 of {taskWorkOrderinfo?.totalResults}
                              </Typography>
                              <Pagination
                                count={
                                  taskWorkOrderinfo?.totalPages <= 0
                                    ? 1
                                    : taskWorkOrderinfo?.totalPages
                                }
                                onChange={(e, value) => {
                                  setPage(value);
                                }}
                                page={page}
                                color='primary'
                              />
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'draft' &&
                            taskList?.length > 0 && (
                              <Box className='button-box'>
                                <Box className='footer-padding'>
                                  <CustomButton
                                    variant='contained'
                                    className={'blue-bg'}
                                    type='submit'
                                    onClick={() => {
                                      submitWorkOrder({ id: id });
                                    }}
                                  >
                                    {'Submit'}
                                  </CustomButton>
                                </Box>
                              </Box>
                            )}
                          {workoredrData?.workOrder?.workOrderStatus == 'qc-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate('/quality-check-work-order', { state: { id: id } });
                                  }}
                                >
                                  {'Quality Inspection'}
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'final-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate(`/details-work-order/${id}`);
                                  }}
                                >
                                  Next
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value='2' className='tab-panel'>
                  <Grid container spacing={0}>
                  <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Grid container item xs={12} className='process-header'>
                            <Grid item xs={0.5} textAlign={'center'}>
                              <Typography className='process-header-field-title'>#</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Face</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className='process-header-field-title'>
                                Operation Details
                              </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                              <Typography className='process-header-field-title'>
                                Tool Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Time</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-new-file'>Program File</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Inspection
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Status</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Action</Typography>
                            </Grid>
                          </Grid>
                          {taskList?.map((data, ind) => {
                            const handleClick = (e) => {
                              navigate(`/details-work-order/${workorderNew.id}`)
                              e.stopPropagation();
                            };
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                key={ind}
                                className='border-items'
                              >
                                <Grid item xs={0.5} className='draft-m1'>
                                  <Grid item xs={3.5} textAlign={'center'}>
                                    <Typography
                                      sx={{
                                        height: '85px',
                                        width: '5px',
                                        borderRadius: '10px',
                                        background:
                                          data?.mainTaskType == 'out-sourced'
                                            ? '#0160B9'
                                            : data?.taskStatus == 'completed'
                                            ? '#1AD04C'
                                            : data?.taskStatus == 'created'
                                            ? '#FFCF95'
                                            : data?.taskStatus == 'ongoing'
                                            ? '#FF9900'
                                            : '#000',
                                        m: '4px',
                                      }}
                                    ></Typography>
                                  </Grid>
                                  <Box textAlign={'center'}>
                                    <Typography className='field-title-3'>
                                      {data?.numericIndex}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box onClick={handleClick} sx={{cursor: 'pointer'}}>
                                    <Typography className='field-title-4'>
                                      {_.get(data, 'nodeInfo.face', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2} className='draft-m1'>
                                  <Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operation:</Typography>
                                      <Typography className='field-title-2'>
                                        {data?.nodeInfo?.stepName}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operator:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.worker, 'name', '-')}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Machine:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.machine, 'name', '-')}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'name', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'number', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'quantity', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2.5} className='draft-m1'>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolHolderItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolHolderItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolHolderItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolHolderItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolTypeItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolTypeItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolTypeItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolTypeItemId?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolInsertItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolInsertItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolInsertItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolInsertItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data?.nodeInfo?.accessories, 'name', '')}
                                    </Typography>
                                    {data?.nodeInfo?.toolAccessoriesItemId?.map(
                                      (item, index) =>
                                        index === 0 && (
                                          <Typography key={index} className='field-title-2'>
                                            {item?.toolCategoryId?.name || '-'}{' '}
                                            {item?.uploadedImages?.length > 0 && (
                                              <IoImageOutline
                                                onClick={() => {
                                                  if (item?.uploadedImages?.length > 0) {
                                                    handleImageModalOpen();
                                                    setImageInfo(
                                                      item?.uploadedImages?.[0]
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          </Typography>
                                        )
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>{data.expected
                                    ? moment(data.expected).format('hh:mm')
                                    : '-'}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>{data?.nodeInfo?.isProgramFileName?.toString() === 'false' ? 'N/A' : data?.nodeInfo?.isProgramFileName?.toString()}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography className='field-title'>
                                    {data?.nodeInfo?.isInspectionRecordNeeded ? 'Y' : 'N'}
                                  </Typography>
                                  <Box className='dimensions-parent'>
                                    {data?.nodeInfo?.dimensions?.map((data, index) => {
                                      return (
                                        <Typography key={index} className='field-title'>
                                          {data}
                                        </Typography>
                                      );
                                    })}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-7'>
                                      <IoImageOutline
                                        color={
                                          data?.nodeInfo?.uploadedData?.length <= 0 ? '#757575' : ''
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          data?.nodeInfo?.instructions
                                            ? 'Instructions : ' + data?.nodeInfo?.instructions
                                            : 'Instructions : ' + '-'
                                        }
                                      >
                                        <InfoOutlinedIcon />
                                      </Tooltip>
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  onClick={() => {
                                    if (
                                      ((data?.taskStatus == 'completed' &&
                                        data?.nodeInfo?.iApprovalNeeded &&
                                        data?.isApproved === 0) ||
                                        (data?.mainTaskType == 'out-sourced' &&
                                          data?.taskStatus == 'created')) &&
                                      data?.isListing &&
                                      workoredrData?.workOrder?.workOrderStatus !== 'draft' &&
                                      workoredrData?.workOrder?.isApproved === 1
                                    ) {
                                      handleOpens();
                                      setTaskType(data?.mainTaskType);
                                      setTaskId(data?.id);
                                    }
                                  }}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {data?.mainTaskType == 'in-house' ? (
                                      <Tooltip
                                        title={data?.mainTaskType == 'in-house' && 'In House Task'}
                                      >
                                        <Typography className='field-title-8'>
                                          <img src={InhouseTask} height={'23px'} width={'23px'} />
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={
                                          data?.mainTaskType == 'out-sourced' && 'Out Sourced Task'
                                        }
                                      >
                                        <Typography className='field-title-8'>
                                          <img
                                            src={Outsourcedtask}
                                            height={'26px'}
                                            width={'26px'}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    )}
                                    <Tooltip title={data?.taskStatus}>
                                      <Typography className='field-title-8'>
                                        {data?.taskStatus == 'completed' ? (
                                          <RiFileList2Line style={{ color: '#1AD04C' }} /> 
                                        ) :
                                        data?.taskStatus == 'created' ? (
                                          <RiFileList2Line style={{ color: '#FFCF95' }} />
                                        ) :
                                        data?.taskStatus == 'ongoing' ? (
                                          <RiFileList2Line style={{ color: '#FF9900' }} />
                                        ) : (
                                          ''
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                {workoredrData?.workOrder?.workOrderStatus === 'draft' ? (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box
                                        pr={'10px'}
                                        onClick={() => {
                                          setTaskId(data.id);
                                          handleDetailsClickOpen();
                                        }}
                                      >
                                        <EditIcon_new />
                                      </Box>

                                      <Box
                                        onClick={() => {
                                          setCurrentId(data.id);
                                          handleDeleteOpen();
                                        }}
                                      >
                                        <DeleteIcon_new />
                                      </Box>
                                    </Box>
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) &&
                                    <Box className='table-icons'>
                                      {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                        handleHandoverModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Handover
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Handover
                                     </Box>}
                                    </Box>}
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) && 
                                    <Box className='table-icons'>
                                      {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                      handleReturnItemsModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Return
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Return
                                     </Box>}
                                    </Box>}
                                    <Box className='table-icons'>
                                      <Box pr={'10px'} pt={'10px'} pb={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                          navigate('/add-ticket');
                                      })}>
                                        Report issue
                                      </Box>
                                    </Box>
                                  </Grid>
                                ) : (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box pr={'10px'}>
                                        <EditIcon_new color='#757575' />
                                      </Box>
                                      <Box>
                                        <DeleteIcon_new color='#757575' />
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })}
                          {taskWorkOrderinfo?.totalResults > 0 && (
                            <Box className='pagination-one'>
                              <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
                                Showing 1 to 5 of {taskWorkOrderinfo?.totalResults}
                              </Typography>
                              <Pagination
                                count={
                                  taskWorkOrderinfo?.totalPages <= 0
                                    ? 1
                                    : taskWorkOrderinfo?.totalPages
                                }
                                onChange={(e, value) => {
                                  setPage(value);
                                }}
                                page={page}
                                color='primary'
                              />
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'draft' &&
                            taskList?.length > 0 && (
                              <Box className='button-box'>
                                <Box className='footer-padding'>
                                  <CustomButton
                                    variant='contained'
                                    className={'blue-bg'}
                                    type='submit'
                                    onClick={() => {
                                      submitWorkOrder({ id: id });
                                    }}
                                  >
                                    {'Submit'}
                                  </CustomButton>
                                </Box>
                              </Box>
                            )}
                          {workoredrData?.workOrder?.workOrderStatus == 'qc-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate('/quality-check-work-order', { state: { id: id } });
                                  }}
                                >
                                  {'Quality Inspection'}
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'final-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate(`/details-work-order/${id}`);
                                  }}
                                >
                                  Next
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value='3' className='tab-panel'>
                  <Grid container spacing={0}>
                  <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Grid container item xs={12} className='process-header'>
                            <Grid item xs={0.5} textAlign={'center'}>
                              <Typography className='process-header-field-title'>#</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Face</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className='process-header-field-title'>
                                Operation Details
                              </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                              <Typography className='process-header-field-title'>
                                Tool Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Time</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-new-file'>Program File</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Inspection
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>
                                Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Status</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography className='process-header-field-title'>Action</Typography>
                            </Grid>
                          </Grid>
                          {taskList?.map((data, ind) => {
                            const handleClick = (e) => {
                              navigate(`/details-work-order/${workorderNew.id}`)
                              e.stopPropagation();
                            };
                            return (
                              <Grid
                                container
                                item
                                xs={12}
                                key={ind}
                                className='border-items'
                              >
                                <Grid item xs={0.5} className='draft-m1'>
                                  <Grid item xs={3.5} textAlign={'center'}>
                                    <Typography
                                      sx={{
                                        height: '85px',
                                        width: '5px',
                                        borderRadius: '10px',
                                        background:
                                          data?.mainTaskType == 'out-sourced'
                                            ? '#0160B9'
                                            : data?.taskStatus == 'completed'
                                            ? '#1AD04C'
                                            : data?.taskStatus == 'created'
                                            ? '#FFCF95'
                                            : data?.taskStatus == 'ongoing'
                                            ? '#FF9900'
                                            : '#000',
                                        m: '4px',
                                      }}
                                    ></Typography>
                                  </Grid>
                                  <Box textAlign={'center'}>
                                    <Typography className='field-title-3'>
                                      {data?.numericIndex}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box onClick={handleClick} sx={{cursor: 'pointer'}}>
                                    <Typography className='field-title-4'>
                                      {_.get(data, 'nodeInfo.face', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2} className='draft-m1'>
                                  <Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operation:</Typography>
                                      <Typography className='field-title-2'>
                                        {data?.nodeInfo?.stepName}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Operator:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.worker, 'name', '-')}
                                      </Typography>
                                    </Box>
                                    <Box className='box-draft-m1'>
                                      <Typography className='field-title-5'>Machine:</Typography>
                                      <Typography className='field-title-2'>
                                        {_.get(data?.nodeInfo?.machine, 'name', '-')}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'name', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'number', '')}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data, 'quantity', '')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={2.5} className='draft-m1'>
                                  <Box>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolHolderItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolHolderItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolHolderItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolHolderItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolTypeItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolTypeItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolTypeItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolTypeItemId?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(
                                        data?.nodeInfo?.toolInsertItemId?.toolCategoryId,
                                        'name',
                                        '-'
                                      )}{' '}
                                      {data?.nodeInfo?.toolInsertItemId?.toolCategoryId && (
                                        <IoImageOutline
                                          onClick={() => {
                                            if (
                                              data?.nodeInfo?.toolInsertItemId?.uploadedImages
                                                ?.length > 0
                                            ) {
                                              handleImageModalOpen();
                                              setImageInfo(
                                                data?.nodeInfo?.toolInsertItemId
                                                  ?.uploadedImages?.[0]
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography className='field-title-2'>
                                      {_.get(data?.nodeInfo?.accessories, 'name', '')}
                                    </Typography>
                                    {data?.nodeInfo?.toolAccessoriesItemId?.map(
                                      (item, index) =>
                                        index === 0 && (
                                          <Typography key={index} className='field-title-2'>
                                            {item?.toolCategoryId?.name || '-'}{' '}
                                            {item?.uploadedImages?.length > 0 && (
                                              <IoImageOutline
                                                onClick={() => {
                                                  if (item?.uploadedImages?.length > 0) {
                                                    handleImageModalOpen();
                                                    setImageInfo(
                                                      item?.uploadedImages?.[0]
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          </Typography>
                                        )
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>{data.expected
                                    ? moment(data.expected).format('hh:mm')
                                    : '-'}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-6'>{data?.nodeInfo?.isProgramFileName?.toString() === 'false' ? 'N/A' : data?.nodeInfo?.isProgramFileName?.toString()}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography className='field-title'>
                                    {data?.nodeInfo?.isInspectionRecordNeeded ? 'Y' : 'N'}
                                  </Typography>
                                  <Box className='dimensions-parent'>
                                    {data?.nodeInfo?.dimensions?.map((data, index) => {
                                      return (
                                        <Typography key={index} className='field-title'>
                                          {data}
                                        </Typography>
                                      );
                                    })}
                                  </Box>
                                </Grid>
                                <Grid item xs={1}>
                                  <Box>
                                    <Typography className='field-title-7'>
                                      <IoImageOutline
                                        color={
                                          data?.nodeInfo?.uploadedData?.length <= 0 ? '#757575' : ''
                                        }
                                      />
                                      <Tooltip
                                        title={
                                          data?.nodeInfo?.instructions
                                            ? 'Instructions : ' + data?.nodeInfo?.instructions
                                            : 'Instructions : ' + '-'
                                        }
                                      >
                                        <InfoOutlinedIcon />
                                      </Tooltip>
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  onClick={() => {
                                    if (
                                      ((data?.taskStatus == 'completed' &&
                                        data?.nodeInfo?.iApprovalNeeded &&
                                        data?.isApproved === 0) ||
                                        (data?.mainTaskType == 'out-sourced' &&
                                          data?.taskStatus == 'created')) &&
                                      data?.isListing &&
                                      workoredrData?.workOrder?.workOrderStatus !== 'draft' &&
                                      workoredrData?.workOrder?.isApproved === 1
                                    ) {
                                      handleOpens();
                                      setTaskType(data?.mainTaskType);
                                      setTaskId(data?.id);
                                    }
                                  }}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {data?.mainTaskType == 'in-house' ? (
                                      <Tooltip
                                        title={data?.mainTaskType == 'in-house' && 'In House Task'}
                                      >
                                        <Typography className='field-title-8'>
                                          <img src={InhouseTask} height={'23px'} width={'23px'} />
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={
                                          data?.mainTaskType == 'out-sourced' && 'Out Sourced Task'
                                        }
                                      >
                                        <Typography className='field-title-8'>
                                          <img
                                            src={Outsourcedtask}
                                            height={'26px'}
                                            width={'26px'}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    )}
                                    <Tooltip title={data?.taskStatus}>
                                      <Typography className='field-title-8'>
                                        {data?.taskStatus == 'completed' ? (
                                          <RiFileList2Line style={{ color: '#1AD04C' }} />
                                        ) :
                                        data?.taskStatus == 'created' ? (
                                          <RiFileList2Line style={{ color: '#FFCF95' }} />
                                        ) :
                                        data?.taskStatus == 'ongoing' ? (
                                          <RiFileList2Line style={{ color: '#FF9900' }} />
                                        ) : (
                                          ''
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                {workoredrData?.workOrder?.workOrderStatus === 'draft' ? (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box
                                        pr={'10px'}
                                        onClick={() => {
                                          setTaskId(data.id);
                                          handleDetailsClickOpen();
                                        }}
                                      >
                                        <EditIcon_new />
                                      </Box>
                                      <Box
                                        onClick={() => {
                                          setCurrentId(data.id);
                                          handleDeleteOpen();
                                        }}
                                      >
                                        <DeleteIcon_new />
                                      </Box>
                                    </Box>
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) &&
                                    <Box className='table-icons'>
                                      {workorderNew?.isExecute ? <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                        handleHandoverModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Handover
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Handover
                                     </Box>}
                                    </Box>}
                                    {(allPermissions?.userModule === 'all' || (returnPermissions?.add && returnPermissions?.view)) &&
                                    <Box className='table-icons'>
                                      {workorderNew?.isExecute ?<Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                      handleReturnItemsModalOpen();
                                      const  obj = {
                                          toolAccessoriesItemId: data?.nodeInfo?.toolAccessoriesItemId,
                                          toolHolderItemId: data?.nodeInfo?.toolHolderItemId,
                                          toolInsertItemId: data?.nodeInfo?.toolInsertItemId,
                                          toolTypeItemId: data?.nodeInfo?.toolTypeItemId,
                                          accessories: data?.nodeInfo?.accessories,
                                        }
                                        setTaskId(data?.id);
                                        setTaskDetailData(obj);
                                        
                                      })}>
                                        Return
                                      </Box> : <Box pr={'10px'} pt={'10px'} fontSize={'14px'} color='#757575' fontWeight={600}>
                                      Return
                                     </Box>}
                                    </Box>}
                                    <Box className='table-icons'>
                                      <Box pr={'10px'}
                                        pb={'10px'} fontSize={'14px'} color='#265AA8' fontWeight={600} onClick={(()=>{
                                          navigate('/add-ticket');
                                      })}>
                                        Report issue
                                      </Box>
                                    </Box>
                                  </Grid>
                                ) : (
                                  <Grid item xs={1}>
                                    <Box className='table-icons'>
                                      <Box pr={'10px'} pt={'30px'}>
                                        <EditIcon_new color='#757575' />
                                      </Box>
                                      <Box>
                                        <DeleteIcon_new color='#757575' />
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })}
                          {taskWorkOrderinfo?.totalResults > 0 && (
                            <Box className='pagination-one'>
                              <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
                                Showing 1 to 5 of {taskWorkOrderinfo?.totalResults}
                              </Typography>
                              <Pagination
                                count={
                                  taskWorkOrderinfo?.totalPages <= 0
                                    ? 1
                                    : taskWorkOrderinfo?.totalPages
                                }
                                onChange={(e, value) => {
                                  setPage(value);
                                }}
                                page={page}
                                color='primary'
                              />
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'draft' &&
                            taskList?.length > 0 && (
                              <Box className='button-box'>
                                <Box className='footer-padding'>
                                  <CustomButton
                                    variant='contained'
                                    className={'blue-bg'}
                                    type='submit'
                                    onClick={() => {
                                      submitWorkOrder({ id: id });
                                    }}
                                  >
                                    {'Submit'}
                                  </CustomButton>
                                </Box>
                              </Box>
                            )}
                          {workoredrData?.workOrder?.workOrderStatus == 'qc-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate('/quality-check-work-order', { state: { id: id } });
                                  }}
                                >
                                  {'Quality Inspection'}
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                          {workoredrData?.workOrder?.workOrderStatus == 'final-approval' && (
                            <Box className='button-box'>
                              <Box className='footer-padding'>
                                <CustomButton
                                  variant='contained'
                                  className={'blue-bg'}
                                  type='submit'
                                  onClick={() => {
                                    navigate(`/details-work-order/${id}`);
                                  }}
                                >
                                  Next
                                </CustomButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>

          {taskList?.map((data, ind) => {
            return (
              <Grid key={ind}>
                {openTask && (
                  <TaskDetailModal
                    handleInventory={handleInventoryOpen}
                    setInventoryModalData={setInventoryModalData}
                    taskData={data?.id}
                    isOpen={openTask}
                    handleClose={handleCloseTask}
                    singleTasks={undefined}
                  />
                )}
              </Grid>
            );
          })}
          {value === '1' && deleteRecord && currentId && (
            <DeleteWarningModal
              open={deleteRecord}
              handleOpen={handleDeleteOpen}
              handleClose={handleDeleteClose}
              handleDelete={deleteTaskWorkOrder}
              id={currentId}
            />
          )}
          {value === '3' && errorMessage && (
            <ErrorPopup
              isOpen={isErrorModalOpen}
              handleClose={handleErrorModalClose}
              errorDetails={errorMessage}
            />
          )}
        </GridWrapper>
        <WorkOrderApproval
          isOpen={showApproval}
          handleClose={handleClose}
          id={id}
          mainTaskType={taskType}
          isTaskApproval={true}
          taskId={taskId}
        />
        {isImageModalOpen && (
          <ViewImage
            imageInfo={imageInfo}
            isOpen={isImageModalOpen}
            handleClose={handleImageModalClose}
          />
        )}
        
          {
            isHandoverModalOpen && id && (
            <HandoverItemsModal
              open={isHandoverModalOpen}
              handleClose={handleHandoverModalClose}
              handleItemsTask={handoverItemsTaskWorkOrder}
              taskDetailData={taskDetailData}
              title={'Handover an Item'}
              taskId={taskId}
              id={id}
            />
          )}
          {
            isReturnItemsModalOpen && id && (
            <HandoverItemsModal
              open={isReturnItemsModalOpen}
              handleClose={handleReturnItemsModalClose}
              handleItemsTask={returnItemsTaskWorkOrder}
              taskDetailData={taskDetailData}
              title={'Return an Item'}
              taskId={taskId}
              id={id}
            />
          )}
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBoxWrapper>
    </React.Fragment>
  );
};

export default DraftWorkorder;
