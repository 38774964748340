import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const CustomSubTitle = styled(Typography)(() => ({
  fontSize: '16px !important',
  fontWeight: '600 !important',
  lineHeight: '21px !important',
  color: '#1D1B1B !important',
  background: '#F6F6F6 !important',
  padding: '8px 24px 8px 24px !important',
  marginTop: '24px !important',
}));

export default CustomSubTitle;
