import { PartErrI, PartI } from '../Types/part';
import { get, post, patch, deleteRequest } from '../Utils/index';

export const getSingleTaskWorkOrder = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/task/${id}`).then((res) => res.data);
};

export const getTaskWorkOrder = async (
  data
): Promise<any | undefined | { parts: { results: [] } } | any> => {
  const page = data?.queryKey[1] || 1;
  const id = data?.queryKey[2];
  const taskListType = data?.queryKey[3];
  const payload = { page, limit: 4, taskListType: taskListType };
  return get(`/customer/task/task-list/${id}`, payload).then((res) => {
    return res.data;
  });
};

export const addTaskWorkOrder = async (data) => {
  return post(`/customer/task`, data);
};

export const handoverItemsTaskWorkOrder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return post(`/customer/task/handover-items/${id}`, data);
};

export const returnItemsTaskWorkOrder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/task/return-items/${id}`, data);
};

export const updateTaskWorkOrder = async (data): Promise<PartI | PartErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/task/${id}`, data).then((res) => res.data);
};
export const updateApprovalNeeed = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/task/approval-needed/${id}`, data).then((res) => res.data);
};
export const updateOutSourced = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/task/outsourced-task-completion/${id}`, data).then((res) => res.data);
};

export const deleteTaskWorkOrder = async (id) => {
  return deleteRequest(`/customer/task/${id}`);
};
export const taskWorlOrderApi = {
  getSingleTaskWorkOrder,
  getTaskWorkOrder,
  addTaskWorkOrder,
  updateTaskWorkOrder,
  updateApprovalNeeed,
  updateOutSourced,
  deleteTaskWorkOrder,
  handoverItemsTaskWorkOrder,
  returnItemsTaskWorkOrder,
};
