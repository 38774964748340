import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ErrorMessage, Form, Formik } from 'formik';
import { TextField, Typography, Select, Button } from '@mui/material';
import * as Yup from 'yup';
import { MenuItem, FormControl } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Loading from 'react-fullscreen-loading';
import { useAddWorkorder, useGetWorkorder, useUpdateWorkorder } from '../../hooks/workorderHooks';
import ErrorPopup from '../../Modals/ErrorPopup';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { DatePicker, LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const SignupSchema = Yup.object().shape({
  title: Yup.string().trim().required('Title is Required'),
  totalQuantity: Yup.number().typeError('Quantity must be a number'),
  startDate: Yup.string().required('Start date is Required'),
  selectTime: Yup.string().required('Start time is Required'),
  priorityLevel: Yup.string().required('priority level is Required'),
});

const Selects = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

// New Style using ClassName

const HeadBox = styled(Box)(() => ({
  height: '84vh !important',
  '.head-text': {
    lineHeight: '32px',
    height: '32px',
    fontSize: '21px',
    padding: '0px 0px 18px 0px',
  },
  '.title-texts': {
    fontSize: '14px',
    color: '#265AA8',
  },
  '.title-last': {
    fontSize: '34px',
    fontWeight: '700',
    color: '#265AA8',
  },
  '.title-box': {
    display: 'flex',
  },
  '.title-sub-box': {
    display: 'flex',
    alignItems: 'center',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    // padding: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    color: '#000',
  },
  '.back-arrow': {
    cursor: 'pointer',
  },
  '.mx-14': {
    margin: '0px 14px',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '9.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
    // marginRight: '14px',
  },
}));

const AddWorkRequest = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const { state } = useLocation();

  const icon = <AiOutlineInfoCircle color='red' />;

  const breadCrumbData = [
    {
      title: 'Work Orders',
      link: '/work-order',
    },
    {
      title: `New Work Request`,
      link: ``,
    },
  ];

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  const { data: workoredrData, isSuccess } = useGetWorkorder(id, !!id);

  const isViewSteps =
    window.location.pathname.startsWith('/work-order/add-procedure') ||
    window.location.pathname.startsWith('/work-order/edit-procedure')
      ? false
      : true;

  const isEditSteps = location.pathname.startsWith('/work-order/edit-procedure/') ? true : false;
  const isAddSteps = window.location.pathname.startsWith('/work-order/add-procedure/')
    ? true
    : false;
  const {
    mutate: addWorkorder,
    isError: isAddWorkorderError,
    error: addWorkorderError,
    isLoading,
  } = useAddWorkorder();
  const {
    mutate: updateWorkorder,
    isError: isUpdateWorkorderError,
    error: updateWorkorderError,
  } = useUpdateWorkorder({
    isViewSteps,
    isEditSteps,
    isAddSteps,
  });
  React.useEffect(() => {
    if (isAddWorkorderError || isUpdateWorkorderError) {
      isAddWorkorderError
        ? setErrorMessage(addWorkorderError?.data?.message)
        : setErrorMessage(updateWorkorderError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isAddWorkorderError, isUpdateWorkorderError]);

  // const { mutateAsync: addMachine, data } = useAddWorkorder();
  const singleWorkOrder: {
    title: string;
    product: {
      name: string;
      id: string;
    };
    totalQuantity: number;
    startDate: string;
    selectTime: string;
    priorityLevel: string;
  } = workoredrData?.workOrder;

  // TODO:- CUSTOM DATE PICKER
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onCancel, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Cancel', method: onCancel },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onCancel()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  // Default start time
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`New Work Request`}
        backToLink='/work-order'
      />
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            title: isEdit ? singleWorkOrder?.title : '',
            totalQuantity: isEdit ? singleWorkOrder?.totalQuantity : '',
            startDate: isEdit ? singleWorkOrder?.startDate : '',
            selectTime: isEdit ? singleWorkOrder?.selectTime : defaultDateTime,
            priorityLevel: isEdit ? singleWorkOrder?.priorityLevel : '',
          }}
          enableReinitialize={true}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const WRNumber = `WR-${
              1000 + (state?.requestCount || 0) + (state?.requestCount ? 1 : 1)
            }`;
            id
              ? updateWorkorder({ ...values, id, type: 'Work Order' })
              : addWorkorder({
                  ...values,
                  type: 'Work Order',
                  requestNumber: WRNumber,
                  workOrderStatus: 'draft',
                });
            // navigate('/add-steps');
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Title
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.title || !touched.title ? false : true}
                    placeholder='title for your Manufacturing order'
                    fullWidth
                    onChange={handleChange}
                    name='title'
                    value={values.title || ''}
                    InputProps={{
                      endAdornment: touched.title && errors.title && icon,
                    }}
                  />
                  {touched.title && errors.title && (
                    <Typography className='validation-text'>{errors.title}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Total Quantity
                    {/* <Require /> */}
                  </Typography>

                  <Input
                    error={!errors.totalQuantity || !touched.totalQuantity ? false : true}
                    placeholder='Title For your Manufacturing'
                    fullWidth
                    onChange={handleChange}
                    value={values.totalQuantity || ''}
                    name='totalQuantity'
                    InputProps={{
                      endAdornment: touched.totalQuantity && errors.totalQuantity && icon,
                    }}
                  />
                  {/* {touched.totalQuantity && errors.totalQuantity && (
                    <Typography className='validation-text'>{errors.totalQuantity}</Typography>
                  )} */}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Start Date
                    <Require />
                  </Typography>
                  {/* <Input
                    error={!errors.startDate || !touched.startDate ? false : true}
                    id='date'
                    fullWidth
                    type='date'
                    placeholder='select start date'
                    name='startDate'
                    value={values.startDate || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {touched.startDate && errors.startDate && (
                    <Typography className='validation-text'>{errors.startDate}</Typography>
                  )} */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast={isEdit ? false : false}
                      // hideTabs
                      dayOfWeekFormatter={(day) => `${day}`}
                      className='date-picker'
                      // openTo='year'
                      views={['year', 'day', 'month']}
                      closeOnSelect={false}
                      value={values.startDate || dayjs(new Date())}
                      inputFormat='DD/MM/YYYY'
                      onChange={(newValue: any) => {
                        setFieldValue('startDate', newValue?.toDate());
                      }}
                      components={{
                        ActionBar: ActionList,
                        // OpenPickerIcon: ClockIcon,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ['today', 'cancel', 'clear'],
                        },
                      }}
                      // renderInput={(params) => {
                      //   return <TextField {...params} placeholder='Select time' />;
                      // }}
                      renderInput={(params) => {
                        if (values?.startDate) {
                          return (
                            <TextField
                              {...params}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                            />
                          );
                        } else {
                          delete params['inputProps'];
                          return (
                            <TextField
                              {...params}
                              disabled={true}
                              placeholder='Select Date'
                              className='date-picker-fieldset'
                              error={false}
                            />
                          );
                        }
                      }}
                    />
                  </LocalizationProvider>
                  {errors.startDate && touched.startDate && (
                    <Typography className='validation-text'>{errors.startDate}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Priority Level
                    <Require />
                  </Typography>
                  <FormControl fullWidth>
                    <Selects
                      error={!errors.priorityLevel || !touched.priorityLevel ? false : true}
                      onChange={handleChange}
                      name='priorityLevel'
                      value={values.priorityLevel || ''}
                      variant='outlined'
                      displayEmpty
                    >
                      <MenuItem value='' disabled>
                        <span className='menu-item-span'>Select Priority level</span>
                      </MenuItem>
                      <MenuItem value='Priority 1'>Priority 1</MenuItem>
                      <MenuItem value='Priority 2'>Priority 2</MenuItem>
                      <MenuItem value='Priority 3'>Priority 3</MenuItem>
                      <MenuItem value='Priority 4'>Priority 4</MenuItem>
                    </Selects>
                    {touched.priorityLevel && errors.priorityLevel && (
                      <Typography className='validation-text'>{errors.priorityLevel}</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title' mt={'2px'}>
                    Start Time
                    <Require />
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      // disablePast={currentSchedule.id ? false : false}
                      hideTabs
                      className='date-picker'
                      openTo='year'
                      views={['hours', 'minutes']}
                      // value={values.selectTime || dayjs(new Date())}
                      value={values.selectTime || defaultDateTime}
                      inputFormat='hh:mm A'
                      onChange={(newValue: any) => {
                        setFieldValue('selectTime', newValue?.toDate());
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder='Select time'
                            className='date-picker-fieldset'
                          />
                        );
                      }}
                    />
                    {/* Currently it's not reequired */}
                    {/* {touched.selectTime && errors.selectTime && (
                      <Typography className='validation-text'>{errors.selectTime}</Typography>
                    )} */}
                    <ErrorMessage
                      name='selectTime'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    disabled={isLoading}
                    // className={_.isEmpty(errors) ? 'yellow-bg' : !id ? 'disable-btn' : ''}
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    Continue
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Loading
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </HeadBox>
    </React.Fragment>
  );
};
export default AddWorkRequest;
