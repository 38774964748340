import React from 'react';

const DocumentIcon = () => {
  return (
    <div>
      <svg
        width='20'
        height='24'
        viewBox='0 0 20 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.1124 22.8624C19.1124 22.8624 18.5999 23.375 17.875 23.375H2.125C2.125 23.375 1.40013 23.375 0.887564 22.8624C0.887564 22.8624 0.375 22.3499 0.375 21.625V2.375C0.375 2.375 0.375 1.65013 0.887563 1.13756C0.887563 1.13756 1.40013 0.625 2.125 0.625H12.625C12.8571 0.625 13.0796 0.717187 13.2437 0.881282L19.3687 7.00628C19.5328 7.17038 19.625 7.39294 19.625 7.625V21.625C19.625 21.625 19.625 22.3499 19.1124 22.8624ZM17.875 21.625V7.98744L12.2626 2.375H2.125V21.625H17.875Z'
          fill='#0160B9'
        />
        <path
          d='M12.625 8.5H18.75C19.2332 8.5 19.625 8.10825 19.625 7.625C19.625 7.14175 19.2332 6.75 18.75 6.75H13.5V1.5C13.5 1.01675 13.1082 0.625 12.625 0.625C12.1418 0.625 11.75 1.01675 11.75 1.5V7.625C11.75 8.10825 12.1418 8.5 12.625 8.5Z'
          fill='#0160B9'
        />
      </svg>
    </div>
  );
};

export default DocumentIcon;
