import { useQuery } from '@tanstack/react-query';
import { dashboard } from '../queryKeys/dashboard';
import { dashboardApi } from '../api/dashboard';

export const useGetDashboard = (isEnable = true) => {
  return useQuery([dashboard.DASHBOARD], dashboardApi.getDashboardData, {
    select: (data) => data.data?.dashboardAllList,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetDashboardCharts = (isEnable = true, dates = {}) => {
  return useQuery([dashboard.DASHBOARD + 'CHARTS', dates], dashboardApi.getDashboardCharts, {
    select: (data) => data.data,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetDashboardGanttCharts = (isEnable = true, dates = {}) => {
  return useQuery(
    [dashboard.DASHBOARD + 'GANTTCHARTS', dates],
    dashboardApi.getDashboardGanttCharts,
    {
      select: (data) => data.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   // const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};
export const useGetDashboardGanttChartsHC = (isEnable = true, dates = {}) => {
  return useQuery(
    [dashboard.DASHBOARD + 'GANTTCHARTSHC', dates],
    dashboardApi.getDashboardGanttChartsHC,
    {
      select: (data) => data.data,
      retry: 0,
      enabled: isEnable,
      // onSuccess: (res: any) => {
      //   // const data = res?.data;
      //   //toast.success(data?.message);
      // },
      // onError: (err: any) => {
      //   const errData = err?.data;
      //   //toast.error(errData.message);
      // },
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDashboardIssues = (isEnable = true) => {
  return useQuery([dashboard.DASHBOARD_ISSUE_LIST], dashboardApi.getDashboardIssueList, {
    select: (data) => data.data?.latestIssues,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetDashboardActivity = (isEnable = true) => {
  return useQuery([dashboard.DASHBOARD_ACTIVITY_LIST], dashboardApi.getDashboardActivityList, {
    select: (data) => data.data,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetMachineData = (isEnable = true, date) => {
  return useQuery([dashboard.DASHBOARD_MACHINE_LIST, date], dashboardApi.getMachineList, {
    select: (data) => data?.data?.machinePerformance,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetDashboardAnalytics = (isEnable = true, date) => {
  return useQuery([dashboard.DASHBOARD_ANALYTICS_LIST, date], dashboardApi.getDashboardAnalycits, {
    select: (data) => data?.data?.analytics,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetProductionVolumns = (isEnable = true, date) => {
  return useQuery([dashboard.DASHBOARD_PRODUCTION_LIST, date], dashboardApi.getProductionData, {
    select: (data) => data?.data?.productionVolumes,
    retry: 0,
    enabled: isEnable,
    onSuccess: (res: any) => {
      // const data = res?.data;
      //toast.success(data?.message);
    },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetActions = (isEnable = true, page, limit) => {
  return useQuery([dashboard.DASHBOARD_ACTIONS_LIST, page, limit], dashboardApi.getActionsData, {
    select: (data) => data?.data?.myActions,
    retry: 0,
    enabled: isEnable,
    // onSuccess: (res: any) => {
    //   // const data = res?.data;
    //   //toast.success(data?.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
