import { deleteRequest, get, patch, post } from '../Utils/index';
import {
  ProcessErrI,
  ProcessFullListI,
  ProcessI,
  ProcessListErrI,
  ProcessListI,
} from '../Types/process';

export const getSingleProcess = async (data): Promise<ProcessI | ProcessErrI> => {
  const id = data?.queryKey[1];
  return get(`/customer/process/${id}`).then((res) => res.data);
};
export const getSingleDeletedProcess = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/process/deleted/${id}`);
};
export const addProcess = async (data) => {
  return post(`/customer/process`, data);
};
export const getProcesses = async (
  data
): Promise<ProcessListI | ProcessListErrI | { processes: { results: [] } }> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/process`, payload).then((res) => res.data);
};
export const getFullProcesses = async (): Promise<ProcessFullListI | { processes: [] }> => {
  return get(`/customer/process/full-list`).then((res) => res.data);
};
export const updateProcess = async (data): Promise<ProcessI | ProcessErrI> => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/process/${id}`, data).then((res) => res.data);
};
export const deleteProcess = async (id) => {
  return deleteRequest(`/customer/process/${id}`);
};
export const restoreProcess = async (id) => {
  return patch(`/customer/process/restore/${id}`);
};
export const hardDeleteProcess = async (id) => {
  return deleteRequest(`/customer/process/hard-delete/${id}`);
};

export const processApi = {
  addProcess,
  getProcesses,
  getSingleProcess,
  deleteProcess,
  updateProcess,
  getFullProcesses,
  restoreProcess,
  hardDeleteProcess,
  getSingleDeletedProcess,
};
