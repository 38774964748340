import React from 'react';
import { Box, Typography } from '@mui/material';
import './cardInfo.css';
import moment from 'moment';
interface Props {
  issueData: any;
}
const IssueCard = ({ issueData }: Props) => {
  return (
    <React.Fragment>
      <Box className='issue-parent-one'>
        <Typography className='issue-title' sx={{ paddingBottom: '5px' }}>
          {issueData?.typeOfIssueId?.issueType}
        </Typography>
        <Typography className='action-sub-title'>
          Reported on :{' '}
          {issueData ? moment(issueData?.createdAt)?.format('hh:MM a, DD MMM, YYYY') : '-'}
        </Typography>
        <Typography className='action-sub-title'>
          Reported by : {issueData?.createBy?.name}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default IssueCard;
