import * as React from 'react';
import { Box } from '@mui/system';
import {
  Modal,
  Radio,
  Switch,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import './../assets/css/modal.css';
import TaskDetailsConfirm from './TaskDetailconfirm';
import OutsourcedDetailsConfirm from './OutsourcedConfirm';
import { useGetSinglTaskWorkOrder } from '../hooks/taskWorkOrderHooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 888,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '50px 20px 30px 30px !important',
  },
  '.radioButton': {
    display: 'flex !important',
    direction: 'column',
    margin: '0px 10px 10px 20px !important',
  },
  '.parent-radio': {
    display: 'flex',
    gap: '14px',
    flexDirection: 'row',
  },
  '.task-pandding': {
    padding: '20px 25px 18px 25px !important',
  },
};

const HeadBox = styled(Box)(() => ({
  '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '32px !important',
  },
  // '.sub-box': {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  // },
  // '.close-icon': {
  //   margin: '20px 20px 0px 0px !important',
  //   cursor: 'pointer',
  // },
  '.switch': {
    padding: '5px 0px',
  },
}));

const InfoBox = styled(Box)(() => ({
  backgroundColor: '#F6F6F6',
  fontSize: '16px',
  fontWeight: 600,
  padding: '14px 20px',
}));

const CustomSelect = styled(Select)(() => ({
  '.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    height: 'inherit !important',
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '30px !important',
  height: 15,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 15 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface PropI {
  isDetailsOpen: boolean;
  DetailsHandleClose: () => void;
  editinhouseData?: any;
  addScheduleData?: any;
  // currentSchedule?: any;
  setCurrentSchedule?: any;
  requestCount?: number;
  changeRequestCount?: any;
  setDetailsOpen?: any;
  taskListType?: string;
  taskId?: string;
}

const TaskDetailsModal = (props: PropI) => {
  const theme = useTheme();

  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));

  const {
    isDetailsOpen,
    DetailsHandleClose,
    addScheduleData,
    // currentSchedule,
    setCurrentSchedule,
    changeRequestCount,
    setDetailsOpen,
    taskListType,
    taskId,
  } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  React.useEffect(() => {
    if (taskId) {
      setIsEdit(true);
    }
  }, [taskId]);
  //get task Single
  const { data: getTask } = useGetSinglTaskWorkOrder(taskId, !!taskId && isEdit);
  const singleTask = getTask?.data;
  const maintenanceType = singleTask?.mainTaskType === 'out-sourced' ? 'periodic' : 'inhouse';
  // const { maintenanceType } = currentSchedule || {};
  const activeRadio = !maintenanceType
    ? false
    : maintenanceType == 'inhouse'
    ? false
    : maintenanceType == 'periodic'
    ? true
    : false;

  const [selectedValue, setSelectedValue] = React.useState(activeRadio);
  // const handleChecked = (e) => {
  //   setSelectedValue(e.target.checked);
  // };

  React.useEffect(() => {
    const activeRadio = !maintenanceType ? false : maintenanceType == 'inhouse' ? false : true;
    setSelectedValue(activeRadio);
  }, [maintenanceType]);
  const isRework = localStorage.getItem('isRework');
  //! set for only one time show modal
  React.useEffect(() => {
    if (isRework == 'true') {
      setDetailsOpen(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={isDetailsOpen}
        onClose={() => {
          setIsEdit(false);
          DetailsHandleClose();
        }}
        aria-labelledby=''
        aria-describedby='modal-modal-description'
      >
        <HeadBox
          sx={style}
          style={{
            height: isMdScreen
              ? selectedValue
                ? '450px'
                : '704px'
              : isLGScreen
              ? selectedValue
                ? '450px'
                : '704px'
              : selectedValue
              ? '450px'
              : '804px',
          }}
        >
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Task 1</Typography>
            {/* <CloseIcon
              className='close-icon'
              onClick={() => {
                setIsEdit(false);
                DetailsHandleClose();
                localStorage.removeItem('isRework');
              }}
            /> */}
          </Box>
          {/* <Box mx={'25px !important'}>
            <FormGroup className='switch'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography>inhouse</Typography>
                <AntSwitch
                  defaultChecked
                  // inputProps={{ 'aria-label': 'ant design' }}
                  inputProps={{ 'aria-label': 'ant design' }}
                  checked={selectedValue == true}
                  onChange={(e) => {
                    handleChecked(e);
                  }}
                />
                <Typography>Periodic</Typography>
              </Stack>
            </FormGroup>
          </Box> */}
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} className='radioButton'>
              {/* display: 'flex', gap: '20px', flexDirection: 'row' */}
              <RadioGroup
                className='parent-radio'
                value={selectedValue === false ? 'inhouse' : 'periodic'}
                onChange={(e) => {
                  setSelectedValue(e.target.value === 'inhouse' ? false : true);
                }}
              >
                <FormControlLabel
                  value='inhouse'
                  control={<Radio size='small' />}
                  label={<Typography>Inhouse</Typography>}
                />
                <FormControlLabel
                  value='periodic'
                  control={<Radio size='small' />}
                  label={<Typography>Outsourced</Typography>}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <InfoBox>Task Information</InfoBox>
          {selectedValue == false ? (
            <TaskDetailsConfirm
              handleClose={DetailsHandleClose}
              taskListType={taskListType || ''}
              currentTask={isEdit && singleTask}
              // editinhouseData={editDailyData}
              // requestCount={requestCount}
              // changeReqCount={changeRequestCount}
              // currentSchedule={currentSchedule}
              // addScheduleData={addScheduleData}
              // setCurrentSchedule={setCurrentSchedule}
            />
          ) : (
            <OutsourcedDetailsConfirm
              taskListType={taskListType || ''}
              handleClose={DetailsHandleClose}
              // requestCount={requestCount}
              changeReqCount={changeRequestCount}
              // currentSchedule={currentSchedule}
              currentTask={isEdit && singleTask}
              addScheduleData={addScheduleData}
              setCurrentSchedule={setCurrentSchedule}
            />
          )}
        </HeadBox>
      </Modal>
    </React.Fragment>
  );
};
export default TaskDetailsModal;
