const factorySchedule = {
  FACTORYSCHEDULE: 'FACTORYSCHEDULE',
  FACTORYSCHEDULES: 'FACTORYSCHEDULES',
  FACTORYSCHEDULES_TOOL_CATEGORY: 'FACTORYSCHEDULES_TOOL_CATEGORY',
  ADD_TOOL_CATEGORY: 'ADD_TOOL_CATEGORY',
  UPDATE_TOOL_CATEGORY: 'UPDATE_TOOL_CATEGORY',
  DELETE_TOOL_CATEGORY: 'DELETE_TOOL_CATEGORY',
  GET_SETTING: 'GET_SETTING',
  TOOL_DETAILS: 'TOOL_DETAILS',
};

export { factorySchedule };
