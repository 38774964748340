import { Box, Button, Divider, Pagination, Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ButtonComponent from './ButtonComponent';
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CustomizeColumn from '../../assets/svg/CustomizeColumn';
import TableDataListIcon from '../../assets/svg/TableDataListIcon';
import CustomButton from './CustomButton';
import Plus from '../../assets/svg/Plus';

const CustomTableButton = styled(Button)({
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '40px',
  textTransform: 'uppercase',
  padding: '10px 16px',
});

interface btnData {
  title: string;
  isBtnShow?: boolean;
  isBtnDisable?: boolean;
  linkData: {
    menuItem: string;
    to: string;
    requestCount?: number;
  }[];
}

interface tableInterface {
  rows: any[];
  columns: any[];
  detailsRedirection?: string;
}

const columns = [
  { id: 'no', label: 'Sr.No', borderBottom: 'none' },
  { id: 'name', label: 'Name', fontcolor: '#fff' },
  {
    id: 'ide',
    label: 'ID',
  },
  {
    id: 'department',
    label: 'Department',
  },
  {
    id: 'line',
    label: 'Line',
  },
  {
    id: 'currentStatus',
    label: 'Current Status',
    display: 'flex',
    alignItems: 'center',
  },
  {
    id: 'nextAvailableOn',
    label: 'Next available on',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const TableComponent = (props: {
  data?: btnData | undefined;
  tableData?: tableInterface;
  totalPages?: any;
  isOptionShow: boolean;
  isEditStock?: boolean;
  isHideDropDown?: boolean;
  width?: number;
  isLoading?: boolean;
  isStateChanged?: boolean;
  isShowFiveRecords?: boolean;
  isDashboardShowFiveRecords?: boolean;
  paginationData?: any;
  page?: number;
  setPage?: any;
  listName?: string;
  requestCount?: number;
  isNoEntityShow?: boolean;
  moduleName?: string;
  redirectPage?: string;
  totalResults?: number;
  isDashboardWidth?: boolean;
  isFilter?: boolean;
  iSColumnsButton?: boolean;
  isTableBottomPadding?: boolean;
  isPlusBtnHide?: boolean;
}) => {
  const isDashboardActive = location.pathname.startsWith('/dashboard');

  const PaginationWrapper = styled(Box)({
    //  ! some new change for pagination
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    position: 'absolute',
    width: props?.isDashboardWidth ? '48%' : isDashboardActive ? '96.5%' : '93.5%',
    padding: '10px 0px',

    '@media(min-width: 425px) and (max-width: 1024px)': {
      width: isDashboardActive ? '92%' : '96%',
    },
    '@media(min-width: 1025px) and (max-width: 1440px)': {
      width: isDashboardActive ? '95%' : '90.5%',
    },
  });
  const width = props?.width ? props.width : 100;
  let isOptionShow = true;
  if (isOptionShow === true || isOptionShow === false) {
    isOptionShow = props.isOptionShow;
  }
  const navigate = useNavigate();
  const theme = useTheme();
  const columnsData = props?.tableData ? props.tableData.columns : columns;
  const isNoEntityShow = props?.isNoEntityShow || false;
  const {
    isHideDropDown = false,
    isFilter = true,
    iSColumnsButton = true,
    isTableBottomPadding = true,
  } = props;
  const moduleName = props?.moduleName;
  const redirectPage = props?.redirectPage;
  const [rowsData, setRowsData] = useState<any>([]);
  const CustomDataGrid = styled(DataGrid)(() => ({
    fontSize: '14px',
    '.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F4F9FF',
    },
    [`& .${gridClasses.cell}.blue-font`]: {
      color: '#0160B9 !important',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px',
    },
    [`& .${gridClasses.cell}.black-font`]: {
      color: '#020202 !important',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px',
    },
  }));
  let isBtnShow = true;

  if (props?.data?.isBtnShow === true || props.data?.isBtnShow === false) {
    isBtnShow = props?.data?.isBtnShow;
  }

  useEffect(() => {
    if (!props?.isLoading) {
      setRowsData(props?.tableData?.rows);
    }
  }, [props?.isLoading, props?.tableData?.rows?.length, props?.isStateChanged, rowsData?.length]);
  const { data, paginationData, page, setPage, listName, totalResults } = props;
  const CustomDivider = styled(Divider)(() => ({
    margin: '0px 16px 0px 18px !important',
  }));
  const customBtnLink = data?.linkData[0]?.to;
  const isHideOptions =
    location.pathname.startsWith('/edit-product') ||
    location.pathname.startsWith('/add-product') ||
    location.pathname.startsWith('/worker-detail') ||
    location.pathname.startsWith('/machine-details') ||
    location.pathname.startsWith('/deleted-items1111') ||
    location.pathname.startsWith('/details-work-order') ||
    location.pathname.startsWith('/other-seting') ||
    location.pathname.startsWith('/completion-report') ||
    location.pathname.startsWith('/tool-details') ||
    location.pathname.startsWith('/deleted-items/tool-details');
  const isWorkOrderNameHideOptions = location.pathname.startsWith('/tool-details');

  function CustomToolbar() {
    return (
      <Box>
        <Box
          className={`${
            isTableBottomPadding ? 'table-toolbar' : 'table-toolbar table-toolbar-padding-bottom'
          }`}
        >
          <Box>
            <Typography className='list-title'>{listName}</Typography>
          </Box>

          <Box>
            {props?.isOptionShow && !isHideOptions && (
              <GridToolbarContainer>
                {iSColumnsButton && (
                  <GridToolbarColumnsButton placeholder={undefined} name='Customize Columns' />
                )}
                <CustomDivider orientation='vertical' className='table-verticle-divider' />
                {isFilter && <GridToolbarFilterButton placeholder={undefined} />}
                {isBtnShow && (
                  <>
                    <CustomDivider orientation='vertical' className='table-verticle-divider' />
                    {!isHideDropDown ? (
                      <ButtonComponent
                        title={data?.title && data?.title}
                        linkData={data?.linkData}
                        isBtnDisable={data?.isBtnDisable}
                      />
                    ) : (
                      !props?.isPlusBtnHide && (
                        <>
                          <CustomTableButton
                            className='blue-bg'
                            onClick={() =>
                              navigate(`${customBtnLink}`, {
                                ...(data?.linkData?.[0]?.requestCount && {
                                  state: { requestCount: data?.linkData?.[0]?.requestCount },
                                }),
                              })
                            }
                          >
                            <Plus fill='white' />{' '}
                            <span className='tb-margin-left'>
                              {data?.title ? data?.title : '---'}
                            </span>
                          </CustomTableButton>
                        </>
                      )
                    )}
                  </>
                )}
              </GridToolbarContainer>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  // const isTableHeight = location.pathname.startsWith('/my-tickets');
  const isTableHeight = location.pathname.startsWith('/deleted-items');
  // TODO: future for history table
  // const isHistoryTable =
  //   location.pathname.startsWith('/machine-details') ||
  //   location.pathname.startsWith('/worker-detail');
  // Calculate header height based on screen size

  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  return (
    <React.Fragment>
      {rowsData?.length <= 0 && !props.isLoading && isNoEntityShow ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxHeight={'5000px'}>
          <Box textAlign={'center'}>
            <TableDataListIcon />
            <Typography>{`No '${moduleName}' added yet`}</Typography>
            <Typography mb={3}>{` ${
              moduleName === 'Work order' ? 'Create' : 'Add'
            }  '${moduleName}' to ${moduleName} List`}</Typography>
            {isBtnShow && (
              <CustomButton
                className='blue-color'
                onClick={() =>
                  navigate(`${redirectPage}`, { state: { requestCount: props?.requestCount } })
                }
              >
                <Plus fill={'#0160B9'} />{' '}
                <span className='ml'>{` ${
                  moduleName === 'Work order' ? 'Create' : 'Add'
                } ${moduleName}`}</span>
              </CustomButton>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            className={isTableHeight ? 'table-height-ticket' : 'table-height'}
            // ! for future useCase
            // height={
            //   isTableHeight
            //     ? '480px !important'
            //     : `${
            //         width === 50 || props?.isShowFiveRecords
            //           ? props?.isDashboardShowFiveRecords
            //             ? '220px !important'
            //             : '300px'
            //           : '520px !important'
            //       }`
            // }
            // sx={{ border: '2px solid blue' }}
            // TODO
            // className={
            //   isTableHeight ? 'table-height' : isHistoryTable ? 'table-height-history' : ''
            // }
            width={`${width}%`}
          >
            <CustomDataGrid
              autoHeight
              sx={{
                '.MuiDataGrid-columnSeparator--sideRight': {
                  display: 'none !important',
                },
              }}
              rows={rowsData || []}
              getRowHeight={() => 'auto'}
              hideFooter
              // TODO
              // getRowHeight={() => 40}
              // pageSize={10}
              // pagination
              // autoHeight={isTableHeight ? false : isHistoryTable ? false : true}
              // rowHeight={36}
              loading={props.isLoading}
              // headerHeight={36}
              headerHeight={isMdScreen ? 90 : 65} // Dynamic header height
              columns={columnsData}
              onCellClick={(e: any) => {
                if (e.field !== 'actions') {
                  const isRedeirction = props.tableData?.detailsRedirection;
                  if (isRedeirction && props.tableData?.detailsRedirection !== 'noRedirection') {
                    navigate(`${props.tableData?.detailsRedirection}/${e?.id}`);
                  }
                }
              }}
              // getRowClassName={(params) =>
              //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              // }
              getCellClassName={(params) => {
                return [
                  'Name',
                  `User's Name`,
                  'Roles',
                  'Line Name',
                  'Department Name',
                  'Supplier Name',
                  'Part Name',
                  'Assigned to',
                  // 'Created by',
                  'Title',
                  isWorkOrderNameHideOptions ? '' : 'Work Order Name',
                  'Issue Type',
                  'Customer Order Name',
                  'Document Name',
                ].includes(params.colDef.headerName || '')
                  ? 'blue-font'
                  : isWorkOrderNameHideOptions
                  ? 'black-font'
                  : 'regular';
              }}
              components={{
                Toolbar: CustomToolbar,
                // OpenFilterButtonIcon: OpenFilterButtonIcon,
                ColumnSelectorIcon: CustomizeColumn,
              }}
              componentsProps={{
                panel: {
                  sx: {
                    top: '-30px !important',
                    left: '810px !important',
                    color: 'red !important',
                  },
                },
              }}
            />
            {!isHideOptions && rowsData?.length > 0 && (
              <PaginationWrapper
              // ! for future use class
              // className='pagination-wrapper'
              >
                <Typography sx={{ color: '#8A8A8A', fontSize: '14px' }}>
                  Showing 1 to 10 of {totalResults}
                </Typography>
                <Pagination
                  page={page}
                  color='primary'
                  count={props?.totalPages || paginationData?.totalPages}
                  variant='outlined'
                  onChange={(e, page) => {
                    setPage(page);
                  }}
                />
              </PaginationWrapper>
            )}
          </Box>
        </>
      )}
    </React.Fragment>
  );
};
export default TableComponent;
