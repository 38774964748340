import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import { useHardDeletePart, useRestorePart } from '../../hooks/partHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import { useDeleteTicket, useGetTicket } from '../../hooks/ticketHooks';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({
  '.stock-btn': {
    margin: '24px 0px 0px 24px',
  },
  '.Evidence': {
    fontSize: '16px',
    fontWeight: 600,
    padding: '20px 0px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 0px !important',
    fontWeight: '600 !important',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
    border: '1px solid red !important',
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
  '.basic-Details-Box': {
    '@media(min-width: 425px) and (max-width: 1024px)': {
      minHeight: '42px !important',
    },
    '@media(min-width: 1025px) and (max-width: 1440px)': {
      minHeight: '38px !important',
    },
  },
}));

const IssueDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const { data: getTicket }: any = useGetTicket(id);
  // const { data: getParts, isSuccess } = useGetPartSingle(id, !!id);
  // const singlePart = getParts?.data;
  const singleTicket = getTicket?.ticket;
  // const { data: getPartDeleted, isSuccess: isSuccessDeleted } = useGetDeletedPart(
  //   id,
  //   !!id && isDeleted
  // );
  // const singlePart = isDeleted ? getPartDeleted?.part : getPart?.part;
  // const Qty_over = singlePart?.avlStock - singlePart?.minimumStockOrder;
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  // const {
  //   mutate: deleteSinglePart,
  //   error: deletePartError,
  //   isError: isDeletePartError,
  // }: any = useDeleteTool();
  const {
    mutate: deleteTicket,
    error: tikitDeleteError,
    isError: isDeleteError,
  } = useDeleteTicket('1');
  React.useEffect(() => {
    if (isDeleteError) {
      setErrorMessage(tikitDeleteError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isDeleteError]);
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: restorePart } = useRestorePart();
  const { mutate: readNotification } = useUpdateNotification();
  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeletePart(id);
    } else {
      deleteTicket(id);
    }
  };

  const handlePartRestore = () => {
    if (isRestore) {
      restorePart(id);
    } else {
      // nothing to restore
    }
  };
  let breadCrumbData = [
    {
      title: 'Issues',
      link: '/my-tickets',
    },
    // {
    //   title: `Tools`,
    //   link: `/catalogue`,
    //   activeTab: '1',
    // },
    {
      title: _.get(singleTicket?.typeOfIssueId, 'issueType', ''),
      link: `/ticket-details/${id}`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: 'test name',
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-ticket/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  // Add Edit stocks
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = React.useState({});

  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={'Issue Name'}
          backToLink='/my-tickets'
          isDetailPage={true}
          activeTab='1'
          detailPageData={detailPageData}
          handleDelete={handlePartDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handlePartRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={4}>
                    <TextFiled>Issue Type </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleTicket?.typeOfIssueId, 'issueType', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Reason </TextFiled>
                    <Typography className='span-color pl-sub-text basic-Details-Box'>
                      {_.get(singleTicket, 'reason', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Object </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleTicket, 'objectType', '')}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2.6}>
                    <TextFiled>Manucturer </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singlePart, 'manufacturer', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.6}>
                    <TextFiled>Quantity </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singlePart, 'quantity', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <TextFiled>Date Bought </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {moment(_.get(singlePart, 'dateBought', '')).format(
                        'hh:mm A, DD MMM, YYYY'
                      )}
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Other Details
                </Typography>
                <Grid container display={'flex'}>
                  <Grid item xs={4}>
                    <TextFiled>Created by </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleTicket?.createBy, 'name', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled>Created on </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {moment(_.get(singleTicket, 'createdAt', '')).format('hh:mm A, DD MMM, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFiled> Assignee </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleTicket?.primaryAssignee, 'name', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography className='Evidence'>Evidence</Typography>
          <Grid container spacing={2}>
            {singleTicket?.uploadedData.length > 0 &&
              singleTicket?.uploadedData?.map((data, ind) => {
                return (
                  <Grid item md={4} key={ind}>
                    <img src={data?.url} alt='material-image' width={'450px'} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Loading background='white' loaderColor='#265AA8' />
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
      </HeadBoxWrapper>
    </>
  );
};
export default IssueDetail;
