import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Card, Typography } from '@mui/material';
import styled from '@emotion/styled';
import DateRange from '../Dashboard/DateRange';
import moment from 'moment';

const MainCard = styled(Card)(() => ({
  boxShadow: 'none',
  '.chart-title': {
    fontSize: '18px',
    fontWeight: '600',
    paddingLeft: 18,
    paddingTop: '15px',
  },
  '.parent-bar-chart': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    background: '#F0F6FF',
    border: '1px solid #DEDEDE',
    margin: '0px 10px',
  },
  '.Overall': {
    fontSize: '16px',
    fontWeight: '600',
  },
  '.percentile': {
    fontSize: '18px',
    fontWeight: '700',
  },
  '.datepicker': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '.border-Line-Custom': {
    border: '10px solid #E6EAEF !important',
  },
}));
interface Props {
  height: string;
  title?: string;
  type?: string;
  data?: any;
  isGridLine?: boolean;
  ganttDates?: any;
  setGranttDatesState?: any;
  setGranttDates?: any;
  isBorder?: boolean;
}
const BarChart = (props: Props) => {
  const {
    title,
    type = 'column',
    data,
    isGridLine = false,
    height,
    ganttDates,
    setGranttDatesState,
    setGranttDates,
    isBorder = true,
  } = props;
  const currentDate: any = moment();

  const options = {
    credits: {
      enabled: false,
    },
    exporting: { enabled: false },
    chart: {
      type: type,
      height: height,
      // ! for future use
      // Click event on tooltip
      // events: {
      //     click: function (event) {
      //         alert(
      //             'x: ' + Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].value) + ', ' +
      //             'y: ' + event.yAxis[0].value
      //         );
      //     }
      // }
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      gridLineWidth: 1, // Adjust the value as needed for the width of the grid lines
    },

    yAxis: {
      min: 0,
      max: 100,
      tickInterval: 25,
      // gridLineColor: 'transparent',
      gridLineColor: isGridLine ? '#E2E2E2' : 'transparent',
      lineColor: '#002242',
      title: {
        text: 'y-axis',
      },
      // ! for future use
      // labels: {
      //     formatter: function () {
      //         return this.value + '%';
      //     }
      // }
      gridLineWidth: 1, // Adjust the value as needed for the width of the grid lines
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        borderRadius: 10,
        dataLabels: {
          // ! here to show upper labels
          enabled: false,
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{red}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    },

    series: data,
  };
  return (
    <div className='App' style={{ border: '1px solid #DEDEDE' }}>
      <MainCard className={`${isBorder ? 'border-Line-Custom' : ''} `}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography className='chart-title'>{title}</Typography>
          </Box>
          <Box className='datepicker' sx={{ padding: '15px 15px 0px 0px' }}>
            <DateRange
              setDatesState={setGranttDatesState}
              dates={ganttDates}
              setDates={setGranttDates}
            />
          </Box>
        </Box>

        {type == 'bar' && (
          <Box className='parent-bar-chart'>
            <Typography className='Overall'>Overall utilization</Typography>
            <Typography className='percentile'>85%</Typography>
          </Box>
        )}
        {/* <Typography className='chart-title'>{title}</Typography> */}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </MainCard>
    </div>
  );
};
export default BarChart;
