import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { issueMappingApi } from '../api/IssueMapping';
import { issueMapping } from '../queryKeys/issueMapping';

export const useAddIssueMapping = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(issueMappingApi.addIssueMapping, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([issueMapping.ISSUEMAPPING]);
      const data = res.data;
      // toast.success(data?.message);
      navigate(`/issue-mapping`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useUpdateIssueMapping = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(issueMappingApi.updateIssueMapping, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([issueMapping.ISSUEMAPPING, id]);
      queryClient.invalidateQueries([issueMapping.ISSUEMAPPINGS]);
      const data = res.data;
      // toast.success(data?.message);
      navigate(`/issue-mapping`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useGetIssueMapping = (id: any, isEnable = true) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useQuery([issueMapping.ISSUEMAPPING, id], issueMappingApi.getSingleIssueMapping, {
    enabled: isEnable,
    select: (data) => data?.data,
    onSuccess: (res: any) => {
      const data = res?.data;
      //toast.success(data?.message);
      //navigate(`/issue-mapping/${data?.issueMapping.id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useGetIssueMappings = (isEnable = true, page: number) => {
  return useQuery([issueMapping.ISSUEMAPPINGS, page], issueMappingApi.getIssueMapping, {
    enabled: isEnable,
    select: (data) => data?.data?.issueMappings,
    onSuccess: (res: any) => {
      const data = res?.data;
      //toast.success(data?.message);
      //navigate(`/issue-mapping/${data?.issueMapping.id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
// ! fullList api of getIssue
export const useGetIssueFullList = (isEnable = true) => {
  return useQuery([issueMapping.ISSUEMAPPING_FULL_LIST], issueMappingApi.getIssueFullList, {
    enabled: isEnable,
    // select: (data) => data?.data?.issueMappings,
    select: (data) => data?.data?.issueMappings,
    onSuccess: (res: any) => {
      const data = res?.data;
      //toast.success(data?.message);
      //navigate(`/issue-mapping/${data?.issueMapping.id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteIssueMapping = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(issueMappingApi.deleteIssueMapping, {
    onSuccess: (res, id) => {
      // queryClient.invalidateQueries([issueMapping.ISSUEMAPPING]);
      queryClient.invalidateQueries([issueMapping.ISSUEMAPPING, id]);
      // ! TODO: for future implementation toast
      // toast.success(data?.message);
      navigate(`/issue-mapping`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
