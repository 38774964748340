import { get } from '../Utils';

export const getDashboardData = async () => {
  return get(`/customer/dashboard`);
};
export const getDashboardCharts = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/charts`, dates);
};
export const getDashboardGanttCharts = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/schedule-workorder`, dates);
};
export const getDashboardGanttChartsHC = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/schedule-workorder-high-charts`, dates);
};
export const getDashboardIssueList = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/latest-issues`, dates);
};
export const getDashboardActivityList = async (data) => {
  const dates = data.queryKey?.[1];
  return get(`/customer/dashboard/recent-activity`, dates);
};
export const getMachineList = async (data) => {
  const startDate = data?.queryKey?.[1]?.startDate;
  const endDate = data?.queryKey?.[1]?.endDate;
  return get(`/customer/dashboard/machine-performance?startDate=${startDate}&endDate=${endDate}`);
};
export const getProductionData = async (data) => {
  const startDate = data?.queryKey?.[1]?.startDate;
  const endDate = data?.queryKey?.[1]?.endDate;
  return get(`/customer/dashboard/production-volumes?startDate=${startDate}&endDate=${endDate}`);
};
export const getDashboardAnalycits = async (data) => {
  const startDate = data?.queryKey?.[1]?.startDate;
  const endDate = data?.queryKey?.[1]?.endDate;
  return get(`/customer/dashboard/analytics?startDate=${startDate}&endDate=${endDate}`);
};
export const getActionsData = async (data) => {
  const page = data.queryKey?.[1];
  const limit = data.queryKey?.[2];
  return get(`/customer/my-action?page=${page}&limit=${limit}`);
};

export const dashboardApi = {
  getDashboardData,
  getDashboardCharts,
  getDashboardGanttCharts,
  getDashboardGanttChartsHC,
  getDashboardIssueList,
  getDashboardActivityList,
  getMachineList,
  getDashboardAnalycits,
  getProductionData,
  getActionsData,
};
