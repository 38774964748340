import * as React from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { Box, Grid, TextField, Typography, Select, MenuItem, FormControl } from '@mui/material';
import CustomButton from '../commonComponents/CustomButton';
import Require from '../commonComponents/Required';
import * as _ from 'lodash';
import { useAddIssueMapping, useUpdateIssueMapping } from '../../hooks/issueMappingHooks';
import ErrorPopup from '../../Modals/ErrorPopup';
import Loading from 'react-fullscreen-loading';
import { useFullGetMobileUsers } from '../../hooks/workerManagementHooks';
import { TfiPlus } from 'react-icons/tfi';

const validationSchema = Yup.object().shape({
  issueType: Yup.string().trim().required('Issue Type is required'),
  owner: Yup.string().required('Owner is required'),
  objectType: Yup.string().required('Object Type is required'),
  reasons: Yup.array()
    .min(1, 'reason is required')
    .of(Yup.string().trim().required('Reason required')),
});
const SelectsNew = styled(Select)(() => ({
  borderRadius: '4px !important',
  '.MuiOutlinedInput-input': {
    padding: '9.5px 14px',
    fontSize: '14 !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8A8A8A',
  },
}));
const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
    fontSize: '14px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const BoxWrapper = styled(Box)(() => ({
  marginTop: '24px',
  '.remove': {
    display: 'flex',
    marginTop: '9px',
    cursor: 'pointer',
    color: '#265AA8',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.mr-14': {
    marginRight: '14px',
  },
  '.issue-mapping-main-padding': {
    padding: '0px 56px 18px 56px !important',
  },
}));

const AddSetupIssue = ({ issueData, issueReqNo }: any) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  //Add Issue
  const {
    mutate: addIssueMapping,
    isError: isAddIssueError,
    error: addIssueError,
    isSuccess: isAddIssueSuccess,
    isLoading,
  } = useAddIssueMapping();
  //Update Isuue
  const {
    mutate: updateIssueMapping,
    isError: isUpdateIssueError,
    error: updateIssueError,
    isSuccess: isUpdateIssueSuccess,
  } = useUpdateIssueMapping();

  //get All user
  const params = { isVerified: true, isCompletion: true };
  const { data: getAllUsers } = useFullGetMobileUsers(true, params);

  const objectTypeOptions = ['Generic', 'Machine'];

  React.useEffect(() => {
    if (!_.isEmpty(issueData)) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    if (isAddIssueError || isUpdateIssueError) {
      isAddIssueError
        ? setErrorMessage(addIssueError?.data?.message)
        : setErrorMessage(updateIssueError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isAddIssueError, isUpdateIssueError, issueData?.id]);

  return (
    <BoxWrapper>
      <Box
        sx={{
          minHeight: '79.5vh',
          position: 'relative',
        }}
      >
        <Formik
          initialValues={{
            // do not need to map values within items because FieldArray
            issueType: isEdit ? issueData?.issueType : '',
            owner: isEdit ? issueData?.owner?.id : '',
            reasons: isEdit ? issueData?.reasons : [''],
            objectType: isEdit ? issueData?.objectType : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const prefix = `IR-${1000 + (issueReqNo || 0) + (issueReqNo ? 1 : 1)}`;
            isEdit
              ? updateIssueMapping({ ...values, id: issueData.id })
              : addIssueMapping({ ...values, issueReqNo: prefix });
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete='off'>
              <Box className='issue-mapping-main-padding'>
                <Typography className='title-typography'>Add Issue</Typography>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography pb={'6px'}>
                        Issue of Type
                        <Require />
                      </Typography>
                      <Input
                        fullWidth
                        placeholder='Enter Issue Type'
                        type='text'
                        name='issueType'
                        value={values?.issueType || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.issueType && errors?.issueType && (
                        <span className='validation-text'>
                          {isEdit ? issueData?.issueType : errors?.issueType}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography pb={'6px'}>
                          Owner
                          <Require />
                        </Typography>
                        <FormControl fullWidth>
                          <SelectsNew
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={values.owner || ''}
                            name='owner'
                            onChange={handleChange}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                            displayEmpty
                          >
                            <MenuItem value='' disabled>
                              <span className='menu-item-span'>Select Owner</span>
                            </MenuItem>
                            {getAllUsers?.map((data, ind) => {
                              return (
                                <MenuItem value={data?.id} key={ind}>
                                  {data?.name}
                                </MenuItem>
                              );
                            })}
                          </SelectsNew>
                        </FormControl>
                        <Typography className='validation-text'>
                          {touched.owner && errors.owner && (
                            <Typography className='validation-text'>
                              {isEdit ? issueData?.owner : errors.owner}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography pb={'6px'}>
                          Object Type
                          <Require />
                        </Typography>
                        <FormControl fullWidth>
                          <SelectsNew
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={values.objectType || ''}
                            name='objectType'
                            onChange={handleChange}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                            displayEmpty
                          >
                            <MenuItem value='' disabled>
                              <span className='menu-item-span'>Choose Object</span>
                            </MenuItem>
                            {objectTypeOptions?.map((option, ind) => {
                              return (
                                <MenuItem value={option} key={ind}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </SelectsNew>
                        </FormControl>
                        <Typography className='validation-text'>
                          {touched.owner && errors.owner && (
                            <Typography className='validation-text'>
                              {isEdit ? issueData?.owner : errors.owner}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* /////////////////////////////////////////////////////////////////////////////// */}
                {/* begin FieldArray for items */}
                <FieldArray
                  name='reasons'
                  render={({ push }) => (
                    <Box>
                      <Box className='row' mt={'15px'}>
                        <Grid container spacing={2}>
                          {values.reasons?.length > 0 &&
                            [
                              ...values.reasons,
                              ...(values.reasons?.length === 1 ? ['', ''] : []),
                              ...(values.reasons?.length === 2 ? [''] : []),
                            ].map((item, index) => {
                              const reason = `reasons[${index}]`;
                              const touchedChecklistItem = getIn(touched, reason);
                              const errorChecklistItem = getIn(errors, reason);

                              return (
                                <Grid item xs={4} key={index}>
                                  <Typography pb={'6px'}>
                                    Reason {index + 1}
                                    {index == 0 && <Require />}
                                  </Typography>
                                  <Input
                                    fullWidth
                                    onChange={(e) => setFieldValue(reason, e.target.value)}
                                    name={reason}
                                    value={item || ''}
                                    placeholder='Enter Reason'
                                  />
                                  {touchedChecklistItem && errorChecklistItem && (
                                    <Typography className='validation-text'>
                                      {errorChecklistItem}
                                    </Typography>
                                  )}
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Box>
                      <Box display={'flex'} mt={'20px'}>
                        <CustomButton
                          variant='outlined'
                          className='mr-14'
                          onClick={() => {
                            push('');
                          }}
                        >
                          <TfiPlus size={'16px'} style={{ paddingRight: '10px' }} /> Add New Reason
                        </CustomButton>
                      </Box>
                    </Box>
                  )}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0px',
                  boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px ',
                  width: '99.5%',
                  paddingTop: '20px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingRight: '55px',
                  }}
                >
                  <CustomButton
                    disabled={isLoading}
                    type='submit'
                    className={!isEdit && !(isValid && dirty) ? 'disable-btn new' : 'blue-bg'}
                    variant='contained'
                  >
                    Save Issue
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        <Loading
          loading={(isEdit ? isUpdateIssueSuccess : isAddIssueSuccess) ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
      </Box>
    </BoxWrapper>
  );
};

export default AddSetupIssue;
