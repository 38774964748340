import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Button, Grid, Stack, Switch, TextField, styled } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import { useWorkorderApproval } from '../hooks/workorderHooks';
import { useUpdateApprovalNeeded, useUpdateOutSourced } from '../hooks/taskWorkOrderHooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import Require from '../pages/commonComponents/Required';
import UploadDocument from '../pages/commonComponents/UploadDocument';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme?.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 838,
  height: 316,
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '20px 25px 18px 25px !important',
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    margin: '40px 0px 0px 0px !important',
  },
  '.buttonFlex': {
    display: 'flex',
  },
  '.mr-15': {
    marginLeft: '8px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.field-title-in': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.fildleft': {
    marginLeft: '17px',
    marginTop: '5px',
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
    marginRight: '140px',
  },
  '.uploadreferencesIcon-3': {
    textTransform: 'capitalize',
    fontSize: '10px',
    color: '#020202',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    height: '0px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.fildborder': {
    borderRadius: '4px',
    padding: '8.5px 20px 8.5px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
    border: '1px solid rgba(138, 138, 138, 1)',
  },
  '.task-mr-23': {
    marginRight: '130px',
    fontSize: 14,
  },
  '.title-head-box1': {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    padding: '16px 2px 0px 2px !important',
  },
};
const styleConfirm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 838,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '20px 25px 18px 25px !important',
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
  },
  '.tool-box-confirm': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
  },
  '.buttonFlex': {
    display: 'flex',
  },
  '.mr-15': {
    marginLeft: '8px',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.field-title-in': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 6px 30px',
    color: '#000',
  },
  '.fildleft': {
    marginLeft: '17px',
    marginTop: '20px',
  },
  '.uploadreferencesIcon-1': {
    display: 'flex !important',
    marginRight: '140px',
  },
  '.uploadreferencesIcon-3': {
    textTransform: 'capitalize',
    fontSize: '10px',
    color: '#020202',
  },
  '.uploadreferences-2': {
    justifyContent: 'space-between !important',
    height: '0px',
    textTransform: 'capitalize',
    color: '#0160B9',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.fildborder': {
    borderRadius: '4px',
    padding: '8.5px 20px 8.5px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
    border: '1px solid rgba(138, 138, 138, 1)',
  },
  '.task-mr-23': {
    marginRight: '130px',
    fontSize: 14,
  },
  '.title-head-box1': {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    padding: '16px 2px 0px 2px !important',
  },
};
const InputField = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '0px 12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));
const RawCustomButton = styled(Button)(() => ({
  padding: '10px, 12px, 10px, 12px !important',
  justify: 'space-between',
  borderRadius: '4px !important',
  width: '150px',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  textTransform: 'capitalize',
  height: '40px !important',
  color: '#8A8A8A',
  '&.MuiButton-outlined': {
    border: 'border: 1px solid rgba(38, 90, 168, 1)',
  },
}));
const CustomTypography = styled(Typography)(() => ({
  color: 'rgba(2, 2, 2, 1)',
  fontWeight: '400',
  fontSize: '14px !important',
  padding: '20px 0px 0px 30px',
}));
function ActionList(props: PickersActionBarProps) {
  const { onAccept, onClear, onCancel, onSetToday } = props;
  const actions = [
    { text: 'Apply', method: onAccept },
    { text: 'Clear', method: onClear },
    { text: 'Today', method: onSetToday },
  ];
  return (
    <Box className='datepicker-btn-wrapper-factory-layout'>
      <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
        {actions[1].text}
      </Button>
      <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
        {actions[0].text}
      </Button>
    </Box>
  );
}
interface partI {
  isOpen: boolean;
  isTaskApproval?: boolean;
  handleClose: () => void;
  id?: string;
  taskId?: string;
  mainTaskType?: string;
}
const WorkOrderApproval = (props: partI) => {
  const { isOpen, handleClose, id, mainTaskType, isTaskApproval, taskId } = props;
  const { mutate: approvalWorkOrder } = useWorkorderApproval();
  // ! for new
  const { mutate: updateApprovalNeeded } = useUpdateApprovalNeeded();
  const { mutate: updateOutSourced } = useUpdateOutSourced();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      {mainTaskType == 'out-sourced' ? (
        <Formik
          initialValues={{
            dispatchDate: '',
            receivedDate: '',
            receiptCondition: '',
            inspectionDone: false,
            uploadedData: [],
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            updateOutSourced({
              ...values,
              workOrder: id,
              mainTaskType: mainTaskType,
              id: taskId,
            });
            handleClose();
            // const isVariantAdd = window.location.pathname.startsWith('/part/add-variant');
            // setOpenModal(true);
            // setValue({
            //   ...values,
            // });
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={style}>
                <Box>
                  <Box className='title-head-box1'>
                    <Typography className='top-up-name'>Outsourced Task details</Typography>
                  </Box>
                  <Box className='modal-body'>
                    <Box className='section-one'></Box>
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={6} lg={4}>
                    <Typography className='field-title'>
                      Dispatch Date
                      <Require />
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        orientation='portrait'
                        dayOfWeekFormatter={(day) => `${day}`}
                        className='date-picker'
                        views={['year', 'day', 'month']}
                        closeOnSelect={false}
                        value={values?.dispatchDate}
                        inputFormat='DD/MM/YYYY'
                        onChange={(newValue: any) => {
                          setFieldValue('dispatchDate', newValue?.toDate());
                        }}
                        components={{
                          ActionBar: ActionList,
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ['today', 'cancel', 'clear'],
                          },
                        }}
                        renderInput={(params) => {
                          if (values) {
                            return (
                              <TextField
                                {...params}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                              />
                            );
                          } else {
                            delete params['inputProps'];
                            return (
                              <TextField
                                {...params}
                                disabled={true}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                                error={false}
                              />
                            );
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      name='operation'
                      render={(msg) => <Typography className='validation-text'>{msg}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={4} md={6} lg={4}>
                    <Typography className='field-title'>
                      Received Date
                      <Require />
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        orientation='portrait'
                        dayOfWeekFormatter={(day) => `${day}`}
                        className='date-picker'
                        views={['year', 'day', 'month']}
                        closeOnSelect={false}
                        value={values?.receivedDate}
                        inputFormat='DD/MM/YYYY'
                        onChange={(newValue: any) => {
                          setFieldValue('receivedDate', newValue?.toDate());
                        }}
                        components={{
                          ActionBar: ActionList,
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ['today', 'cancel', 'clear'],
                          },
                        }}
                        renderInput={(params) => {
                          if (values) {
                            return (
                              <TextField
                                {...params}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                              />
                            );
                          } else {
                            delete params['inputProps'];
                            return (
                              <TextField
                                {...params}
                                disabled={true}
                                placeholder='Select Date'
                                className='date-picker-fieldset'
                                error={false}
                              />
                            );
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} md={6} lg={4}>
                    <Typography className='field-title'>
                      Receipt Condition
                      <Require />
                    </Typography>
                    <InputField
                      placeholder='Enter receiptCondition'
                      fullWidth
                      onChange={handleChange}
                      name='receiptCondition'
                    />
                    {/* <InputField
                    error={!errors.operator || !touched.operator ? false : true}
                    placeholder='Enter Tool operator'
                    fullWidth
                    onChange={handleChange}
                    name='operator'
                    value={values?.operator}
                    InputProps={{
                      endAdornment: touched.operator && errors.operator && icon,
                    }}
                  /> */}
                    {/* {touched.operator && errors.operator && (
                    <Typography className='validation-text'>
                      {typeof errors.operator === 'string' ? errors.operator : ''}
                    </Typography>
                  )} */}
                  </Grid>
                </Grid>
                <Typography className='field-title' mt={'30px'}>
                  Inspection Done
                  {/* <Require /> */}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={4} className='buttonFlex' mt={'0px'}>
                    <RawCustomButton
                      sx={{
                        border: values?.inspectionDone ? '2px solid #265AA8' : '1px solid #265AA8',
                      }}
                      variant='outlined'
                      onClick={() => {
                        setFieldValue('inspectionDone', true);
                      }}
                    >
                      Yes
                    </RawCustomButton>
                    <RawCustomButton
                      sx={{
                        border:
                          values?.inspectionDone == false
                            ? '2px solid #265AA8'
                            : '1px solid #265AA8',
                      }}
                      variant='outlined'
                      className='mr-15'
                      onClick={() => {
                        setFieldValue('inspectionDone', false);
                      }}
                    >
                      No
                    </RawCustomButton>
                  </Grid>
                  <Grid item xs={4} md={6} lg={4}>
                    <UploadDocument
                      name={values?.uploadedData}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isAttachment={false}
                      createFileName='uploadedData'
                      isTaskUpload={true}
                      outsourcedTask={true}
                    />
                  </Grid>
                  <Grid item xs={4} md={6} lg={4}>
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      className='fildborder mr-30'
                    >
                      <Typography className='task-mr-23'>Completed</Typography>

                      <Box className=''>
                        <AntSwitch
                          name='iApprovalNeeded'
                          onChange={(event, checked) => {
                            // setFieldValue('iApprovalNeeded', checked);
                          }}
                          // value={values?.complitd}
                          // defaultChecked
                          inputProps={{ 'aria-label': 'ant design' }}
                          // checked={
                          //   typeof values?.isBatchTracking === 'string'
                          //     ? false
                          //     : values?.isBatchTracking === true
                          // }
                          // onChange={handleChange}
                          // onChange={(e: any) => {
                          //   // handleChecked(e);
                          //   // e.target.checked;
                          //   setFieldValue('isBatchTracking', e.target.checked);
                          // }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Box>
                  <Box className='tool-box'>
                    <Box>
                      <CustomModalButton
                        variant='outlined'
                        className='blue-color'
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </CustomModalButton>
                    </Box>
                    <Box>
                      <CustomModalButton
                        variant='contained'
                        type='submit'
                        className='blue-bg ml-20'
                        // onClick={async () => {
                        //   if (id && !partData?.isVariantAdd) {
                        //     setInventoryModalData({ ...updatePartData, id });
                        //   } else {
                        //     setInventoryModalData(updatePartData);
                        //   }
                        //   handleInventory();
                        //   handleClose();
                        // }}
                      >
                        Confirm
                      </CustomModalButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            isApproved: 0,
            remarks: '',
          }}
          enableReinitialize={true}
          // validationSchema={SignupSchema}
          onSubmit={(values) => {
            if (isTaskApproval) {
              updateApprovalNeeded({
                ...values,
                workOrder: id,
                mainTaskType: mainTaskType,
                id: taskId,
              });
            } else {
              approvalWorkOrder({ ...values, id: id });
            }
            handleClose();
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={styleConfirm}>
                <Box>
                  <Box className='title-head-box'>
                    <Typography className='top-up-name'>
                      {' '}
                      {isTaskApproval ? 'Task Approval' : 'Submitted Work Order Approval'}
                    </Typography>
                    <CloseIcon onClick={handleClose} className='close-icon' />
                  </Box>
                  <Box className='modal-body'>
                    <Box className='section-one'>
                      {/* <Typography className='title-typography'> Enter Raw Material Details</Typography> */}
                    </Box>
                    <Grid className='buttonFlex' mt={'5px'} mb={'10px'} ml={'20px'} mr={'10px'}>
                      <RawCustomButton
                        sx={{
                          border:
                            values?.isApproved == 1 ? '2px solid #265AA8' : '1px solid #265AA8',
                        }}
                        variant='outlined'
                        onClick={(newValue: any) => {
                          setFieldValue(`isApproved`, 1);
                        }}
                        // disabled={values?.isInspection === true}
                      >
                        Yes
                      </RawCustomButton>
                      <RawCustomButton
                        sx={{
                          border:
                            values?.isApproved == 2 ? '2px solid #265AA8' : '1px solid #265AA8',
                        }}
                        variant='outlined'
                        className='mr-15'
                        onClick={(newValue: any) => {
                          setFieldValue(`isApproved`, 2);
                        }}
                        // disabled={values?.batch?.[index]?.isInspection === false}
                      >
                        No
                      </RawCustomButton>
                    </Grid>
                    <Grid item xs={12} container spacing={0} className='fildleft'>
                      <Grid item xs={7}>
                        <Typography className='field-title'>Remarks</Typography>
                        <InputField
                          // error={!errors.operation || !touched.operation ? false : true}
                          placeholder='Enter Remarks'
                          fullWidth
                          onChange={handleChange}
                          name='remarks'
                          value={values?.remarks}
                          // InputProps={{
                          //   endAdornment: touched.name && errors.name && icon,
                          // }}
                        />
                        {/* {touched.operation && errors.operation && (
                  <Typography className='validation-text'>
                    {typeof errors.operation === 'string' ? errors.operation : ''}
                  </Typography>
                )} */}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  <Box className='tool-box-confirm'>
                    <CustomModalButton variant='contained' type='submit' className='blue-bg ml-20'>
                      Confirm
                    </CustomModalButton>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
export default WorkOrderApproval;
