import { get, post, put, patch } from '../Utils/index';

export const login = async (data) => {
  return post(`/customer/auth/login`, data);
};
export const forgotPassword = (data) => {
  return post(`/customer/auth/forgot-password`, data);
};
export const changePassword = (data) => {
  return put(`/customer/profile/change-password`, data);
};
export const updateProfile = async (data) => {
  delete data['id'];
  return patch(`/customer/profile/update-info`, data);
};
export const verifyToken = () => {
  return get(`/customer/profile/me`);
};
export const resetPassword = (data) => {
  return post(`/customer/auth/reset-password`, data);
};
export const logout = () => {
  return post(`/customer/profile/logout`);
};

export const authApi = {
  login,
  logout,
  verifyToken,
  forgotPassword,
  changePassword,
  resetPassword,
  updateProfile,
};
