import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import BookIcon from '../../assets/svg/BookIcon';
import { Collapse, List, ListItemText } from '@mui/material';
import _ from 'lodash';

const RecentActivityCard = ({ cardData }: any) => {
  const CardWrapper = styled(Card)(() => ({
    border: '1px solid #DEDEDE',
    height: '72px !important',
    borderRadius: '2px',
    padding: '5px 16px 3px 16px',
    margin: '0px 5px 10px 4px',
    '.MuiCardContent-root': {
      padding: '0px',
    },
    '.MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
    '.parent-recent': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.title': {
      color: '#1D1B1B',
      fontWeight: 400,
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
    },
    '.cursor-pointer': {
      cursor: 'pointer',
      fontSize: '16px',
    },
    '.comment': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '11px',
    },
    '.discussion-btn': {
      marginTop: '14px',
      borderRadius: '10px',
      border: '1px solid #265AA8',
    },
    '.messgae': {
      color: '#777676',
      fontWeight: 400,
      fontSize: '12px',
      paddingTop: '5px',
    },
  }));
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    // setOpen(!open);
  };
  return (
    <CardWrapper
      elevation={0}
      // style={{
      //   boxShadow: !open ? '0px 2px 10px rgba(40, 40, 40, 0.1)' : 'inset 0px -2px 0px #265AA8',
      // }}
    >
      <CardContent>
        <Box onClick={handleClick} display='flex'>
          <ListItemText>
            <Box className='parent-recent'>
              <Typography className='title'>
                <BookIcon />
                {_.get(cardData, 'title', '-')}
              </Typography>
              {/* <Box>
                {open ? (
                  <ExpandLess className='cursor-pointer' />
                ) : (
                  <SlArrowRight className='cursor-pointer' />
                )}
              </Box> */}
            </Box>
            <Typography className='messgae'>{!open && _.get(cardData, 'message', '-')}</Typography>
          </ListItemText>
        </Box>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <Typography>{_.get(cardData, 'message', '-')}</Typography>
            {/* <Box className='comment'> */}
            {/* <Typography>By {_.get(cardData?.createdBy, 'name', '-')}</Typography> */}
            {/* <Box>
                <CommentIcon />
                20
              </Box> */}
            {/* </Box> */}
            {/* <Button variant='outlined' fullWidth className='discussion-btn'>
              View Discussion
            </Button> */}
          </List>
        </Collapse>
      </CardContent>
    </CardWrapper>
  );
};

export default RecentActivityCard;
