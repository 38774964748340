import { get, post, patch, deleteRequest } from '../Utils/index';

export const addConsumables = async (data): Promise<any> => {
  delete data['supplierId'];
  return post(`/customer/consumable`, data).then((res) => res.data);
};
export const getSingleConsumables = async (data): Promise<any> => {
  const id = data?.queryKey[1];
  return get(`/customer/consumable/${id}`).then((res) => res.data);
};
export const getConsumables = async (
  data
): Promise<any | undefined | { data: { results: [] } } | any> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/consumable`, payload).then((res) => {
    return res.data;
  });
};
export const updateConsumables = async (data): Promise<any> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/consumable/${id}`, data).then((res) => res.data);
};
export const getSingleDeletedConsumables = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/consumable/deleted/${id}`);
};
export const hardDeleteConsumables = async (id) => {
  return deleteRequest(`/customer/consumable/hard-delete/${id}`);
};
export const deleteConsumables = async (id) => {
  return deleteRequest(`/customer/consumable/${id}`);
};
export const restoreConsumable = async (id) => {
  return patch(`/customer/consumable/restore/${id}`);
};
export const validateConsumablesName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/consumable/validate`, data);
};

export const consumablesApi = {
  getConsumables,
  addConsumables,
  updateConsumables,
  getSingleConsumables,
  deleteConsumables,
  getSingleDeletedConsumables,
  hardDeleteConsumables,
  restoreConsumable,
  validateConsumablesName,
};
