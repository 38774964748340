import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CustomButton from '../commonComponents/CustomButton';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import Require from '../commonComponents/Required';
import SupplierConfirm from '../../Modals/SupplierConfirm';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useGetSupplier } from '../../hooks/supplierHooks';
import Loading from 'react-fullscreen-loading';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Supplier name is Required')
    .matches(/^[ a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  contact: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit phone number.')
    .required('Supplier phone number is required.'),
  code: Yup.string().trim().required('Supplier code is Required'),
});

const Input = styled(TextField)(() => ({
  borderRadius: '4px',
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '40px',
    padding: '1px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8A8A8A',
      borderRadius: '4px',
    },
  },
}));

const HeadBox = styled(Box)(() => ({
  height: '82.8vh',
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '10px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 8px 0px',
    color: '#000',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.footer-padding': {
    padding: '16px 56px 10px 20px',
  },
}));

const AddSupplier = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({});
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const { data: supplierDetails, isSuccess } = useGetSupplier(id, !!id);

  const supplier = supplierDetails;

  const breadCrumbData = [
    {
      title: 'Catalogue',
      link: '/catalogue',
    },
    {
      title: `Supplier`,
      link: `/catalogue`,
      activeTab: '5',
    },
    {
      title: `${isEdit ? supplier?.name : 'Add Supplier'}`,
      link: isEdit ? `/edit-supplier/${id}` : '/add-supplier',
    },
  ];
  const icon = <AiOutlineInfoCircle color='red' />;

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`${isEdit ? 'Edit' : 'Add'} a Supplier`}
        backToLink='/catalogue'
        activeTab='5'
      />
      <HeadBox className='main-component-padding'>
        <Formik
          initialValues={{
            name: isEdit ? supplier?.name : '',
            contact: isEdit ? supplier?.contact : '',
            code: isEdit ? supplier?.code : '',
          }}
          validationSchema={SignupSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            setValue(values);
            handleOpen();
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Typography className='title-typography'> Enter Supplier Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Supplier Name
                    <Require />
                  </Typography>
                  <Input
                    error={!errors.name || !touched.name ? false : true}
                    placeholder='Enter Supplier Name'
                    fullWidth
                    onChange={handleChange}
                    name='name'
                    value={values.name}
                    InputProps={{
                      style: { fontSize: 14 }, // Set the font size here
                      endAdornment: touched.name && errors.name && icon,
                    }}
                  />
                  {touched.name && errors.name && (
                    <Typography className='validation-text'>
                      {isEdit ? errors.name : errors.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Contact
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.contact || !touched.contact ? false : true}
                    placeholder='Enter Supplier Phone Number'
                    fullWidth
                    onChange={handleChange}
                    name='contact'
                    value={values.contact}
                    InputProps={{
                      style: { fontSize: 14 }, // Set the font size here
                      endAdornment: touched.contact && errors.contact && icon,
                    }}
                  />
                  {touched.contact && errors.contact && (
                    <Typography className='validation-text'>{errors.contact}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className='field-title'>
                    Supplier Code
                    <Require />
                  </Typography>

                  <Input
                    error={!errors.code || !touched.code ? false : true}
                    placeholder='Enter Supplier Code'
                    fullWidth
                    onChange={handleChange}
                    value={values.code}
                    name='code'
                    InputProps={{
                      style: { fontSize: 14 }, // Set the font size here
                      endAdornment: touched.code && errors.code && icon,
                    }}
                  />
                  {touched.code && errors.code && (
                    <Typography className='validation-text'>{errors.code}</Typography>
                  )}
                </Grid>
              </Grid>
              <Box className='button-box'>
                <Box className='footer-padding'>
                  <CustomButton
                    className={!id && !(isValid && dirty) ? 'disable-btn' : 'blue-bg'}
                    variant='contained'
                    type='submit'
                  >
                    {isEdit ? 'Save Changes' : 'Add Supplier'}
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {isModalOpen && (
          <SupplierConfirm supplierData={value} isOpen={isModalOpen} handleClose={handleClose} />
        )}
        <Loading
          loading={id && !isSuccess ? true : false}
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBox>
    </React.Fragment>
  );
};
export default AddSupplier;
