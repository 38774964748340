import { get, post, patch, deleteRequest } from '../Utils/index';

export const addIssueMapping = async (data) => {
  return post(`/customer/issue-mapping`, data);
};
export const getSingleIssueMapping = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/issue-mapping/${id}`);
};
export const updateIssueMapping = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/issue-mapping/${id}`, data);
};
export const deleteIssueMapping = async (id) => {
  return deleteRequest(`/customer/issue-mapping/${id}`);
};
export const getIssueMapping = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/issue-mapping`, payload);
};
export const getIssueFullList = async () => {
  return get(`/customer/issue-mapping/full-list`);
};
export const issueMappingApi = {
  addIssueMapping,
  getSingleIssueMapping,
  getIssueMapping,
  updateIssueMapping,
  getIssueFullList,
  deleteIssueMapping,
};
