import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import ErrorPopup from './ErrorPopup';
import './../assets/css/modal.css';
import { Formik } from 'formik';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { useTicketResolved } from '../hooks/ticketHooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};

const CustomYesModalButton = styled(Button)(() => ({
  padding: '10px 16px 10px !important',
  borderRadius: '4px !important',
  fontSize: '14px !important',
  fontWeight: '600 !important',
  height: '40px',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'center',
}));
const TextFiled = styled(Typography)(() => ({
  color: 'rgba(2, 2, 2, 1)',
  fontSize: '18px !important',
  fontWeight: '600px',
  padding: '24px 0px 0px 0px',
}));

const InventoryBox = styled(Box)(() => ({
  '.note-title': {
    fontSize: '12px',
    color: '#014686',
    marginTop: '8px',
  },
  '.note-font-weight': {
    fontWeight: 700,
  },
}));
const BoxWrapper = styled(Box)(() => ({
  '.conform-title': {
    fontSize: '18px !important',
    fontWeight: 600,
    padding: '1.5rem 8rem',
    textAlign: 'center',
  },
  '.sub-box-confirm': {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px',
  },
  '.title-head-icon': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 24px 0px 24px',
  },
}));

interface Prop {
  handleClose: () => void;
  isOpen: boolean;
  data?: any;
  id?: any;
  setResolveOpen?: any;
}
const IssuesConfirmModal = (props: Prop) => {
  const { handleClose, isOpen: open, id } = props;
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [showStockErr, setShowStockErr] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { mutate: completeTask } = useTicketResolved();
  // const {
  //   mutate: addCustomer,
  //   isError: isaddCustomerError,
  //   error: addCustomerError,
  //   isLoading,
  // } = useAddCustomer();
  // const {
  //   mutate: updateCustomer,
  //   isError: isupdateCustomerError,
  //   error: updateCustomerError,
  // } = useUpdateCustomer();

  // React.useEffect(() => {
  //   if (isaddCustomerError || isupdateCustomerError) {
  //     isaddCustomerError
  //       ? setErrorMessage(addCustomerError?.data?.message)
  //       : setErrorMessage(updateCustomerError?.data?.message);
  //     setIsErrorModalOpen(true);
  //     // handleClose();
  //   }
  // }, [isaddCustomerError, isupdateCustomerError]);
  function ActionList(props: PickersActionBarProps) {
    const { onAccept, onClear, onSetToday } = props;
    const actions = [
      { text: 'Apply', method: onAccept },
      { text: 'Clear', method: onClear },
      { text: 'Today', method: onSetToday },
    ];
    return (
      <Box className='datepicker-btn-wrapper-factory-layout'>
        <Button className='blue-color datepicker-btn-factory-layout' onClick={() => onClear()}>
          {actions[1].text}
        </Button>
        <Button className='blue-bg datepicker-btn' variant='outlined' onClick={() => onAccept()}>
          {actions[0].text}
        </Button>
      </Box>
    );
  }
  const defaultDateTime = new Date();
  defaultDateTime.setHours(10);
  defaultDateTime.setMinutes(0);
  defaultDateTime.setSeconds(0);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Formik
          initialValues={{}}
          enableReinitialize={true}
          // validationSchema={SignupSchema}
          // onSubmit={(values) => {
          //   updateCustomer({ ...values, props.data?.id });
          // }}
          // onClick={() => {
          //   if (showStockErr) {
          //     (id && !isVariantAdd) || (props.data?.id && !isVariantAdd)
          //       ? updateCustomer({ ...props.data })
          //       : addCustomer({ ...props.data });
          //   } else {
          //     setShowStockErr(true);
          //   }
          // }}
          onSubmit={(values) => {
            // if (props.data.isWorkOrder === 0) {
            // updateCustomer({ ...values, ...props.data, isWorkOrder: 1 });
            // setShowStockErr(true);
            // }
          }}
          // onSubmit={(values: any) => {
          //   setOpenModal(true);
          //   setValue({
          //     ...values,
          //   });
          // }}
        >
          {({ handleSubmit }) => (
            <InventoryBox sx={style}>
              <form onSubmit={handleSubmit}>
                <BoxWrapper>
                  <Box className='box-sub-info'>
                    {/* <Typography className='span-color'>{props?.data?.quantity || 0}</Typography> */}
                    <TextFiled className='conform-title'>
                      Are you sure you want to resolve this issue?
                    </TextFiled>

                    {showStockErr && (
                      <Typography className='validation-text'>
                        {/* {'Please Enter Valid Stock Quantity'} */}
                      </Typography>
                    )}
                  </Box>

                  <Box className='sub-box-confirm'>
                    {/* <CustomModalButton
                variant='outlined'
                className='blue-color edit-btn'
                onClick={handleClose}
              >
                Cancel
              </CustomModalButton> */}
                    <CustomYesModalButton
                      // disabled={isLoading}
                      variant='outlined'
                      // type=''
                      onClick={() => {
                        handleClose();
                      }}
                      className='ml-20'
                      // ! for future use
                      // onClick={() => {
                      //   if (showStockErr) {
                      //     (id && !isVariantAdd) || (props.data?.id && !isVariantAdd)
                      //       ? updateCustomer({ ...props.data })
                      //       : addCustomer({ ...props.data });
                      //   } else {
                      //     setShowStockErr(true);
                      //   }
                      // }}
                      // onClick={() => {
                      //   id ? updateCustomer({ ...props.data }) : addCustomer({ ...props.data });
                      //   setShowStockErr(true);
                      // }}
                    >
                      Cancel
                    </CustomYesModalButton>
                    <CustomYesModalButton
                      // disabled={isLoading}
                      variant='contained'
                      type='submit'
                      className='blue-bg ml-20'
                      onClick={() => {
                        if (typeof completeTask == 'function') {
                          completeTask(id);
                        }
                        handleClose();
                        // setResolveOpen(false);
                      }}

                      // ! for future use
                      // onClick={() => {
                      //   if (showStockErr) {
                      //     (id && !isVariantAdd) || (props.data?.id && !isVariantAdd)
                      //       ? updateCustomer({ ...props.data })
                      //       : addCustomer({ ...props.data });
                      //   } else {
                      //     setShowStockErr(true);
                      //   }
                      // }}
                      // onClick={() => {
                      //   id ? updateCustomer({ ...props.data }) : addCustomer({ ...props.data });
                      //   setShowStockErr(true);
                      // }}
                    >
                      Confirm
                    </CustomYesModalButton>
                  </Box>
                </BoxWrapper>
              </form>
            </InventoryBox>
          )}
        </Formik>
      </Modal>
      {isErrorModalOpen && (
        <ErrorPopup
          isOpen={isErrorModalOpen}
          handleClose={handleErrorModalClose}
          errorDetails={errorMessage}
        />
      )}
    </div>
  );
};
export default IssuesConfirmModal;
