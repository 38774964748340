import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useGetIssueMapping, useGetIssueMappings } from '../../hooks/issueMappingHooks';
import AddSetupIssue from './AddSetupIssue';
import Loading from 'react-fullscreen-loading';
import { useLocation, useParams } from 'react-router-dom';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';

const HeadBoxWrapper = styled(Box)(() => ({
  '.header-box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.header-box-get-issue': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  '.margin-left': {
    marginLeft: '10px',
  },
  '.margin-bottom': {
    marginBottom: '24px',
  },
  '.title-margin': {
    margin: '0px !important',
  },
}));

interface issueI {
  issueType?: string;
  objectType?: string;
  owner?: {
    name?: string;
    id?: string;
  };
  issueReqNo?: string;
  reasons?: string;
  id?: string;
}

const AddIssueMapping = () => {
  const { state } = useLocation();
  const { mutate: readNotification } = useUpdateNotification();

  const { id } = useParams<string>();

  const { data: getSingleIssueData } = useGetIssueMapping(id, !!id);
  const { data: getIssuesResponse, isSuccess, isError } = useGetIssueMappings(true, 1);
  const currentIssueNumber = getIssuesResponse?.totalResults;

  // TODO : future use
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  // const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  // const permission = userRole?.data?.permission;
  // const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));

  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);

  const breadCrumbData = [
    // {
    //   title: 'Factory Management',
    //   link: '/issue-mapping',
    // },
    {
      title: 'Issue ',
      link: '/issue-mapping',
      activeTab: '4',
    },
    {
      title: 'add Issue',
      link: '/add-issue-mapping',
    },
  ];

  return (
    <React.Fragment>
      <BreadcrumbNavigation
        breadCrumbData={breadCrumbData}
        pageTitle={`Issue Mapping`}
        backToLink={null}
      />
      <HeadBoxWrapper>
        <Box>
          <AddSetupIssue
            issueData={getSingleIssueData?.issueMapping}
            issueReqNo={currentIssueNumber}
          />
        </Box>

        <Loading
          loading={!isSuccess ? (!isError ? true : false) : false}
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWrapper>
    </React.Fragment>
  );
};
export default AddIssueMapping;
