import React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
import { useAddAccessory, useUpdateAccessory } from '../hooks/accessoryHooks';
import ErrorPopup from './ErrorPopup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.accessories-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '70px 20px 30px 30px !important',
  },
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface partI {
  isOpen: boolean;
  handleClose: () => void;
  partData: any;
  handleInventory: () => void;
  setInventoryModalData: any;
}
const AccessoriesConfirm = (props: partI) => {
  const { isOpen, handleClose, partData } = props;
  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [showStockErr, setShowStockErr] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const { mutate: addAccessory, isError: isAddPartError, error: addPartError } = useAddAccessory();

  const {
    mutate: updateAccessory,
    isError: isUpdatePartError,
    error: updatePartError,
  } = useUpdateAccessory();

  React.useEffect(() => {
    if (isAddPartError || isUpdatePartError) {
      isAddPartError
        ? setErrorMessage(addPartError?.data?.message)
        : setErrorMessage(updatePartError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [isAddPartError, isUpdatePartError]);

  const updatePartData = {
    name: partData?.name,
    number: partData?.number,
    quantity: partData?.quantity,
    manufacturer: partData?.manufacturer,
    dateBought: partData?.dateBought,
    category: partData?.category,
    // ...(partData?.supplierPartNumber && { supplierPartNumber: partData?.supplierPartNumber }),
    // ...(partData?.minimumStockOrder && { minimumStockOrder: partData?.minimumStockOrder }),
    // ...(partData?.supplierId && { supplier: partData?.supplierId }),
    // ...(partData?.procurementTime && { procurementTime: partData?.procurementTime }),
    // avlStock: partData?.avlStock,
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the Accessories details</Typography>
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>Accessories Details</CustomSubTitle>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <CustomTypography>Accessories Name </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <CustomTypography>Accessories Number </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'number', '')}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <CustomTypography>Manufacturer </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'manufacturer', '-')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4} md={6} lg={4}>
                  <CustomTypography>Quantity </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'quantity', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={6} lg={4}>
                  <CustomTypography>Date Bought </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {moment(partData?.dateBought).format('hh:mm A, DD MMM, YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='accessories-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color edit-btn'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              // TODO: functionality future for Inventory use
              // onClick={async () => {
              //   if (id && !partData?.isVariantAdd) {
              //     setInventoryModalData({ ...updatePartData, id });
              //   } else {
              //     setInventoryModalData(updatePartData);
              //   }
              //   handleInventory();
              //   handleClose();
              // }}
              onClick={() => {
                id ? updateAccessory({ ...updatePartData, id }) : addAccessory(updatePartData);
                setShowStockErr(true);
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
    </Modal>
  );
};
export default AccessoriesConfirm;
