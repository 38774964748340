import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import { useDeletePart, useHardDeletePart, useRestorePart } from '../../hooks/partHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import moment from 'moment';
import { useUpdateNotification } from '../../hooks/notificationHooks';
import { useQueryClient } from '@tanstack/react-query';
import { user } from '../../queryKeys/userManagement';
import './../../assets/css/detailsInformationBox.css';
import ErrorPopup from '../../Modals/ErrorPopup';
import AddInventory from '../../Modals/AddInventory';
import CustomButton from '../commonComponents/CustomButton';
import BarChart from '../commonComponents/BarChart';
import { useGetSingleWorkOrder } from '../../hooks/workorderHooks';
import TableComponent from '../commonComponents/Table';
import TaskDetailsView from './TaskDetailsView';
import FinalApprovalConfirm from '../../Modals/FinalApprovalConfirm';
import ReWorkConfirm from '../../Modals/ReWorkTaskConfirm';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));
const ShareBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',
  padding: '10px 60px',
  color: '#0160B9',
  '.share-title': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    gap: '10px',
    cursor: 'pointer',
  },
}));
const HeadBoxWrapper = styled(Box)(() => ({
  // height: '78vh !important',
  '.stock-btn': {
    // margin: '24px 0px 0px 24px',
  },
  '.usage-title': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 3px 40px !important',
    fontWeight: '600 !important',
  },
  '.usage-title-mr1': {
    lineHeight: '26px !important',
    height: '24px !important',
    fontSize: '24px !important',
    color: 'rgba(28, 28, 28, 1)',
    fontWeight: '600 !important',
    textAlign: 'center',
    paddingTop: '1rem',
  },
  '.parent-Approval': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '1rem',
  },
  '.usage-title-mr2': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '40px',
    marginTop: '10px',
  },
  '.validation-text': {
    color: 'red',
    fontSize: '12px',
  },
  '.addworkorderbutton-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.parent-chart': {
    margin: '15px 0px',
  },
  '.button-box': {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    boxShadow: 'rgba(40, 40, 40, 0.08) 0px -6px 4px -6px',
    position: 'absolute',
    bottom: '1px',
    left: '0px',
    width: '100%',
  },
  '.field-title': {
    fontSize: 500,
    fontWeight: 600,
    color: 'rgba(2, 2, 2, 1)',
  },
  '.disable-btn': {
    background: '#C9C9C9',
  },
  '.rm-footer-padding': {
    padding: '16px 0px 10px 20px',
  },
  '.border': {
    border: '1px solid #8A8A8A',
    padding: '20px 10px 18px 30px',
    marginTop: '10px',
  },
  '.fildborder': {
    border: '1px solid #8A8A8A',
    borderColor: '#8A8A8A',
    borderRadius: '4px',
    padding: '7px 20px 7px 0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    lable: 'sadas',
  },
  '.work-detail-divider-mr-1': {
    margin: '15px 0px 0px 40px',
  },
  '.button-mr-2': {
    margin: '25px 0px 20px 0px',
  },
  '.borderMargin': { padding: '10px 02px 0px 0px' },
  '.buttonFlex': {
    display: 'flex',
    padding: '0px 0px',
  },
  '.title-basic': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '20px 0px 18px 0px !important',
    fontWeight: '600 !important',
  },
  '.details': {
    display: 'flex',
    marginLeft: '20px',
  },
  '.checklist-details': {
    display: 'flex',
    marginLeft: '35px',
  },

  '.details-color': {
    fontSize: 14,
    fontWeight: 500,
    color: '#8A8A8A',
  },
  '.details-mr1': {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: '8px',
    padding: '12px',
    color: '#8A8A8A',
    height: '15px',
  },
  '.details-mr9': {
    fontSize: 14,
    fontWeight: 600,
  },
  '.details-mr10': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.details-mr7': {
    fontSize: 14,
    fontWeight: 500,
    padding: '15px 0px 0px 30px !important',
    color: '#8A8A8A',
    height: '15px',
  },
  '.details-mr6': {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: '10px',
    padding: '12px',
    color: '#020202',
    height: '15px',
  },
  '.details-mr8': {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: '10px',
    padding: '5px 0px 0px 0px',
    color: '#020202',
    height: '15px',
  },
  '.details-mr5': {
    marginLeft: '10px',
    color: '#8A8A8A',
  },
  '.details-mr3': {
    marginLeft: '10px',
  },
  '.details-border': {
    border: '1px solid #8A8A8A',
    padding: '5px 5px 5px 5px',
    display: 'flex',
    marginTop: '8px',
    marginLeft: '8px',
  },
  '.details-mr4': {
    marginLeft: '20px',
  },
  '.details-mr2': {
    fontSize: 14,
    fontWeight: 500,
    padding: '0px 0px 18px 0px',
    color: '#020202',
  },
  '.details-sub-mr1': {
    marginTop: '30px',
  },

  '.color-mr1': {
    color: '#265AA8',
  },
  '.MuiDivider-root': {
    margin: '0px 0px !important',
  },
  '.work-divider-mr-2': {
    paddingLeft: '1px',
    paddingTop: '0px',
    margin: '0px 0px !important',
  },
  '.table-mr1': {
    margin: '0px 0px 10px 0px',
  },
  '.mr-18': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.mr-19': {
    lineHeight: '32px !important',
    height: '32px !important',
    fontSize: '18px !important',
    margin: '30px 0px 20px 0px !important',
    fontWeight: '600 !important',
  },
  '.menu-item-span': {
    color: '#9E9E9E !important',
  },
  '.mr-23': {
    marginRight: '315px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.mr-31': {
    marginRight: '299px',
    display: 'flex',
    justifyContent: 'start',
  },
  '.mr-30': {
    marginTop: '30px',
  },
  '.checklist-text': {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '24px',
  },
  '.process-header': {
    height: '40px !important',
    border: '1px solid #DEDEDE !important',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '8px 8px 0px 0px',
    alignItems: 'center',
  },

  '.process-header-field-title': {
    fontSize: 14,
    fontWeight: 400,
  },
  '.border-items': {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '8px !important',
  },
  '.date-picker-fieldset': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8A8A8A',
      },
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
  '.date-picker': {
    width: '100%',
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '8.5px 14px !important',
    },
    '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
}));
// ! for testing purposes barChart Data
const barChartData = [
  {
    name: 'Inspection 1',
    color: '#1AD04C',
    data: [
      {
        name: 'd1',
        y: 98,
      },
      {
        name: 'd2',
        y: 98,
      },
      {
        name: 'd3',
        y: 98,
      },
      {
        name: 'week 4',
        y: 98,
      },
      {
        name: 'd5',
        y: 98,
      },
      {
        name: 'd6',
        y: 98,
      },
      {
        name: 'd7',
        y: 98,
      },
      {
        name: 'd8',
        y: 98,
      },
      {
        name: 'd9',
        y: 98,
      },
      {
        name: 'd10',
        y: 98,
      },
    ],
  },
  {
    name: 'Inspection 2',
    color: '#FF9900',
    data: [
      {
        name: 'd1',
        y: 78,
      },
      {
        name: 'd2',
        y: 78,
      },
      {
        name: 'd3',
        y: 78,
      },
      {
        name: 'week 4',
        y: 78,
      },
      {
        name: 'd5',
        y: 78,
      },
      {
        name: 'd6',
        y: 78,
      },
      {
        name: 'd7',
        y: 78,
      },
      {
        name: 'd8',
        y: 78,
      },
      {
        name: 'd9',
        y: 78,
      },
      {
        name: 'd10',
        y: 78,
      },
    ],
  },
  {
    name: 'Inspection 3',
    color: '#BF0B4C',
    data: [
      {
        name: 'd1',
        y: 58,
      },
      {
        name: 'd2',
        y: 58,
      },
      {
        name: 'd3',
        y: 58,
      },
      {
        name: 'week 4',
        y: 58,
      },
      {
        name: 'd5',
        y: 58,
      },
      {
        name: 'd6',
        y: 58,
      },
      {
        name: 'd7',
        y: 58,
      },
      {
        name: 'd8',
        y: 58,
      },
      {
        name: 'd9',
        y: 58,
      },
      {
        name: 'd10',
        y: 58,
      },
    ],
  },
];

const DetailsWorkOrder = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const [showApproval, setShowApproval] = React.useState(false);
  const [reWorTask, setReWorkTask] = React.useState(false);
  const colors = ['#1AD04C', '#FF9900', '#BF0B4C'];
  const { data: singleWorkOrder } = useGetSingleWorkOrder(true, id);
  const inputData = singleWorkOrder?.data?.qualityCheckTaskResponse?.measurements;
  const singleWorkOrderData = singleWorkOrder?.data;
  const taskLists = singleWorkOrder?.data?.taskList;
  const columnData =
    singleWorkOrder?.data?.qualityCheckTaskResponse?.measurements?.[0]?.dimensions || [];
  // ! column Data for workOrder measurement
  const measurementsCols = [
    { field: 'no', headerName: '#', flex: 0.2 },
    { field: 'measurements', headerName: 'Measurements', flex: 1 },
  ]?.concat(
    columnData?.map((data) => {
      return {
        field: data?.name,
        headerName: data?.name,
        flex: 1,
      };
    })
  );

  const measurementsRows = singleWorkOrderData?.qualityCheckTaskResponse?.measurements?.map(
    (obj, index) => {
      const measurements = {
        id: index + 1,
        no: index + 1,
        measurements: obj.title,
      };

      obj?.dimensions.forEach((dim) => {
        measurements[dim.name] = dim.value;
      });

      return measurements;
    }
  );
  const finalBarChartData = inputData?.map((inspection, index) => {
    const color = colors[index % colors.length];
    const data = inspection?.dimensions?.map((dimension) => ({
      name: dimension.name,
      y: parseInt(dimension.value),
    }));
    return {
      name: inspection.title,
      color,
      data,
    };
  });
  const currentDate: any = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  const [ganttDates, setGranttDatesState] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  const setGranttDates = (e, { startDate, endDate }) => {
    setGranttDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  const queryClient = useQueryClient();
  const userRole: any = queryClient.getQueryData([user.LOGGED_IN_USER]);
  const permission = userRole?.data?.permission;
  const isAllPermission = !_.isEmpty(permission?.find((role) => role?.userModule === 'all'));
  const isUpdateIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.edit === true
  );
  const isAddIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.add === true
  );
  const isDeleteIconShow = permission?.find(
    (role) => role?.userModule === 'part' && role?.delete === true
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const {
    mutate: deleteSinglePart,
    error: deletePartError,
    isError: isDeletePartError,
  }: any = useDeletePart();
  React.useEffect(() => {
    if (isDeletePartError) {
      setErrorMessage(deletePartError?.data?.message);
      setIsErrorModalOpen(true);
      // handleClose();
    }
  }, [isDeletePartError]);
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: restorePart } = useRestorePart();
  const { mutate: readNotification } = useUpdateNotification();
  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeletePart(id);
    } else {
      deleteSinglePart(id);
    }
  };

  const handlePartRestore = () => {
    if (isRestore) {
      restorePart(id);
    } else {
      // nothing to restore
    }
  };
  let breadCrumbData = [
    {
      title: 'Workorders',
      link: '/work-order',
    },
    {
      title: `New Production Request`,
      link: `/work-order`,
    },
  ];
  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: 'name',
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-part/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
    // {
    //   title: `+ Download PDF`,
    //   link: `/part/add-variant/add/${id}`,
    // },
  ];
  React.useEffect(() => {
    if (state?.isRead) {
      readNotification(state?.id);
      state.isRead = false;
    }
  }, []);
  const measurementsTableData = {
    columns: measurementsCols,
    rows: measurementsRows,
    // detailsRedirection: '/tool-details',
  };
  const isHideOption = location.pathname.startsWith('/details-work-order'); // Final Report & submit button removed

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseConfirm = () => setShowApproval(false);
  const handleCloseReWork = () => setReWorkTask(false);
  const [currentData, setCurrentData] = React.useState({});

  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={`Final Report ${isHideOption ? ': Draft' : ''}`}
          backToLink={`/work-order`}
          isDetailPage={false}
          activeTab='2'
          detailPageData={detailPageData}
          handleDelete={handlePartDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handlePartRestore}
          isUpdateIconShow={!!isUpdateIconShow || isAllPermission}
          isDeleteIconShow={!!isDeleteIconShow || isAllPermission}
          isAddIconShow={!!isAddIconShow || isAllPermission}
        />
      </Box>
      <ShareBox>
        {/* TODO: functionality pending 15 april  */}
        {/* <Box className='share-title'>
          <GoShareAndroid fontSize={'16px'} /> Share
        </Box>
        <Box className='share-title'>
          <FiDownload /> Download PDF
        </Box> */}
      </ShareBox>
      <HeadBoxWrapper>
        <Grid container xs={12} padding={4}>
          <Box className='details-box-border padding-bottom'>
            {/* <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography> */}
            <Typography className='usage-title-mr1'>
              {`${singleWorkOrderData?.number ? singleWorkOrderData?.number : '-'} : ${
                singleWorkOrderData?.title ? singleWorkOrderData?.title : '-'
              } - Final Report`}
            </Typography>
            <Grid container spacing={2} className='usage-title-mr2'>
              <Grid spacing={0} item xs={10}>
                <Divider />
              </Grid>
            </Grid>
            {/* <Grid container display={'flex'}> */}
            <Grid
              container
              item
              xs={12}
              // spacing={2}
              className='details'
              sx={{ padding: '30px 30px 25px 25px' }}
            >
              <Grid container spacing={2} className=''>
                <Grid item xs={4} lg={4} className='details-color' sx={{ display: 'flex' }}>
                  <Box>
                    <Typography className='details-mr1'>Customer Name</Typography>
                    <Typography className='details-mr1'>Drawing</Typography>
                    <Typography className='details-mr1'>Created By</Typography>
                    <Typography className='details-mr1'>Product & Quantity</Typography>
                  </Box>
                  <Box>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.customer ? singleWorkOrderData?.customer : '-'}
                    </Typography>
                    <Typography className='details-mr6'>-</Typography>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.createBy?.name}
                    </Typography>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.productDetails?.map((data, ind) => {
                        return (
                          <Typography key={ind}>
                            {data?.product}-{data?.quantity}
                          </Typography>
                        );
                      })}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} lg={4} className='details-color' sx={{ display: 'flex' }}>
                  <Box>
                    <Typography className='details-mr1'>Start Date</Typography>
                    <Typography className='details-mr1'>Expected Time</Typography>
                    <Typography className='details-mr1'>Time Taken</Typography>
                    <Typography className='details-mr1'>Issues seen</Typography>
                    <Typography className='details-mr7'>Issue 1 reported on</Typography>
                    <Typography className='details-mr7'>Issue 2 reported on</Typography>
                  </Box>
                  <Box>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.startDate
                        ? moment(singleWorkOrderData?.startDate).format('hh:mm A, DD MMM, YYYY')
                        : '-'}
                    </Typography>
                    <Typography className='details-mr6'>
                      {(singleWorkOrderData?.expectedTimeSum / (1000 * 60 * 60)).toFixed(1)} Hrs
                    </Typography>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.actualTimeSum
                        ? (singleWorkOrderData?.actualTimeSum / (1000 * 60 * 60)).toFixed(1)
                        : '-'}{' '}
                      hrs
                    </Typography>
                    <Typography className='details-mr6'>Y</Typography>
                    <Typography className='details-mr6'>11/11/11, 12:00</Typography>
                    <Typography className='details-mr6'>11/11/11, 12:00</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} lg={4} className='details-color' sx={{ display: 'flex' }}>
                  <Box>
                    <Typography className='details-mr1'>Submitted On</Typography>
                    <Typography className='details-mr1'>Aprroved by</Typography>
                    <Typography className='details-mr1'>Approved on</Typography>
                  </Box>
                  <Box>
                    <Typography className='details-mr6'>
                      {singleWorkOrder
                        ? moment(singleWorkOrderData?.createdAt).format('hh:mm A, DD MMM, YYYY')
                        : '-'}
                    </Typography>
                    <Typography className='details-mr6'>
                      {singleWorkOrderData?.approved ? singleWorkOrderData?.approved?.name : '-'}
                    </Typography>
                    <Typography className='details-mr6'>
                      {singleWorkOrder
                        ? moment(singleWorkOrderData?.approvedDate).format('hh:mm A, DD MMM, YYYY')
                        : '*'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* {toolList?.map((data, ind) => {
            return ( */}
              <Grid container xs={12} className=''>
                {/* <Grid container item xs={4} className='details'> */}
                <Grid container item xs={1}>
                  <Typography className='details-mr1'>Remarks</Typography>
                </Grid>
                <Grid className='details-mr4'>
                  <Typography className='details-mr8'>{singleWorkOrderData?.remarks}</Typography>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
            <Grid className='details-sub-mr1'>
              <Typography id='modal-modal-title' className='details-sub-title'>
                QC Approval
              </Typography>
            </Grid>
            {/* //!new data */}
            <Grid container xs={12} display={'flex'} mt={'20px'} mb={'10px'} gap={'50px'}>
              <Grid item xs={3} pl={'30px'} display={'flex'} direction={'column'} width={'45%'}>
                <Box className='process-header' p={'8.5px 30px 8.5px 20px'}>
                  <Typography className='process-header-field-title'>Checklist #123</Typography>
                </Box>
                {singleWorkOrderData?.qualityCheckTaskResponse?.checklistItems?.map(
                  (checkList, index) => {
                    return (
                      <Grid container xs={12} className='border-items' key={index}>
                        <Grid
                          item
                          xs={6}
                          className='field-title'
                          pt={'0px !important'}
                          pl={'20px !important'}
                        >
                          <Box>
                            <Typography className='details-mr9'>{checkList?.name}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                          <Box>
                            <Typography className='details-mr10'>{checkList?.answer}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
              <Grid item md={7} className='details-color' display={'flex'}>
                <Box>
                  <Typography className='details-mr1'>Approved On</Typography>
                  <Typography className='details-mr1'>Approved by</Typography>
                  <Typography className='details-mr1'>Remarks</Typography>
                </Box>
                <Box>
                  <Typography className='details-mr6'>
                    {singleWorkOrder
                      ? moment(singleWorkOrderData?.qcApprovedDate).format('hh:mm A, DD MMM, YYYY')
                      : '-'}
                  </Typography>
                  <Typography className='details-mr6'>
                    {singleWorkOrderData?.qcApproved ? singleWorkOrderData?.qcApproved?.name : '-'}
                  </Typography>
                  <Typography className='details-mr6'>{singleWorkOrderData?.remarks}</Typography>
                </Box>
              </Grid>
            </Grid>
            {/* //! this is dainemic table start */}
            {measurementsTableData?.rows?.length > 0 && (
              <Grid item xs={12} m={'20px'}>
                <Grid item xs={12}>
                  <TableComponent
                    // data={BtnParts}
                    // isLoading={isToolLoading || isToolFetching}
                    // isPlusBtnHide={true}
                    isHideDropDown={true}
                    tableData={measurementsTableData || []}
                    isOptionShow={false}
                    isEditStock={true}
                    // paginationData={partInfo}
                    // page={page}
                    // setPage={setPage}
                    isShowFiveRecords={true}
                    // listName={'Tools List'}
                    // isNoEntityShow={tableDataForParts?.rows?.length <= 0 && isToolSuccess}
                    // moduleName={'tool'}
                    // redirectPage={'/add-tool'}
                    // totalResults={partInfo?.totalResults}
                    isFilter={false}
                    iSColumnsButton={false}
                  />
                </Grid>
              </Grid>
            )}
            {finalBarChartData?.length > 0 && (
              <Box className='parent-chart'>
                <BarChart
                  title='Comparison with operator data'
                  type='column'
                  data={finalBarChartData}
                  height='500'
                  ganttDates={ganttDates}
                  setGranttDatesState={setGranttDatesState}
                  setGranttDates={setGranttDates}
                />
              </Box>
            )}
            {/* //! task list View----- */}
            {taskLists?.map((taskData, index) => {
              return (
                <Box key={index}>
                  <TaskDetailsView taskData={taskData} />
                </Box>
              );
            })}
            {/* </Grid> */}
          </Box>
          {isHideOption && (
            <Box className='parent-Approval'>
              <CustomButton
                variant='contained'
                className={'blue-bg'}
                type='submit'
                onClick={() => {
                  setShowApproval(true);
                }}
              >
                {'Submit Final Approval'}
              </CustomButton>
            </Box>
          )}
        </Grid>
        <Loading
          // loading={
          //   id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          // }
          background='white'
          loaderColor='#265AA8'
        />

        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
        {open && (
          <AddInventory
            handleClose={handleClose}
            // handleOpen={handleOpen}
            data={currentData}
            isOpen={open}
          />
        )}
        <FinalApprovalConfirm
          isOpen={showApproval}
          handleClose={handleCloseConfirm}
          setReWorkTask={setReWorkTask}
          id={id}
        />
        <ReWorkConfirm open={reWorTask} handleClose={handleCloseReWork} id={id} />
      </HeadBoxWrapper>
    </>
  );
};
export default DetailsWorkOrder;
