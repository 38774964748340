import { deleteRequest, get, patch, post, put } from '../Utils/index';
export const addWorkorder = async (data) => {
  return post(`/customer/work-order`, data);
};

export const submitWorkorder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/submit/${id}`, data);
};
export const workOrderArrange = async (data) => {
  return post(`/customer/work-order/arrange`, data);
};

export const workorderApproval = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/approval-needed/${id}`, data);
};
export const getWorkordersCurrent = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, execute: false };
  return get(`/customer/work-order?type=current`, payload);
};
export const getWorkordersOngoing = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 5, execute: true };
  return get(`/customer/work-order?type=current`, payload);
};

export const getWorkordersCompleted = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10, type: 'completed' };
  return get(`/customer/work-order`, payload);
};

export const getWorkordersDraft = async (data) => {
  const page = data?.queryKey[1] || 1;
  const payload: any = { page, limit: 10, type: 'draft' };
  return get(`/customer/work-order`, payload);
};
export const getFullWorkorders = async () => {
  return get(`/customer/work-order/full-list`);
};
export const getWorkorder = async (data) => {
  const id = data.queryKey[1];
  return get(`/customer/work-order/${id}`);
};
export const getDeletedWorkorder = async (data) => {
  const id = data.queryKey[1];
  return get(`/customer/work-order/deleted/${id}`);
};
export const updateWorkorder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/${id}`, data);
};
export const updateScheduleWorkorder = async (data) => {
  const id = data?.id;
  delete data['id'];
  return patch(`/customer/work-order/schedule-chart/${id}`, data);
};
export const cancelWorkorder = async (id) => {
  return patch(`/customer/work-order/cancel/${id}`);
};
export const deleteWorkorder = async (id) => {
  return deleteRequest(`/customer/work-order/${id}`);
};
export const restoreWorkorder = async (id) => {
  return patch(`/customer/work-order/restore/${id}`);
};
export const hardDeleteWorkorder = async (id) => {
  return deleteRequest(`/customer/work-order/hard-delete/${id}`);
};

export const qualityInspection = (data) => {
  const id = data.queryKey[1];
  return get(`/customer/work-order/quality-inspection/${id}`);
};

export const updateQcApproval = async (data) => {
  const id = data?.qualityCheckTaskResponse?.id;
  delete data?.qualityCheckTaskResponse['id'];
  return put(`/customer/work-order/qc-approval/${id}`, data);
};
export const getSingleWorkOrder = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/work-order/final-report-draft/${id}`);
};

export const updateFinalApproval = async (data) => {
  const id = data?.id;
  delete data['id'];
  return put(`/customer/work-order/final-approval/${id}`, data);
};
export const workOrderNumber = async (data) => {
  return post(`/customer/work-order/workorder-number`, data);
};

export const workorderApi = {
  addWorkorder,
  getWorkordersCurrent,
  getWorkordersCompleted,
  getWorkordersDraft,
  getFullWorkorders,
  getWorkorder,
  updateWorkorder,
  cancelWorkorder,
  deleteWorkorder,
  restoreWorkorder,
  hardDeleteWorkorder,
  getDeletedWorkorder,
  updateScheduleWorkorder,
  submitWorkorder,
  workorderApproval,
  workOrderArrange,
  getWorkordersOngoing,
  qualityInspection,
  updateQcApproval,
  getSingleWorkOrder,
  updateFinalApproval,
  workOrderNumber,
};
