import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { workorderApi } from '../api/workorder';
import { workorder } from '../queryKeys/workorders';

export const useAddWorkorder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.addWorkorder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([workorder.WORKORDER]);
      const id = res.data.workOrder.id;
      // toast.success(data.message);
      // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
      // navigate(`/work-order`);
      navigate(`/draft-work-order/${id}`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useGetWorkordersCurrent = (isEnabled = true, page) => {
  return useQuery([workorder.WORKORDERS, page], workorderApi.getWorkordersCurrent, {
    enabled: isEnabled,
    select: (data) => data.data?.workOrders,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetWorkordersOnGoing = (isEnabled = true, page) => {
  return useQuery([workorder.WORKORDER_ONGOING, page], workorderApi.getWorkordersOngoing, {
    enabled: isEnabled,
    select: (data) => data.data?.workOrders,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetWorkordersCompleted = (isEnabled = true, page) => {
  return useQuery([workorder.WORKORDERS_COMPLETED, page], workorderApi.getWorkordersCompleted, {
    enabled: isEnabled,
    select: (data) => data.data?.workOrders,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useGetWorkordersDraft = (isEnabled = true, page) => {
  return useQuery([workorder.WORKORDERS_DRAFT, page], workorderApi.getWorkordersDraft, {
    enabled: isEnabled,
    select: (data) => data.data?.workOrders,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetFullWorkorders = () => {
  return useQuery([workorder.WORKORDERS + 'FULL'], workorderApi.getFullWorkorders, {
    select: (data) => data.data?.workOrders,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetWorkorder = (id, isEnabled = true) => {
  const navigate = useNavigate();
  return useQuery([workorder.WORKORDER, id], workorderApi.getWorkorder, {
    select: (data) => data?.data,
    enabled: isEnabled,
    onSuccess: (res: any) => {
      const data = res?.data;
    },

    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};
export const useGetDeletedWorkorder = (id, isEnabled = true) => {
  return useQuery([workorder.WORKORDER + 'DELETED', id], workorderApi.getDeletedWorkorder, {
    select: (data) => data?.data,
    enabled: isEnabled,
    onSuccess: (res: any) => {
      const data = res?.data;
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
    refetchOnWindowFocus: false,
  });
};

// export const useUpdateWorkorder = (val?: any) => {
//   const navigate = useNavigate();
//   const queryClient = useQueryClient();

//   return useMutation(workorderApi.updateWorkorder, {
//     onSuccess: (res, payload) => {
//       localStorage.setItem('isPopupDisplayed', 'false');
//       const data = res.data;
//       // toast.success(data.message);
//       queryClient.invalidateQueries([workorder.WORKORDERS]);
//       queryClient.refetchQueries([workorder.WORKORDER, data?.workOrder?.id]);
//       if ((val?.isAddSteps || val?.isEditSteps) && data?.workOrder?.workOrderStatus !== 'draft') {
//         navigate(`/work-order/view-procedure/${data?.workOrder?.id}`, {
//           state: { isModalShow: payload?.workOrderStatus === 'draft' ? false : true },
//         });
//       }
//       // OlD Functionality
//       // else if (data?.workOrder?.workOrderStatus !== 'draft') {
//       //   navigate(`/work-order/view-procedure/${data?.workOrder?.id}`);
//       // }
//       else {
//         // OlD Functionality
//         // navigate(`/work-order/edit-procedure/${data?.workOrder?.id}`);
//         window.location.href = `/work-order/view-procedure/${data?.workOrder?.id}`;
//       }
//     },
//     // },
//     onError: (err: any) => {
//       const errData = err?.data;
//       // toast.error(errData.message);
//     },
//   });
// };
export const useUpdateWorkorder = (val?: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(workorderApi.updateWorkorder, {
    onSuccess: (res, payload) => {
      // localStorage.setItem('isPopupDisplayed', 'false');
      const data = res.data;
      // if (data?.workOrder?.id) {
      if (data?.workOrder?.id) {
        navigate(`/draft-work-order/${data?.workOrder?.id}`);
      }
      // if (data?.id) {
      //   navigate(`/draft-work-order/${data?.id}`);
      // }
    },
    // },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useUpdateScheduleWorkorder = (val?: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(workorderApi.updateScheduleWorkorder, {
    onSuccess: (res, payload) => {
      // localStorage.setItem('isPopupDisplayed', 'false');
      const data = res.data;
      // toast.success(data.message);
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      queryClient.refetchQueries([workorder.WORKORDER, data?.workOrder?.id]);
    },
    // },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useCancelWorkorder = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(workorderApi.cancelWorkorder, {
    onSuccess: (res) => {
      const data = res.data;
      // toast.success(data.message);
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      queryClient.refetchQueries([workorder.WORKORDER, data?.workOrder?.id]);

      navigate(`/work-order`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useDeleteWorkorder = (activeTab = '1') => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(workorderApi.deleteWorkorder, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      queryClient.removeQueries([workorder.WORKORDER, id]);
      // toast.success(data.message);
      navigate(`/work-order`, { state: { activeTab } });
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useRestoreWorkorder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.restoreWorkorder, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      //toast.success(data.message);
      // const materialType = data?.part?.partMaterialType;
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useHardDeleteWorkorder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.hardDeleteWorkorder, {
    onSuccess: (res, id) => {
      const data = res.data;
      queryClient.invalidateQueries([workorder.WORKORDERS]);
      // queryClient.invalidateQueries([part.PART, id]);
      navigate('/deleted-items');
    },
    onError: (err: any) => {
      const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

export const useSubmitWorkorder = () => {
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.submitWorkorder, {
    onSuccess: (res) => {
      // queryClient.invalidateQueries([workorder.WORKORDER]);
      const data = res.data;
      // toast.success(data.message);
      // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
      navigate(`/work-order`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useWorkorderApproval = () => {
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.workorderApproval, {
    onSuccess: (res) => {
      // queryClient.invalidateQueries([workorder.WORKORDER]);
      const data = res.data;
      // toast.success(data.message);
      // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
      navigate(`/work-order`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useWorkorderArrange = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.workOrderArrange, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([workorder.WORKORDER]);
      const data = res.data;
      // toast.success(data.message);
      // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
      navigate(`/work-order`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

export const useQualityInspection = (isEnabled = true, id) => {
  return useQuery([workorder.WORKORDERS, id], workorderApi.qualityInspection, {
    enabled: isEnabled,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useQcApproval = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(workorderApi.updateQcApproval, {
    onSuccess: (res, payload) => {
      const data = res.data;
      navigate(`/draft-work-order/${data?.data?.id}`);
      // queryClient.invalidateQueries([workorder.WORKORDER]);
      // toast.success(data.message);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};

// ! new test
export const useGetSingleWorkOrder = (isEnabled = false, id) => {
  return useQuery([workorder.SINGLE_WORKORDER, id], workorderApi.getSingleWorkOrder, {
    enabled: isEnabled,
    select: (data) => data.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useUpdateFinalApproval = () => {
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(workorderApi.updateFinalApproval, {
    onSuccess: (res) => {
      // queryClient.invalidateQueries([workorder.WORKORDER]);
      const data = res.data;
      // toast.success(data.message);
      // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
      // navigate(`/work-order`);
    },
    onError: (err: any) => {
      const errData = err?.data;
      // toast.error(errData.message);
    },
  });
};
export const useWorkOrderNumber = () => {
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  return useMutation(workorderApi.workOrderNumber, {
    // onSuccess: (res) => {
    //   queryClient.invalidateQueries([workorder.WORKORDER_NUMBER]);
    //   const data = res.data;
    //   // toast.success(data.message);
    //   // navigate(`/work-order/add-procedure/${data?.workOrder?.id}`);
    //   navigate(`/work-order`);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   // toast.error(errData.message);
    // },
  });
};
