import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { customer } from '../queryKeys/catalogue';
import { PartErrI } from '../Types/part';
import { customerApi } from '../api/customer';

export const useGetCustomerOrder = (isEnabled, page) => {
  return useQuery([customer.CUSTOMERS, page], customerApi.getCustomerOrder, {
    // initialData: { data: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};
export const useGetCustomerConfirmation = (isEnabled, page) => {
  return useQuery([customer.CUSTOMERS_CONFIRM, page], customerApi.getCustomerConfirmation, {
    // initialData: { data: { results: [] } },
    enabled: isEnabled,

    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const getCustomerCompletedorders = (isEnabled, page) => {
  return useQuery([customer.CUSTOMERS_COMPLETE, page], customerApi.getCustomerCompletedorders, {
    // initialData: { data: { results: [] } },
    enabled: isEnabled,
    select: (res: any) => res?.data,
    // onSuccess: (res: any) => {
    //   const data = res?.data;
    //   //toast.success(data.message);
    // },
    // onError: (err: any) => {
    //   const errData = err?.data;
    //   //toast.error(errData.message);
    // },
    refetchOnWindowFocus: false,
  });
};

export const useAddCustomer = (isRedirect = false) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(customerApi.addCustomer, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([customer.CUSTOMERS]);
      queryClient.invalidateQueries([customer.CUSTOMERSFULL]);
      queryClient.invalidateQueries([customer.CUSTOMERS_COMPLETE]);
      const data = res;
      navigate(`/customer-Order`, { state: { activeTab: '1' } });
    },
    onError: (err: PartErrI) => {
      return err;
    },
  });
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(customerApi.updateCustomer, {
    onSuccess: (res, ids) => {
      const data = res.data;
      queryClient.invalidateQueries([customer.CUSTOMERS]);
      queryClient.invalidateQueries([customer.CUSTOMERS_CONFIRM]);
      queryClient.invalidateQueries([customer.CUSTOMERSFULL]);
      queryClient.invalidateQueries([customer.CUSTOMERS_COMPLETE]);
      if (data?.workOrder?.id) {
        navigate(`/new-work-order/${data?.workOrder?.id}`, { state: { activeTab: '1' } });
      } else {
        navigate('/customer-Order');
      }
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};
export const useUpdateCustomerApprove = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(customerApi.updateCustomerApprove, {
    onSuccess: (res, id) => {
      const data = res;
      queryClient.invalidateQueries([customer.CUSTOMERS]);
      queryClient.invalidateQueries([customer.CUSTOMER, id]);
      navigate(`/customer-Order`, { state: { activeTab: '1' } });
    },
    onError: (err: any) => {
      return err;
      // const errData = err?.data;
      //toast.error(errData.message);
    },
  });
};

// export const useGetFullAccssory = (isEnabled) => {
//   return useQuery([accessory.ACCESSORYS + 'FULL'], accessoryApi.getFullAccessory, {
//     // initialData: { suppliers: [] },
//     enabled: isEnabled,
//     select: (res: any) => res?.data,
//     onSuccess: () => {
//       // const data = res?.data;
//     },
//     // onError: () => {
//     //   // const errData = err?.data;
//     //   // toast.error(errData.message);
//     // },
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetCustomerSingle = (id, isEnable = true) => {
  return useQuery([customer.CUSTOMER, id], customerApi.getSingleCustomer, {
    enabled: isEnable,
    // onSuccess: (res) => {
    //   // const data = res?.data;
    // },
    onError: (err: PartErrI) => {
      return err;
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteCustomer = (isPart = true) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(customerApi.deleteCustomer, {
    onSuccess: (res, id) => {
      queryClient.invalidateQueries([customer.CUSTOMER]);
      queryClient.removeQueries([customer.CUSTOMER, id]);
      navigate(`/customer-Order`);
    },
  });
};
