import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import CustomModalButton from '../pages/commonComponents/CustomModalButton';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import './../assets/css/modal.css';
import { Grid } from '@mui/material';
import CustomSubTitle from '../pages/commonComponents/CustomSubTitle';
import { useAddCustomer, useUpdateCustomer } from '../hooks/cusromerHooks';
import ErrorPopup from './ErrorPopup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 578,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  '.tool-box': {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0px',
    margin: '20px !important',
  },
  '.checklistItems-children': {
    overflow: 'auto',
    height: '200px',
  },
};

const CustomTypography = styled(Typography)(() => ({
  color: 'gray',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

interface partI {
  isOpen: boolean;
  handleClose: () => void;
  partData: any;
}
const CustomerOrderConfirmModel = (props: partI) => {
  const { isOpen, handleClose, partData } = props;
  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const handleErrorModalClose = () => setIsErrorModalOpen(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {
    mutate: addCustomer,
    error: addCustomerOrderError,
    isError: isAddCustomerOrderError,
  } = useAddCustomer();
  const {
    mutate: updateCustomer,
    error: updateCustomerOrderError,
    isError: isUpdateCustomerOrderError,
  } = useUpdateCustomer();

  React.useEffect(() => {
    if (isAddCustomerOrderError || isUpdateCustomerOrderError) {
      isAddCustomerOrderError
        ? setErrorMessage(addCustomerOrderError?.data?.message)
        : setErrorMessage(updateCustomerOrderError?.data?.message);
      setIsErrorModalOpen(true);
    }
  }, [addCustomerOrderError, updateCustomerOrderError]);

  interface IUser {
    name?: string;
    orderName?: string;
    remarks?: string;
    productDetails?: any;
    id?: string;
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby=''
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box>
          <Box className='title-head-box'>
            <Typography className='top-up-name'>Please confirm the New Customer Order</Typography>
          </Box>
          <Box className='modal-body'>
            <Box className='section-one'>
              <CustomSubTitle>Basic Details</CustomSubTitle>
              <Grid container item xs={10} spacing={2} lg={12}>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Customer Name</CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'name', '')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Customer Order Name </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'orderName', '-')}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={6} lg={4}>
                  <CustomTypography>Remarks </CustomTypography>
                  <Typography className='span-color pl-sub-text'>
                    {_.get(partData, 'remarks', '-')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className='section-one'>
              <Box
                sx={{
                  paddingTop: '20px',
                }}
              >
                <CustomSubTitle>Checklist Items</CustomSubTitle>
              </Box>
              <Box className='checklistItems-children'>
                {partData?.productDetails?.map((item, ind) => {
                  return (
                    <>
                      <Grid container item xs={10} spacing={2} key={ind} lg={12}>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Product</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {_.get(item, 'product', '')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={6} lg={3}>
                          <CustomTypography>Quantity</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {_.get(item, 'quantity', '-')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={6} lg={4}>
                          <CustomTypography>Delivery date</CustomTypography>
                          <Typography className='span-color pl-sub-text'>
                            {item?.deliveryDateRequested
                              ? moment(item?.deliveryDateRequested)?.format('hh:MM a, DD MMM, YYYY')
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className='tool-box'>
            <CustomModalButton
              variant='outlined'
              className='blue-color edit-btn'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CustomModalButton>
            <CustomModalButton
              variant='contained'
              type='submit'
              className='blue-bg ml-20'
              onClick={() => {
                id ? updateCustomer({ ...partData, id }) : addCustomer({ ...partData });
              }}
            >
              Confirm & Save
            </CustomModalButton>
          </Box>
        </Box>
        {isErrorModalOpen && (
          <ErrorPopup
            isOpen={isErrorModalOpen}
            handleClose={handleErrorModalClose}
            errorDetails={errorMessage}
          />
        )}
      </Box>
    </Modal>
  );
};
export default CustomerOrderConfirmModel;
