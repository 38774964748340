import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import { useLocation, useParams } from 'react-router-dom';
import {
  useDeletePart,
  useGetPart,
  useHardDeletePart,
  useRestorePart,
} from '../../hooks/partHooks';
import _ from 'lodash';
import Loading from 'react-fullscreen-loading';
import './../../assets/css/detailsInformationBox.css';
import { useGetDeletedFinishedGoods } from '../../hooks/finishedGoodsHooks';

const TextFiled = styled(Typography)(() => ({
  color: 'gray',
  paddingTop: '10px',
  fontSize: '14px !important',
  padding: '24px 0px 0px 24px',
}));

const HeadBoxWrapper = styled(Box)(() => ({}));

const RawMaterialsDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDeleted = location.pathname.startsWith('/deleted-items');
  const isRestore = location.pathname.startsWith('/deleted-items');
  const {
    data: getRawMaterial,
    isSuccess: isSuccess,
    isError,
  } = useGetPart(id, !!id && !isDeleted);
  const { data: getRawMaterialDeleted, isSuccess: isSuccessDeleted } = useGetDeletedFinishedGoods(
    id,
    !!id && isDeleted
  );
  const singleRawMaterial = isDeleted ? getRawMaterialDeleted?.part : getRawMaterial?.part;
  const { mutate: deleteSinglePart } = useDeletePart();
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: restorePart } = useRestorePart();

  const handlePartDelete = () => {
    if (isDeleted) {
      hardDeletePart(id);
    } else {
      deleteSinglePart(id);
    }
  };

  const handlePartRestore = () => {
    if (isRestore) {
      restorePart(id);
    } else {
      // nothing to restore
    }
  };

  const rowMaterial =
    location.pathname.startsWith('/raw-material-details') ||
    location.pathname.startsWith('/deleted-items/raw-material-details/');

  let breadCrumbData = [
    {
      title: 'Catalogue',
      link: '/tools',
    },
    {
      title: rowMaterial ? 'raw material' : 'part',
      link: '/raw-materials',
      activeTab: '4',
    },
    {
      title: _.get(singleRawMaterial, 'name', ''),
      link: ``,
    },
  ];

  if (isDeleted) {
    breadCrumbData = [
      {
        title: 'Deleted Items',
        link: '/deleted-items',
      },
      {
        title: _.get(singleRawMaterial, 'name', ''),
        link: ``,
      },
    ];
  }

  const detailPageData = [
    {
      title: 'edit',
      link: `/edit-raw-material/${id}`,
    },
    {
      title: 'delete',
      link: '',
    },
  ];

  return (
    <>
      <Box>
        <BreadcrumbNavigation
          breadCrumbData={breadCrumbData}
          pageTitle={rowMaterial ? _.get(singleRawMaterial, 'name', '') : 'part Name'}
          backToLink='/raw-materials'
          activeTab='4'
          isDetailPage={true}
          detailPageData={detailPageData}
          handleDelete={handlePartDelete}
          isDeleted={isDeleted}
          isRestore={isRestore}
          handleRestore={handlePartRestore}
        />
      </Box>
      <HeadBoxWrapper className='main-padding'>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Basic Details
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    {' '}
                    <TextFiled>Raw Material Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'name', '')}
                    </Typography>
                    <TextFiled>Supplier Part Number </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'supplierPartNumber', '')}
                    </Typography>
                    {/* <TextFiled>
              Procurement Time:{' '}
              <Typography className='span-color'>
                {singleRawMaterial?.procurementTime
                  ? moment(singleRawMaterial?.procurementTime).format('HH:mm:ss')
                  : '-'}
              </Typography>
            </TextFiled> */}
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>Raw Material Number </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'number', '')}
                    </Typography>
                    <TextFiled>Quantity </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial, 'avlStock', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className='details-box-border padding-bottom'>
                <Typography id='modal-modal-title' className='details-sub-title'>
                  Supplier Details
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <TextFiled>Supplier Name </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial?.supplier, 'name', '')}
                    </Typography>
                    <TextFiled>Supplier Code </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial?.supplier, 'code', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFiled>Supplier Contact </TextFiled>
                    <Typography className='span-color pl-sub-text'>
                      {_.get(singleRawMaterial?.supplier, 'contact', '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* <Divider /> */}
        </Box>
        <Loading
          loading={
            id && (isDeleted ? !isSuccessDeleted : !isSuccess) ? (!isError ? true : false) : false
          }
          background='white'
          loaderColor='#265AA8'
        />
      </HeadBoxWrapper>
    </>
  );
};
export default RawMaterialsDetails;
