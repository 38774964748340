import { deleteRequest, get, patch, post } from '../Utils/index';

export const getRawMaterials = async (
  data
): Promise<any | undefined | { data: { results: [] } } | any> => {
  const page = data?.queryKey[1] || 1;
  const payload = { page, limit: 10 };
  return get(`/customer/raw-material`, payload).then((res) => {
    return res.data;
  });
};
export const getFullRawMaterials = async () => {
  return get(`/customer/raw-material/full-list`);
};

export const addRawMaterials = async (data): Promise<any> => {
  delete data['supplierId'];
  return post(`/customer/raw-material`, data).then((res) => res.data);
};

export const updateRawMaterials = async (data): Promise<any> => {
  const id = data?.id;
  delete data['id'];
  delete data['supplierId'];
  return patch(`/customer/raw-material/${id}`, data).then((res) => res.data);
};

export const getSingleRawMaterials = async (data): Promise<any | any> => {
  const id = data?.queryKey[1];
  return get(`/customer/raw-material/${id}`).then((res) => res.data);
};

export const deleteRawMaterial = async (id) => {
  return deleteRequest(`/customer/raw-material/${id}`);
};

export const getSingleDeletedRawMaterial = async (data) => {
  const id = data?.queryKey[1];
  return get(`/customer/raw-material/deleted/${id}`);
};

export const hardDeleteRawMaterial = async (id) => {
  return deleteRequest(`/customer/raw-material/hard-delete/${id}`);
};

export const restoreRawMaterial = async (id) => {
  return patch(`/customer/raw-material/restore/${id}`);
};
export const validateRawMaterialName = async (data) => {
  delete data['scheduleData'];
  return post(`/customer/raw-material/validate`, data);
};

export const rawMaterialApi = {
  getRawMaterials,
  getFullRawMaterials,
  addRawMaterials,
  updateRawMaterials,
  getSingleRawMaterials,
  deleteRawMaterial,
  getSingleDeletedRawMaterial,
  hardDeleteRawMaterial,
  restoreRawMaterial,
  validateRawMaterialName,
};
